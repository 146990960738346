export const updateTokens = (accessToken, refreshToken) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  window.dispatchEvent(
    new CustomEvent('token-updated', {
      detail: { accessToken, refreshToken },
    }),
  );
};

export const clearTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  window.dispatchEvent(new CustomEvent('token-cleared'));
};
