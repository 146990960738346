/* eslint-disable */
import moment from 'moment-timezone';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { adults, babies, boys } from '../constants/typeOfPersons';
import routes from '../routes/routes';
import {
  endDateGraterThanStartDate,
  startDateAndEndDateAreValid,
} from './checkValidURLParams';
import { useReservationDispatch, useReservationState } from './Reservation';

// eslint-disable-next-line no-useless-escape
const dateRegex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
const dateRegexDateFormat =
  /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/;

const withUrlParams = (Component) =>
  function WithUrlParams(props) {
    const dispatch = useReservationDispatch();
    const {
      readyToBeUsed,
      startDate,
      endDate,
      adults: adultsState,
      children: childrenState,
      babies: babiesState,
    } = useReservationState();
    const searchParams = queryString.parse(props.location.search);
    const { search } = window.location;
    const searchParamsFromURL = new URLSearchParams(search);
    const url = new URL(window.location.href);

    useEffect(() => {
      const thereAreMissingFields = (objectToReview) => {
        if (objectToReview.defaultPropertyID) {
          dispatch({
            type: 'setOnlyDefaultPropertyID',
            payload: {
              defaultPropertyID: objectToReview.defaultPropertyID.toString(),
            },
          });
        }
        if (objectToReview.promoCode) {
          dispatch({
            type: 'setOnlyPromoCode',
            payload: {
              promoCode: objectToReview.promoCode.toString(),
            },
          });
        }

        if (props.location.pathname === routes.home) {
          if (parseInt(objectToReview.babies, 10) > 1) {
            const maxAllowedBabiesPerAdult = Math.round(
              (parseInt(objectToReview.adults, 10) + parseInt(objectToReview.children, 10)) / 2,
            );
            if (
              parseInt(objectToReview.babies, 10) > maxAllowedBabiesPerAdult
            ) {
              url.searchParams.set('babies', maxAllowedBabiesPerAdult);
              window.location.href = url.toString();
            }
          }
        }
        if (props.location.pathname === routes.places) {
          // Check is zone exists
          if (!objectToReview.zone) return true;
          if (
            !objectToReview.checkin ||
            (!dateRegex.test(objectToReview.checkin) &&
              !dateRegexDateFormat.test(objectToReview.checkin))
          ) {
            url.searchParams.set(
              'checkin',
              moment(startDate).format('YYYY-MM-DD'),
            );
          }
          if (
            !objectToReview.checkout ||
            (!dateRegex.test(objectToReview.checkout) &&
              !dateRegexDateFormat.test(objectToReview.checkout))
          ) {
            url.searchParams.set(
              'checkout',
              moment(endDate).format('YYYY-MM-DD'),
            );
          }
          if (
            !objectToReview.adults ||
            parseInt(objectToReview.adults, 10) < 1
          ) {
            url.searchParams.set('adults', adultsState);
          }
          if (
            !objectToReview.children ||
            parseInt(objectToReview.children, 10) < 0
          ) {
            url.searchParams.set('children', childrenState);
          }
          if (
            !objectToReview.babies ||
            parseInt(objectToReview.babies, 10) < 0
          ) {
            url.searchParams.set('babies', babiesState);
          }
          if (parseInt(objectToReview.babies, 10) > 1) {
            const maxAllowedBabiesPerAdult = Math.round(
            (parseInt(objectToReview.adults, 10) + parseInt(objectToReview.children, 10)) / 2,
            );
            if (
              parseInt(objectToReview.babies, 10) > maxAllowedBabiesPerAdult
            ) {
              url.searchParams.set('babies', maxAllowedBabiesPerAdult);
              window.location.href = url.toString();
            }
          }
          if (window.location.href !== url.toString())
            window.location.href = url.toString();
        } else if (
          !objectToReview.checkin ||
          (!dateRegex.test(objectToReview.checkin) &&
            !dateRegexDateFormat.test(objectToReview.checkin)) ||
          !objectToReview.checkout ||
          (!dateRegex.test(objectToReview.checkout) &&
            !dateRegexDateFormat.test(objectToReview.checkout)) ||
          (objectToReview.adults && parseInt(objectToReview.adults, 10) < 1) ||
          (objectToReview.children &&
            parseInt(objectToReview.children, 10) < 0) ||
          (objectToReview.babies && parseInt(objectToReview.babies, 10) < 0)
        ) {
          return true;
        }

        return false;
      };

      if (!readyToBeUsed) {
        if (thereAreMissingFields(searchParams)) {
          if (props.location.pathname === routes.home) return;
          window.location.href = routes.home;
        }

        const actualDate = moment();
        const urlStartDate = searchParams.checkin
          ? moment(searchParams.checkin, 'DD-MM-YYYY')
          : null;
        const urlEndDate = searchParams.checkout
          ? moment(searchParams.checkout, 'DD-MM-YYYY')
          : null;
        const isOldDate =
          urlStartDate.isValid() &&
          urlEndDate.isValid() &&
          (urlStartDate < actualDate || urlEndDate < actualDate);
        const correctRange = !isOldDate && urlStartDate < urlEndDate;

        const payload = {
          startDate: correctRange
            ? moment(searchParams.checkin, 'DD-MM-YYYY')
                .hour(12)
                .minutes(0)
                .seconds(0)
            : moment().hour(12).minutes(0),
          endDate: correctRange
            ? moment(searchParams.checkout, 'DD-MM-YYYY')
                .hour(12)
                .minutes(0)
                .seconds(0)
            : moment().add(2, 'days').hour(12).minutes(0),
          [adults]: searchParams.adults ? parseInt(searchParams.adults, 10) : 2,
          [boys]: searchParams.children
            ? parseInt(searchParams.children, 10)
            : 0,
          [babies]: searchParams.babies ? parseInt(searchParams.babies, 10) : 0,
        };

        dispatch({
          type: 'setAllRelevantDataAndReadyToUse',
          payload,
        });
      }
    }, [
      dispatch,
      props.location.pathname,
      props.location.search,
      props.navigate,
      readyToBeUsed,
      props,
      searchParams,
      adultsState,
      babiesState,
      childrenState,
      endDate,
      startDate,
      url,
    ]);

    const startDateFromURL = searchParamsFromURL.get('checkin');
    const endDateFromURL = searchParamsFromURL.get('checkout');
    const adultsFromURL = parseInt(searchParamsFromURL.get('adults'), 10);
    const childrenFromURL = parseInt(searchParamsFromURL.get('children'), 10);
    const babiesFromURL = parseInt(searchParamsFromURL.get('babies'), 10);

    const formattedStartDateFromURL = startDateFromURL
      ? moment(startDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(
          'YYYY-MM-DD',
        )
      : null;
    const formattedEndDateFromURL = endDateFromURL
      ? moment(endDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(
          'YYYY-MM-DD',
        )
      : null;

    // eslint-disable-next-line max-len
    const maxCapacityIsOverreached = useCallback(
      () =>
        adultsFromURL >= 1 &&
        adultsFromURL + childrenFromURL <= 6 &&
        babiesFromURL <= 3,
      [adultsFromURL, childrenFromURL, babiesFromURL],
    );

    const updateUrlWithoutNavigation = useCallback((newUrl) => {
      const currentState = window.history.state;
      const currentTitle = document.title;
      window.history.replaceState(currentState, currentTitle, newUrl);
    }, []);

    useEffect(() => {
      // If dates are valid and endDate is greater than startDate
      if (
        startDateAndEndDateAreValid(
          formattedStartDateFromURL,
          formattedEndDateFromURL,
        ) &&
        endDateGraterThanStartDate(
          formattedStartDateFromURL,
          formattedEndDateFromURL,
        )
      ) {
        dispatch({
          type: 'setStartDate',
          payload: moment(formattedStartDateFromURL),
        });
        dispatch({
          type: 'setEndDate',
          payload: moment(formattedEndDateFromURL),
        });
      }

      // If dates are valid and endDate is not greater than startDate
      if (
        startDateAndEndDateAreValid(
          formattedStartDateFromURL,
          formattedEndDateFromURL,
        ) &&
        !endDateGraterThanStartDate(
          formattedStartDateFromURL,
          formattedEndDateFromURL,
        )
      ) {
        dispatch({
          type: 'setStartDate',
          payload: moment(formattedStartDateFromURL),
        });
        dispatch({
          type: 'setEndDate',
          payload: moment(formattedStartDateFromURL).add(2, 'days'),
        });

        url.searchParams.set(
          'checkout',
          moment(formattedStartDateFromURL).add(2, 'days').format('YYYY-MM-DD'),
        );
        updateUrlWithoutNavigation(url.toString());
      }
      // If endDate does not exist on queryParmas but startDate exists
      if (formattedStartDateFromURL && !formattedEndDateFromURL) {
        dispatch({
          type: 'setStartDate',
          payload: moment(formattedStartDateFromURL),
        });
        dispatch({
          type: 'setEndDate',
          payload: moment(formattedStartDateFromURL).add(2, 'days'),
        });
      }

      // If startDate does not exist on queryParmas but endDate exists
      if (!formattedStartDateFromURL && formattedEndDateFromURL) {
        dispatch({
          type: 'setStartDate',
          payload: moment(formattedEndDateFromURL).add(-2, 'days'),
        });
        dispatch({
          type: 'setEndDate',
          payload: moment(formattedEndDateFromURL),
        });
      }

      // Check if valid numbers
      if (
        !Number.isNaN(adultsFromURL) &&
        !Number.isNaN(childrenFromURL) &&
        !Number.isNaN(babiesFromURL)
      ) {
        if (!maxCapacityIsOverreached()) {
          dispatch({ type: 'setadults', payload: 1 });
          dispatch({ type: 'setchildren', payload: 0 });
          dispatch({ type: 'setbabies', payload: 0 });
        } else {
          dispatch({ type: 'setadults', payload: parseInt(adultsFromURL, 10) });
          dispatch({
            type: 'setchildren',
            payload: parseInt(childrenFromURL, 10),
          });
          dispatch({ type: 'setbabies', payload: parseInt(babiesFromURL, 10) });
        }
        dispatch({ type: 'setItAsReadyToBeUsed' });
      }
    }, [
      dispatch,
      formattedStartDateFromURL,
      formattedEndDateFromURL,
      adultsFromURL,
      childrenFromURL,
      babiesFromURL,
      maxCapacityIsOverreached,
      updateUrlWithoutNavigation,
    ]);

    return <Component {...props} />;
  };

export default withUrlParams;
