import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { petWrapper, notPetWrapper } from './index.module.scss';
import { ReactComponent as PetsIcon } from '../../assets/icons/pets_icon.svg';
import { ReactComponent as NoPetsIcon } from '../../assets/icons/icon_no_pets_white.svg';

function PetsBanner({ warning }) {
  const intl = useIntl();
  const typeBanner = warning ? petWrapper : notPetWrapper;

  return (
    <div className={typeBanner}>
      {!warning ? <NoPetsIcon /> : <PetsIcon />}
      <div>
        {warning ? (
          <>
            <p>
              <strong>{intl.formatMessage({ id: 'DetailsPage.Pets.Text1' })}</strong>
            </p>
            <p>
              <strong>{intl.formatMessage({ id: 'DetailsPage.Pets.Text2' })}</strong>
            </p>
          </>
        ) : (
          <>
            <p>
              <strong>{intl.formatMessage({ id: 'DetailsPage.PetsNotAvailable.Text1' })}</strong>
            </p>
            <p>
              {intl.formatMessage({ id: 'DetailsPage.PetsNotAvailable.Text2' })}
            </p>
          </>
        )}

      </div>
    </div>
  );
}

PetsBanner.defaultProps = {
  warning: false,
};

PetsBanner.propTypes = {
  warning: PropTypes.bool,
};

export default PetsBanner;
