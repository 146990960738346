import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import makeRequestToApServer from '../../../../../services/ApServer/askToApServer';
import { allowedMethods } from '../../../../../constants/ApServer/apServer';
import routes from '../../../../../constants/ApServer/apRoutes';
import openNotification from '../../../../../components/NotificacionMessage';
import { form, formRow, formActions } from './index.module.scss';

const ResetPasswordForm = ({ onSave, email, setIsLoading, isLoading }) => {
  const intl = useIntl();

  const onResetPassword = useCallback(() => {
    setIsLoading(true);
    makeRequestToApServer(
      allowedMethods.post,
      `${routes.authPasswordReset}`,
      { email },
      ({ statusCode }) => {
        setIsLoading(false);
        if (statusCode === 200)
          openNotification(
            'success',
            intl.formatMessage({
              id: 'ResetPasswordForm.Request.Success.Text',
            }),
            intl.formatMessage({
              id: 'ResetPasswordForm.Request.Success.Message.Text',
            }),
            () => {
              onSave();
            },
            null,
          );
      },
      (err) => {
        setIsLoading(false);
        openNotification(
          'error',
          intl.formatMessage({
            id: 'PrivateArea.Request.Error.Default.Text',
          }),
          err?.response?.data?.message,
          () => {},
          null,
        );
      },
    );
  }, [onSave, email, intl, setIsLoading]);

  return (
    <>
      <div className={form}>
        <div className={formRow}>
          <span>
            {intl.formatMessage({
              id: 'ResetPasswordForm.Information.Text',
            })}
          </span>
        </div>
      </div>
      <div className={formActions}>
        <button type="submit" onClick={onResetPassword} disabled={isLoading}>
          {intl.formatMessage({
            id: 'ResetPasswordForm.Button.Text',
          })}
        </button>
      </div>
    </>
  );
};

ResetPasswordForm.defaultProps = {
  email: '',
};

ResetPasswordForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  email: PropTypes.string,
  setIsLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ResetPasswordForm;
