import React from 'react';
import { PropTypes } from 'prop-types';
import { wrapper } from './index.module.scss';

const Tag = ({ children, className }) => (
  <div className={`${wrapper} ${className}`}>
    <span>
      {children}
    </span>
  </div>
);

Tag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

Tag.defaultProps = {
  className: '',
};
export default Tag;
