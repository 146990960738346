import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Services from "../../constants/services";
import * as typesOfPerson from "../../constants/typeOfPersons";
import PersonCounter from "../PersonCounter";
import ModalForGroups from "./components/ModalForGroups";
import { wrapper } from "./index.module.scss";
// import ModalForSingleAdult from './components/ModalForSingleAdult';
import PetFilter from "../PetFilter";
import ModalForBabies from "./components/ModalForBabies";
import ModalForPaxLimit from "./components/ModalForPaxLimit";

const Counter = ({
  typeOfPerson,
  showLines,
  onClickAdd,
  onClickLess,
  count,
  service,
  disabledMinus,
  disabledPlus,
}) => (
  <PersonCounter
    typeOfPerson={typeOfPerson}
    onClickAdd={() => onClickAdd(typeOfPerson)}
    onClickLess={() => onClickLess(typeOfPerson)}
    count={count}
    disabledMinus={disabledMinus}
    disabledPlus={disabledPlus}
    showLines={showLines}
    kampaohService={service}
  />
);

Counter.defaultProps = {
  typeOfPerson: typesOfPerson.adults,
  disabledMinus: PropTypes.bool,
  disabledPlus: PropTypes.bool,
  showLines: false,
  onClickAdd: () => {},
  onClickLess: () => {},
};

Counter.propTypes = {
  typeOfPerson: PropTypes.oneOf(Object.values(typesOfPerson)),
  disabledMinus: PropTypes.bool,
  disabledPlus: PropTypes.bool,
  showLines: PropTypes.bool,
  onClickAdd: PropTypes.func,
  onClickLess: PropTypes.func,
  service: PropTypes.oneOf(Object.values(Services)).isRequired,
  count: PropTypes.number.isRequired,
};

const Counters = ({
  disabled,
  showBackground,
  showLines,
  onChange,
  values,
  service,
  maxCount,
  minCount,
  fpaFilter,
  setFpaFilter,
  petsAllowed,
}) => {
  const [lastTypeChanged, setLastTypeChanged] = useState();
  const [counters, setCounters] = useState(values);
  const [showModalForGroups, setShowModalForGroups] = useState(false);
  // const [showModalForSingleAdult, setShowModalForSingleAdult] = useState(false);
  const [showModalForPaxLimit, setShowModalForPaxLimit] = useState(false);
  const [showModalForBabies, setShowModalForBabies] = useState(false);

  const handleOnClickAdd = (type) => {
    setLastTypeChanged(type);
    setCounters((prev) => ({ ...prev, [type]: prev[type] + 1 }));
    onChange({ ...counters, [type]: counters[type] + 1 });
  };

  const handleOnClickLess = (type) => {
    // eslint-disable-next-line max-len
    const maxBabies =
      counters.adults + counters.children - 1 > 1
        ? Math.round((counters.adults + counters.children - 1) / 2)
        : Math.round((counters.adults + counters.children) / 2);
    if (
      (type === "children" || type === "adults") &&
      maxBabies <= counters.babies
    ) {
      setCounters((prev) => ({ ...prev, babies: maxBabies }));
      onChange({ ...counters, babies: maxBabies });
    }

    setLastTypeChanged(type);
    setCounters((prev) => ({ ...prev, [type]: prev[type] - 1 }));
    onChange({ ...counters, [type]: counters[type] - 1 });
  };
  const handleModalForGroupsClosing = () => {
    setCounters((prev) => ({
      ...prev,
      [lastTypeChanged]: prev[lastTypeChanged] - 1,
    }));
    setShowModalForGroups(false);
    onChange({ ...counters, [lastTypeChanged]: counters[lastTypeChanged] - 1 });
  };

  const handleModalPaxLimitClosing = () => {
    setShowModalForPaxLimit(false);
  };

  const updateCounters = (change) => {
    setCounters((prev) => ({
      ...prev,
      [lastTypeChanged]: prev[lastTypeChanged] + change,
    }));
    onChange({
      ...counters,
      [lastTypeChanged]: counters[lastTypeChanged] + change,
    });
  };

  const handleModalForBabiesClosing = () => {
    const maxBabies =
      counters.adults + counters.children - 1 > 1
        ? Math.round((counters.adults + counters.children - 1) / 2)
        : Math.round((counters.adults + counters.children) / 2);
    if (lastTypeChanged === "children" || lastTypeChanged === "adults") {
      if (maxBabies <= counters.babies) {
        updateCounters(1);
      } else {
        updateCounters(-1);
      }
    }
    if (lastTypeChanged === "babies") {
      if (maxBabies <= counters.babies) {
        updateCounters(-1);
      } else {
        updateCounters(1);
      }
    }
    setShowModalForBabies(false);
  };

  const haveReachedTheMaxCount = (type) =>
    maxCount !== undefined &&
    type !== typesOfPerson.babies &&
    counters.adults + counters.children === maxCount;

  const haveReachedTheMinCount = (type) =>
    minCount !== undefined &&
    type !== typesOfPerson.babies &&
    type !== typesOfPerson.boys &&
    counters.adults === minCount;

  useEffect(() => {
    if (counters.adults + counters.children > 6) setShowModalForGroups(true);
    const maxAllowedBabiesPerAdult = Math.round(
      (counters.adults + counters.children) / 2,
    );
    if (counters.babies > maxAllowedBabiesPerAdult) {
      setShowModalForBabies(true);
    }
  }, [counters]);

  useEffect(() => {
    setCounters(values);
  }, [values]);

  return (
    <div className={showBackground ? wrapper : ""}>
      {Object.values(typesOfPerson).map((type) => (
        <Counter
          disabledMinus={disabled || haveReachedTheMinCount(type)}
          disabledPlus={
            disabled ||
            // || haveReachedTheBabiesNumberLimit(type)
            haveReachedTheMaxCount(type)
          }
          key={type}
          typeOfPerson={type}
          showLines={showLines}
          count={counters[type]}
          service={service}
          onClickAdd={handleOnClickAdd}
          onClickLess={handleOnClickLess}
        />
      ))}
      <PetFilter
        fpaFilter={fpaFilter}
        setFpaFilter={setFpaFilter}
        petsAllowed={petsAllowed}
      />
      <ModalForGroups
        visible={showModalForGroups}
        onOk={handleModalForGroupsClosing}
        onCancel={handleModalForGroupsClosing}
      />
      <ModalForBabies
        visible={showModalForBabies}
        onOk={handleModalForBabiesClosing}
        onCancel={handleModalForBabiesClosing}
        counterBabies={counters.babies}
        counterAdults={counters.adults}
        counterChildren={counters.children}
      />
      <ModalForPaxLimit
        visible={showModalForPaxLimit}
        onOk={handleModalPaxLimitClosing}
        onCancel={handleModalPaxLimitClosing}
      />
    </div>
  );
};

Counters.propTypes = {
  disabled: PropTypes.bool,
  showBackground: PropTypes.bool,
  showLines: PropTypes.bool,
  onChange: PropTypes.func,
  values: PropTypes.shape({
    adults: PropTypes.number,
    children: PropTypes.number,
    babies: PropTypes.number,
  }),
  service: PropTypes.oneOf(Object.values(Services)).isRequired,
  maxCount: PropTypes.number,
  minCount: PropTypes.number,
  fpaFilter: PropTypes.string,
  setFpaFilter: PropTypes.func,
  petsAllowed: PropTypes.bool,
};

Counters.defaultProps = {
  disabled: false,
  showBackground: true,
  showLines: false,
  onChange: () => {},
  values: {
    adults: 1,
    children: 0,
    babies: "0",
  },
  maxCount: undefined,
  minCount: 1,
  fpaFilter: "none",
  setFpaFilter: () => {},
  petsAllowed: undefined,
};

export { Counter };
export default Counters;
