import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CustomModal from '../../../CustomModal';

const ModalForBabies = ({
  visible, onOk, onCancel, counterBabies, counterAdults, counterChildren,
}) => (
  <CustomModal
    visible={visible}
    onOk={onOk}
    onCancel={onCancel}
  >
    <>
      <FormattedMessage id="ModalForBabies.title" values={{ counterBabies: counterBabies - 1 }} tagName="h1" />
      <FormattedMessage id="ModalForBabies.description" values={{ adults: 2 * (counterBabies) - 1, beds: 2 * (counterBabies) ? Math.round(((counterAdults + counterChildren) + 2) / 2) : Math.round(((counterAdults + counterChildren) + 2) / 2), babies: counterBabies }} tagName="p" />
    </>
  </CustomModal>
);
ModalForBabies.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  counterBabies: PropTypes.number.isRequired,
  counterAdults: PropTypes.number.isRequired,
  counterChildren: PropTypes.number.isRequired,
};

ModalForBabies.defaultProps = {
  visible: false,
  onOk: () => {},
  onCancel: () => {},
};

export default ModalForBabies;
