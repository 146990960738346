import { gql } from '@apollo/client';

const tents = gql`
  query GetTents($filters: [String], $lang: I18NLocaleCode!) {
    tents (pagination: { limit: -1 }, filters: {camping: {ulysesId: {in: $filters}}}, locale: $lang) {
      data{
        id,
        attributes {
          name,
          roomTypeId,
          pets,
          privateBathroom,
          featuredImage{
            data{
              id,
              attributes{
                formats,
                url,
              }
            }
          }
          camping{
            data{
              id,
              attributes{
                campingName,
                ulysesId,
                propertyName,
                campings_related{
                  data{
                    id,
                    attributes{
                      ulysesId,
                      propertyName,
                      campingName
                    }
                  }
                }
              }
            }
          },
          tent_model{
            data{
              id,
              attributes{
                name,
                area,
                height,
                maxGuests,
              }
            }
          }
        }
      }
    }
  }
`;

export default tents;
