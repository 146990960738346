import calcDaysBetweenCheckInAndCheckOut from '../../utils/daysBetweenCheckInAndCheckOut';

const thereAreRoomsAvailable = (rate) => rate.roomsAvailable > 0;

const isAvailableRatePlan = (rate) => rate.available;

const priceIsNotZero = (rate) => {
  const hasZeroEurNight = rate.roomRateDetailed.find((room) => room.rate === 0);
  return rate.totalRate > 0 && !hasZeroEurNight;
};

const ratePlanNamePrivateNotContainsDollarsOrNone = (rate) => (
  rate.ratePlanNamePrivate ? (!(rate.ratePlanNamePrivate.includes('$$$$') || rate.ratePlanNamePrivate.includes('##NONE##'))) : true
);

const ratePlanNamePrivateNotContainsUniqueAndIsNotAvailable = (rate) => (
  rate.ratePlanNamePrivate ? (!(rate.ratePlanNamePrivate.includes('##UNIQUE##') && !rate.roomsAvailable)) : true
);

const failAtMinLos = (rate, endDate, startDate) => (
  parseInt(Math.max.apply(null, rate.roomRateDetailed.map((room) => room.minLos)), 10)
  <= parseInt(calcDaysBetweenCheckInAndCheckOut({ endDate, startDate }), 10)
);

const failAtMaxLos = (rate, endDate, startDate) => (
  parseInt(Math.min.apply(null, rate.roomRateDetailed.map((room) => room.maxLos)), 10) > 0
    ? (parseInt(Math.min.apply(null, rate.roomRateDetailed.map((room) => room.maxLos)), 10)
        >= parseInt(calcDaysBetweenCheckInAndCheckOut({ endDate, startDate }), 10))
    : true
);

const notClosedToArrivalOrDeparture = (rate) => (
  rate.roomRateDetailed[0].closedToArrival === false
    && rate.roomRateDetailed[rate.roomRateDetailed.length - 1].closedToDeparture === false
);

const checkAvailableRatePlan = (ratePlan, endDate, startDate) => {
  const isAvailable = ratePlan.filter((element) => (
    thereAreRoomsAvailable(element)
    && isAvailableRatePlan(element)
    && priceIsNotZero(element)
    && ratePlanNamePrivateNotContainsDollarsOrNone(element)
    && ratePlanNamePrivateNotContainsUniqueAndIsNotAvailable(element)
    && failAtMinLos(element, endDate, startDate)
    && failAtMaxLos(element, endDate, startDate)
    && notClosedToArrivalOrDeparture(element)
  ));
  return isAvailable;
};

export default checkAvailableRatePlan;
