import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import { Spin } from 'antd';
import {
  docPDFStyle,
  navPDFStyle,
  SpinnerStyle,
} from './index.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// https://gist.github.com/wojtekmaj/f265f55e89ccb4ce70fbd2f8c7b1d95d

const PdfLoader = ({ document }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // eslint-disable-next-line no-shadow
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <Document
        file={document}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={(
          <Spin
            size="large"
            className={SpinnerStyle}
          />
        )}
        className={docPDFStyle}
        width="100%"
      >
        <Page pageNumber={pageNumber} />
      </Document>
      {numPages && (
      <div className={navPDFStyle}>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          {'<'}
        </button>
        <p>
          {pageNumber || (numPages ? 1 : '--')}
          {' '}
          -
          {' '}
          {numPages || '--'}
        </p>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          {'>'}
        </button>
      </div>
      )}
    </>
  );
};

PdfLoader.defaultProps = {
  document: null,
};

PdfLoader.propTypes = {
  document: PropTypes.string,
};

export default PdfLoader;
