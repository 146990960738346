import { jwtDecode } from 'jwt-decode';
import { updateTokens, clearTokens } from '../../utils/tokenHelper';
import makeRequestToApServer from './askToApServer';
import { allowedMethods } from '../../constants/ApServer/apServer';
import { onRefreshed, setRefreshing } from '../../interceptors/tokenManager';
import routes from '../../constants/ApServer/apRoutes';
import ROUTES from '../../routes/routes';

export const isAccessTokenExpired = () => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    return true;
  }

  try {
    const decodedToken = jwtDecode(accessToken);
    const expirationTime = decodedToken.exp;

    if (typeof expirationTime !== 'number' || Number.isNaN(expirationTime)) {
      return true;
    }

    const currentTime = Date.now() / 1000;

    return expirationTime < currentTime;
  } catch (error) {
    return true;
  }
};

export const isRefreshTokenExpired = () => {
  const refreshToken = localStorage.getItem('refreshToken');

  if (!refreshToken) {
    return true;
  }

  try {
    const decodedToken = jwtDecode(refreshToken);
    const expirationTime = decodedToken.exp;

    if (typeof expirationTime !== 'number' || Number.isNaN(expirationTime)) {
      return true;
    }

    const currentTime = Date.now() / 1000;

    return expirationTime < currentTime;
  } catch (error) {
    return true;
  }
};
export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  await makeRequestToApServer(
    allowedMethods.post,
    routes.authRefresh,
    { refresh_token: refreshToken },
    (response) => {
      /* eslint-disable camelcase */
      const { access_token, refresh_token } = response.data;

      setRefreshing(false);
      onRefreshed(access_token);
      updateTokens(access_token, refresh_token);
    },
    () => {
      clearTokens();
      window.location.href = ROUTES.auth;
    },
  );
};
