import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useCountries } from '../../../../../utils/useCountries';
import makeRequestToApServer from '../../../../../services/ApServer/askToApServer';
import { allowedMethods } from '../../../../../constants/ApServer/apServer';
import routes from '../../../../../constants/ApServer/apRoutes';
import openNotification from '../../../../../components/NotificacionMessage';
import {
  form,
  formRow,
  error,
  formActions,
  saveButton,
} from './index.module.scss';

const DirectionForm = ({ initialValues, onSave }) => {
  const countriesArray = useCountries();
  const intl = useIntl();
  const formik = useFormik({
    initialValues: {
      country: initialValues.country || '',
      direction: initialValues.direction || '',
      postalCode: initialValues.postalCode || '',
    },
    validationSchema: Yup.object({
      country: Yup.string().required(
        intl.formatMessage({
          id: 'DirectionForm.Validation.Country.Required',
        }),
      ),
      direction: Yup.string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'DirectionForm.Validation.Direction.Required',
          }),
        ),
      postalCode: Yup.string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'DirectionForm.Validation.PostalCode.Required',
          }),
        ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      makeRequestToApServer(
        allowedMethods.put,
        routes.authMe,
        values,
        ({ statusCode }) => {
          if (statusCode === 200)
            openNotification(
              'success',
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Title.Text',
              }),
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Message.Text',
              }),
              () => {
                onSave(values);
              },
              null,
            );
        },
        (err) => {
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      ).finally(() => {
        setSubmitting(false);
      });
    },
  });

  const sortedCountriesList = useMemo(
    () =>
      countriesArray
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((country) => (
          <option
            key={country.value}
            value={country.value}
            label={country.label}
          >
            {country.label}
          </option>
        )),
    [countriesArray],
  );
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={form}>
        <div className={formRow}>
          <span htmlFor="country">
            {intl.formatMessage({
              id: 'DirectionForm.Validation.Country.Text',
            })}
          </span>
          <select
            id="country"
            name="country"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.country}
          >
            <option
              value=""
              label={intl.formatMessage({
                id: 'DirectionForm.Validation.Country.Default',
              })}
            >
              {intl.formatMessage({
                id: 'DirectionForm.Validation.Country.Default',
              })}
            </option>
            {sortedCountriesList}
          </select>
          {formik.touched.country && formik.errors.country ? (
            <div className={error}>{formik.errors.country}</div>
          ) : null}
        </div>
        <div className={formRow}>
          <span htmlFor="direction">
            {intl.formatMessage({
              id: 'DirectionForm.Validation.Direction.Text',
            })}
          </span>
          <input
            type="text"
            id="direction"
            name="direction"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.direction}
          />
          {formik.touched.direction && formik.errors.direction ? (
            <div className={error}>{formik.errors.direction}</div>
          ) : null}
        </div>
        <div className={formRow}>
          <span htmlFor="postalCode">
            {intl.formatMessage({
              id: 'DirectionForm.Validation.PostalCode.Text',
            })}
          </span>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postalCode}
          />
          {formik.touched.postalCode && formik.errors.postalCode ? (
            <div className={error}>{formik.errors.postalCode}</div>
          ) : null}
        </div>
      </div>

      <div className={formActions}>
        <button
          type="submit"
          className={saveButton}
          disabled={formik.isSubmitting}
        >
          {intl.formatMessage({
            id: 'DirectionForm.Button.Text',
          })}
        </button>
      </div>
    </form>
  );
};

DirectionForm.propTypes = {
  initialValues: PropTypes.shape({
    country: PropTypes.string,
    direction: PropTypes.string,
    postalCode: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DirectionForm;
