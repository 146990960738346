/* eslint-disable max-len */
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Services from '../../constants/services';
import useCampings from '../../services/Campings/useCampings';
import { useFilterState } from '../../services/Filter';
import { useReservationState } from '../../services/Reservation';
import sorters from '../../constants/sorters';
import calcDaysBetweenCheckInAndCheckOut from '../../utils/daysBetweenCheckInAndCheckOut';
import checkAvailableRatePlan from './utils';

const SearchContext = React.createContext();

const SearchProvider = ({ children, type }) => {
  const {
    state: {
      campings,
      ratePlansAlreadyFetched: campingsRatePlansAlreadyFetched,
    },
  } = useCampings();

  const { activeCharacteristicsFilters, activeRegionsFilters, pricesToFilter } = useFilterState();
  const {
    endDate, startDate, defaultPropertyID,
  } = useReservationState();

  const [sorter, setSorter] = useState(sorters.RELEVANCY);
  const [filteredRatePlans, setFilteredRatePlans] = useState({});
  const [filteredStatus, setFilteredStatus] = useState({});
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const fpa = searchParams.get('fpa');

  const isCampService = () => type === Services.CAMP;
  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
    endDate: moment.utc(endDate),
    startDate: moment.utc(startDate),
  });

  const campingsWithRatePlansFetched = useCallback(() => Object.keys(campings).reduce((acc, key) => {
    if (campings[key].ratePlansAlreadyFetched && campings[key].ratePlans.length > 0) {
      acc[key] = campings[key];
    } else {
      // console.error('RatePlans have not yet been recovered');
      acc[key] = campings[key];
    }
    return acc;
  }, {}), [campings]);

  const getProperties = () => {
    const properties = campings;

    if (Services.CAMP === type) {
      return campingsWithRatePlansFetched();
    }
    return properties;
  };

  const properties = getProperties();
  const onlyIfHasFilteredCharacteristics = (propertyID) => {
    const placeFilterIds = properties[propertyID]?.features?.map((filter) => filter.id);
    const activeFiltersIds = activeCharacteristicsFilters?.map((filter) => filter.id);

    const regionsInPlaces = properties[propertyID]?.region?.id;
    const regionsActivatedInFilters = activeRegionsFilters?.map((t) => (t.id));

    if (regionsActivatedInFilters.length > 0) {
      return regionsActivatedInFilters.indexOf(regionsInPlaces) > -1
      && !activeFiltersIds.some((id) => placeFilterIds?.indexOf(id) === -1);
    }
    return !activeFiltersIds.some((id) => placeFilterIds?.indexOf(id) === -1);
  };

  const isInPriceRange = (campingRates) => {
    const filteredCampingRates = campingRates.filter((rate) => {
      const priceByNight = rate / daysBetweenCheckInAndCheckOut;
      return (priceByNight >= pricesToFilter[0] && priceByNight <= pricesToFilter[1]);
    });
    return filteredCampingRates;
  };

  const sortByPrice = (a, b, ascending) => {
    if (!a.length === 0 && !b.length === 0) {
      return 0;
    }

    if (a.length === 0) {
      return 1;
    }

    if (b.length === 0) {
      return -1;
    }

    if (ascending) {
      return Math.floor(Math.min(...a.map((priceDetail) => priceDetail.totalRate))) < Math
        .floor(Math.min(...b.map((priceDetail) => priceDetail.totalRate))) ? -1 : 1;
    }

    return Math.floor(Math.min(...a.map((priceDetail) => priceDetail.totalRate))) < Math
      .floor(Math.min(...b.map((priceDetail) => priceDetail.totalRate))) ? 1 : -1;
  };

  const filterPropertiesByTentsPrice = (propertyID) => {
    if (pricesToFilter.length === 0) {
      return true;
    }

    if (filteredRatePlans[propertyID] === undefined) {
      return true;
    }

    if (filteredRatePlans[propertyID].length === 0) {
      return true;
    }

    const campingRatesAfterFilter = isCampService()
      ? isInPriceRange(filteredRatePlans[propertyID]
        .map((priceDetail) => priceDetail.totalRate))
      : isInPriceRange([filteredRatePlans[propertyID].totalRate]);

    return campingRatesAfterFilter.length !== 0;
  };

  const filterByLocation = useCallback(
    () => (true),
    [],
  );

  const sortByOrder = (a, b) => {
    if (properties[a].order !== null && properties[b].order !== null) {
      if (properties[a].order - properties[b].order >= 0) return 1;
      return -1;
    }
    return 0;
  };

  const sortByOpenDates = (a, b) => {
    if (
      properties[a].openDates
        && properties[b].openDates
    ) {
      if ( // If a is closed and b open
        (moment(properties[a].openDates.from).add(-1, 'days') >= startDate
          && moment(properties[b].openDates.from).add(-1, 'days') < startDate)
          || (moment(properties[b].openDates.to).add(1, 'days') > endDate
          && moment(properties[a].openDates.to).add(1, 'days') <= endDate)
      ) {
        return 1;
      }
      if ( // If a is open and b closed
        (moment(properties[a].openDates.from).add(-1, 'days') < startDate
          && moment(properties[b].openDates.from).add(-1, 'days') >= startDate)
          || (moment(properties[b].openDates.to).add(1, 'days') <= endDate
          && moment(properties[a].openDates.to).add(1, 'days') > endDate)
      ) {
        return -1;
      }
    }
    return 0;
  };

  const sortByValidRatePlans = (a, b) => {
    const hasAvailableRatePlan = (property) => property.ratePlans && property.ratePlans.some((plan) => plan.boards && plan.boards.some((board) => board.plans && board.plans.some((planDetail) => planDetail.roomRateDetailed && planDetail.roomRateDetailed.some((roomRate) => roomRate.available))));

    const aAvailable = hasAvailableRatePlan(properties[a]);
    const bAvailable = hasAvailableRatePlan(properties[b]);

    if (aAvailable === bAvailable) {
      return 0;
    }
    if (aAvailable && !bAvailable) {
      return -1;
    }
    return 1;
  };

  const sortByulysesId = (a, b) => {
    if (properties[a].ulysesId === defaultPropertyID
      && properties[b].ulysesId !== defaultPropertyID) { return -1; }
    if (properties[b].ulysesId === defaultPropertyID
      && properties[a].ulysesId !== defaultPropertyID) { return 1; }
    return 0;
  };

  const sortByAvailability = (a, b) => {
    if (properties[b].ratePlans && properties[b].ratePlans.map((room) => room.boards).length > 0
      && properties[a].ratePlans && properties[a].ratePlans.map((room) => room.boards).length > 0
      && properties[b].tents.pets === true && properties[a].tents.pets === true) {
      const searchA = checkAvailableRatePlan(properties[a].ratePlans, endDate, startDate);
      const searchB = checkAvailableRatePlan(properties[b].ratePlans, endDate, startDate);

      if (!searchA.length && searchB.length) return 1;
      if (searchA.length && !searchB.length) return -1;
    }
    return 0;
  };

  const campingFilteredPets = (camping) => {
    switch (fpa) {
      case 'none':
        return camping;
      case 'pets':
        return camping?.tents?.some((tent) => tent.pets === true);
      default:
        return camping;
    }
  };
  const propertiesToShow = activeRegionsFilters
    .concat(activeCharacteristicsFilters)
    .concat(pricesToFilter).length > 0
    ? Object.keys(properties)
      .filter(filterByLocation)
      .filter(onlyIfHasFilteredCharacteristics)
      .filter(filterPropertiesByTentsPrice)
      .filter((property) => campingFilteredPets(properties[property], fpa))
    : Object.keys(properties).filter(filterByLocation)
      .filter((property) => campingFilteredPets(properties[property], fpa));

  const propertiesToShowSorted = (selectedProperties) => {
    let itemsSorted = selectedProperties;
    const ratePlanKey = (id) => (isCampService()
      ? properties[id].ulysesId
      : properties[id].roomTypeId);
    if (sorters.LOWEST_TO_HIGHEST === sorter) {
      itemsSorted = selectedProperties
        .sort((a, b) => sortByPrice(
          filteredRatePlans[ratePlanKey(a)],
          filteredRatePlans[ratePlanKey(b)],
          true,
        ));
    }

    if (sorters.HIGHEST_TO_LOWEST === sorter) {
      itemsSorted = selectedProperties
        .sort((a, b) => sortByPrice(
          filteredRatePlans[ratePlanKey(a)],
          filteredRatePlans[ratePlanKey(b)],
          false,
        ));
    }

    if (sorters.RATING === sorter) {
      itemsSorted = selectedProperties
      // TODO Check how to calculate the score or rating
        .sort((a, b) => (properties[a].score || 5) - (properties[b].score || 5)).reverse();
    }

    if (sorters.RELEVANCY === sorter) {
      itemsSorted = selectedProperties
        .sort(sortByOrder)
        .sort(sortByulysesId)
        .sort(sortByValidRatePlans)
        .sort(sortByAvailability)
        .sort(sortByOpenDates);
    }

    return itemsSorted;
  };

  // if (window.Cypress) {
  //   console.log('propertiesToShow', properties);
  // }
  return (
    <SearchContext.Provider value={{
      propertiesToShow: propertiesToShowSorted(propertiesToShow),
      sorter,
      setSorter,
      setFilteredRatePlans,
      filteredRatePlans,
      filteredStatus,
      setFilteredStatus,
      campings,
      isLoading: !campingsRatePlansAlreadyFetched,
    }}
    >
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.element.isRequired,
  type: PropTypes.oneOf(Object.values(Services)),
};

SearchProvider.defaultProps = {
  type: Object.values(Services)[0],
};

export { SearchContext };
export default SearchProvider;
