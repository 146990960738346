import { FlagOutlined } from '@ant-design/icons';
import { PropTypes } from 'prop-types';
import React from 'react';
import {
  active as _active, previous as _previous,
  bullet, bulletWrapper,
  wrapper,
} from './index.module.scss';

const Bullet = ({
  children, active, previous, onClick, "data-cy":dataCy

}) => <div className={`${bulletWrapper} ${active ? _active : ''} ${previous ? _previous : ''}`} onKeyPress={onClick} onClick={onClick} role="presentation" data-cy={dataCy}><div className={bullet}>{children}</div></div>;

Bullet.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
  active: PropTypes.bool,
  previous: PropTypes.bool,
  onClick: PropTypes.func,
  "data-cy": PropTypes.string
};

Bullet.defaultProps = {
  active: false,
  previous: false,
  onClick: () => {},
  "data-cy": undefined,
};

const Stepper = ({ currentStep, stepsTotalNumber, onClick }) => (
  <div className={wrapper}>
    {[...Array(stepsTotalNumber).keys()].map((key) => {
      if (key < stepsTotalNumber - 1) {
        return (
          <Bullet
            key={key}
            previous={currentStep > key}
            active={currentStep === key}
            onClick={() => (currentStep > key ? onClick(key) : null)}
            data-cy={`checkout-step-${key + 1}`}
          >
            {key + 1}
          </Bullet>
        );
      }
      return <Bullet key={key} active={currentStep === key}><FlagOutlined /></Bullet>;
    })}
  </div>
);

Stepper.propTypes = {
  currentStep: PropTypes.number,
  stepsTotalNumber: PropTypes.number,
  onClick: PropTypes.func
};

Stepper.defaultProps = {
  currentStep: 0,
  stepsTotalNumber: 5,
  onClick: () => {},
};
export default Stepper;
