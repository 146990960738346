import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
// import { useIntl } from 'react-intl';
// import { FormattedMessage } from 'react-intl';
// import moment from 'moment';
import CardImage from "./components/CardImage";
import CardContent from "./components/CardContent";
import {
  cardContainer,
  cardShowWrapper,
  open,
  display,
  notPriceAvailable,
  cardShow,
  // notAvailableWarning,
} from "./index.module.scss";
import { useShowRoomsState } from "../../../../../../../services/ShowRooms";
import useCampings from "../../../../../../../services/Campings/useCampings";
import CardFooter from "./components/CardFooter";
import CardShowRoom from "../../../../../components/CardShowRoom";
import "./styles.css";
import AvailabilityDrawer from "../../../../../../../components/AvailabilityDrawer";
import ROUTES from "../../../../../../../routes/routes";
import { URL } from "../../../../../../../constants/cms";
import Services from "../../../../../../../constants/services";
import Status from "../../../../../../../constants/status";
import isInViewport from "../../../../../../../utils/isInViewPort";
import { SearchContext } from "../../../../../../../contexts/SearchContext";
import isMobile from "../../../../../../../utils/isMobile";
import { useReservationState } from "../../../../../../../services/Reservation";

const CampingCard = ({ propertyID, type }) => {
  const { showRooms } = useShowRoomsState();
  const {
    state: {
      campings: { [propertyID]: camping },
    },
    tentState: { tents },
  } = useCampings();
  const [showAvailabilityDrawer, setShowAvailabilityDrawer] = useState(false);
  const {
    filteredStatus: { [propertyID]: status },
  } = useContext(SearchContext);
  const propertyRatePlans =
    camping && camping.ratePlans ? camping.ratePlans : [];
  const [showShowRoom, setshowShowRoom] = useState(false);
  const footerButton = useRef(null);
  const cardContainerRef = useRef(null);
  const cardShowRoomRef = useRef(null);
  const reservation = useReservationState();
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const filters = searchParams.get("filters");
  const fpa = searchParams.get("fpa");
  const [filteredPlans, setFilteredPlans] = useState([]);
  const wcPrivateIdsByLang = {
    es: "7",
    en: "43",
    fr: "44",
    pt: "76",
    it: "77",
  };
  const currentLang = localStorage.getItem("countryCode");
  const WCPRIVATE =
    wcPrivateIdsByLang[currentLang] || parseInt(searchParams.get("lang"), 10);
  // const intl = useIntl();

  // const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
  //   endDate: moment.utc(reservation.endDate),
  //   startDate: moment.utc(reservation.startDate),

  // });

  const isAvailable = status === Status.AVAILABLE;
  const handleOpenShowRoom = () => {
    const { isBottomIn, bottomOffset } = isInViewport(
      cardContainerRef,
      "bottom",
    );
    const { isTopIn } = isInViewport(cardContainerRef, "top");

    if (!isBottomIn && isTopIn) {
      if (isMobile()) {
        cardContainerRef.current.scrollIntoView({ behavior: "smooth" });
      } else if (window.innerHeight > -bottomOffset) {
        window.scrollBy({
          top: -bottomOffset,
          left: 0,
          behavior: "smooth",
        });
      } else {
        cardShowRoomRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const ratePlans = useMemo(() => {
    const roomTypeIdExistsInTentsAndRatePlans = (ratePlan) =>
      Object.keys(tents)
        .map((tentId) => parseInt(tentId, 10))
        .indexOf(ratePlan.id) >= 0;

    return propertyRatePlans.filter(roomTypeIdExistsInTentsAndRatePlans);
  }, [propertyRatePlans, tents]);

  const handleOnClick =
    ratePlans && ratePlans.length > 0
      ? () =>
          footerButton &&
          footerButton.current &&
          !showAvailabilityDrawer &&
          footerButton.current.click()
      : () => {
          navigate(
            `${
              ROUTES.propertyDetails
            }/${propertyID}?checkin=${reservation.startDate.format(
              "YYYY-MM-DD",
            )}&checkout=${reservation.endDate.format("YYYY-MM-DD")}&adults=${
              reservation.adults
            }&children=${reservation.children}&babies=${reservation.babies}`,
          );
        };

  useEffect(() => {
    if (showRooms[propertyID] && ratePlans.length > 0 && !showShowRoom) {
      setshowShowRoom(true);
    }
  }, [propertyID, showRooms, showShowRoom, ratePlans]);

  useEffect(() => {
    const updateIfDifferent = (newFiltered) => {
      if (
        filteredPlans?.length !== newFiltered?.length ||
        newFiltered?.some((plan, index) => plan.id !== filteredPlans[index]?.id)
      ) {
        setFilteredPlans(newFiltered);
      }
    };

    let filtered = ratePlans;

    if (camping) {
      let tentsThatMatch = Object.values(tents);

      if (filters?.includes(WCPRIVATE)) {
        tentsThatMatch = tentsThatMatch.filter((tent) => tent.privateBathroom);
      }
      if (fpa === "pets") {
        tentsThatMatch = tentsThatMatch.filter((tent) => tent.pets);
      }
      const roomTypeIds = tentsThatMatch.map((tent) =>
        parseInt(tent.roomTypeId, 10),
      );
      filtered = ratePlans.filter((plan) => roomTypeIds.includes(plan.id));

      if (filters === WCPRIVATE && fpa === "pets") {
        // eslint-disable-next-line max-len
        tentsThatMatch = filtered.reduce(
          (min, plan) => (plan.avgPrice < min ? plan.avgPrice : min),
          Infinity,
        );
      }
    }

    updateIfDifferent(filtered);
  }, [filters, fpa, tents, ratePlans, camping, filteredPlans, WCPRIVATE]);

  return camping ? (
    <>
      <div className={cardContainer} ref={cardContainerRef}>
        <CardImage
          onClick={handleOnClick}
          image={
            camping.featuredImage ? `${URL}${camping.featuredImage.url}` : ""
          }
          propertyID={propertyID}
          type={type}
        />
        <CardContent
          propertyID={propertyID}
          onClick={handleOnClick}
          roomPlans={filteredPlans}
        />
        <div
          className={`${
            Status.AVAILABLE !== status ? cardShow : cardShowWrapper
          } ${showRooms[propertyID] ? open : ""}
            ${camping.ratePlansAlreadyFetched ? "" : display} ${
            Status.AVAILABLE !== status ? notPriceAvailable : ""
          }`}
        >
          <CSSTransition
            timeout={250}
            classNames="animatedChild"
            in={showShowRoom}
            mountOnEnter
            unmountOnExit
            onEntered={handleOpenShowRoom}
          >
            <div ref={cardShowRoomRef}>
              <CardShowRoom
                ratePlans={ratePlans}
                propertyID={propertyID}
                priceFormat="byPricePerNightAndTotal"
                property={camping}
                type={Services.CAMP}
              />
            </div>
          </CSSTransition>
        </div>
        {showAvailabilityDrawer && (
          <AvailabilityDrawer
            visible={showAvailabilityDrawer}
            onClose={() => setShowAvailabilityDrawer(false)}
            property={camping}
            kampaohService={Services.CAMP}
            propertyID={propertyID}
          />
        )}
        {/* {Status.NOTAVAILABLE === status && (
          <div className={notAvailableWarning}>
            <span>
              {intl.formatMessage({ id: 'Card.Footer.NotAvailableWarning' })}
            </span>
          </div>
        )} */}
        {/* {Status.MINSTAYWARNING === status && notAvailableRatePerMinStay && (
          <div className={notAvailableWarning}>
            <span>
              <FormattedMessage
              id="Card.Footer.MinLosWarning"
              values={{ minLos: getDays, currentNights: daysBetweenCheckInAndCheckOut }} />
            </span>
          </div>
        )} */}
        {camping.ratePlansAlreadyFetched &&
          camping.tents &&
          camping.tents.length > 0 && (
            <CardFooter
              availability={isAvailable}
              propertyID={propertyID}
              type={type}
              onClick={(openState) => {
                setshowShowRoom(openState);
              }}
              cardContainerRef={footerButton}
              open={isAvailable && showShowRoom}
              ratePlans={ratePlans}
            />
          )}
      </div>
    </>
  ) : null;
};

CampingCard.propTypes = {
  propertyID: PropTypes.string,
  type: PropTypes.string,
};

CampingCard.defaultProps = {
  propertyID: "",
  type: "",
};

export default CampingCard;
