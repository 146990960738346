import React, { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { footerConditionStyle } from './index.module.scss';
import { useConditionsState } from '../../../services/Conditions';

const FooterCondition = forwardRef((props, ref) => {
  const {
    KPFooter,
  } = useConditionsState();

  const conditionsToShow = KPFooter;

  return (
    <div className={footerConditionStyle} ref={ref}>
      {conditionsToShow && (
      <ReactMarkdown
        source={conditionsToShow.description}
      />
      )}
    </div>
  );
});

FooterCondition.defaultProps = {
};

FooterCondition.propTypes = {

};

export default FooterCondition;
