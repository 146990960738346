import React, { useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import { useNavigate } from '@reach/router';
import { useAuthState, useAuthDispatch } from '../../services/Auth/useAuth';
import ROUTES from '../../routes/routes';
import actionsTypes from '../../services/Auth/actionsTypes';
import { profilePopup, separator } from './index.module.scss';

const ProfilePopup = ({ anchorEl, onClose }) => {
  const { accessToken } = useAuthState();
  const dispatch = useAuthDispatch();
  const navigate = useNavigate();

  const intl = useIntl();

  // Control to close the popup if page resize is done
  useEffect(() => {
    const handleResize = () => onClose();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [onClose]);

  // Control that closes the popup when clicked out of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl, onClose]);

  const handleNavigation = useCallback(
    (path, event) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(path);
      onClose();
    },
    [navigate, onClose],
  );

  const navigateToLogin = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const params = new URLSearchParams(window.location.search);
      params.delete('redirect');
      const redirect = `${window.location.pathname}${
        // eslint-disable-next-line prefer-template
        params.toString() ? '?' + params.toString() : ''
      }`;

      if (window.location.pathname !== ROUTES.auth) {
        navigate(`${ROUTES.auth}?redirect=${encodeURIComponent(redirect)}`);
      }
      onClose();
    },
    [navigate, onClose],
  );

  const handleLogout = useCallback(() => {
    dispatch({ type: actionsTypes.LOGOUT });
  }, [dispatch]);

  const actionList = useMemo(
    () =>
      accessToken ? (
        <>
          <li>
            <button
              onMouseDown={(e) => handleNavigation(ROUTES.me, e)}
              type="button"
              tabIndex={-1}
            >
              {intl.formatMessage({
                id: 'ProfilePopup.Button.PrivateArea.Text',
              })}
            </button>
          </li>
          <li>
            <button
              onMouseDown={(e) => handleNavigation(ROUTES.home, e)}
              type="button"
              tabIndex={-1}
            >
              {intl.formatMessage({ id: 'ProfilePopup.Button.Book.Text' })}
            </button>
          </li>
          <div className={separator} />
          <li>
            <button
              onMouseDown={() => handleLogout()}
              type="button"
              tabIndex={-1}
            >
              {intl.formatMessage({ id: 'ProfilePopup.Button.Logout.Text' })}
            </button>
          </li>
        </>
      ) : (
        <>
          <li>
            <button
              onMouseDown={(e) => navigateToLogin(e)}
              type="button"
              tabIndex={-1}
            >
              {intl.formatMessage({ id: 'ProfilePopup.Button.Register.Text' })}
            </button>
          </li>
          <li>
            <button
              onMouseDown={(e) => navigateToLogin(e)}
              type="button"
              tabIndex={-1}
            >
              {intl.formatMessage({ id: 'ProfilePopup.Button.Login.Text' })}
            </button>
          </li>
        </>
      ),
    [accessToken, handleNavigation, handleLogout, intl, navigateToLogin],
  );

  return ReactDOM.createPortal(
    <div className={profilePopup} role="dialog">
      <ul>{actionList}</ul>
    </div>,
    document.getElementById('menuBtn'),
  );
};

export default ProfilePopup;
