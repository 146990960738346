/* eslint-disable no-param-reassign */
import sendEmail from '../../../../../services/EmailAPI';

const proxyServerError404 = (reservation) => sendEmail(
  reservation.guestFirstName.concat(' ', reservation.guestLastName, ' - NO RESERVA, NO COBRO, NO DISPONIBLE'),
  'Esa tienda ya no se encuentra disponible. \n'.concat(
    'RESERVATION: \n',
    JSON.stringify(reservation, null, 4), '\n',
  ),
);

const proxyServerError500 = (reservation, secret) => sendEmail(
  reservation.guestFirstName.concat(' ', reservation.guestLastName, ' - NO RESERVA, NO COBRO, FALLO EN ULYSESCLOUD O STRIPE'),
  'No hemos podido realizar tu reserva. Escribe a reservas@kampaoh.com \n'.concat(
    'RESERVATION: \n',
    JSON.stringify(reservation, null, 4), '\n',
    'ERROR: \n',
    JSON.stringify(secret),
  ),
);

const proxyServerError402 = (reservation, secret) => {
  secret.message = secret?.body?.request?.status;
  secret.code = secret?.body?.response?.data?.code;

  return sendEmail(
    reservation.guestFirstName.concat(' ', reservation.guestLastName, ' - NO RESERVA, NO COBRO, FALLO EN ULYSESCLOUD O STRIPE'),
    'No hemos podido realizar tu reserva. Escribe a reservas@kampaoh.com \n'.concat(
      'RESERVATION: \n',
      JSON.stringify(reservation, null, 4), '\n',
      'ERROR: \n',
      JSON.stringify(secret),
    ),
  );
};

export { proxyServerError404, proxyServerError500, proxyServerError402 };
