import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import queryString from 'query-string';
import ROUTES from '../routes/routes';
import { useAuthState } from './Auth/useAuth';

const CheckNoAuthRoute = ({
  component: Component,
  path,
  location,
  ...rest
}) => {
  const { accessToken } = useAuthState();

  const query = queryString.parse(window.location.search);

  const { redirect } = query;
  return (
    <>
      {accessToken == null ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...rest} path={path} location={location} />
      ) : (
        <Redirect noThrow="true" to={redirect || ROUTES.home} />
      )}
    </>
  );
};

CheckNoAuthRoute.defaultProps = {
  component: null,
  path: '/',
  location: {},
};

CheckNoAuthRoute.propTypes = {
  component: PropTypes.func,
  path: PropTypes.string,
  location: PropTypes.shape({
    key: PropTypes.string,
    search: PropTypes.string,
  }),
};

export default CheckNoAuthRoute;
