/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const PasswordResetLayout =
  (Component) =>
  ({ ...props }) =>
    (
      <Layout style={{ minHeight: '100vh' }}>
        <Content>
          <Component {...props} />
        </Content>
      </Layout>
    );

PasswordResetLayout.propTypes = {};
PasswordResetLayout.defaultProps = {};

export default PasswordResetLayout;
