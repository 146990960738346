import { PropTypes } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LanguageContext } from '../../../locale/contexts/Language';
import { getMainLocale, locales } from '../../../locale/translations';
import { languageRow, languageSection } from './index.module.scss';

const LanguagesMenu = ({ setCurrentLanguage, activeLanguage }) => {
  const handleLanguageChange = (locale) => {
    const url = new URL(window.location.href);
    url.searchParams.set('lang', getMainLocale(locale));
    setCurrentLanguage(getMainLocale(locale));
    localStorage.setItem('countryCode', getMainLocale(locale));
    window.location.href = url.toString();
  };
  const countryCode = window.localStorage.getItem('countryCode');

  return (
    <div className={languageRow} data-cy="language-selection-menu">
      {locales.map((locale) => (
        <div
          onClick={() => handleLanguageChange(locale)}
          key={locale}
          onKeyDown={() => handleLanguageChange(locale)}
          role="button"
          tabIndex={locale}
          style={{
            borderBottom:
              activeLanguage === getMainLocale(locale) ||
              countryCode === getMainLocale(locale)
                ? '1px solid #fff'
                : 'none',
          }}
        >
          <FormattedMessage
            id={`#languageSelectorButton.language.${getMainLocale(locale)}`}
          />
        </div>
      ))}
    </div>
  );
};

const LanguageSection = () => {
  const { setCurrentLanguage, currentLanguage } = useContext(LanguageContext);
  const [activeLanguage, setActiveLanguage] = useState('es');

  useEffect(() => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const languageFromURL = searchParams.get('lang');
    if (languageFromURL) setCurrentLanguage(languageFromURL);
    setActiveLanguage(currentLanguage);
  }, [currentLanguage, setActiveLanguage, setCurrentLanguage]);

  return (
    <div className={languageSection}>
      <LanguagesMenu
        setCurrentLanguage={setCurrentLanguage}
        activeLanguage={activeLanguage}
      />
    </div>
  );
};

LanguagesMenu.propTypes = {
  setCurrentLanguage: PropTypes.func.isRequired,
  activeLanguage: PropTypes.string.isRequired,
};

export default LanguageSection;
