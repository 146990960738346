/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  wrapper,
  unitDetail,
  image,
  info,
  title,
  location,
  characteristics,
} from './index.module.scss';
import { URL } from '../../../../constants/cms';
import { ReactComponent as LocationIcon } from '../../../../assets/icons/location.svg';
import AvailabilityContext from '../../contexts/AvailabilityContext';
import useCampings from '../../../../services/Campings/useCampings';

const UnitSelector = () => {
  const { tentState: { tents } } = useCampings();
  const intl = useIntl();
  const activeButtonRef = useRef();
  const wrapperRef = useRef();
  const {
    selectedUnit,
  } = useContext(AvailabilityContext);
  const unitModel = 'tent_model';

  const units = Object.values(tents).find((tent) => parseInt(tent?.roomTypeId, 10)
  === parseInt(selectedUnit?.roomTypeId, 10));
  useEffect(() => {
    if (activeButtonRef.current) {
      activeButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    }
  }, [units]);

  return (
    <div className={wrapper} ref={wrapperRef}>
      {selectedUnit && (
      <div className={unitDetail}>
        <div
          className={image}
          style={{
            // eslint-disable-next-line no-nested-ternary
            backgroundImage: `url(${units.featuredImage && units.featuredImage.url
              ? units.featuredImage.url.includes('https')
                ? units.featuredImage.url
                : `${URL}${units.featuredImage.url}`
              : null})`,
          }}
        />
        <div className={info}>
          <div className={title}>
            {units?.tent_model?.name}
          </div>
          <div className={location}>
            <LocationIcon />
            {' '}
            {units?.camping?.propertyName}
          </div>
          <div className={characteristics}>
            {`${units[unitModel].height}m ${intl.formatMessage({ id: 'AvailabilityDrawer.UnitSelector.Characteristic.Height' })}, ${units[unitModel].maxGuests} ${intl.formatMessage({ id: 'AvailabilityDrawer.UnitSelector.Characteristic.Persons' })}`}
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default UnitSelector;
