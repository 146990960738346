import moment from 'moment';
import Status from '../constants/status';
import calcDaysBetweenCheckInAndCheckOut from '../utils/daysBetweenCheckInAndCheckOut';
// This function can be used to validate the ratePlans of a property (1)
// or to validate a single ratePlan (2). In both cases, it is needed to
// pass, as parameters, a ratePlan array and a property object.

// In case 1, the status will be collected from all ratePlans passed. So, if one of the
// units (tents/vans) is available, the status will be available as well. So this functionality
// may be perfect for collecting the main status of a property.

// In case 2, the status will be the status of this room/ratePlan, so it is more specific.
// It may be perfect to validate a single ratePlan.

const validatePropertyRatePlansV2 = (ratePlans, property) => {
  const startDate = moment(ratePlans?.find((plan) => plan?.roomRateDetailed?.date));
  const endDate = moment(
    ratePlans[0]?.roomRateDetailed[ratePlans[0]?.roomRateDetailed?.length - 1].date,
  ).clone().add(1, 'day');
  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({ endDate, startDate });
  const thereAreRoomsAvailable = (rate) => rate.available;
  const isAvailable = (rate) => rate.available;

  const minLosMinorOrEqualThanReservationDays = ({ minLos }) => (
    parseInt(minLos, 10) <= parseInt(daysBetweenCheckInAndCheckOut, 10));
  const maxLosMajorOrEqualThanReservationDays = ({ maxLos }) => (
    maxLos > 0
      ? parseInt(maxLos, 10) >= parseInt(daysBetweenCheckInAndCheckOut, 10)
      : true);
  const maxLosMinorThanReservationDays = ({ maxLos }) => (
    maxLos > 0
      ? parseInt(maxLos, 10) < parseInt(daysBetweenCheckInAndCheckOut, 10)
      : false);
  const minLosMajorThanReservationDays = ({ minLos }) => (
    parseInt(minLos, 10) > parseInt(daysBetweenCheckInAndCheckOut, 10));
  const priceIsMajorThanZero = (rate) => rate.amount > 0;
  const priceIsNotZero = (rate) => (
    rate.roomRateDetailed.filter(priceIsMajorThanZero)
  );
  const minLosIsOk = (rate) => (
    rate.roomRateDetailed
      .filter(minLosMinorOrEqualThanReservationDays).length === rate.roomRateDetailed.length);
  const maxLosIsOk = (rate) => (
    rate.roomRateDetailed
      .filter(maxLosMajorOrEqualThanReservationDays).length === rate.roomRateDetailed.length);
  const failAtMinLos = (rate) => (
    rate.roomRateDetailed.filter(minLosMajorThanReservationDays).length > 0);
  const failAtMaxLos = (rate) => (
    rate.roomRateDetailed.filter(maxLosMinorThanReservationDays).length > 0);
  const notClosedToArrivalOrDeparture = (rate) => (
    rate?.roomRateDetailed[0].closedToArrival === false
  );

  const decidePriceTextToRender = () => {
    if (
      (moment(property.openFrom).subtract(1, 'days') >= startDate)
          || (moment(property.openTo).add(2, 'days') <= endDate) // Add 2 day to get reservation the same day and alow checkout next day
    ) {
      return ({
        status: Status.CLOSED,
        message: 'ValidatePropertyRatePlans.Closed',
        ratePlans: [],
      });
    }

    let validRates = ratePlans.filter(notClosedToArrivalOrDeparture);
    if (validRates.length === 0 && ratePlans.length > 0) {
      if (ratePlans[0].roomRateDetailed[0].closedToArrival) {
        return ({
          status: Status.CANNOTENTERTHISDAY,
          message: 'ValidatePropertyRatePlans.CanNotEnterThisDay',
          ratePlans: [],
          value: startDate,
        });
      }
      if (ratePlans[0].roomRateDetailed[ratePlans[0].roomRateDetailed.length - 1]
        .closedToDeparture) {
        return ({
          status: Status.CANNOTLEAVETHISDAY,
          message: 'ValidatePropertyRatePlans.CanNotLeaveThisDay',
          ratePlans: [],
          value: endDate,
        });
      }
    }

    validRates = validRates
      .filter(isAvailable)
      .filter(thereAreRoomsAvailable)
      .filter(priceIsNotZero)
      .filter(minLosIsOk)
      .filter(maxLosIsOk);

    const ratePlansWithFailAtMinLos = ratePlans
      .filter(isAvailable)
      .filter(thereAreRoomsAvailable)
      .filter(priceIsNotZero)
      .filter(failAtMinLos);
    const ratePlansWithFailAtMaxLos = ratePlans
      .filter(isAvailable)
      .filter(thereAreRoomsAvailable)
      .filter(priceIsNotZero)
      .filter(failAtMaxLos);

    const notAvailableAndFailAtMinLos = (
      validRates.length === 0 && ratePlansWithFailAtMinLos.length > 0);
    if (notAvailableAndFailAtMinLos) {
      return ({
        status: Status.MINSTAYWARNING,
        message: 'ValidatePropertyRatePlans.Minimum',
        value: Math.min(...ratePlansWithFailAtMinLos
          .map((priceDetail) => Math
            .max(...priceDetail.roomRateDetailed.map((details) => details.minLos)))),
        ratePlans: [],
      });
    }

    const notAvailableAndFailAtMaxLos = (
      validRates.length === 0 && ratePlansWithFailAtMaxLos.length > 0);
    if (notAvailableAndFailAtMaxLos) {
      return ({
        status: Status.MAXSTAYWARNING,
        message: 'ValidatePropertyRatePlans.Maximum',
        value: Math.max(...ratePlansWithFailAtMaxLos.map((priceDetail) => Math
          .min(...priceDetail.roomRateDetailed.map((details) => details.maxLos)
            .filter((n) => n > 0)))),
        ratePlans: [],
      });
    }

    const thereAreAvailableRooms = validRates.length > 0;

    if (thereAreAvailableRooms) {
      return ({
        status: Status.AVAILABLE,
        message: 'Available',
        ratePlans: validRates,
      });
    }
    if (!thereAreAvailableRooms) {
      return ({
        status: Status.NOTAVAILABLE,
        message: 'ValidatePropertyRatePlans.VanNotAvailable',
        ratePlans: validRates,
      });
    }

    const notAvailable = validRates.length === 0;
    if (notAvailable) {
      return ({
        status: Status.NOTAVAILABLE,
        message: 'ValidatePropertyRatePlans.NotAvailable',
        ratePlans: [],
      });
    }

    return ({
      status: Status.OTHER,
      message: 'Other',
      ratePlans: [],
    });
  };
  return decidePriceTextToRender();
};

export default validatePropertyRatePlansV2;
