import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedNumber, FormattedPlural, useIntl,
} from 'react-intl';
import moment from 'moment';
import { useAuthState } from '../../../services/Auth/useAuth';
import TribeTag from '../../TribeTag'
import s from './index.module.scss';

const FormattedPriceByNight = ({
  daysBetweenCheckInAndCheckOut, pax, dates, minPrice, roomPlans,
}) => {
  const intl = useIntl();
  const { accessToken } = useAuthState();
  const { startDate, endDate} = dates;

  return (
    <div className={s.formattedPrice}>
      <div className={s.priceWrapper}>
        <span className={s.from}>{`${intl.formatMessage({ id: 'Card.Button.From', defaultMessage: 'Desde' })}`}</span>
        <span className={s.fullPrice}>
          <span className={s.price}>
            <FormattedNumber
              value={minPrice}
              // eslint-disable-next-line react/style-prop-object
              style="currency"
              currency="EUR"
            />
          </span>
          <span className={s.separator}>/</span>
          <span className={s.night}>{`${intl.formatMessage({ id: 'Card.Button.Night', defaultMessage: 'noche' })}`}</span>
        </span>
        {startDate && endDate ? (
          <span className={s.infoText}>
            {moment(startDate).locale(intl.locale).format('DD MMM')}
            {' → '}
            {moment(endDate).locale(intl.locale).format('DD MMM')}
            {' ('}
            {daysBetweenCheckInAndCheckOut}
            {' '}
            <FormattedPlural
              value={daysBetweenCheckInAndCheckOut}
              one={intl.formatMessage({ id: 'Card.Button.Night' })}
              other={intl.formatMessage({ id: 'Card.Button.Nights' })}
            />
            {') '}
          </span>
        ) : (
          <span className={s.person}>
            {`${pax ?? 2} `}
            <FormattedPlural
              value={pax}
              one={intl.formatMessage({ id: 'Card.Button.Person' })}
              other={intl.formatMessage({ id: 'Card.Button.People' })}
            />
          </span>
        )}
      </div>
      {accessToken && 
      <div className={s.tribeWrapper}>
        <TribeTag roomPlans={roomPlans} smallSize={false} />
      </div>
      }
    </div>
  );
};

FormattedPriceByNight.propTypes = {
  daysBetweenCheckInAndCheckOut: PropTypes.number.isRequired,
  dates: PropTypes.shape(
    {startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment)}
  ).isRequired,
  pax: PropTypes.number,
  minPrice: PropTypes.number,
  roomPlans: PropTypes.shape().isRequired,
};

FormattedPriceByNight.defaultProps = {
  pax: undefined,
  minPrice: undefined,
};

export default FormattedPriceByNight;
