import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useUserState } from '../../services/UserDetails/useUserDetails';
import { ReactComponent as NoProfileImage } from '../../assets/icons/icon_no_profile_image.svg';
import { avatar, img } from './index.module.scss';

const UserAvatar = memo(({ className }) => {
  const { name } = useUserState();

  const avatarClass = `${avatar} ${className}`.trim();

  if (name && name.length > 0) {
    const initial = name[0];
    return <div className={avatarClass}>{initial.toUpperCase()}</div>;
  }
  return (
    <div className={avatarClass}>
      <NoProfileImage className={img} alt="Default avatar" />
    </div>
  );
});

UserAvatar.defaultProps = {
  className: '',
};

UserAvatar.propTypes = {
  className: PropTypes.string,
};

export default UserAvatar;
