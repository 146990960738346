import moment from 'moment';
import Status from '../constants/status';
import calcDaysBetweenCheckInAndCheckOut from '../utils/daysBetweenCheckInAndCheckOut';

const validatePropertyRatePlansV3 = (ratePlans, property) => {
  const startDate = moment(ratePlans[0]?.roomRateDetailed[0]?.date);
  const endDate = moment(
    ratePlans[0].roomRateDetailed[ratePlans[0]?.roomRateDetailed?.length - 1]?.date,
  ).clone().add(1, 'day');
  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({ endDate, startDate });
  // eslint-disable-next-line max-len
  const thereAreRoomsAvailable = (rate) => rate.roomRateDetailed.find((roomRate) => roomRate.available);
  const isAvailable = (rate) => rate.available;
  const minLosMinorOrEqualThanReservationDays = ({ minStay }) => (
    parseInt(minStay, 10) <= parseInt(daysBetweenCheckInAndCheckOut, 10));
  const maxLosMajorOrEqualThanReservationDays = ({ maxLos }) => (
    maxLos > 0
      ? parseInt(maxLos, 10) >= parseInt(daysBetweenCheckInAndCheckOut, 10)
      : true);
  const maxLosMinorThanReservationDays = ({ maxStay }) => (
    maxStay > 0
      ? parseInt(maxStay, 10) < parseInt(daysBetweenCheckInAndCheckOut, 10)
      : false);
  const minLosMajorThanReservationDays = ({ minStay }) => (
    parseInt(minStay, 10) > parseInt(daysBetweenCheckInAndCheckOut, 10));
  const priceIsMajorThanZero = (rate) => rate.amount > 0;
  const priceIsNotZero = (rate) => (
    rate.roomRateDetailed.filter(priceIsMajorThanZero)
  );
  const minLosIsOk = (rate) => (
    rate.roomRateDetailed
      .filter(minLosMinorOrEqualThanReservationDays).length === rate.roomRateDetailed.length);
  const maxLosIsOk = (rate) => (
    rate.roomRateDetailed
      .filter(maxLosMajorOrEqualThanReservationDays).length === rate.roomRateDetailed.length);
  const failAtMinLos = (rate) => (
    rate.roomRateDetailed.filter(minLosMajorThanReservationDays).length > 0);
  const failAtMaxLos = (rate) => (
    rate.roomRateDetailed.filter(maxLosMinorThanReservationDays).length > 0);
  const notClosedToArrivalOrDeparture = (rate) => (
    rate.roomRateDetailed[0]?.closedToArrival === false
        && rate.roomRateDetailed[rate.roomRateDetailed.length - 1]?.closedToDeparture === false
  );
  const decidePriceTextToRender = () => {
    if (
      (moment(property.openFrom).subtract(1, 'days') >= startDate)
          || (moment(property.openTo).add(2, 'days') <= endDate) // Add 2 day to get reservation the same day and alow checkout next day
    ) {
      return ({
        status: Status.CLOSED,
        message: 'ValidatePropertyRatePlans.Closed',
        ratePlans: [],
      });
    }
    let validRates = ratePlans.filter(notClosedToArrivalOrDeparture);
    if (validRates.length === 0 && ratePlans.length > 0) {
      if (ratePlans[0]?.roomRateDetailed[0]?.closedToArrival) {
        return ({
          status: Status.CANNOTENTERTHISDAY,
          message: 'ValidatePropertyRatePlans.CanNotEnterThisDay',
          ratePlans: [],
          value: startDate,
        });
      }
      if (ratePlans[0]?.roomRateDetailed[ratePlans[0]?.roomRateDetailed.length - 1]
        ?.closedToDeparture) {
        return ({
          status: Status.CANNOTLEAVETHISDAY,
          message: 'ValidatePropertyRatePlans.CanNotLeaveThisDay',
          ratePlans: [],
          value: endDate,
        });
      }
    }
    validRates = validRates
      .filter(isAvailable)
      .filter(thereAreRoomsAvailable)
      .filter(priceIsNotZero)
      .filter(minLosIsOk)
      .filter(maxLosIsOk);
    const ratePlansWithFailAtMinLos = ratePlans
      .filter(isAvailable)
      .filter(thereAreRoomsAvailable)
      .filter(priceIsNotZero)
      .filter(failAtMinLos);
    const ratePlansWithFailAtMaxLos = ratePlans
      .filter(isAvailable)
      .filter(thereAreRoomsAvailable)
      .filter(priceIsNotZero)
      .filter(failAtMaxLos);
    const notAvailableAndFailAtMinLos = (
      validRates.length === 0 && ratePlansWithFailAtMinLos.length > 0);
    if (notAvailableAndFailAtMinLos) {
      return ({
        status: Status.MINSTAYWARNING,
        message: 'ValidatePropertyRatePlans.Minimum',
        value: Math.min(...ratePlansWithFailAtMinLos
          .map((priceDetail) => Math
            .max(...priceDetail.roomRateDetailed.map((details) => details.minStay)))),
        ratePlans: [],
      });
    }
    const notAvailableAndFailAtMaxLos = (
      validRates.length === 0 && ratePlansWithFailAtMaxLos.length > 0);
    if (notAvailableAndFailAtMaxLos) {
      return ({
        status: Status.MAXSTAYWARNING,
        message: 'ValidatePropertyRatePlans.Maximum',
        value: Math.max(...ratePlansWithFailAtMaxLos.map((priceDetail) => Math
          .min(...priceDetail.roomRateDetailed.map((details) => details.maxStay)
            .filter((n) => n > 0)))),
        ratePlans: [],
      });
    }
    // eslint-disable-next-line max-len
    const thereAreAvailableRooms = validRates.find((roomPlan) => roomPlan.roomRateDetailed.every((room) => room.available));
    if (thereAreAvailableRooms) {
      return ({
        status: Status.AVAILABLE,
        message: 'Available',
        ratePlans: validRates,
      });
    }
    if (!thereAreAvailableRooms) {
      return ({
        status: Status.NOTAVAILABLE,
        message: 'ValidatePropertyRatePlans.NotAvailable',
        ratePlans: validRates,
      });
    }
    const notAvailable = validRates.length === 0;
    if (notAvailable) {
      return ({
        status: Status.NOTAVAILABLE,
        message: 'ValidatePropertyRatePlans.NotAvailable',
        ratePlans: validRates,
      });
    }
    return ({
      status: Status.OTHER,
      message: 'Other',
      ratePlans: [],
    });
  };
  return decidePriceTextToRender();
};

export default validatePropertyRatePlansV3;
