import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useCampings from './Campings/useCampings';

const ShowRoomsState = React.createContext();
const ShowRoomsDispatch = React.createContext();

const asNormalizedHotels = (result, campingId) => ({
  ...result,
  [campingId]: false,
});

const showRoomsReducer = (state, action) => {
  switch (action.type) {
    case 'addShowRooms':
      return {
        ...state,
        showRooms: action.payload,
      };
    case 'activateShowRoom':
      return {
        ...state,
        showRooms: {
          ...state.showRooms,
          [action.payload.propertyID]: true,
        },
      };
    case 'deactivateShowRoom':
      return {
        ...state,
        showRooms: {
          ...state.showRooms,
          [action.payload.propertyID]: false,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const ShowRoomsProvider = ({ children }) => {
  const { state: { campings } } = useCampings();
  const [state, dispatch] = React.useReducer(showRoomsReducer, {
    showRooms: {},
    errors: [],
  });
  useEffect(() => {
    if (
      Object.keys(campings).length !== Object.keys(state.showRooms).length
      && Object.entries(campings).length > 0
    ) {
      dispatch({
        type: 'addShowRooms',
        payload: Object.keys(campings).reduce(asNormalizedHotels, {}),
      });
    }
  }, [campings, dispatch, state.showRooms]);
  return (
    <ShowRoomsState.Provider value={state}>
      <ShowRoomsDispatch.Provider value={dispatch}>
        {children}
      </ShowRoomsDispatch.Provider>
    </ShowRoomsState.Provider>
  );
};

ShowRoomsProvider.propTypes = {
  children: PropTypes.element,
};

ShowRoomsProvider.defaultProps = {
  children: {},
};

const useShowRoomsState = () => {
  const context = React.useContext(ShowRoomsState);
  if (context === undefined) {
    throw new Error('Filter context must be used within a provider');
  }
  return context;
};

const useShowRoomsDispatch = () => {
  const context = React.useContext(ShowRoomsDispatch);
  if (context === undefined) {
    throw new Error('Filter dispatch must be used within a provider');
  }
  return context;
};

export { ShowRoomsProvider, useShowRoomsState, useShowRoomsDispatch };
