import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import ConditionAcceptance from '../../../ConditionAcceptance';

const PetsNotAvailableConditionAccepted = ({ checked, changeChecked }) => {
  const intl = useIntl();
  return (
    <ConditionAcceptance
      markdownSource={intl.formatMessage({ id: 'Resume.noPetsConditions' })}
      checked={checked}
      changeChecked={changeChecked}
      data-cy="checkout-step2-no-pets"
    />
  );
};

PetsNotAvailableConditionAccepted.defaultProps = {
  checked: false,
  changeChecked: () => {},
};

PetsNotAvailableConditionAccepted.propTypes = {
  checked: PropTypes.bool,
  changeChecked: PropTypes.func,
};

export default PetsNotAvailableConditionAccepted;
