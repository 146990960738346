import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import {
  disabled,
  inner,
  notSelected,
  petFilterContainer,
  petFilterWrapper,
} from "./index.module.scss";

function PetFilter({ fpaFilter, setFpaFilter, petsAllowed }) {
  const intl = useIntl();
  const [fpa, setFpa] = useState("");
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const fpaParam = searchParams.get("fpa");

  const handleClickPetButton = (condition) => {
    switch (condition) {
      case "none":
        setFpaFilter("none");
        setFpa("none");
        break;
      case "pets":
        setFpaFilter("pets");
        setFpa("pets");
        break;
      case "allergy":
        setFpaFilter("allergy");
        setFpa("allergy");
        break;
      default:
        setFpaFilter("none");
        setFpa("none");
        break;
    }
  };

  const changeInternalFilter = (condition) => {
    switch (condition) {
      case "none":
        setFpa("none");
        break;
      case "pets":
        setFpa("pets");
        break;
      case "allergy":
        setFpa("allergy");
        break;
      default:
        setFpa("none");
        break;
    }
  };

  useEffect(() => {
    changeInternalFilter(fpaFilter);
  }, [fpaFilter]);

  useEffect(() => {
    setFpa(fpaParam);
    setFpaFilter(fpaParam);
    searchParams.set("fpa", fpaFilter);
    // eslint-disable-next-line
  }, [fpaParam]);

  return (
    <div>
      <div className={petFilterWrapper}>
        {fpa === "pets" ? (
          <button
            shape="circle"
            type="button"
            className={inner}
            key={1}
            onClick={() => handleClickPetButton("none")}
            data-cy="fpa-pets-button"
          >
            <span>
              <CheckIcon />
            </span>
          </button>
        ) : (
          <button
            shape="circle"
            type="button"
            aria-label="add"
            className={notSelected}
            disabled={petsAllowed === false && disabled}
            key={1}
            onClick={() => handleClickPetButton("pets")}
            data-cy="fpa-pets-button"
          />
        )}
        <div
          className={
            fpa === "allergy" || petsAllowed === false
              ? disabled
              : petFilterContainer
          }
        >
          <span>
            <strong>{intl.formatMessage({ id: "ModalForPets.title" })}</strong>
          </span>
          <span>{intl.formatMessage({ id: "ModalForPets.subtitle" })}</span>
        </div>
      </div>
      <div className={petFilterWrapper}>
        {fpa === "allergy" ? (
          <button
            shape="circle"
            type="button"
            className={inner}
            key={2}
            onClick={() => handleClickPetButton("none")}
            data-cy="fpa-allergy-button"
          >
            <span>
              <CheckIcon />
            </span>
          </button>
        ) : (
          <button
            shape="circle"
            type="button"
            aria-label="add"
            className={notSelected}
            disabled={petsAllowed === true && disabled}
            key={2}
            onClick={() => handleClickPetButton("allergy")}
            data-cy="fpa-allergy-button"
          />
        )}
        <div
          className={
            fpa === "pets" || petsAllowed === true
              ? disabled
              : petFilterContainer
          }
        >
          <span>
            <strong>
              {intl.formatMessage({ id: "ModalForAlergies.title" })}
            </strong>
          </span>
          <strong>
            <span>
              {intl.formatMessage({ id: "ModalForAlergies.subtitle" })}
            </span>
          </strong>
        </div>
      </div>
    </div>
  );
}
PetFilter.propTypes = {
  fpaFilter: PropTypes.string,
  setFpaFilter: PropTypes.func,
  petsAllowed: PropTypes.bool,
};
PetFilter.defaultProps = {
  fpaFilter: "none",
  setFpaFilter: () => {},
  petsAllowed: undefined,
};
export default PetFilter;
