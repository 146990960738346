import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import makeRequestToApServer from '../../../../../services/ApServer/askToApServer';
import { allowedMethods } from '../../../../../constants/ApServer/apServer';
import routes from '../../../../../constants/ApServer/apRoutes';
import openNotification from '../../../../../components/NotificacionMessage';
import {
  form,
  formRow,
  error,
  formActions,
  saveButton,
  select,
} from './index.module.scss';

const GenreForm = ({ initialValues, onSave }) => {
  const intl = useIntl();
  const formik = useFormik({
    initialValues: {
      genre: initialValues.genre || '',
    },
    validationSchema: Yup.object({
      genre: Yup.string().required(
        intl.formatMessage({
          id: 'GenreForm.Validation.Genre.Required',
        }),
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      makeRequestToApServer(
        allowedMethods.put,
        routes.authMe,
        values,
        ({ statusCode }) => {
          if (statusCode === 200)
            openNotification(
              'success',
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Title.Text',
              }),
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Message.Text',
              }),
              () => {
                onSave(values);
              },
              null,
            );
        },
        (err) => {
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      ).finally(() => {
        setSubmitting(false);
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={form}>
        <div className={formRow}>
          <span>
            {intl.formatMessage({
              id: 'GenreForm.Validation.Genre.Text',
            })}
          </span>
          <select
            className={select}
            id="genre"
            name="genre"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.genre}
          >
            <option
              value=""
              label={intl.formatMessage({
                id: 'GenreForm.Validation.Genre.Default',
              })}
            >
              {intl.formatMessage({
                id: 'GenreForm.Validation.Genre.Default',
              })}
            </option>
            <option
              value="M"
              label={intl.formatMessage({
                id: 'GenreForm.Validation.Genre.M',
              })}
            >
              {intl.formatMessage({
                id: 'GenreForm.Validation.Genre.M',
              })}
            </option>
            <option
              value="F"
              label={intl.formatMessage({
                id: 'GenreForm.Validation.Genre.W',
              })}
            >
              {intl.formatMessage({
                id: 'GenreForm.Validation.Genre.W',
              })}
            </option>
            {/* WIP <option
              value="O"
              label={intl.formatMessage({
                id: 'GenreForm.Validation.Genre.O',
              })}
            ></option> 
            <option
              value="N"
              label={intl.formatMessage({
                id: 'GenreForm.Validation.Genre.N',
              })}
            >
              {intl.formatMessage({
                id: 'GenreForm.Validation.Genre.N',
              })}
            </option>
            */}
          </select>
          {formik.touched.genre && formik.errors.genre ? (
            <div className={error}>{formik.errors.genre}</div>
          ) : null}
        </div>
      </div>

      <div className={formActions}>
        <button
          type="submit"
          className={saveButton}
          disabled={formik.isSubmitting}
        >
          {intl.formatMessage({
            id: 'GenreForm.Button.Text',
          })}
        </button>
      </div>
    </form>
  );
};

GenreForm.propTypes = {
  initialValues: PropTypes.shape({
    genre: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default GenreForm;
