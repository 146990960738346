const routes = {
  authVerify: '/auth/verify',
  authLogin: '/auth/login',
  authRegister: '/auth/register',
  authMe: '/auth/me',
  authRefresh: '/auth/refresh',
  emailVerify: '/email/verify',
  userSummary: '/me/travels/sumup',
  upconmingTrip: '/me/travels/upcoming',
  pastTrip: '/me/travels/past',
  authEmailVerification: '/auth/email/verification',
  authPasswordReset: '/auth/reset-password',
  authPassword: '/auth/password',
  socialLoginGoogle: '/social-login/google',
  socialLoginFacebook: '/social-login/facebook',
  settingsUnsubscribe: '/settings/unsubscribe',
  settingsUser: '/settings/user',
};

export default routes;
