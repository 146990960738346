/* eslint-disable max-len */
import React, { useEffect, useState, useContext, useRef } from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import {
  useReservationState,
  useReservationDispatch,
} from '../../../services/Reservation';
import useCampings from '../../../services/Campings/useCampings';
import MainSection from './components/MainSection';
import Services from '../../../constants/services';
import { useFilterDispatch } from '../../../services/Filter';
import { CampingFeaturesContext } from '../../../services/CampingFeatures/Provider';
import CustomSpin from '../../../components/CustomSpin';
import { noResultsWrapper } from './index.module.scss';
import TentPlacesProvider from '../../../services/Campings/TentDataProvider';
import { makeRequestToCmsServer } from '../../../services/API';
import { routes } from '../../../constants/cms';
import FooterCondition from '../../../components/Footer/FooterCondition';
import useDateRangeValidator from '../../../utils/useDateRangeValidator';
import CheckingUrlParams from '../../../services/CheckUrlParamsAndPassThemToReservation';

const Places = () => {
  const dispatchReservation = useReservationDispatch();
  const reservation = useReservationState();
  const dispatchFilters = useFilterDispatch();

  const AvailableFilterCharacteristics = useContext(CampingFeaturesContext);
  const [startDate, setStartDate] = useState(reservation.startDate);
  const [endDate, setEndDate] = useState(reservation.endDate);
  const [adults, setAdults] = useState(reservation.adults);
  const [children, setChildren] = useState(reservation.children);
  const [babies, setBabies] = useState(reservation.babies);
  const [filteredCampings, setCamping] = useState();
  const [loadTents, setLoadTents] = useState(false);
  const [regionsState, setRegionsState] = useState();

  const {
    state: { isLoadingCampings, ratePlansAlreadyFetched, campings },
  } = useCampings();

  useEffect(() => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const zoneFromURL = parseInt(searchParams.get('zone'), 10);
    const fetchRegions = async () => {
      if (zoneFromURL && !loadTents) {
        const regions = await makeRequestToCmsServer(routes.regions);
        const filteredCampingsArr = Object.keys(campings).reduce((acc, key) => {
          if (regions && regions.length > 0) {
            const filteredRegions = regions.filter((region) => {
              const filteredZones = region.zone.filter(
                (zone) => zone.internalID === zoneFromURL,
              );
              return filteredZones.length > 0; // Include regions with at least one matching zone
            });
            const campingInternalID = campings[key].region.zone.find(
              (zoneCamping) => zoneCamping.internalID === zoneFromURL,
            );
            if (
              campingInternalID &&
              filteredRegions.some((region) =>
                region.zone.some(
                  (zone) =>
                    zone.internalID ===
                    parseInt(campingInternalID.internalID, 10),
                ),
              )
            ) {
              acc[key] = campings[key].ulysesId;
            }
          }
          return Object.values(acc);
        }, {});
        setCamping(filteredCampingsArr);
        setLoadTents(true);
        setRegionsState(regions);
        return regions;
      }
      return null;
    };
    if (Object.keys(campings).length > 0) fetchRegions();
  }, [campings, loadTents]);

  useEffect(() => {
    if (Object.keys(campings).length > 0 && ratePlansAlreadyFetched) {
      dispatchReservation({ type: 'setRatePlansAsFetched' });
    }
  }, [dispatchReservation, campings, ratePlansAlreadyFetched]);

  useEffect(() => {
    dispatchReservation({ type: 'setKampaohService', payload: Services.CAMP });
  }, [dispatchReservation]);

  useEffect(() => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const startDateFromURL = searchParams.get('checkin');
    const endDateFromURL = searchParams.get('checkout');
    const adultsFromURL = parseInt(searchParams.get('adults'), 10);
    const childrenFromURL = parseInt(searchParams.get('children'), 10);
    const babiesFromURL = parseInt(searchParams.get('babies'), 10);

    const formattedStartDateFromURL = startDateFromURL
      ? moment(startDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(
          'YYYY-MM-DD',
        )
      : null;
    const formattedEndDateFromURL = endDateFromURL
      ? moment(endDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(
          'YYYY-MM-DD',
        )
      : null;

    setStartDate(formattedStartDateFromURL || reservation.startDate);
    setEndDate(formattedEndDateFromURL || reservation.endDate);
    setAdults(adultsFromURL || reservation.adults);
    setChildren(childrenFromURL || reservation.children);
    setBabies(babiesFromURL || reservation.babies);
  }, [
    setStartDate,
    setEndDate,
    setAdults,
    setChildren,
    setBabies,
    reservation,
  ]);

  useEffect(() => {
    dispatchReservation({ type: 'setStartDate', payload: moment(startDate) });
    dispatchReservation({ type: 'setEndDate', payload: moment(endDate) });
    dispatchReservation({ type: 'setadults', payload: adults });
    dispatchReservation({ type: 'setchildren', payload: children });
    dispatchReservation({ type: 'setbabies', payload: babies });
  }, [startDate, endDate, adults, children, babies, dispatchReservation]);

  useEffect(() => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const filtersFromURL = searchParams.get('filters')
      ? searchParams.get('filters').split(',')
      : null;
    if (filtersFromURL && filtersFromURL.length && filtersFromURL[0] !== '') {
      filtersFromURL.map((filter) => {
        if (AvailableFilterCharacteristics.length > 0) {
          const filteredFilter = AvailableFilterCharacteristics.find(
            (obj) => obj.id === parseInt(filter, 10),
          );
          if (filteredFilter)
            return dispatchFilters({
              type: 'addCharacteristicsFilter',
              payload: filteredFilter,
            });
          return null;
        }
        return null;
      });
    }
  }, [AvailableFilterCharacteristics, dispatchFilters]);

  useEffect(() => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const minPriceFromURL = parseInt(searchParams.get('minPrice'), 10);
    const maxPriceFromURL = parseInt(searchParams.get('maxPrice'), 10);

    if (minPriceFromURL && maxPriceFromURL) {
      if (minPriceFromURL >= 35 && maxPriceFromURL <= 950) {
        dispatchFilters({
          type: 'addPricesToFilter',
          payload: [minPriceFromURL, maxPriceFromURL],
        });
      }
    }
    if (minPriceFromURL && !maxPriceFromURL) {
      if (minPriceFromURL >= 35) {
        dispatchFilters({
          type: 'addPricesToFilter',
          payload: [minPriceFromURL, 950],
        });
      }
    }
    if (!minPriceFromURL && maxPriceFromURL) {
      if (maxPriceFromURL <= 950) {
        dispatchFilters({
          type: 'addPricesToFilter',
          payload: [35, maxPriceFromURL],
        });
      }
    }
  }, [dispatchFilters]);

  const scrollRef = useRef(null);

  const url = new URL(window.location);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const fpaFilter = params.get('fpa');

    if (
      fpaFilter !== 'pets' &&
      fpaFilter !== 'allergy' &&
      fpaFilter !== 'none'
    ) {
      params.set('fpa', 'none');
      window.location.search = params.toString();
    }
  }, [url]);

  useDateRangeValidator();

  return !isLoadingCampings && loadTents && filteredCampings !== undefined ? (
    <TentPlacesProvider campings={filteredCampings}>
      <MainSection scrollRef={scrollRef} regions={regionsState} />
      <FooterCondition ref={scrollRef} />
    </TentPlacesProvider>
  ) : (
    <div className={noResultsWrapper}>
      <CustomSpin />
    </div>
  );
};

Places.propTypes = {
  scrollRef: PropTypes.shape({
    current: PropTypes.objectOf(PropTypes.type),
  }).isRequired,
};
export default CheckingUrlParams(Places);
