import { useContext } from 'react';
import { LocationsDispatch, LocationsState } from './DataProvider';

const useLocations = () => {
  const state = useContext(LocationsState);
  if (state === undefined) {
    throw new Error('Locations state context must be used within a provider');
  }

  const dispatch = useContext(LocationsDispatch);
  if (dispatch === undefined) {
    throw new Error('Locations dispatch context must be used within a provider');
  }

  return {
    state,
    dispatch,
  };
};

export default useLocations;
