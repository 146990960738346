/* eslint-disable no-fallthrough */
import React, { useEffect, useState, useCallback, useContext } from "react";
import { injectIntl } from "react-intl";
import { Result, Progress } from "antd";
import { navigate } from "@reach/router";
import { useReservationState } from "../../../../services/Reservation";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/clap.svg";
import {
  iconWrapper,
  titleWrapper,
  subtitleWrapper,
  wrapper,
} from "./index.module.scss";
import ROUTES from "../../../../routes/routes";
import { LanguageContext } from "../../../../locale/contexts/Language";

const Confirmation = injectIntl(({ intl }) => {
  const reservation = useReservationState();
  const { currentLanguage } = useContext(LanguageContext);
  const [percent, setPercent] = useState(0);
  const goToURL = useCallback(() => {
    switch (currentLanguage) {
      case "es":
        window.location.href =
          reservation?.confirmationUrl ||
          "https://es.kampaoh.com/gracias-por-tu-reserva/";
        break;
      case "en":
        window.location.href =
          reservation?.confirmationUrl ||
          "https://en.kampaoh.com/thank-you-for-your-booking/";
        break;
      case "fr":
        window.location.href =
          reservation?.confirmationUrl ||
          "https://fr.kampaoh.com/merci-pour-votre-reservation/";
        break;
      case "it":
        window.location.href =
          reservation?.confirmationUrl ||
          "https://it.kampaoh.com/grazie-per-la-tua-prenotazione/";
        break;
      case "pt":
        window.location.href =
          reservation?.confirmationUrl ||
          "https://pt.kampaoh.com/obrigado-pela-sua-reserva/";
        break;
      default:
        window.location.href =
          reservation?.confirmationUrl ||
          "https://es.kampaoh.com/gracias-por-tu-reserva/";
        break;
    }
  }, [currentLanguage, reservation]);
  useEffect(() => {
    setInterval(() => {
      setPercent((oldPercent) => oldPercent + 5);
    }, 200);
  }, [percent]);
  useEffect(() => {
    if (percent === 100) {
      goToURL();
    }
  }, [percent, goToURL]);

  if (!reservation.readyToBeUsed) {
    navigate(ROUTES.home);
    return <div className={wrapper} />;
  }
  return (
    <div className={wrapper}>
      <Result
        status="success"
        title={
          <p className={titleWrapper}>
            {intl.formatMessage(
              {
                id: "Confirmation.Title",
                defaultMessage: "Reserva realizada",
              },
              { br: <br /> },
            )}
          </p>
        }
        subTitle={
          <p className={subtitleWrapper}>
            {intl
              .formatMessage({
                id: "Confirmation.Subtitle",
                defaultMessage: "Has realizado un pago para ",
              })
              .concat(reservation.selectedHotel)}
          </p>
        }
        extra={
          <>
            <Progress percent={percent} status="active" strokeColor="#ffd100" />
          </>
        }
        icon={
          <div className={iconWrapper}>
            <SuccessIcon />
          </div>
        }
      />
    </div>
  );
});

export default Confirmation;
