import React, { useState, useMemo } from "react";
import { Layout } from "antd";
import { wrapper } from "./index.module.scss";
import ReservationInfo from "../ReservationInfo";
import FiltersDrawer from "../FiltersDrawer";
import FeaturesFilter from "../FeaturesFilter";
import {
  useReservationDispatch,
  useReservationState,
} from "../../../services/Reservation";
import DestinationsDrawer from "../DestinationsDrawer";
import { useTopBannerState } from "../../../services/TopBanner/Provider";
import useDeviceDetect from "../../../utils/useDeviceDetect";

const ReservationInfoAndFilteringHeader = () => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showDestinationDrawer, setShowDestinationsDrawer] = useState(false);
  const reservation = useReservationState();
  const dispatch = useReservationDispatch();
  const { status } = useTopBannerState();
  const { isMobile } = useDeviceDetect();
  const handleOnDestinationPick = (location) => {
    dispatch({ type: "setPreferredLocation", payload: location });
    setShowDestinationsDrawer(false);
  };

  const topStyle = useMemo(() => {
    if (status) {
      return isMobile ? "66px" : "136px";
    }
    return isMobile ? "-1px" : "69px";
  }, [status, isMobile]);
  return (
    <>
      <Layout.Header className={wrapper} style={{ top: topStyle }}>
        {reservation.readyToBeUsed && (
          <>
            <ReservationInfo
              onFilterButtonClick={() => setShowFilterModal(true)}
            />
            <FeaturesFilter />
          </>
        )}
      </Layout.Header>
      <FiltersDrawer
        visible={showFilterModal}
        onFilter={() => setShowFilterModal(false)}
        onShowMoreDestinations={() => {
          setShowFilterModal(false);
          setShowDestinationsDrawer(true);
        }}
        onClose={() => setShowFilterModal(false)}
      />
      <DestinationsDrawer
        visible={showDestinationDrawer}
        onDestinationPick={handleOnDestinationPick}
        onClose={() => setShowDestinationsDrawer(false)}
      />
    </>
  );
};

ReservationInfoAndFilteringHeader.propTypes = {};

ReservationInfoAndFilteringHeader.defaultProps = {};
export default ReservationInfoAndFilteringHeader;
