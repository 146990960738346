import React from 'react';
import PropTypes from 'prop-types';

const FilterState = React.createContext();
const FilterDispatch = React.createContext();

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'addCharacteristicsFilter':
      return {
        ...state,
        activeCharacteristicsFilters: [
          ...state.activeCharacteristicsFilters,
          action.payload,
        ],
      };

    case 'removeCharacteristicsFilter':
      return {
        ...state,
        activeCharacteristicsFilters: [
          ...state.activeCharacteristicsFilters.filter(
            (filter) => filter.id !== action.payload.id,
          ),
        ],
      };

    case 'resetCharacteristicsFilter':
      return { ...state, activeCharacteristicsFilters: [] };

    case 'addRegionsFilter':
      return {
        ...state,
        activeRegionsFilters: [...state.activeRegionsFilters, action.payload],
      };

    case 'removeRegionsFilter':
      return {
        ...state,
        activeRegionsFilters: [
          ...state.activeRegionsFilters.filter(
            (filter) => filter.id !== action.payload.id,
          ),
        ],
      };

    case 'resetRegionsFilter':
      return { ...state, activeRegionsFilters: [] };

    case 'addPricesToFilter':
      return { ...state, pricesToFilter: action.payload };

    case 'resetPricesToFilter':
      return { ...state, pricesToFilter: [] };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const FilterProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(filterReducer, {
    activeCharacteristicsFilters: [],
    activeRegionsFilters: [],
    pricesToFilter: [],
  });

  return (
    <FilterState.Provider value={state}>
      <FilterDispatch.Provider value={dispatch}>
        {children}
      </FilterDispatch.Provider>
    </FilterState.Provider>
  );
};

FilterProvider.propTypes = {
  children: PropTypes.element,
};

FilterProvider.defaultProps = {
  children: {},
};

const useFilterState = () => {
  const context = React.useContext(FilterState);
  if (context === undefined) {
    throw new Error('Filter context must be used within a provider');
  }
  return context;
};

const useFilterDispatch = () => {
  const context = React.useContext(FilterDispatch);
  if (context === undefined) {
    throw new Error('Filter dispatch must be used within a provider');
  }
  return context;
};

export { FilterProvider, useFilterState, useFilterDispatch };
