import React from 'react';
import { PropTypes } from 'prop-types';
import { button, activeButton, wrapper } from './index.module.scss';
import {
  useFilterDispatch,
  useFilterState,
} from '../../../../../../services/Filter';

const FilterButtons = ({ availableFilters, type, onClick }) => {
  const { activeCharacteristicsFilters } = useFilterState();
  const dispatch = useFilterDispatch();
  const belongsToTypeAndIsFilterable = (filter) =>
    filter.category === type && filter.filterable;

  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };

  const updateQueryParams = (filter, addItem) => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    let activeFiltersIds = activeCharacteristicsFilters.map((itm) => itm.id);
    if (addItem) {
      activeFiltersIds.push(filter.id);
    } else {
      activeFiltersIds = activeFiltersIds.filter((num) => num !== filter.id);
    }

    const filtersIdQueryString = activeFiltersIds.join(',');
    params.set('filters', filtersIdQueryString);

    url.search = params.toString();
    updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
  };
  return (
    <div className={wrapper}>
      {availableFilters.map((filter) =>
        belongsToTypeAndIsFilterable(filter) ? (
          <button
            type="button"
            key={filter.id}
            className={
              activeCharacteristicsFilters.filter(
                (activeFilter) => activeFilter.id === filter.id,
              ).length > 0
                ? activeButton
                : button
            }
            onClick={() => {
              if (
                activeCharacteristicsFilters.filter(
                  (activeFilter) => activeFilter.id === filter.id,
                ).length > 0
              ) {
                dispatch({
                  type: 'removeCharacteristicsFilter',
                  payload: filter,
                });
                updateQueryParams(filter, false);
              } else {
                dispatch({ type: 'addCharacteristicsFilter', payload: filter });
                updateQueryParams(filter, true);
              }
              onClick();
            }}
          >
            {filter.name}
          </button>
        ) : null,
      )}
    </div>
  );
};

FilterButtons.propTypes = {
  type: PropTypes.string,
  availableFilters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.shape({
        render: PropTypes.func,
      }),
      icon: PropTypes.shape({
        render: PropTypes.func,
      }),
    }),
  ),
  onClick: PropTypes.func,
};

FilterButtons.defaultProps = {
  type: '',
  availableFilters: [],
  onClick: () => {},
};

export default FilterButtons;
