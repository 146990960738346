import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { useIntl } from 'react-intl';
import CustomModal from '../../../CustomModal';

const ModalForPaxLimit = ({
  visible, onOk, onCancel,
}) => {
  const intl = useIntl();
  return (
    <CustomModal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <ReactMarkdown source={intl.formatMessage({ id: 'Kampaoh.Reservation.MaxPax' })} />
    </CustomModal>
  );
};
ModalForPaxLimit.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

ModalForPaxLimit.defaultProps = {
  visible: false,
  onOk: () => {},
  onCancel: () => {},
};

export default ModalForPaxLimit;
