import { navigate } from '@reach/router';
import { message } from 'antd';
import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Services from '../../../../constants/services';
import ROUTES from '../../../../routes/routes';
import sendEmail from '../../../../services/EmailAPI';
import {
  calcTimeLimitForTodaysBooking,
  showNotReservationAllowedConfirmModal,
} from '../../../../utils/timeLimitForTodaysBooking';

/* const { search } = window.location;
const searchParams = new URLSearchParams(search);
const startDateFromURL = searchParams.get('checkin');
const endDateFromURL = searchParams.get('checkout');
const formattedStartDateFromURL = startDateFromURL
  ? moment(startDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD'])?.format('YYYY-MM-DD')
  : null;
const formattedEndDateFromURL = endDateFromURL
  ? moment(endDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD'])?.format('YYYY-MM-DD')
  : null;
 */
const steps = [
  {
    title: <FormattedMessage id="Checkout.Step1.Title" />,
    route: ROUTES.resume,
    actions: () => {
      window.dataLayer.push({
        event: 'initiateCheckoutBE',
      });
    },
  },
  {
    title: <FormattedMessage id="Checkout.Step2.Title" />,
    route: ROUTES.policies,
    actions: () => {},
  },
  {
    title: <FormattedMessage id="Checkout.Step3.Title" />,
    route: ROUTES.form,
    actions: async (reservation) => {
      try {
        await sendEmail(
          '$0001$'.concat(reservation.guestEmail),
          JSON.stringify(reservation, null, 4),
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  },
  {
    title: <FormattedMessage id="Checkout.Step4.Title" />,
    route: ROUTES.payment,
    actions: () => {},
  },
  {
    title: null,
  },
];

const onGoBackToStep = (
  currentStep,
  setCurrentStep,
  reservation,
  propertyFromURL,
  roomFromURL,
  promotionFromURL,
  offerFromURL,
  rateFromURL,
  boardFromURL,
  childrenFromURL,
  babiesFromURL,
  adultsFromURL,
  startDateFromURL,
  endDateFromURL,
  fpaFromURL,
  newStep
) => {
  const { adults, children, babies } = reservation;
  if (currentStep === 0) {
    navigate(
      `${
        ROUTES?.roomDetails
      }/${propertyFromURL}/${roomFromURL}?checkin=${startDateFromURL}&checkout=${endDateFromURL}&adults=${adults}&children=${children}&babies=${babies}${
        rateFromURL ? `&rate=${rateFromURL}` : ""
      }${boardFromURL ? `&board=${boardFromURL}` : ""}${
        offerFromURL !== null &&
        offerFromURL !== undefined &&
        !Number.isNaN(offerFromURL)
          ? `&offer=${offerFromURL}`
          : ""
      }${promotionFromURL ? `&promotion=${promotionFromURL}` : ""}${
        fpaFromURL ? `&fpa=${fpaFromURL}` : ""
      }`
    );
  } else {
    setCurrentStep((prev) => prev - (currentStep - newStep));
    navigate(
      `${
        ROUTES?.book + steps[newStep].route
      }?checkin=${startDateFromURL}&checkout=${endDateFromURL}${
        rateFromURL !== null &&
        rateFromURL !== undefined &&
        `&rate=${rateFromURL}`
      }${
        boardFromURL !== undefined &&
        boardFromURL !== null &&
        `&board=${boardFromURL}`
      }${
        offerFromURL !== null && offerFromURL !== undefined
          ? `&offer=${offerFromURL}`
          : ""
      }${`&adults=${adultsFromURL}`}${`&children=${childrenFromURL}`}${`&babies=${babiesFromURL}`}${
        promotionFromURL !== null ? `&promotion=${promotionFromURL}` : ""
      }${propertyFromURL ? `&property=${propertyFromURL}` : ""}${
        roomFromURL ? `&room=${roomFromURL}` : ""
      }${fpaFromURL ? `&fpa=${fpaFromURL}` : ""}`
    );
  }
};

const onGoBack = (
  currentStep,
  setCurrentStep,
  reservation,
  propertyFromURL,
  roomFromURL,
  promotionFromURL,
  offerFromURL,
  rateFromURL,
  boardFromURL,
  childrenFromURL,
  babiesFromURL,
  adultsFromURL,
  startDateFromURL,
  endDateFromURL,
  fpaFromURL
) =>
  onGoBackToStep(
    currentStep,
    setCurrentStep,
    reservation,
    propertyFromURL,
    roomFromURL,
    promotionFromURL,
    offerFromURL,
    rateFromURL,
    boardFromURL,
    childrenFromURL,
    babiesFromURL,
    adultsFromURL,
    startDateFromURL,
    endDateFromURL,
    fpaFromURL,
    currentStep - 1
  );

const handleOnClick = ({
  currentStep,
  reservation,
  property,
  dispatch,
  setCurrentStep,
  setCheckingReservationUpdates,
  form,
  intl,
  filteredRatePlans,
  tent,
  propertyFromURL,
  roomFromURL,
  promotionFromURL,
  offerFromURL,
  rateFromURL,
  boardFromURL,
  childrenFromURL,
  babiesFromURL,
  adultsFromURL,
  startDateFromURL,
  endDateFromURL,
  camping,
  fpaFromURL,
}) => {
  const { startDate, kampaohService } = reservation;
  const transformRatePlans = filteredRatePlans?.flatMap((plan) =>
    plan?.plans?.map((subPlan) => ({
      code: plan?.code,
      id: plan?.id,
      name: plan?.name,
      ...subPlan,
    })),
  );

  switch (`${currentStep}`) {
    case '0': {
      const limitHour = calcTimeLimitForTodaysBooking(property);
      if (
        limitHour &&
        moment(startDate)
          .tz('Europe/Madrid')
          .isSame(moment().tz('Europe/Madrid'), 'day') &&
        moment().tz('Europe/Madrid').isAfter(limitHour)
      ) {
        showNotReservationAllowedConfirmModal(-1, property, intl);
      } else {
        setCheckingReservationUpdates(true);
      }
      break;
    }

    case '1':
      if (kampaohService === Services.CAMP) {
        dispatch({ type: 'acceptPartyPolicy', payload: true });
        dispatch({
          type: 'acceptReservationParticularPolicy',
          payload: true,
        });
        dispatch({ type: 'acceptReservationPolicy', payload: true });
        dispatch({ type: 'acceptPrivacyPolicy', payload: true });
        dispatch({ type: 'guestClub', payload: true });

        dispatch({
          type: 'setPriceByNight',
          payload: transformRatePlans[0]?.roomRateDetailed,
        });
        dispatch({ type: 'setCampingName', payload: property?.campingName });
        dispatch({ type: 'setSelectedHotelId', payload: propertyFromURL });
        dispatch({ type: 'setItAsReadyToBeUsed', payload: true });
        dispatch({
          type: 'setTotalPrice',
          payload: transformRatePlans[0]?.totalPrice,
        });
        dispatch({
          type: 'setDeadlineDay',
          payload: transformRatePlans[0]?.propertyPolicy?.deadlineDay,
        });
        dispatch({ type: 'setRateId', payload: transformRatePlans[0]?.rateId });
        dispatch({ type: 'setBoardId', payload: transformRatePlans[0]?.id });
        dispatch({
          type: 'setOfferId',
          payload: transformRatePlans[0]?.offer?.id,
        });
        dispatch({
          type: 'setOfferInfo',
          payload: transformRatePlans[0]?.offer,
        });
        dispatch({
          type: 'setPromotionInfo',
          payload: transformRatePlans[0]?.promotion,
        });
        dispatch({
          type: 'setPromotionId',
          payload: transformRatePlans[0]?.promotion?.id,
        });
        dispatch({ type: 'setRateId', payload: transformRatePlans[0]?.rateId });
        dispatch({ type: 'setRoomId', payload: parseInt(roomFromURL, 10) });
        dispatch({
          type: 'setRatePlanPropertyPolicy',
          payload: transformRatePlans[0]?.propertyPolicy,
        });
        dispatch({
          type: 'setRatePlanPropertyPolicyId',
          payload: transformRatePlans[0]?.propertyPolicy?.id,
        });
        dispatch({
          type: 'setNonRefundable',
          payload: transformRatePlans[0]?.propertyPolicy?.nonRefundable,
        });
        dispatch({
          type: 'setSelectedRoomType',
          payload: tent?.tent_model?.name,
        });
        dispatch({ type: 'setSelectedHotel', payload: property?.propertyName });
        dispatch({
          type: 'setRateName',
          payload: transformRatePlans[0]?.rateName,
        });
        dispatch({
          type: 'setSelectedRoomTypeId',
          payload: parseInt(roomFromURL, 10),
        });
        dispatch({ type: 'setPets', payload: tent?.pets });
        dispatch({
          type: 'setDepositPercentage',
          payload: transformRatePlans[0]?.propertyPolicy?.depositPercentage,
        });
        dispatch({
          type: 'setConfirmationUrl',
          payload: camping?.confirmationUrl,
        });
        dispatch({
          type: 'setPets',
          payload: fpaFromURL === 'pets',
        });
      } else {
        dispatch({ type: 'acceptReservationPolicy', payload: true });
        dispatch({ type: 'acceptPickUpPolicy', payload: true });
        dispatch({ type: 'acceptUsePolicy', payload: true });
        dispatch({ type: 'acceptPrivacyPolicy', payload: true });
      }
      window.dataLayer.push({
        event: 'politicaSubmitBE',
      });
      setCurrentStep((step) => step + 1);
      navigate(
        `${
          ROUTES?.book + steps[currentStep + 1].route
        }?checkin=${startDateFromURL}&checkout=${endDateFromURL}${
          rateFromURL && `&rate=${rateFromURL}`
        }${boardFromURL && `&board=${boardFromURL}`}${
          offerFromURL !== null &&
          offerFromURL !== undefined &&
          !Number.isNaN(offerFromURL)
            ? `&offer=${offerFromURL}`
            : ''
        }${`&adults=${adultsFromURL}`}${`&children=${childrenFromURL}`}${`&babies=${babiesFromURL}`}${
          promotionFromURL !== null && !Number.isNaN(promotionFromURL)
            ? `&promotion=${promotionFromURL}`
            : ''
        }${`&property=${propertyFromURL}`}${`&room=${roomFromURL}`}${
          fpaFromURL ? `&fpa=${fpaFromURL}` : ''
        }`,
      );
      break;

    case '2': {
      const validateForm = async () => {
        try {
          form
            .validateFields()
            .then(async (values) => {
              dispatch({
                type: 'fillUserForm',
                payload: values,
              });
              window.dataLayer.push({
                event: 'formSubmitBE',
                guestFirstName: values.Name,
                guestLastName: values.Surname,
                guestGender: values.Genre,
                guestCountry: values.Country,
                guestCity: values.City,
                guestZip: values.PostalCode,
                guestEmail: values.Email,
                guestPhone: values.prefix.concat(values.Phone),
                guestCompany: values.COMP,
                guestClub: values.CLUB,
                guestMeetUsBy: values.FUENTE,
                guestIdentity: values.DNI.replace(' ', ''),
                guestPartyAccepted: 'Aceptado en la Web (API)',
              });
              setCheckingReservationUpdates(true);
            })
            .catch(() => {
              message.error(
                intl.formatMessage({ id: 'UserForm.Validation.Error' }),
              );
            });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      };
      validateForm();
      break;
    }

    default:
      break;
  }
};

export { handleOnClick, onGoBack, onGoBackToStep, steps };

