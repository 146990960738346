import React, { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import WelcomeForm from './components/WelcomeForm';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import VerifyEmailForm from './components/VerifyEmailForm';
import Drawer from '../../components/Drawer/Drawer';
import makeRequestToApServer from '../../services/ApServer/askToApServer';
import { allowedMethods } from '../../constants/ApServer/apServer';
import routes from '../../constants/ApServer/apRoutes';
import openNotification from '../../components/NotificacionMessage';
import PasswordResetForm from './components/PasswordResetForm';
import CustomSpin from '../../components/CustomSpin';

const AuthPage = () => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeForm, setActiveForm] = useState('');
  const [email, setEmail] = useState('');
  const [socialProviders, setSocialProviders] = useState([]);
  const [hasKampaohAccount, setHasKampaohAccount] = useState(false);

  const handleSubmit = useCallback(
    (values) => {
      setIsLoading(true);
      makeRequestToApServer(
        allowedMethods.get,
        routes.authVerify,
        values,
        (response) => {
          if (response?.statusCode === 200) {
            setIsLoading(false);
            if (Object.keys(response?.data).length === 0) {
              setActiveForm('register');
              setIsOpen(true);
              setEmail(values.email);
            } else if (response?.data?.is_verified) {
              setActiveForm('login');
              setIsOpen(true);
              setEmail(values.email);
              const providers = response?.data?.socialLogin.map(
                (provider) => provider.provider_name,
              );
              setHasKampaohAccount(response?.data?.has_kampaoh_account);
              providers.push('kampaoh');
              setSocialProviders(providers);
            } else {
              setActiveForm('verifyEmail');
              setIsOpen(true);
              setEmail(values.email);
            }
          }
        },
        (err) => {
          setIsLoading(false);
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      );
    },
    [intl],
  );

  const onCloseDrawer = useCallback(() => {
    setIsOpen(false);
    setActiveForm('');
  }, []);

  const onOpenRecoverPassword = useCallback(() => {
    setActiveForm('passwordReset');
  }, []);

  const getForm = useMemo(() => {
    switch (activeForm) {
      case 'register':
        return (
          <RegisterForm
            email={email}
            onClose={onCloseDrawer}
            setIsLoading={setIsLoading}
          />
        );
      case 'login':
        return (
          <LoginForm
            email={email}
            onClose={onCloseDrawer}
            onOpenRecoverPassword={onOpenRecoverPassword}
            socialProviders={socialProviders}
            hasKampaohAccount={hasKampaohAccount}
            setIsLoading={setIsLoading}
          />
        );
      case 'verifyEmail':
        return (
          <VerifyEmailForm
            email={email}
            onClose={onCloseDrawer}
            setIsLoading={setIsLoading}
          />
        );
      case 'passwordReset':
        return (
          <PasswordResetForm
            email={email}
            onClose={onCloseDrawer}
            setIsLoading={setIsLoading}
          />
        );
      default:
        return null;
    }
  }, [
    activeForm,
    email,
    onCloseDrawer,
    onOpenRecoverPassword,
    socialProviders,
    hasKampaohAccount,
  ]);

  const Spinner = useMemo(() => isLoading && <CustomSpin />, [isLoading]);

  return (
    <>
      {Spinner}
      <WelcomeForm onSubmit={handleSubmit} />
      <Drawer isOpen={isOpen}>{getForm}</Drawer>
    </>
  );
};

export default AuthPage;
