import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { wrapper, title, errorWrapper } from './index.module.scss';
import AvailabilityCalendar from './components/AvailabilityCalendar';
import AvailabilityContext from '../../contexts/AvailabilityContext';

const AvailabilityPanel = ({
  formatOpenDate,
  formatEndDate,
  startDateAvailable,
  endDateAvailable,
  setEndDateAvailable,
  setStartDateAvailable,
  openFrom,
}) => {
  const {
    error,
    onError,
    selectedUnit,
    currentDates,
    showCalendarUnavailable,
  } = useContext(AvailabilityContext);
  const [shownError, setShownError] = useState(error);

  useEffect(() => {
    if (error) {
      setShownError(error);
    } else {
      setShownError(null);
    }
  }, [error, onError]);

  useEffect(() => {
    setShownError(null);
  }, [selectedUnit, currentDates]);

  return (
    <div className={wrapper}>
      {!showCalendarUnavailable && (
        <div className={title}>
          <FormattedMessage id="AvailabilityDrawer.AvailabilityPanel.Title" />
        </div>
      )}
      {shownError && (
        <div className={errorWrapper}>
          <div>{shownError}</div>
        </div>
      )}
      <AvailabilityCalendar
        formatOpenDate={formatOpenDate}
        formatEndDate={formatEndDate}
        startDateAvailable={startDateAvailable}
        endDateAvailable={endDateAvailable}
        setStartDateAvailable={setStartDateAvailable}
        setEndDateAvailable={setEndDateAvailable}
        openFrom={openFrom}
      />
    </div>
  );
};

AvailabilityPanel.propTypes = {
  formatOpenDate: PropTypes.string.isRequired,
  formatEndDate: PropTypes.string.isRequired,
  startDateAvailable: PropTypes.string.isRequired,
  endDateAvailable: PropTypes.string.isRequired,
  setEndDateAvailable: PropTypes.func.isRequired,
  setStartDateAvailable: PropTypes.func.isRequired,
  openFrom: PropTypes.instanceOf(moment).isRequired,
};
AvailabilityPanel.defaultProps = {};
export default AvailabilityPanel;
