/* eslint-disable max-len */
import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { message } from 'antd';
import moment from 'moment';
import {
  wrapper,
  footer,
  deleteZone,
  disabled,
  footerNotDateFound,
} from './index.module.scss';
import CustomDrawer from '../CustomDrawer';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import UnitSelector from './components/UnitSelector';
import AvailabilityPanel from './components/AvailabilityPanel';
import AvailabilityContext, {
  AvailabilityProvider,
} from './contexts/AvailabilityContext';
import {
  useReservationDispatch,
  useReservationState,
} from '../../services/Reservation';
import {
  datesAreValid,
  peopleAreValid,
  reservationValidationErrorMessages,
} from '../../services/CheckValidReservation';
import CustomSpin from '../CustomSpin';
import calcDaysBetweenCheckInAndCheckOut from '../../utils/daysBetweenCheckInAndCheckOut';
import Services from '../../constants/services';
import ROUTES from '../../routes/routes';
import ActionButton from '../ActionButton';
import DatePickerDrawer from '../DatePickerDrawer';
import { SearchContext } from '../../contexts/SearchContext';
import Status from '../../constants/status';
import useCampings from '../../services/Campings/useCampings';
import { usePrevious } from '../../services/usePrevious';

export const AvailabilitySelector = ({
  customButton,
  singleMode,
  formatOpenDate,
  formatEndDate,
  onClose,
  openFrom,
  onOk,
}) => {
  const intl = useIntl();
  const {
    fetchingUnits,
    areAnyError,
    currentDates: { startDate, endDate },
    selectedUnit,
    property,
    selectedUnitRatePlan,
    checkedErrors,
    dispatch,
    availableUnits,
    filteredReferences,
    filteredRates,
    showCalendarUnavailable,
    startDateAvailable,
    setStartDateAvailable,
    endDateAvailable,
    setEndDateAvailable,
  } = useContext(AvailabilityContext);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const fpa = searchParams.get('fpa');
  const reservation = useReservationState();
  const dispatchReservation = useReservationDispatch();

  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
    endDate: moment.utc(endDate),
    startDate: moment.utc(startDate),
  });

  const firstDayAvailable = useMemo(
    () => moment(filteredRates?.date),
    [filteredRates],
  );

  // eslint-disable-next-line
  const endDateFirstDayAvailable = useMemo(() => {
    return moment(firstDayAvailable).add(daysBetweenCheckInAndCheckOut, 'days');
  }, [firstDayAvailable, daysBetweenCheckInAndCheckOut]);

  const [referenceRates, setReferenceRates] = useState();

  const prevFirstDayAvailable = usePrevious(firstDayAvailable);
  const prevEndDateFirstDayAvailable = usePrevious(endDateFirstDayAvailable);

  useEffect(() => {
    if (
      !moment(prevFirstDayAvailable).isSame(firstDayAvailable) ||
      !moment(prevEndDateFirstDayAvailable).isSame(endDateFirstDayAvailable)
    ) {
      setStartDateAvailable(firstDayAvailable);
      setEndDateAvailable(endDateFirstDayAvailable);
    }
  }, [
    firstDayAvailable,
    endDateFirstDayAvailable,
    prevFirstDayAvailable,
    prevEndDateFirstDayAvailable,
    setStartDateAvailable,
    setEndDateAvailable,
  ]);

  useEffect(() => {
    if (selectedUnit && filteredReferences) {
      setReferenceRates(filteredReferences);
    } else {
      setReferenceRates();
    }
  }, [selectedUnit, filteredReferences]);
  const areThereOpenDates = () => !startDateAvailable && !endDateAvailable;

  const getDatesBetween = (startDateString, endDateString) => {
    const newStartDate = moment(startDateString);
    const newEndDate = moment(endDateString);
    const datesBetween = [];
    const currentDate = newStartDate;
    while (currentDate.isSameOrBefore(newEndDate)) {
      datesBetween.push(currentDate.format('YYYY-MM-DD').toString());
      currentDate.add(1, 'day');
    }
    return datesBetween;
  };
  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };

  const updateQueryParams = useCallback(
    (key, value) => {
      searchParams.set(key, value);
      const url = new window.URL(window.location);
      const urlSearchParams = searchParams.toString();
      const currentParams = new URLSearchParams(url.search).toString();
      if (currentParams !== urlSearchParams) {
        updateUrlWithoutNavigation(
          `${url.pathname}?${searchParams.toString()}`,
        );
      }
    },
    [searchParams],
  );
  const datesBetween = getDatesBetween(startDateAvailable, endDateAvailable);
  const filteredReference = referenceRates?.filter((item) =>
    datesBetween.includes(item.date),
  );

  const startDateReference = filteredReference
    ? filteredReference?.find(
        (rate) =>
          rate.date ===
          moment(startDateAvailable).format('YYYY-MM-DD').toString(),
      )
    : filteredReference;
  const endDateReference = filteredReference
    ? filteredReference?.find(
        (rate) =>
          rate.date ===
          moment(endDateAvailable).format('YYYY-MM-DD').toString(),
      )
    : filteredReference;
  const referenceWithoutEndDate = filteredReference
    ? filteredReference?.filter(
        (rate) =>
          rate.date !==
          moment(endDateAvailable).format('YYYY-MM-DD').toString(),
      )
    : filteredReference;
  const areThereAvailableRooms = () =>
    referenceWithoutEndDate &&
    referenceWithoutEndDate?.every((element) => element.quantityAvailable > 0);
  const isClosedToArrival = () =>
    startDateReference && startDateReference?.closedToArrival === true;
  const isClosedToDeparture = () =>
    endDateReference && endDateReference.closedToArrival === true;
  const isClosedRate = () =>
    referenceWithoutEndDate &&
    referenceWithoutEndDate?.some((element) => element.closed === true);
  const amountIsGreaterThanZero = () =>
    referenceWithoutEndDate &&
    referenceWithoutEndDate?.every((element) => element.amountAfterTax > 0);
  const minNightsRoom = () =>
    filteredReference?.every(
      (element) => element.minStay <= filteredReference.length - 1,
    );

  const isDisabled =
    fetchingUnits ||
    !startDateAvailable ||
    !endDateAvailable ||
    areAnyError ||
    !checkedErrors ||
    availableUnits.length < 1 ||
    !filteredReference ||
    !areThereAvailableRooms() ||
    isClosedToArrival() ||
    isClosedToDeparture() ||
    isClosedRate() ||
    !amountIsGreaterThanZero() ||
    !minNightsRoom();

  return (
    <div className={wrapper}>
      {fetchingUnits ? (
        <CustomSpin />
      ) : (
        <>
          <UnitSelector singleMode={singleMode} />
          <AvailabilityPanel
            formatOpenDate={formatOpenDate}
            formatEndDate={formatEndDate}
            startDateAvailable={startDateAvailable}
            endDateAvailable={endDateAvailable}
            setStartDateAvailable={setStartDateAvailable}
            setEndDateAvailable={setEndDateAvailable}
            openFrom={openFrom}
          />
          <div
            className={!showCalendarUnavailable ? footer : footerNotDateFound}
          >
            {!showCalendarUnavailable && (
              <div
                className={`${deleteZone} ${
                  areThereOpenDates() ? disabled : ''
                }`}
              >
                <CloseIcon /> {/* eslint-disable-next-line */}
                <span
                  onClick={() => {
                    setStartDateAvailable(null);
                    setEndDateAvailable(null);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <FormattedMessage id="AvailabilityDrawer.DeleteDates" />
                </span>
              </div>
            )}
            <div>
              {selectedUnit &&
                (customButton ? (
                  customButton({
                    disabled: isDisabled,
                    currentDates: { startDate, endDate },
                    selectedUnitRatePlan,
                  })
                ) : (
                  <ActionButton
                    onClick={async () => {
                      if (
                        datesAreValid(reservation) &&
                        peopleAreValid(reservation) &&
                        !showCalendarUnavailable
                      ) {
                        dispatchReservation({ type: 'setItAsReadyToBeUsed' });
                        dispatchReservation({
                          type: 'setRatePlansAsNotFetched',
                        });
                        dispatch({ type: 'resetHotelsInitialState' });
                        const url = `${ROUTES.roomDetails}/${
                          property.ulysesId
                        }/${selectedUnit.roomTypeId}?checkin=${moment(
                          startDateAvailable,
                        ).format('YYYY-MM-DD')}&checkout=${moment(
                          endDateAvailable,
                        ).format('YYYY-MM-DD')}&adults=${
                          reservation.adults
                        }&children=${reservation.children}&babies=${
                          reservation.babies
                        }&fpa=${fpa}`;
                        updateQueryParams(
                          'checkin',
                          moment(startDateAvailable).format('YYYY-MM-DD'),
                        );
                        updateQueryParams(
                          'checkout',
                          moment(endDateAvailable).format('YYYY-MM-DD'),
                        );
                        dispatchReservation({
                          type: 'setStartDate',
                          payload: startDateAvailable,
                        });
                        dispatchReservation({
                          type: 'setEndDate',
                          payload: endDateAvailable,
                        });
                        window.open(url, '_blank');
                        if (onOk) {
                          onOk();
                        }
                      } else {
                        reservationValidationErrorMessages(reservation).map(
                          (errorMessage) =>
                            message.error(
                              intl.formatMessage({
                                id: errorMessage,
                                defaultMessage:
                                  'Ha ocurrido algo con su reserva, revise los datos',
                              }),
                            ),
                        );
                      }
                      if (showCalendarUnavailable) {
                        onClose();
                      }
                    }}
                    disabled={!showCalendarUnavailable ? isDisabled : false}
                    size="sm"
                  >
                    {!showCalendarUnavailable
                      ? 'OK'
                      : intl.formatMessage({ id: 'Card.Footer.Close' })}
                  </ActionButton>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

AvailabilitySelector.propTypes = {
  customButton: PropTypes.func,
  singleMode: PropTypes.bool,
  formatOpenDate: PropTypes.string.isRequired,
  formatEndDate: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  openFrom: PropTypes.instanceOf(moment).isRequired,
  onOk: PropTypes.func,
};

AvailabilitySelector.defaultProps = {
  customButton: undefined,
  singleMode: false,
  onClose: () => {},
  onOk: () => {},
};

const InnerDrawer = ({
  visible,
  onClose,
  service,
  customButton,
  singleMode,
  property,
  propertyID,
  onOk,
}) => {
  const intl = useIntl();
  const dispatch = useReservationDispatch();
  const { endDate, startDate } = useReservationState();

  const {
    reservationInitialDates: { startDate: _startDate, endDate: _endDate },
    getReferenceRate,
    setCurrentDates,
    setReservationInitialDates,
    setLoadingRatePlans,
    showMoreDatesDrawer,
    setShowMoreDatesDrawer,
    setRelaunchGetReference,
  } = useContext(AvailabilityContext);

  const {
    filteredStatus: { [propertyID]: status },
  } = useContext(SearchContext);

  const propertyId = property?.ulysesId;
  const {
    state: {
      campings: { [propertyId]: camping },
    },
  } = useCampings();
  const openFrom = moment(camping?.openFrom).format('YYYY-MM-DD');
  const openFromDate = moment(property.openFrom).add(2, 'days');
  const formatOpenDate = moment(property.openFrom).format('YYYY-MM-DD');
  const formatEndDate = openFromDate.format('YYYY-MM-DD');

  useEffect(() => {
    dispatch({ type: 'setItAsUpdatingAvailability', payload: true });
    return () =>
      dispatch({ type: 'setItAsUpdatingAvailability', payload: false });
  }, [dispatch]);

  return (
    <>
      <CustomDrawer
        visible={visible}
        height="95vh"
        title={intl.formatMessage({
          id: `#AvailabilityDrawer.${service}.Title`,
        })}
        subTitle={intl.formatMessage({
          id: `#AvailabilityDrawer.${service}.SubTitle`,
        })}
        icon={<CloseIcon />}
        onClose={onClose}
        mask
      >
        <AvailabilitySelector
          customButton={customButton}
          singleMode={singleMode}
          formatOpenDate={formatOpenDate}
          formatEndDate={formatEndDate}
          onClose={onClose}
          openFrom={openFrom}
          onOk={onOk}
        />
      </CustomDrawer>
      <DatePickerDrawer
        initialEndDate={
          status === Status.CLOSED ? moment(formatEndDate) : _endDate
        }
        initialStartDate={
          status === Status.CLOSED ? moment(formatOpenDate) : _startDate
        }
        visible={showMoreDatesDrawer}
        onClose={() => {
          if (startDate === null || endDate === null) {
            setCurrentDates({
              startDate:
                status === Status.CLOSED ? moment(formatEndDate) : _endDate,
              endDate:
                status === Status.CLOSED ? moment(formatOpenDate) : _startDate,
            });
          }

          setShowMoreDatesDrawer(false);
        }}
        onOk={(dates) => {
          setLoadingRatePlans(true);
          setReservationInitialDates(dates);
          setCurrentDates(dates);
          getReferenceRate(dates);
          setShowMoreDatesDrawer(false);
        }}
        setRelaunchGetReference={setRelaunchGetReference}
        openFrom={openFrom}
      />
    </>
  );
};

InnerDrawer.propTypes = {
  visible: PropTypes.bool,
  singleMode: PropTypes.bool,
  onClose: PropTypes.func,
  customButton: PropTypes.element,
  service: PropTypes.oneOf(Object.values(Services)).isRequired,
  property: PropTypes.objectOf(PropTypes.shape({ property: PropTypes.func }))
    .isRequired,
  propertyID: PropTypes.string,
  onOk: PropTypes.func,
};

InnerDrawer.defaultProps = {
  visible: false,
  singleMode: false,
  onClose: () => {},
  customButton: undefined,
  propertyID: '',
  onOk: () => {},
};

const AvailabilityDrawer = ({
  visible,
  onClose,
  ratePlans,
  property,
  rateId,
  kampaohService: service,
  unitId,
  singleMode,
  customButton,
  propertyID,
  onOk,
}) => {
  const dispatch = useReservationDispatch();
  const { kampaohService } = useReservationState();

  useEffect(() => {
    if (service !== kampaohService) {
      dispatch({ type: 'setKampaohService', payload: service });
    }
  }, [dispatch, kampaohService, service]);

  return (
    <AvailabilityProvider
      property={property}
      ratePlans={ratePlans}
      rateId={rateId}
      unitId={unitId}
      visibility={visible}
    >
      <InnerDrawer
        visible={visible}
        onClose={onClose}
        service={service}
        customButton={customButton}
        singleMode={singleMode}
        property={property}
        propertyID={propertyID}
        onOk={onOk}
      />
    </AvailabilityProvider>
  );
};

AvailabilityDrawer.propTypes = {
  visible: PropTypes.bool,
  singleMode: PropTypes.bool,
  onClose: PropTypes.func,
  ratePlans: PropTypes.objectOf(PropTypes.shape({ ratePlans: PropTypes.func })),
  property: PropTypes.objectOf(
    PropTypes.shape({
      property: PropTypes.func,
      openFrom: PropTypes.string,
      openTo: PropTypes.string,
    }),
  ).isRequired,
  rateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unitId: PropTypes.string,
  kampaohService: PropTypes.oneOf(Object.values(Services)).isRequired,
  customButton: PropTypes.element,
  propertyID: PropTypes.string,
  onOk: PropTypes.func,
};

AvailabilityDrawer.defaultProps = {
  visible: false,
  singleMode: false,
  onClose: () => {},
  ratePlans: [],
  rateId: undefined,
  unitId: undefined,
  customButton: undefined,
  propertyID: '',
  onOk: () => {},
};
export default AvailabilityDrawer;
