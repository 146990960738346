// TODDO BUTTONS STYLE
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  carouselContainer,
  carouselButtons,
  prev,
  next,
} from './index.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/return.svg';

const Carousel = ({ children }) => {
  const sliderRef = useRef(null);

  return (
    <div className={carouselContainer}>
      <Slider
        ref={sliderRef}
        dots={false}
        arrows={false}
        infinite={false}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        variableWidth
        swipeToSlide
      >
        {React.Children.map(children, (child) => (
          <div key={child}>{child}</div>
        ))}
      </Slider>
      <div className={carouselButtons}>
        <button
          className={prev}
          onClick={() => sliderRef.current.slickPrev()}
          type="button"
        >
          <Arrow />
        </button>
        <button
          className={next}
          onClick={() => sliderRef.current.slickNext()}
          type="button"
        >
          <Arrow />
        </button>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Carousel;
