import React from 'react';
import { PropTypes } from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { FormattedDate } from 'react-intl';
import moment from 'moment';
import useDeviceDetect from '../../../../utils/useDeviceDetect';
import { wrapper, locationIcon } from './index.module.scss';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { ReactComponent as DownArrowIcon } from '../../../../assets/icons/down-arrow.svg';
import { ReactComponent as RightArrowIcon } from '../../../../assets/icons/right-arrow.svg';
import { useReservationState } from '../../../../services/Reservation';

const DatesSelector = ({ onClick }) => {
  const { startDate, endDate } = useReservationState();
  const isLoading = false;
  const { isMobile } = useDeviceDetect();

  const currentYear = moment().year();
  const startYear = moment(startDate).year();
  const endYear = moment(endDate).year();
  
  const formatOptions = isMobile && (startYear === currentYear && endYear === currentYear)
    ? { month: 'long', day: '2-digit' }
    : { year: 'numeric', month: 'long', day: '2-digit' };

  return (
    <>
      <div
        className={wrapper}
        onClick={!isLoading ? onClick : undefined}
        onKeyPress={!isLoading ? onClick : undefined}
        role="button"
        tabIndex={0}
      >
        <div>
          <CalendarIcon className={locationIcon} />
          {' '}
          {!isLoading ? (
          <span>
            <FormattedDate
              value={startDate}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...formatOptions}
            />
            {' '}
            <RightArrowIcon />
            {' '}
            <FormattedDate
              value={endDate}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...formatOptions}
            />
          </span>
        ) : <LoadingOutlined />}
        </div>

        <DownArrowIcon />
      </div>
    </>

  );
};

DatesSelector.propTypes = {
  onClick: PropTypes.func,
};

DatesSelector.defaultProps = {
  onClick: () => {},
};
export default DatesSelector;
