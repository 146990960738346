const userReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        ...action.payload,
      };
    case 'CLEAR_USER':
      return {
        id: null,
        email: '',
        name: '',
        createdAt: '',
        updatedAt: '',
      };
    default:
      return state;
  }
};

export default userReducer;
