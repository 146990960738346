/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const VerifyEmailLayout =
  (Component) =>
  ({ ...props }) =>
    (
      <Layout style={{ minHeight: '100vh' }}>
        <Content>
          <Component {...props} />
        </Content>
      </Layout>
    );

VerifyEmailLayout.propTypes = {};

VerifyEmailLayout.defaultProps = {};
export default VerifyEmailLayout;
