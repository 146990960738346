import React from 'react';
import { PropTypes } from 'prop-types';
import { wrapper } from './index.module.scss';

const FloatingBar = ({ children }) => (
  <div className={`${wrapper}`}>
    {children}
  </div>
);

FloatingBar.propTypes = {
  children: PropTypes.element.isRequired,
};

FloatingBar.defaultProps = {

};
export default FloatingBar;
