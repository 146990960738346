const initialState = {
  campings: [],
  tents: [],
  tentFeatures: [],
  campingFeatures: [],
  conditions: [],
  locations: [],
  isLoadingCampings: false,
  isLoadingLocations: false,
  isLoadingRatePlans: false,
  ratePlansAlreadyFetched: false,
  errors: [],
  areRatePlansFetched: false,
};

export default initialState;
