/* eslint-disable max-len */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useContext } from "react";
import { PropTypes } from "prop-types";
import { Spin } from "antd";
import {
  wrapper,
  SpinnerContainerStyle,
  countryCard,
} from "./index.module.scss";
import { routes } from "../../constants/cms";
import { makeRequestToCmsServer } from "../../services/API";
import { LanguageContext } from "../../locale/contexts/Language";
// import ZoneWrapper from './ZoneWrapper';
import ZoneModal from "./zoneModal";
import { ReactComponent as SpainMap } from "../../assets/map/spain_map.svg";
import { ReactComponent as FranceMap } from "../../assets/map/france_map.svg";
// import { ReactComponent as FilipinesMap } from '../../assets/map/filipines_map.svg';
import { ReactComponent as MoroccoMap } from "../../assets/map/morocco_map.svg";
import { ReactComponent as RepDomiMap } from "../../assets/map/repDomi_map.svg";
import { ReactComponent as CerdenaMap } from "../../assets/map/cerdena_map.svg";
import { ReactComponent as PortugalMap } from "../../assets/map/portugal_map.svg";
// import { ReactComponent as ItalylMap } from '../../assets/map/italy_map.svg';

const MapComponent = ({ onChange }) => {
  const [zones, setZones] = useState();
  const [isLoadingZones, setIsLoadingZones] = useState(false);
  const [subZoneVisible, setSubZoneVisible] = useState(false);
  const [subZoneId, setSubZoneId] = useState();

  const { currentLanguage } = useContext(LanguageContext);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingZones(true);
      const response = await makeRequestToCmsServer(
        `${routes.zones}&populate[regions][populate][1]=zone&locale=${currentLanguage}`,
      );
      if (response) {
        const mappedZones = response.map((zone) => {
          const sum = zone.regions
            .map((region) => region.campings.length)
            .reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0,
            );
          return {
            internalID: zone.internalID,
            top: zone.top - 70,
            left: zone.left,
            name: zone.Name,
            campingsCount: sum,
            regions: zone.regions,
          };
        });
        setZones(mappedZones);
        setIsLoadingZones(false);
      }
    };
    fetchData();
  }, [currentLanguage]);

  const handleOnDestinationPick = (location) => {
    if (location.campingsCount === 0) return null;
    return onChange({ location });
  };

  const openSubZoneWrapper = (zoneId) => {
    setSubZoneId(zoneId);
    setSubZoneVisible(true);
  };

  const handleClose = () => {
    setSubZoneVisible(false);
    // Additional code to handle any other necessary actions when closing the component
  };

  const mapsIdComponents = {
    1: SpainMap,
    2: FranceMap,
    3: PortugalMap,
    4: CerdenaMap,
    5: RepDomiMap,
    6: MoroccoMap,
  };
  const countriesId = [1, 2, 3, 4, 5, 6];
  // eslint-disable-next-line react/prop-types
  const MapById = ({ id }) => {
    const ComponentMap = mapsIdComponents[id];
    if (!ComponentMap) {
      return <div>Mapa no disponible</div>;
    }
    return <ComponentMap />;
  };

  return !zones && isLoadingZones ? (
    <Spin className={SpinnerContainerStyle} size="large" />
  ) : (
    <>
      <div className={wrapper}>
        {zones
          ?.filter((zone) => countriesId.includes(zone.internalID))
          .slice()
          .sort((a, b) => {
            if (a.internalID === 1) return -1;
            if (b.internalID === 1) return 1;
            return a.internalID - b.internalID;
          })
          .map((zone) => (
            // eslint-disable-next-line no-shadow
            <div
              data-cy="country-card"
              className={countryCard}
              onClick={() =>
                zone.regions.length > 1
                  ? openSubZoneWrapper()
                  : handleOnDestinationPick({
                      name: zone.name,
                      id: zone.internalID,
                      campingsCount: zone.campingsCount,
                    })
              }
            >
              <MapById id={zone.internalID} />
              <strong>{zone.name}</strong>
            </div>
          ))}
      </div>
      <ZoneModal
        visible={subZoneVisible}
        zoneId={subZoneId}
        zones={zones}
        onClose={handleClose}
        onChange={onChange}
      />
    </>
  );
};

MapComponent.propTypes = {
  onChange: PropTypes.func,
};

MapComponent.defaultProps = {
  onChange: () => {},
};

export default MapComponent;
