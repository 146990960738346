import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { PropTypes } from 'prop-types';
import { LanguageContext } from './Language';
import messages from '../translations';

const CustomIntlProvider = ({ children }) => {
  const { currentLanguage } = useContext(LanguageContext);
  return (
    <IntlProvider locale={currentLanguage.replace('_', '-')} messages={messages(currentLanguage)}>
      {children}
    </IntlProvider>
  );
};

CustomIntlProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CustomIntlProvider;
