import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { hurryUpWrapper, subTitle } from './index.module.scss';
import { ReactComponent as CronoIcon } from '../../assets/icons/crono.svg';

function HighDemandWarning({ titleLabel, subtitleLabel }) {
  const intl = useIntl();

  return (
    <div className={hurryUpWrapper}>
      <CronoIcon />
      <div>
        <p><strong>{intl.formatMessage({ id: titleLabel })}</strong></p>
        <p className={subTitle}>{intl.formatMessage({ id: subtitleLabel })}</p>
      </div>
    </div>
  );
}

HighDemandWarning.defaultProps = {
  titleLabel: '',
  subtitleLabel: '',
};

HighDemandWarning.propTypes = {
  titleLabel: PropTypes.string,
  subtitleLabel: PropTypes.string,
};

export default HighDemandWarning;
