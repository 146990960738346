import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import pt from './pt.json';

const locales = ['en_US', 'es_ES', 'fr_FR', 'it_IT', 'pt_PT'];

const messages = (locale) => {
  switch (locale) {
    case 'en_US':
    case 'en-US':
    case 'en_GB':
    case 'en-GB':
      return en;
    case 'es-ES':
    case 'es_ES':
      return es;
    case 'fr_FR':
    case 'fr-FR':
      return fr;
    case 'it_IT':
    case 'it-IT':
      return it;
    case 'pt_PT':
    case 'pt-PT':
      return pt;
    default:
      if (locale.includes('es')) return es;
      if (locale.includes('fr')) return fr;
      if (locale.includes('en')) return en;
      if (locale.includes('it')) return it;
      if (locale.includes('pt')) return pt;
      return es;
  }
};

const getMainLocale = (locale) => {
  switch (locale) {
    case 'en_US':
    case 'en-US':
    case 'en_GB':
    case 'en-GB':
    case 'en':
      return 'en';
    case 'es-ES':
    case 'es_ES':
    case 'es':
      return 'es';
    case 'fr_FR':
    case 'fr-FR':
    case 'fr':
      return 'fr';
    case 'it_IT':
    case 'it-IT':
    case 'it':
      return 'it';
    case 'pt_PT':
    case 'pt-PT':
    case 'pt':
      return 'pt';
    default:
      if (locale.includes('es')) return 'es';
      if (locale.includes('fr')) return 'fr';
      if (locale.includes('en')) return 'en';
      if (locale.includes('it')) return 'it';
      if (locale.includes('pt')) return 'pt';
      return 'es';
  }
};

export { locales, getMainLocale };
export default messages;
