import React from 'react';
import { PropTypes } from 'prop-types';
import { cardScore } from './index.module.scss';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';

const Score = ({ score, className }) => (
  <p className={`${cardScore} ${className}`}>
    <StarIcon />
    {' '}
    <span>{score}</span>
  </p>
);

Score.propTypes = {
  score: PropTypes.number.isRequired,
  className: PropTypes.string,
};

Score.defaultProps = {
  className: '',
};

export default Score;
