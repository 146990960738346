import React, { useContext, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, message } from 'antd';
import { navigate } from '@reach/router';
import * as s from './index.module.scss';
import { datesAreValid, peopleAreValid, reservationValidationErrorMessages } from '../../../../services/CheckValidReservation';
import { useReservationDispatch, useReservationState } from '../../../../services/Reservation';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as ReturnIcon } from '../../../../assets/icons/return.svg';
import Counters from '../../../../components/Counters';
import RangeDatePicker from '../../../../components/RangeDatePicker';
import makeRequestToServer from '../../../../services/API';
import { LanguageContext } from '../../../../locale/contexts/Language';
import useCampings from '../../../../services/Campings/useCampings';
import { AvailabilitySelector } from '../../../../components/AvailabilityDrawer';
import { AvailabilityProvider } from '../../../../components/AvailabilityDrawer/contexts/AvailabilityContext';
import WizardStep from '../../../Home/components/ReservationWizard/components/WizardStep';
import ActionButton from '../../../../components/ActionButton';
import validatePropertyRatePlans from '../../../../services/validatePropertyRatePlans';
import Services from '../../../../constants/services';
import Status from '../../../../constants/status';
import ROUTES from '../../../../routes/routes';

const UnitReservationWizard = ({
  visible, onClose, property, unitId, service, initialRateId,
}) => {
  const intl = useIntl();
  const { currentLanguage } = useContext(LanguageContext);
  const {
    startDate: _startDate,
    endDate: _endDate,
    adults: adultsCount,
    children: boysCount,
    babies: babiesCount,
    promoCode,
  } = useReservationState();

  const reservation = useReservationState();
  const dispatch = useReservationDispatch();
  const { dispatch: dispatchCampings, tentState: { tents: { [unitId]: tent } } } = useCampings();
  const [currentStep, setCurrentStep] = useState(1);
  const [dates, setDates] = useState({ startDate: _startDate, endDate: _endDate });
  const [{ adults, children, babies }, setPersons] = useState({
    adults: adultsCount,
    children: boysCount,
    babies: babiesCount,
  });

  const unit = tent;

  const dispatchReservation = (ratePlans) => {
    if (datesAreValid(reservation) && peopleAreValid(reservation)) {
      dispatch({ type: 'setRatePlansAsFetched' });
      dispatchCampings({
        type: 'addRatePlanToCamping',
        payload: {
          campingId: property.ulysesId,
          ratePlans,
        },
      });
      dispatch({ type: 'setItAsReadyToBeUsed', payload: true });
      window.dataLayer.push({
        event: 'searchStartBE',
        checkinDate: dates.startDate.format('YYYY/MM/DD'),
        checkoutDate: dates.endDate.format('YYYY/MM/DD'),
        bookingNights: dates.endDate.diff(dates.startDate, 'days'),
        numAdults: adultsCount,
        numKids: boysCount,
        numBabies: babiesCount,
      });
    } else {
      reservationValidationErrorMessages(reservation).map((errorMessage) => (
        message.error(intl.formatMessage({ id: errorMessage, defaultMessage: 'Ha ocurrido algo con su reserva, revise los datos' }))));
    }
  };

  const askForOnePropertyRatePlan = async (_dates = dates) => {
    const { data } = await makeRequestToServer(`/getRatePlans?detailedRates=true&propertyID=${property.ulysesId}&startDate=${_dates.startDate.format('YYYY-MM-DD')}&endDate=${_dates.endDate.format('YYYY-MM-DD')}&adults=${adults}&children=${children}${promoCode !== '' ? '&promoCode='.concat(promoCode) : '&includePromoCode=false'}&lang=${currentLanguage}`);
    if (!data && reservation.promoCode !== '') {
      const { data: altenativeRatePlans } = await makeRequestToServer(`/getRatePlans?detailedRates=true&propertyID=${property.ulysesId}&startDate=${_dates.startDate.format('YYYY-MM-DD')}&endDate=${_dates.endDate.format('YYYY-MM-DD')}&adults=${adults}&children=${children}&includePromoCode=false&lang=${currentLanguage}`);
      return altenativeRatePlans;
    }
    return data;
  };

  const isClosedDates = () => Object.values(dates).every((value) => value);

  const onDatesOk = () => {
    if (datesAreValid({ ...dates })) {
      setCurrentStep((prev) => prev + 1);
    } else {
      reservationValidationErrorMessages({ ...dates }).map((errorMessage) => (
        message.error(intl.formatMessage({ id: errorMessage, defaultMessage: 'Ha ocurrido algo con su reserva, revise los datos' }))));
    }
  };

  const onDatesReset = () => {
    setDates({ startDate: null, endDate: null });
  };

  const onPersonsReset = () => {
    setPersons({
      adults: 0,
      children: 0,
      babies: 0,
    });
  };

  const onPersonsOk = async () => {
    dispatch({ type: 'setStartDate', payload: dates.startDate });
    dispatch({ type: 'setEndDate', payload: dates.endDate });
    dispatch({ type: 'setadults', payload: adults });
    dispatch({ type: 'setchildren', payload: children });
    dispatch({ type: 'setbabies', payload: babies });
    const ratePlans = await askForOnePropertyRatePlan();
    if (ratePlans) {
      const unitRatePlans = ratePlans.filter((rate) => `${rate.roomTypeID}` === unitId);
      if (unitRatePlans.length > 0
        && validatePropertyRatePlans(unitRatePlans, property).status === Status.AVAILABLE) {
        dispatchReservation(ratePlans);
        if (initialRateId) {
          navigate(`${ROUTES.roomDetails}/${property.ulysesId}/${unitId}?checkin=${dates.startDate.format('YYYY-MM-DD')}&checkout=${dates.endDate.format('YYYY-MM-DD')}&adults=${adults}&children=${children}&babies=${babies}`);
        }
        onClose();
      } else {
        setCurrentStep((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    dispatch({ type: 'setKampaohService', payload: service });
  }, [service, dispatch]);

  return (
    <>
      <WizardStep
        mask
        visible={visible && currentStep >= 1}
        onClose={onClose}
        title={<FormattedMessage id="UnitReservationWizard.step1.title" />}
        footer={(
          <div className={s.footer}>
            <div className={`${s.deleteZone} ${!isClosedDates() ? s.disabled : ''}`}>
              <CloseIcon />
              {' '}
              <span
                onClick={onDatesReset}
                onKeyPress={onDatesReset}
                role="button"
                tabIndex={0}
              >
                <FormattedMessage id="UnitReservationWizard.step1.deleteDates" />
              </span>
            </div>
            <div>
              <Button className={`${!isClosedDates() ? s.disabled : ''}`} onClick={onDatesOk}>OK</Button>
            </div>
          </div>
        )}
        icon={<CloseIcon />}
        zIndex={16}
      >
        <RangeDatePicker
          startDate={dates.startDate}
          endDate={dates.endDate}
          onChange={(_dates) => {
            setDates(_dates);
          }}
        />
      </WizardStep>
      <WizardStep
        visible={visible && currentStep >= 2}
        onClose={() => setCurrentStep((prev) => prev - 1)}
        title={<FormattedMessage id="UnitReservationWizard.step2.title" />}
        footer={(
          <div className={s.footer}>
            <div className={`${s.deleteZone} ${!isClosedDates() ? s.disabled : ''}`}>
              <CloseIcon />
              {' '}
              <span
                onClick={onPersonsReset}
                onKeyPress={onPersonsReset}
                role="button"
                tabIndex={0}
              >
                <FormattedMessage id="UnitReservationWizard.step2.deleteCounters" />
              </span>
            </div>
            <div>
              <Button className={`${!isClosedDates() ? s.disabled : ''}`} onClick={() => { onPersonsOk(); }}>OK</Button>
            </div>
          </div>
)}
        icon={<ReturnIcon />}
        zIndex={17}
      >
        <div className={s.wrapper}>
          <Counters
            showBackground={false}
            showLines
            onChange={(persons) => { setPersons(persons); }}
            values={{ adults, children, babies }}
            service={service}
            maxCount={unit.tent_model.maxGuests}
          />
        </div>
      </WizardStep>
      <WizardStep
        visible={visible && currentStep >= 3}
        onClose={() => setCurrentStep((prev) => prev - 1)}
        title={<FormattedMessage id="UnitReservationWizard.step3.title" />}
        icon={<ReturnIcon />}
        zIndex={18}
      >
        <div className={s.availabilityWrapper}>
          <AvailabilityProvider
            property={property}
            unitId={unitId}
            visibility
          >
            <AvailabilitySelector
              singleMode
              formatOpenDate={dates?.startDate}
              formatEndDate={dates?.endDate}
              customButton={({ disabled, currentDates }) => (
                <ActionButton
                  disabled={disabled}
                  size="sm"
                  className={s.actionButton}
                  onClick={async () => {
                    const ratePlans = await askForOnePropertyRatePlan(currentDates);
                    if (ratePlans) {
                      const unitRatePlans = ratePlans.filter((rate) => `${rate.roomTypeID}` === unitId);
                      if (unitRatePlans.length === 1) {
                        dispatch({ type: 'setStartDate', payload: currentDates.startDate });
                        dispatch({ type: 'setEndDate', payload: currentDates.endDate });
                        dispatchReservation(ratePlans);
                        navigate(`${ROUTES.roomDetails}/${property.ulysesId}/${unitId}${unitRatePlans.length === 1 ? `/${unitRatePlans[0].rateID}` : ''}?checkin=${dates.startDate.format('YYYY-MM-DD')}&checkout=${dates.endDate.format('YYYY-MM-DD')}&adults=${adults}&children=${children}&babies=${babies}`);
                        onClose();
                      } else {
                        reservationValidationErrorMessages({ ...dates }).forEach((errorMessage) => {
                          message.error(intl.formatMessage({ id: errorMessage, defaultMessage: 'Ha ocurrido algo con su reserva, revise los datos' }));
                        });
                        onClose();
                      }
                    }

                    onClose();
                  }}
                >
                  OK
                </ActionButton>
              )}
            />
          </AvailabilityProvider>
        </div>
      </WizardStep>
    </>
  );
};

UnitReservationWizard.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  property: PropTypes.shape({ ulysesId: PropTypes.string }).isRequired,
  unitId: PropTypes.string.isRequired,
  service: PropTypes.oneOf(Object.values(Services)).isRequired,
  initialRateId: PropTypes.string,
};

UnitReservationWizard.defaultProps = {
  visible: false,
  onClose: () => {},
  initialRateId: undefined,

};
export default UnitReservationWizard;
