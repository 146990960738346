import React from 'react';
import { Button } from 'antd';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { button } from './index.module.scss';
import { ReactComponent as ArrowIcon } from '../../../../../../assets/icons/return.svg';

const types = {
  POLICY: 'policy',
  CONDITION: 'condition',
};

const AcceptanceButton = ({ onClick, type }) => {
  const intl = useIntl();
  return (
    <Button
      size="large"
      type="link"
      shape="round"
      className={button}
      onClick={onClick}
    >
      {intl.formatMessage({ id: type === types.POLICY ? 'Policies.ViewHere' : 'Policies.ViewPropertyConditions' })}
      {' '}
      <ArrowIcon />
    </Button>
  );
};

AcceptanceButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(types)),
};

AcceptanceButton.defaultProps = {
  onClick: () => {},
  type: types.POLICY,
};

export default AcceptanceButton;
