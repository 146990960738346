import React from 'react';
import PropTypes from 'prop-types';
import CustomDrawer from '../../../../../../components/CustomDrawer';
import { wrapper, footer as _footer } from './index.module.scss';

const WizardStep = ({
  visible, onClose, children, title, footer, icon, zIndex, mask,
}) => (
  <CustomDrawer
    title={title}
    visible={visible}
    onClose={onClose}
    icon={icon}
    zIndex={zIndex}
    mask={mask}
    subTitle={null}
  >
    <div className={wrapper}>
      {children}

      {footer && (
      <div className={_footer}>
        {footer}
      </div>
      )}
    </div>
  </CustomDrawer>
);

WizardStep.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.element.isRequired,
  icon: PropTypes.element,
  footer: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  zIndex: PropTypes.number,
  mask: PropTypes.bool,
};

WizardStep.defaultProps = {
  visible: false,
  onClose: () => {},
  title: '',
  footer: null,
  icon: null,
  zIndex: undefined,
  mask: false,
};

export default WizardStep;
