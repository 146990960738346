import React from 'react';
import { PropTypes } from 'prop-types';
import { wrapper } from './index.module.scss';

const ReservationInfo = ({ data }) => (
  <div className={wrapper}>
    {data.map(({ icon: Icon, message, title, id }) => (
      <div key={id}>
        <div>
          <Icon />
        </div>
        <div>
          <p>{title}</p>
          <p>{message}</p>
        </div>
      </div>
    ))}
  </div>
);

ReservationInfo.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      title: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
};

ReservationInfo.defaultProps = {};
export default ReservationInfo;
