import { Form, Radio, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import rules from '../validationRules';
import {
    howDidYouMeetUsWrapper, labelStyle,
    radioElementStyle,
    radioGroupStyle,
} from './index.module.scss';

const HowDidYouMeetUs = () => {
  const intl = useIntl();
  const fieldTitle = intl.formatMessage({ id: 'UserForm.Validation.Meet.Title', defaultMessage: '¿Cómo nos has conocido?' });
  const fieldLabel = useMemo(
    () => <Typography.Text className={labelStyle} strong>{fieldTitle}</Typography.Text>,
    [fieldTitle],
  );

  return (
    <div className={howDidYouMeetUsWrapper}>
      <Form.Item name="FUENTE" rules={rules.FUENTE} label={fieldLabel}>
        <Radio.Group className={radioGroupStyle} data-cy="checkout-step3-source">
          <Radio className={radioElementStyle} value="Facebook">Facebook</Radio>
          <Radio className={radioElementStyle} value="Instagram">Instagram</Radio>
          <Radio className={radioElementStyle} value="Tiktok">TikTok</Radio>
          <Radio className={radioElementStyle} value="Booking">Booking</Radio>
          <Radio className={radioElementStyle} value="Google">Google</Radio>
          <Radio className={radioElementStyle} value="Boca">{intl.formatMessage({ id: 'UserForm.Validation.Meet.MouthToMouth', defaultMessage: 'Boca a boca' })}</Radio>
          <Radio className={radioElementStyle} value="Otro">{intl.formatMessage({ id: 'UserForm.Validation.Meet.Other', defaultMessage: 'Otro' })}</Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default HowDidYouMeetUs;
