import React from 'react';
import { FormattedMessage } from 'react-intl';

const rules = {
  Email: [
    {
      type: 'email',
      message: <FormattedMessage id="UserForm.Validation.Email.Valid" />,
    },
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Email.Required" />,
    },
    {
    // eslint-disable-next-line no-useless-escape
      pattern: /^(.(?!\.con$))+$/i,
      message: <FormattedMessage id="UserForm.Validation.Email.ConEnded" />,
    },
  ],
  Name: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Name.Required" />,
    },
  ],
  Surname: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Surname.Required" />,
    },
  ],
  Phone: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Phone.Required" />,
    },
    {
      // eslint-disable-next-line no-useless-escape
      pattern: /^[0-9]{3}[-\s\.]?[0-9]{3,7}$/im,
      message: <FormattedMessage id="UserForm.Validation.Phone.Valid" />,
    },
  ],
  DNI: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.DNI.Required" />,
    },
  ],
  COMP: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Company.Required" />,
    },
  ],
  FUENTE: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Meet.Required" />,
    },
  ],
  Genre: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Genre.Required" />,
    },
  ],
  Country: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.Country.Required" />,
    },
  ],
  PostalCode: [
    {
      required: true,
      message: <FormattedMessage id="UserForm.Validation.PostalCode.Required" />,
    },
  ],
};

export default rules;
