import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import makeRequestToApServer from '../../../../../services/ApServer/askToApServer';
import { allowedMethods } from '../../../../../constants/ApServer/apServer';
import routes from '../../../../../constants/ApServer/apRoutes';
import openNotification from '../../../../../components/NotificacionMessage';
import {
  form,
  formRow,
  error,
  formActions,
  saveButton,
  customDateInput,
} from './index.module.scss';

const minAge = 18;

const BirthDateForm = ({ initialValues, onSave }) => {
  const intl = useIntl();

  const minDate = React.useMemo(() => {
    const today = new Date();
    return new Date(
      today.getFullYear() - minAge,
      today.getMonth(),
      today.getDate(),
    );
  }, []);
  const formik = useFormik({
    initialValues: {
      birthDate: initialValues.birthDate || '',
    },
    validationSchema: Yup.object({
      birthDate: Yup.date()
        .max(
          minDate,
          intl.formatMessage({
            id: 'BirthDateForm.Validation.BirthDate.AgeLimit',
          }),
        )
        .required(
          intl.formatMessage({
            id: 'BirthDateForm.Validation.BirthDate.Required',
          }),
        ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      makeRequestToApServer(
        allowedMethods.put,
        routes.authMe,
        values,
        ({ statusCode }) => {
          if (statusCode === 200)
            openNotification(
              'success',
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Title.Text',
              }),
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Message.Text',
              }),
              () => {
                onSave(values);
              },
              null,
            );
        },
        (err) => {
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      ).finally(() => {
        setSubmitting(false);
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={form}>
        <div className={formRow}>
          <label htmlFor="birthDate">
            {intl.formatMessage({
              id: 'BirthDateForm.Validation.BirthDate.Text',
            })}
          </label>
          <input
            className={customDateInput}
            type="date"
            id="birthDate"
            name="birthDate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.birthDate}
          />
          {formik.touched.birthDate && formik.errors.birthDate ? (
            <div className={error}>{formik.errors.birthDate}</div>
          ) : null}
        </div>
      </div>

      <div className={formActions}>
        <button
          type="submit"
          className={saveButton}
          disabled={formik.isSubmitting}
        >
          {intl.formatMessage({
            id: 'BirthDateForm.Button.Text',
          })}
        </button>
      </div>
    </form>
  );
};

BirthDateForm.propTypes = {
  initialValues: PropTypes.shape({
    birthDate: PropTypes.string.isRequired,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default BirthDateForm;
