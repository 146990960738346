import React from 'react';
import { PropTypes } from 'prop-types';
import { wrapper, characteristic, icon } from './index.module.scss';

const Characteristics = ({ data }) => (
  <div className={wrapper}>
    {data.map(({ icon: Icon, message, title }, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className={characteristic} key={key}>
        <div className={icon}>
          <Icon />
        </div>
        <div>
          <p>{title}</p>
          <p>{message}</p>
        </div>
      </div>
    ))}
  </div>
);

Characteristics.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.objectOf(PropTypes.any),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  })).isRequired,
};

Characteristics.defaultProps = {

};
export default Characteristics;
