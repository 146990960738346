import { useLayoutEffect, useState } from 'react';
import {
  isAccessTokenExpired,
  isRefreshTokenExpired,
} from '../services/ApServer/authService';
import { useAuthState } from '../services/Auth/useAuth';
import { clearTokens } from './tokenHelper';
import ROUTES from '../routes/routes';

/* eslint-disable */
const useRefreshToken = () => {
  const { accessToken } = useAuthState();
  const [expiredTokens, setExpiredTokens] = useState(false);
  useLayoutEffect(() => {
    const refreshToken = async () => {
      try {
        if (accessToken !== null) {
          const isAccessTokenExp = await isAccessTokenExpired();

          if (isAccessTokenExp) {
            const isRefreshTokenExp = await isRefreshTokenExpired();
            if (isRefreshTokenExp) {
              setExpiredTokens(true);
              clearTokens();
              window.location.href = ROUTES.auth;
            } else {
              setExpiredTokens(false);
            }
          } else {
            setExpiredTokens(false);
          }
        }
      } catch (error) {}
    };

    refreshToken();
  }, []);
  /* eslint-enable */
  return expiredTokens;
};
export default useRefreshToken;
