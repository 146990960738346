import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import ConditionAcceptance from '../../../ConditionAcceptance';
import { conditionStyle } from './index.module.scss';

const KampaohClubConditionAcceptance = ({ checked, changeChecked }) => {
  const intl = useIntl();
  return (
    <div className={conditionStyle}>
      <ConditionAcceptance
        markdownSource={intl.formatMessage({ id: 'UserForm.Validation.Club.Title', defaultMessage: '¿Quieres pertenecer al club Kampaoh?' })}
        subtitle={intl.formatMessage({ id: 'UserForm.Validation.Club.Text', defaultMessage: '' })}
        checked={checked}
        changeChecked={changeChecked}
      />
    </div>

  );
};

KampaohClubConditionAcceptance.defaultProps = {
  checked: false,
  changeChecked: () => {},
};

KampaohClubConditionAcceptance.propTypes = {
  checked: PropTypes.bool,
  changeChecked: PropTypes.func,
};

export default KampaohClubConditionAcceptance;
