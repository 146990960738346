import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Arrow } from '../../../../assets/icons/left-arrow.svg';
import { header } from './index.module.scss';

const ProfileHeader = ({ name, email }) => (
  <div className={header}>
    <h2>{name}</h2>
    <p>
      {email?.length > 1 && <Arrow />}
      {email}
    </p>
  </div>
);

ProfileHeader.defaultProps = {
  name: '',
  email: '',
};

ProfileHeader.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
};

export default ProfileHeader;
