import React, { useEffect, useState, useCallback } from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, message } from "antd";
import ReactMarkdown from "react-markdown";
import * as s from "./index.module.scss";
import RangeDatePicker from "../../../../components/RangeDatePicker";
import {
  useReservationDispatch,
  useReservationState,
} from "../../../../services/Reservation";
import {
  datesAreValid,
  peopleAreValid,
  reservationValidationErrorMessages,
} from "../../../../services/CheckValidReservation";
import Counters from "../../../../components/Counters";
import WizardStep from "./components/WizardStep";
import { adults, babies, boys } from "../../../../constants/typeOfPersons";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { ReactComponent as ReturnIcon } from "../../../../assets/icons/return.svg";
import MapComponent from "../../../../components/MapComponent";
import CustomModal from "../../../../components/CustomModal";

const ReservationWizard = ({
  visible,
  onClose,
  disableDestination,
  disableLastDestinationStep,
  step,
  fpaFilter,
  setFpaFilter,
}) => {
  const intl = useIntl();
  const [currentWizardStep, setCurrentWizardStep] = useState(
    disableDestination ? 2 : 1,
  );
  const [showModalForClosedDate, setShowModalForClosedDate] = useState(false);
  const {
    startDate,
    endDate,
    [adults]: adultsCount,
    [boys]: boysCount,
    [babies]: babiesCount,
    kampaohService,
  } = useReservationState();
  const reservation = useReservationState();
  const dispatch = useReservationDispatch();

  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };
  const isClosedDates = () => startDate && endDate;

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);
  const onStepTwoOk = () => {
    if (datesAreValid(reservation)) {
      onClose();
    } else {
      reservationValidationErrorMessages(reservation).map((errorMessage) =>
        message.error(
          intl.formatMessage({
            id: errorMessage,
            defaultMessage: "Ha ocurrido algo con su reserva, revise los datos",
          }),
        ),
      );
    }
  };

  const updateQueryParams = useCallback(
    (key, value) => {
      const url = new URL(window.location);
      const params = new URLSearchParams(url.search);
      if (value || typeof value === "number") {
        params.set(key, value);
      } else {
        params.delete(key);
      }

      const urlSearchParams = params.toString();
      const currentParams = new URLSearchParams(url.search).toString();
      if (currentParams !== urlSearchParams) {
        updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
      }

      params.set("adults", reservation.adults);
      params.set("children", reservation.children);
      params.set("babies", reservation.babies);
      params.set("fpa", fpaFilter);
      url.search = params.toString();
      updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
    },
    [reservation, fpaFilter],
  );

  const onStepThreeOk = () => {
    if (peopleAreValid(reservation)) {
      updateQueryParams();
      onClose();
    }
    reservationValidationErrorMessages(reservation).map((errorMessage) =>
      message.error(
        intl.formatMessage({
          id: errorMessage,
          defaultMessage: "Ha ocurrido algo con su reserva, revise los datos",
        }),
      ),
    );
  };

  const handleOnClose = useCallback(() => {
    switch (currentWizardStep) {
      case 3:
        setCurrentWizardStep(2);
        break;
      case 4:
        setCurrentWizardStep(3);
        break;
      case 2:
        if (!disableDestination) {
          setCurrentWizardStep(1);
        }
        break;
      default:
        if (startDate && !endDate) {
          const defaultEndDate = startDate?.clone().add(2, "days");
          dispatch({ type: "setEndDate", payload: defaultEndDate });
          dispatch({ type: "setStartDate", payload: startDate });
          updateQueryParams("checkin", startDate?.format("YYYY-MM-DD"));
          updateQueryParams("checkout", defaultEndDate?.format("YYYY-MM-DD"));
        }

        if (startDate && endDate) {
          dispatch({ type: "setEndDate", payload: endDate });
          dispatch({ type: "setStartDate", payload: startDate });
          updateQueryParams("checkin", startDate?.format("YYYY-MM-DD"));
          updateQueryParams("checkout", endDate?.format("YYYY-MM-DD"));
        }

        onClose();
        break;
    }
  }, [
    currentWizardStep,
    disableDestination,
    startDate,
    endDate,
    dispatch,
    onClose,
    updateQueryParams,
  ]);

  const handleModalForClosedDate = () => setShowModalForClosedDate(false);

  const getComponent = () => {
    switch (step) {
      case 1:
        return (
          <div className={s.wrapper} style={{ height: "100%" }}>
            <MapComponent
              onChange={(location) => {
                dispatch({
                  type: "setPreferredLocation",
                  payload: location.location.name,
                });
                dispatch({
                  type: "setPreferredZoneName",
                  payload: location.location.name,
                });
                dispatch({
                  type: "setPreferredZoneId",
                  payload: location.location.id,
                });
                onClose();
              }}
            />
          </div>
        );
      case 2:
        return (
          <RangeDatePicker
            startDate={startDate}
            endDate={endDate}
            onChange={(dates) => {
              dispatch({ type: "setStartDate", payload: dates.startDate });
              dispatch({ type: "setEndDate", payload: dates.endDate });
            }}
          />
        );
      case 3:
        return (
          <div className={s.wrapper}>
            <Counters
              service={kampaohService}
              showBackground={false}
              showLines
              values={{
                children: boysCount,
                adults: adultsCount,
                babies: babiesCount,
              }}
              fpaFilter={fpaFilter}
              setFpaFilter={setFpaFilter}
              onChange={(counter) => {
                Object.keys(counter).forEach((key) => {
                  dispatch({ type: `set${key}`, payload: counter[key] });
                });
              }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const getTitle = () => {
    switch (step) {
      case 1:
        return (
          <FormattedMessage
            id={`#ReservationWizard.step1.${kampaohService}.title`}
          />
        );
      case 2:
        return <FormattedMessage id="ReservationWizard.step2.title" />;

      case 3:
        return <FormattedMessage id="ReservationWizard.step3.title" />;
      default:
        return null;
    }
  };

  const getFooter = () => {
    if (step === 1) {
      return null;
    }
    return (
      <div className={s.footer}>
        <div>
          <Button
            className={`${!isClosedDates() ? s.disabled : ""}`}
            disabled={!isClosedDates()}
            onClick={async () => {
              await updateQueryParams(
                "checkin",
                reservation?.startDate.format("YYYY-MM-DD"),
              );
              await updateQueryParams(
                "checkout",
                reservation?.endDate.format("YYYY-MM-DD"),
              );
              // eslint-disable-next-line max-len
              if (
                step === 2 ||
                (step === 3 &&
                  disableDestination &&
                  !disableLastDestinationStep)
              ) {
                onStepTwoOk();
              } else {
                onStepThreeOk();
              }
            }}
            data-cy="ok-button"
          >
            Listo
          </Button>
        </div>
      </div>
    );
  };

  const getIcon = () => {
    if (step === 1 || (step === 2 && disableDestination)) {
      return <CloseIcon />;
    }
    return <ReturnIcon />;
  };

  useEffect(() => {
    if (
      !visible &&
      currentWizardStep !== 1 &&
      currentWizardStep !== 2 &&
      !disableDestination
    ) {
      setCurrentWizardStep(1);
    }
  }, [visible, currentWizardStep, disableDestination]);

  useEffect(() => {
    setCurrentWizardStep(disableDestination ? 2 : 1);
  }, [disableDestination]);

  return (
    <>
      <CustomModal
        visible={showModalForClosedDate}
        onOk={handleModalForClosedDate}
        onCancel={handleModalForClosedDate}
      >
        <ReactMarkdown
          source={intl.formatMessage({ id: "Kampaoh.Reservation.ClosedDates" })}
        />
      </CustomModal>
      <WizardStep
        visible={visible && currentWizardStep >= 1}
        onClose={handleOnClose}
        title={getTitle(1)}
        footer={getFooter(1)}
        icon={getIcon(1)}
        zIndex={18}
        mask
      >
        {getComponent(1)}
      </WizardStep>
      <WizardStep
        visible={visible && currentWizardStep >= 2}
        onClose={handleOnClose}
        title={getTitle(2)}
        footer={getFooter(2)}
        icon={getIcon(2)}
        zIndex={18}
        mask={disableDestination}
      >
        {getComponent(2)}
      </WizardStep>
      <WizardStep
        visible={visible && currentWizardStep >= 3}
        onClose={handleOnClose}
        title={getTitle(3)}
        footer={getFooter(3)}
        icon={getIcon(3)}
        zIndex={4}
      >
        {getComponent(3)}
      </WizardStep>
      <WizardStep
        visible={visible && currentWizardStep === 4}
        onClose={handleOnClose}
        title={getTitle(1)}
        footer={null}
        icon={getIcon(4)}
        zIndex={5}
      >
        {getComponent(1)}
      </WizardStep>
    </>
  );
};

ReservationWizard.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  disableDestination: PropTypes.bool,
  disableLastDestinationStep: PropTypes.bool,
  step: PropTypes.number,
  fpaFilter: PropTypes.string,
  setFpaFilter: PropTypes.func,
};

ReservationWizard.defaultProps = {
  visible: false,
  onClose: () => {},
  disableDestination: false,
  disableLastDestinationStep: false,
  step: false,
  fpaFilter: "none",
  setFpaFilter: () => {},
};
export default ReservationWizard;
