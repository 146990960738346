import React from 'react';
import { Button } from 'antd';
import { PropTypes } from 'prop-types';
import { ReactComponent as FaqIcon } from '../../assets/icons/faq.svg';
import { wrapper, inner } from './index.module.scss';

const FaqButton = (props) => {
  const { onScroll } = props;

  return (
    <>
      <div className={wrapper}>
        <Button className={inner} shape="circle" icon={<FaqIcon />} onClick={onScroll} />
      </div>
    </>
  );
};

FaqButton.defaultProps = {
  onScroll: () => {},
};

FaqButton.propTypes = {
  onScroll: PropTypes,
};

export default FaqButton;
