import { gql } from '@apollo/client';

const locations = gql`
  query GetGenericTexts ($lang: I18NLocaleCode!){
    genericTexts (pagination: { limit: -1 }, locale: $lang) {
      data{
        id,
        attributes {
          FieldName,
          description,
        }
      }
    }
  }
`;

export default locations;
