import moment from 'moment';
import { makeRequestToServerV3 } from "../services/API";

export const useFetchDateRatePlan = async ({
  propertyFromURL,
  roomFromURL,
  startDateParam,
  endDateParam,
  adultsFromURL,
  childrenFromURL,
  currentLanguage
}) => {
  const { data: dateRatePlan } = await makeRequestToServerV3(
    `/properties/${propertyFromURL}/rooms/${roomFromURL}/boards/plans?startDate=${moment(
      startDateParam
    )?.format("YYYY-MM-DD")}&endDate=${moment(endDateParam)?.format(
      "YYYY-MM-DD"
    )}&adults=${adultsFromURL}&children=${childrenFromURL}&lang=${currentLanguage}`
  );

  return dateRatePlan;
};

export default useFetchDateRatePlan;
