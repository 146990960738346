import { Form, Input, Select, Typography } from 'antd';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import rules from '../validationRules';
import {
  formItemStyle,
  inputStyle,
  phoneFieldWrapper,
} from './index.module.scss';
import prefixPhoneNumbers from './prefixPhoneNumbers';

const prefixSelector = (
  <Form.Item name="prefix" noStyle>
    <Select
      style={{
        width: 70,
      }}
      data-cy="checkout-step3-phone-prefix"
    >
      {prefixPhoneNumbers.map((number) => (
        <Select.Option value={'+'.concat(number.toString())} key={number}>
          {'+'.concat(number)}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const PhoneNumberField = ({ field, disabled, "data-cy": dataCy }) => {
  const intl = useIntl();
  const fieldTitle = intl.formatMessage({
    id: `#UserForm.Validation.${field}.Title`,
    defaultMessage: 'Phone',
  });
  const fieldPlaceholder = intl.formatMessage({
    id: `#UserForm.Validation.${field}.Placeholder`,
    defaultMessage: field,
  });
  const fieldLabel = useMemo(
    () => <Typography.Text strong>{fieldTitle}</Typography.Text>,
    [fieldTitle],
  );

  return (
    <div className={phoneFieldWrapper}>
      <Form.Item
        className={formItemStyle}
        name={field}
        rules={rules[field]}
        label={fieldLabel}
        normalize={(values) => values.trim()}
      >
        <Input
          className={inputStyle}
          addonBefore={prefixSelector}
          placeholder={fieldPlaceholder}
          disabled={disabled}
          data-cy={dataCy}
        />
      </Form.Item>
    </div>
  );
};

PhoneNumberField.defaultProps = {
  disabled: false,
  "data-cy": undefined
};

PhoneNumberField.propTypes = {
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  "data-cy": PropTypes.string
};

export default PhoneNumberField;
