// eslint-disable-next-line no-underscore-dangle
const URL = `${window._env_.REACT_APP_CMS_URL || 'https://config.kampaoh.com'}`;

const routes = {
  campings: '/api/campings',
  tents: '/api/tents',
  genericTexts: '/api/generic-texts',
  campingfeatures: '/api/features',
  tentfeatures: '/api/tent-features',
  regions: '/api/regions?populate=*',
  conditions: '/api/conditions',
  topBanner: '/api/top-banner',
  zones: '/api/zones?populate[regions][populate][0]=campings',
  graphql: '/graphql',
};

export {
  URL,
  routes,
};
