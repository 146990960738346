import React from 'react';
import { Rate } from 'antd';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import {
  rateText,
  rateStyle,
  ratingBlock,
  ratingElement,
  scoreElement,
  scoreWrapper,
  starIcon,
} from './index.module.scss';
import { ReactComponent as StarIcon } from '../../../../assets/icons/star.svg';
import './styles.scss';

const CustomRate = ({ value }) => (
  <Rate
    className={rateStyle}
    disabled
    defaultValue={value}
    allowHalf
    character={<StarIcon className={starIcon} />}
  />
);

CustomRate.propTypes = {
  value: PropTypes.number.isRequired,
};

const CampingRating = injectIntl(({ intl, rating }) => {
  const { score, reviews, ...rest } = rating;
  return (
    <div className="campingRating">
      <div className={scoreWrapper}>
        <div className={scoreElement}>{score}</div>
        <div>
          {intl.formatMessage({ id: 'Rating.Title1', defaultMessage: 'Valoración de los usuarios' }, { rating: reviews })}
        </div>
      </div>
      <div className={ratingBlock}>
        {Object.keys(rest).map((key) => (
          <div className={ratingElement} key={key}>
            <p className={rateText}>
              {intl.formatMessage({ id: `#Rating.${key}`, defaultMessage: key })}
            </p>
            <CustomRate value={rating[key]} />
          </div>
        ))}
      </div>
    </div>
  );
});

CampingRating.propTypes = {
  rating: PropTypes.shape({
    score: PropTypes.number,
    reviews: PropTypes.number,
  }),
};

CampingRating.defaultProps = {
  rating: {},
};

export default CampingRating;
