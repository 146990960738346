import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "@reach/router";
import { useIntl } from "react-intl";
import ROUTES from "../../routes/routes";
import { ReactComponent as KampaohWithText } from "../../assets/icons/kampaoh-with-text.svg";
import {
  wrapper,
  content,
  logoContainer,
  textContainer,
  title,
  description,
  leftContainer,
  buttonsContainer,
  loginButton,
  signupButton,
  kampaohLogo,
  tooltip,
  tooltiptext,
} from "./index.module.scss";

const PromotionBanner = ({ titleKey, descriptionKey }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleLoginClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const params = new URLSearchParams(window.location.search);
      params.delete("redirect");
      const redirect = `${window.location.pathname}${
        // eslint-disable-next-line prefer-template
        params.toString() ? "?" + params.toString() : ""
      }`;
      navigate(`${ROUTES.auth}?redirect=${encodeURIComponent(redirect)}`);
    },
    [navigate],
  );

  return (
    <div className={wrapper}>
      <div className={logoContainer}>
        <KampaohWithText className={kampaohLogo} />
        <span>{intl.formatMessage({ id: "PromotionBanner.Logo.Text" })}</span>
      </div>
      <div className={content}>
        <div className={textContainer}>
          <div className={title}>{intl.formatMessage({ id: titleKey })}</div>
          <p className={description}>
            {intl.formatMessage({ id: descriptionKey })}
            <span className={tooltip}>
              {intl.formatMessage({ id: "PromotionBanner.tooltip" })}
              <span className={tooltiptext}>
                {intl.formatMessage({ id: "PromotionBanner.tooltiptext" })}
              </span>
            </span>
          </p>
        </div>
        <div className={leftContainer}>
          <div className={buttonsContainer}>
            <button
              className={loginButton}
              onClick={handleLoginClick}
              type="button"
            >
              {intl.formatMessage({ id: "PromotionBanner.LoginButton.Text" })}
            </button>
            <button
              className={signupButton}
              onClick={handleLoginClick}
              type="button"
            >
              {intl.formatMessage({ id: "PromotionBanner.SignUpButton.Text" })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

PromotionBanner.propTypes = {
  descriptionKey: "",
  titleKey: "",
};

PromotionBanner.defaultProps = {
  titleKey: PropTypes.string,
  descriptionKey: PropTypes.string,
};

export default PromotionBanner;
