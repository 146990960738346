import React from 'react';
import { PropTypes } from 'prop-types';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { drawer, withMask, subtitle } from './index.module.scss';

const CustomDrawer = ({
  visible, height, onClose, title, children, icon, mask, titleless, zIndex, subTitle,
}) => (
  <Drawer
    title={
      titleless ? null : (
        <div>
          <h3>{title}</h3>
          <span className={subtitle}>{subTitle}</span>
        </div>
      )
    }
    placement="bottom"
    closable
    onClose={onClose}
    visible={visible}
    key="bottom"
    height={height}
    className={`${drawer} ${mask ? withMask : ''}`}
    headerStyle={{
      textAlign: 'center',
      height: `${titleless ? '35px' : '70px'}`,
      border: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flex: `0 0 ${titleless ? '35px' : '70px'}`,
    }}
    mask={mask}
    destroyOnClose
    closeIcon={titleless ? null : icon}
    style={{ zIndex }}
  >
    {children}
  </Drawer>
);

CustomDrawer.propTypes = {
  visible: PropTypes.bool,
  height: PropTypes.string,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.element.isRequired,
  icon: PropTypes.element,
  mask: PropTypes.bool,
  titleless: PropTypes.bool,
  zIndex: PropTypes.number,
};

CustomDrawer.defaultProps = {
  visible: false,
  height: '90vh',
  onClose: () => {},
  title: 'Title',
  subTitle: 'SubTitle',
  icon: <CloseOutlined />,
  mask: false,
  titleless: false,
  zIndex: undefined,
};
export default CustomDrawer;
