import React, { useContext } from 'react';
import ConditionKamping from './components/ConditionsKamping';
import { wrapper } from './index.module.scss';
import { CheckoutContext } from '../..';

const PoliciesAcceptance = () => {
  const { onComplete } = useContext(CheckoutContext);
  return (
    <div className={wrapper}>
      <ConditionKamping onComplete={onComplete} />
    </div>
  );
};

PoliciesAcceptance.propTypes = {};

export default PoliciesAcceptance;
