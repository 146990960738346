import { Button } from "antd";
import { PropTypes } from "prop-types";
import React from "react";
import { PulseLoader } from "react-spinners";
import { buttonSm, innerButton, wrapper } from "./index.module.scss";

const SIZES = {
  LG: "lg",
  SM: "sm",
};

const ActionButton = ({
  children, onClick, disabled, icon, className, htmlType, loading, size, "data-cy": dataCy
}) => (
      <Button
        onClick={onClick}
        disabled={disabled}
        htmlType={htmlType}
        className={`${wrapper} ${size === SIZES.SM ? buttonSm : ''} ${className ?? ''}`}
        data-cy={dataCy}
      >
        {loading && <PulseLoader color="black" size={3} css="margin-right: 10px" />}
        <span
          className={innerButton}
        >
          {children}
        </span>
        {icon}
      </Button>
    );

ActionButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  className: PropTypes.string,
  htmlType: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SIZES)),
  "data-cy": PropTypes.string
};

ActionButton.defaultProps = {
  onClick: () => {},
  disabled: true,
  icon: null,
  className: undefined,
  htmlType: "button",
  loading: false,
  size: SIZES.LG,
  "data-cy": undefined
};
export default ActionButton;
