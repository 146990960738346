import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { wrapper, title, resetFilter } from './index.module.scss';
import { ReactComponent as ErrorFilterIcon } from '../../../../assets/icons/filter-error.svg';
import { useFilterDispatch } from '../../../../services/Filter';

const NoResults = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const dispatch = useFilterDispatch();

  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };
  const handleFilter = () => {
    const activeFilters = params.get('filters');
    activeFilters.split(',').map((currentFilter) => {
      const parsedFilter = { id: currentFilter };
      return dispatch({ type: 'removeCharacteristicsFilter', payload: parsedFilter });
    });
    params.set('filters', '');
    updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
  };

  return (
    <div className={wrapper}>
      <div>
        <ErrorFilterIcon />
        <p className={title}><FormattedMessage id="NoResults.title" /></p>
        <p><FormattedMessage id="NoResults.text" /></p>
        <Button className={resetFilter} onClick={handleFilter}>
          <strong><FormattedMessage id="NoResults.button" /></strong>
        </Button>
      </div>
    </div>
  );
};

NoResults.propTypes = {

};

NoResults.defaultProps = {

};
export default NoResults;
