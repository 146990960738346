/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { Button } from "antd";
import { useNavigate } from "@reach/router";
import moment from "moment";
import {
  useReservationState,
  useReservationDispatch,
} from "../../../services/Reservation";
import useCampings from "../../../services/Campings/useCampings";
import { adults, boys, babies } from "../../../constants/typeOfPersons";
import WizardStep from "../../../pages/Home/components/ReservationWizard/components/WizardStep";
import RangeDatePicker from "../../RangeDatePicker";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as GuestIcon } from "../../../assets/icons/icon_guest.svg";
import { ReactComponent as PetsIcon } from "../../../assets/icons/pets_black.svg";
import { ReactComponent as NoPetsIcon } from "../../../assets/icons/allergic_pets_black.svg";
import * as s from "../../../pages/Home/components/ReservationWizard/index.module.scss";
import {
  headerInfo,
  headerInfoParent,
  headerInfoContainer,
  datesTopNavBar,
  headerInfoPersons,
  headerInfoPets,
  headerInfoWrapper,
  headerInfoDestination,
  navigationButtonStyle,
  filterButton,
  wrapper,
  footer,
  okButton,
  propertiesWrapper,
  searchNotFound,
} from "./index.module.scss";
import { usePrevious } from "../../../services/usePrevious";
import Services from "../../../constants/services";
import { ReactComponent as FilterIcon } from "../../../assets/icons/icon_filter.svg";
// import GoingBackButton from '../../GoingBackButton';
import PersonSelectorDrawer from "../../PersonSelectorDrawer";
import ROUTES from "../../../routes/routes";
import MapComponent from "../../MapComponent";
import { makeRequestToCmsServer } from "../../../services/API";
import { routes } from "../../../constants/cms";
import { SearchContext } from "../../../contexts/SearchContext";
import sorters from "../../../constants/sorters";
import { LanguageContext } from "../../../locale/contexts/Language";
import SearchBar from "../../../pages/Home/components/SearchBar";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location.svg";

const ReservationInfo = injectIntl(({ intl, onFilterButtonClick }) => {
  const {
    startDate,
    endDate,
    [adults]: adultsCount,
    [boys]: boysCount,
    [babies]: babiesCount,
    preferredZoneName,
    preferredZoneId,
    kampaohService,
    promoCode,
  } = useReservationState();
  const dispatch = useReservationDispatch();
  const { askForCampingsRatePlans, dispatch: dispatchCampings } = useCampings();
  const navigate = useNavigate();
  const [showPersonStep, setShowPersonStep] = useState(false);
  const [showLocationStep, setShowLocationStep] = useState(false);
  const [showDateStep, setShowDateStep] = useState(false);
  const totalPersons = adultsCount + boysCount;
  const prevShowDateStep = usePrevious(showDateStep);
  const { currentLanguage } = useContext(LanguageContext);
  const { setSorter } = useContext(SearchContext);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const fpa = searchParams.get("fpa");
  const zoneFromUrl = searchParams.get("zone");
  const filters = searchParams.get("filters");
  const [fpaFilter, setFpaFilter] = useState(fpa || "none");
  const [properties, setProperties] = useState();
  const [inputValue, setInputValue] = useState("");
  const reservationContext = useReservationState();
  const langFromUrl = searchParams.get("lang");

  const [currentDates, setCurrentDates] = useState({
    startDate: startDate?.clone(),
    endDate: endDate?.clone(),
  });
  const [selectedCalendarDates, setSelectedCalendarDates] = useState({
    startDate: startDate?.clone(),
    endDate: endDate?.clone(),
  });

  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };

  useEffect(() => {
    if (
      startDate !== currentDates?.startDate &&
      endDate !== currentDates?.endDate
    ) {
      setCurrentDates({
        startDate: startDate?.clone(),
        endDate: endDate?.clone(),
      });
      setSelectedCalendarDates({
        startDate: startDate?.clone(),
        endDate: endDate?.clone(),
      });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const zone = searchParams.get("zone");
    const location = searchParams.get("location");
    const region = searchParams.get("region");
    if (location && region)
      dispatch({ type: "setPreferredLocation", payload: { location, region } });
    const fetchData = async () => {
      const response = await makeRequestToCmsServer(
        `${routes.zones}&filters[internalID][$eq]=${zoneFromUrl}&locale=${currentLanguage}`,
      );
      if (response && zone) {
        const filteredZone = response.find(
          (obj) => obj.internalID === parseInt(zone, 10),
        );
        if (filteredZone) {
          dispatch({
            type: "setPreferredZoneName",
            payload: filteredZone.Name,
          });
          dispatch({
            type: "setPreferredZoneId",
            payload: filteredZone.internalID,
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [dispatch, currentLanguage]);

  const onOkey = (step, dateChanged) => {
    const reservation = {
      preferredZoneId,
      startDate,
      endDate,
      adults: adultsCount,
      children: boysCount,
      babies: babiesCount,
      promoCode,
      fpa: fpaFilter,
    };
    dispatchCampings({ type: "resetHotelsInitialState" });
    askForCampingsRatePlans(reservation);

    if (step === 2) {
      if (!dateChanged) {
        setSelectedCalendarDates({ startDate, endDate });
      }
      setShowDateStep(false);
    } else {
      setShowPersonStep(false);
    }
    dispatch({ type: "setItAsReadyToBeUsed" });
    dispatch({ type: "setRatePlansAsNotFetched" });
    window.window.scrollTo(0, 0);

    if (!dateChanged) {
      const url = new URL(window.location);
      const params = new URLSearchParams(url.search);
      params.set("checkin", moment(startDate).format("YYYY-MM-DD"));
      params.set("checkout", moment(endDate).format("YYYY-MM-DD"));
      url.search = params.toString();

      updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
    }
  };

  const getFooter = (step) => {
    if (step === 1) {
      return null;
    }
    return (
      <div className={footer}>
        <div>
          <Button
            onClick={() => onOkey(step)}
            disabled={!currentDates.startDate || !currentDates.endDate}
            className={okButton}
          >
            OK
          </Button>
        </div>
      </div>
    );
  };

  const handleOnDestinationPick = (location) => {
    dispatch({ type: "setPreferredLocation", payload: location.name });
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    if (location) {
      params.set("zone", location.id);
      url.search = params.toString();
      updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
    } else {
      url.searchParams.delete("zone");
      window.history.replaceState({}, document.title, url);
    }

    setShowLocationStep(false);
    window.window.scrollTo(0, 0);
    if (location && location.ulysesId)
      navigate(
        `${ROUTES.propertyDetails}/${
          location.ulysesId
        }?checkin=${startDate.format("YYYY-MM-DD")}&checkout=${endDate.format(
          "YYYY-MM-DD",
        )}&adults=${adults}&children=${boys}&babies=${babies}&fpa=${fpaFilter}${
          preferredZoneId ? `&zone=${preferredZoneId}` : ""
        }`,
      );
  };

  const handleGoToProperty = (property) => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    navigate(
      `${ROUTES.propertyDetails}/${
        property.propertyId
      }/?checkin=${reservationContext.startDate.format(
        "YYYY-MM-DD",
      )}&checkout=${reservationContext.endDate.format("YYYY-MM-DD")}&adults=${
        reservationContext.adults
      }&children=${reservationContext.children}&babies=${
        reservationContext.babies
      }&fpa=${params.get("fpa")}`,
    );
  };

  useEffect(() => {
    if (showDateStep !== prevShowDateStep) {
      if (showDateStep) {
        dispatch({ type: "setItAsUpdatingDates", payload: true });
      } else {
        dispatch({ type: "setItAsUpdatingDates", payload: false });
      }
    }
  }, [showDateStep, prevShowDateStep, dispatch]);

  const updateQueryParams = (key, value) => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    if (value || typeof value === "number") {
      params.set(key, value);
    } else {
      params.delete(key);
    }

    const urlSearchParams = params.toString();
    const currentParams = new URLSearchParams(url.search).toString();
    if (currentParams !== urlSearchParams) {
      updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
    }
    url.search = params.toString();
    updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
  };

  return (
    <div className={wrapper}>
      {/* <GoingBackButton /> */}
      <div className={headerInfoParent}>
        <div className={headerInfoContainer}>
          <div className={headerInfo}>
            <span
              data-cy="header-destination-button"
              className={headerInfoDestination}
              onClick={() => {
                setShowLocationStep(true);
              }}
              onKeyPress={() => {
                setShowLocationStep(true);
              }}
              role="button"
              tabIndex="0"
            >
              {preferredZoneName}
            </span>
            <span
              className={datesTopNavBar}
              onClick={() => {
                setShowDateStep(true);
              }}
              onKeyPress={() => {
                setShowDateStep(true);
              }}
              role="button"
              tabIndex="0"
            >
              {moment(selectedCalendarDates.startDate)
                .locale(intl.locale)
                .format("MMM DD")}
              &nbsp;-&nbsp;
              {moment(selectedCalendarDates.endDate)
                .locale(intl.locale)
                .format("MMM DD")}
            </span>
          </div>
          <div className={headerInfoWrapper}>
            <div
              data-cy="header-persons-button"
              className={headerInfoPersons}
              role="button"
              onClick={() => {
                setShowPersonStep(true);
              }}
              onKeyPress={() => {
                setShowPersonStep(true);
              }}
              tabIndex="0"
            >
              <span>
                <GuestIcon />
                &nbsp; &nbsp;
              </span>
              <span>
                <strong>x{totalPersons}</strong>
              </span>
            </div>
            {fpa === "pets" && (
              <div
                className={headerInfoPets}
                role="button"
                tabIndex="0"
                onClick={() => {
                  setShowPersonStep(true);
                }}
                onKeyPress={() => {
                  setShowPersonStep(true);
                }}
              >
                <PetsIcon />
              </div>
            )}
            {fpa === "allergy" && (
              <div
                className={headerInfoPets}
                role="button"
                tabIndex="0"
                onClick={() => {
                  setShowPersonStep(true);
                }}
                onKeyPress={() => {
                  setShowPersonStep(true);
                }}
              >
                <NoPetsIcon />
              </div>
            )}
          </div>
        </div>
      </div>
      <Button
        className={navigationButtonStyle}
        shape="circle"
        icon={<FilterIcon className={filterButton} />}
        onClick={onFilterButtonClick}
      />
      {showPersonStep && (
        <PersonSelectorDrawer
          initCountersValues={{
            adults: adultsCount,
            children: boysCount,
            babies: babiesCount,
          }}
          visible={showPersonStep}
          onClose={() => {
            setShowPersonStep(false);
          }}
          onOk={(counter) => {
            const url = new URL(window.location);
            const params = new URLSearchParams(url.search);
            Object.keys(counter).forEach((key) => {
              dispatch({ type: `set${key}`, payload: counter[key] });
              params.set(key, counter[key]);
              params.set("fpa", fpaFilter);
            });
            dispatch({ type: "setItAsReadyToBeUsed" });
            dispatchCampings({ type: "resetHotelsInitialState" });
            dispatch({ type: "setRatePlansAsNotFetched" });
            url.search = params.toString();
            updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
            window.window.scrollTo(0, 0);
            if (kampaohService === Services.CAMP) {
              dispatchCampings({ type: "resetHotelsInitialState" });
            }
            setShowPersonStep(false);
          }}
          fpaFilter={fpaFilter}
          setFpaFilter={setFpaFilter}
        />
      )}

      <WizardStep
        mask
        visible={showLocationStep}
        title={
          <FormattedMessage
            id={`#ReservationWizard.step1.${kampaohService}.title`}
          />
        }
        icon={<CloseIcon />}
        onClose={() => {
          setShowLocationStep(false);
          setInputValue();
        }}
      >
        <SearchBar
          setProperties={setProperties}
          properties={properties}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />

        {properties?.length > 0 ? (
          properties?.map((property) => (
            <div
              data-cy="property-card"
              className={propertiesWrapper}
              onClick={() => handleGoToProperty(property)}
              onKeyDown={() => handleGoToProperty(property)}
              tabIndex={property.id}
              role="button"
            >
              <span>{property?.propertyName}</span>
              <div>
                <LocationIcon />
                <span>{property?.location}</span>
              </div>
            </div>
          ))
        ) : properties?.length === 0 ? (
          <span className={searchNotFound}>
            {intl.formatMessage({ id: "SearchInput.notResultsFound" })}
            {`"${inputValue}"`}
          </span>
        ) : (
          <div className={s.wrapper} style={{ height: "85%" }}>
            <MapComponent
              onChange={(location) => {
                dispatch({
                  type: "setPreferredLocation",
                  payload: location.location.name,
                });
                dispatch({
                  type: "setPreferredZoneName",
                  payload: location.location.name,
                });
                dispatch({
                  type: "setPreferredZoneId",
                  payload: location.location.id,
                });
                handleOnDestinationPick(location.location);
                window.location.href = `${
                  ROUTES.places
                }?checkin=${startDate.format(
                  "YYYY-MM-DD",
                )}&checkout=${endDate.format(
                  "YYYY-MM-DD",
                )}&adults=${adultsCount}&children=${boysCount}&babies=${babiesCount}&fpa=${fpaFilter}&filters=${filters}${
                  location.location.id ? `&zone=${location.location.id}` : ""
                }&lang=${langFromUrl}`;
                setSorter(sorters.RELEVANCY);
                dispatchCampings({ type: "resetHotelsInitialState" });
                dispatchCampings({
                  type: "setIsLoadingCampings",
                  payload: true,
                });
                setShowLocationStep(false);
              }}
            />
          </div>
        )}
      </WizardStep>
      <WizardStep
        visible={showDateStep}
        title={<FormattedMessage id="ReservationWizard.step2.title" />}
        icon={<CloseIcon />}
        onClose={() => {
          setShowDateStep(false);
          const checkinFromUrl = searchParams.get("checkin");
          const checkoutFromUrl = searchParams.get("checkout");
          const dateChanged =
            currentDates?.startDate?.format("YYYY-MM-DD") !== checkinFromUrl ||
            currentDates?.endDate?.format("YYYY-MM-DD") !== checkoutFromUrl;

          if (currentDates?.startDate && !currentDates?.endDate) {
            const defaultEndDate = currentDates?.startDate
              ?.clone()
              .add(2, "days");
            dispatch({
              type: "setStartDate",
              payload: currentDates?.startDate,
            });
            dispatch({ type: "setEndDate", payload: defaultEndDate });
            updateQueryParams(
              "checkin",
              currentDates?.startDate.format("YYYY-MM-DD"),
            );
            updateQueryParams("checkout", defaultEndDate.format("YYYY-MM-DD"));

            setSelectedCalendarDates({
              startDate: currentDates?.startDate,
              endDate: defaultEndDate,
            });
            setCurrentDates({
              startDate: currentDates?.startDate,
              endDate: defaultEndDate,
            });
            onOkey(2, dateChanged);
          } else if (
            dateChanged &&
            currentDates?.startDate &&
            currentDates?.endDate
          ) {
            dispatch({
              type: "setStartDate",
              payload: currentDates?.startDate,
            });
            dispatch({ type: "setEndDate", payload: currentDates?.endDate });
            updateQueryParams(
              "checkin",
              currentDates?.startDate.format("YYYY-MM-DD"),
            );
            updateQueryParams(
              "checkout",
              currentDates?.endDate?.format("YYYY-MM-DD"),
            );
            setSelectedCalendarDates({
              startDate: currentDates.startDate,
              endDate: currentDates?.endDate,
            });
            setCurrentDates({
              startDate: currentDates.startDate,
              endDate: currentDates?.endDate,
            });
            onOkey(2);
          }
        }}
        footer={getFooter(2)}
        mask
      >
        <RangeDatePicker
          startDate={currentDates.startDate}
          endDate={currentDates.endDate}
          onChange={(dates) => {
            setCurrentDates(dates);
            if (dates.startDate.isBefore(dates.endDate)) {
              dispatch({ type: "setStartDate", payload: dates.startDate });
              dispatch({ type: "setEndDate", payload: dates.endDate });
            }
          }}
        />
      </WizardStep>
    </div>
  );
});

ReservationInfo.defaultProps = {
  intl: () => {},
  setVisible: () => {},
  showPlace: false,
};

ReservationInfo.propTypes = {
  intl: PropTypes.func,
  setVisible: PropTypes.func,
  showPlace: PropTypes.bool,
};

export default ReservationInfo;
