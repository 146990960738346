import React from 'react';
import { PropTypes } from 'prop-types';
import { filterIconsContainer, tag } from './index.module.scss';

const FeaturesTags = ({ features }) => (
  <div className={filterIconsContainer}>
    {features.map((filter) => (
      <div
        key={filter.id}
        className={tag}
      >
        <span>{filter.name}</span>
      </div>
    ))}
  </div>
);

FeaturesTags.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.shape({
      render: PropTypes.func,
    }),
    icon: PropTypes.shape({
      render: PropTypes.func,
    }),
  })),
};

FeaturesTags.defaultProps = {
  features: [],
};

export default FeaturesTags;
