import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Carousel, Button, Image } from 'antd';
import {
  carouselStyle,
  carouselImage,
  carouselContainer,
  dot,
  prev,
  next,
} from './index.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/slider-arrow.svg';

const CustomCarousel = ({ photos, photosPreview }) => {
  const imagesToShow = Object.keys(photos);
  const refCarousel = useRef(null);
  return (
    <div
      className={carouselContainer}
      onClick={(e) => e.stopPropagation()}
      onKeyPress={(e) => e.stopPropagation()}
      role="button"
      tabIndex={0}
    >
      <Button onClick={() => refCarousel.current.prev()}>
        <Arrow className={prev} />
      </Button>
      <Image.PreviewGroup>
        <Carousel
          ref={refCarousel}
          className={carouselStyle}
          arrows={false}
          dots={{ className: dot }}
          draggable
        >
          {imagesToShow.map((key, index) => (
            <div key={key}>
              <Image
                src={photos[key]}
                alt="Imagen"
                className={carouselImage}
                loading={index === 0 ? 'eager' : 'lazy'}
                preview={{
                  src: photosPreview[key],
                }}
              />
            </div>
          ))}
        </Carousel>
      </Image.PreviewGroup>
      <Button onClick={() => refCarousel.current.next()}>
        <Arrow className={next} />
      </Button>
    </div>
  );
};

CustomCarousel.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.string),
  photosPreview: PropTypes.arrayOf(PropTypes.string),
};

CustomCarousel.defaultProps = {
  photos: [],
  photosPreview: [],
};

export default CustomCarousel;
