import React from 'react';
import { PropTypes } from 'prop-types';
import { commonLabel } from './index.module.scss';
import Tag from '../Tag';

const CommonLabels = ({ labels }) => (
  labels.split(',').map((label) => (
    <Tag className={commonLabel} key={label}>
      {label}
    </Tag>
  ))
);

CommonLabels.propTypes = {
  labels: PropTypes.string.isRequired,
};

export default CommonLabels;
