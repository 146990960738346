const updateUrlWithoutNavigation = (newUrl) => {
  const currentState = window.history.state;
  const currentTitle = document.title;
  window.history.replaceState(currentState, currentTitle, newUrl);
};

export default (_minPrice, _maxPrice) => {
    const url = new URL(window.location);
    const params = new URLSearchParams(url.search);
    params.set("minPrice", _minPrice);
    params.set("maxPrice", _maxPrice);
    url.search = params.toString();
    updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
};
