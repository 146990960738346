import React from 'react';
import { PropTypes } from 'prop-types';
import { dividerContainer, line, text } from './index.module.scss';

const DividerWithText = ({ dividerText = 'o' }) => (
  <div className={dividerContainer}>
    <div className={line} />
    <div className={text}>{dividerText}</div>
    <div className={line} />
  </div>
);

DividerWithText.propTypes = {
  dividerText: PropTypes.string.isRequired,
};

export default DividerWithText;
