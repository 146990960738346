/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from "react";
import { Button, message } from "antd";
import { navigate } from "@reach/router";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment-timezone";
import ReactMarkdown from "react-markdown";
import routes from "../../../../routes/routes";
import {
  wrapper,
  CTAButton as buttonStyle,
  propertiesWrapper,
  searchNotFound,
} from "./index.module.scss";
import {
  useReservationState as getReservation,
  useReservationDispatch,
} from "../../../../services/Reservation";
import {
  datesAreValid,
  peopleAreValid,
  reservationValidationErrorMessages,
} from "../../../../services/CheckValidReservation";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import WizardStep from "../ReservationWizard/components/WizardStep";
import CustomModal from "../../../../components/CustomModal";
import MapComponent from "../../../../components/MapComponent";
import { LanguageContext } from "../../../../locale/contexts/Language";
import SearchBar from "../SearchBar";
import { ReactComponent as LocationIcon } from "../../../../assets/icons/location.svg";

const CTAButton = injectIntl(({ intl, personCounters, fpaFilter }) => {
  const [showDestinationPicker, setShowDestinationPicker] = useState(false);
  const reservation = getReservation();
  const dispatch = useReservationDispatch();
  const isLoading = false;
  const { startDate, endDate, kampaohService } = getReservation();
  const [showModalForClosedDate, setShowModalForClosedDate] = useState(false);
  const { currentLanguage } = useContext(LanguageContext);
  const [properties, setProperties] = useState();
  const [inputValue, setInputValue] = useState("");

  const getRates = (location) => {
    if (datesAreValid(reservation) && peopleAreValid(personCounters)) {
      Object.keys(personCounters).forEach((key) => {
        dispatch({ type: `set${key}`, payload: personCounters[key] });
      });
      dispatch({ type: "setItAsReadyToBeUsed" });
      dispatch({ type: "setRatePlansAsNotFetched" });
      window.dataLayer.push({
        event: "searchStartBE",
        checkinDate: moment(startDate).format("YYYY/MM/DD"),
        checkoutDate: moment(endDate).format("YYYY/MM/DD"),
        bookingNights: moment(endDate).diff(startDate, "days"),
        numAdults: personCounters.adults,
        numKids: personCounters.children,
        numBabies: personCounters.babies,
        zoneName: location.location.name,
        zone: location.location.id,
      });
      navigate(
        `${routes.places}?checkin=${reservation.startDate.format(
          "YYYY-MM-DD",
        )}&checkout=${reservation.endDate.format("YYYY-MM-DD")}&adults=${
          personCounters.adults
        }&children=${personCounters.children}&babies=${
          personCounters.babies
        }&zone=${
          location.location.id
        }&fpa=${fpaFilter}&lang=${currentLanguage}`,
      );
    } else {
      reservationValidationErrorMessages(reservation).map((errorMessage) =>
        message.error(
          intl.formatMessage({
            id: errorMessage,
            defaultMessage: "Ha ocurrido algo con su reserva, revise los datos",
          }),
        ),
      );
    }
  };

  const handleOnClick = () => setShowDestinationPicker((prev) => !prev);

  const handleModalForClosedDate = () => setShowModalForClosedDate(false);

  const handleGoToProperty = (property) => {
    navigate(
      `${routes.propertyDetails}/${
        property.propertyId
      }/?checkin=${reservation.startDate.format(
        "YYYY-MM-DD",
      )}&checkout=${reservation.endDate.format("YYYY-MM-DD")}&adults=${
        reservation.adults
      }&children=${reservation.children}&babies=${reservation.babies}`,
    );
  };

  return (
    <div className={wrapper}>
      <Button
        type="primary"
        shape="round"
        size="large"
        className={buttonStyle}
        onClick={handleOnClick}
        loading={isLoading}
        data-cy="search-accommodation-button"
      >
        <SearchIcon />
        {intl.formatMessage({
          id: "Kampaoh.ViewPrices",
          defaultMessage: "Buscar alojamiento",
        })}
      </Button>
      <WizardStep
        visible={showDestinationPicker}
        onClose={() => {
          setShowDestinationPicker(false);
          setInputValue();
        }}
        title={
          <FormattedMessage
            id={`#ReservationWizard.step1.${kampaohService}.title`}
          />
        }
        footer={null}
        zIndex={18}
        icon={<CloseIcon />}
        mask
      >
        <SearchBar
          setProperties={setProperties}
          properties={properties}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />

        {properties?.length > 0 ? (
          properties?.map((property) => (
            // eslint-disable-next-line max-len
            <div
              className={propertiesWrapper}
              onClick={() => handleGoToProperty(property)}
              onKeyDown={() => handleGoToProperty(property)}
              tabIndex={property.id}
              role="button"
            >
              <span>{property?.propertyName}</span>
              <div>
                <LocationIcon />
                <span>{property?.location}</span>
              </div>
            </div>
          ))
        ) : properties?.length === 0 ? (
          <span className={searchNotFound}>
            {intl.formatMessage({ id: "SearchInput.notResultsFound" })}
            {`"${inputValue}"`}
          </span>
        ) : (
          <div style={{ height: "85%" }}>
            <MapComponent
              onChange={(location) => {
                setShowDestinationPicker(false);
                dispatch({
                  type: "setPreferredLocation",
                  payload: location.location.name,
                });
                dispatch({
                  type: "setPreferredZoneName",
                  payload: location.location.name,
                });
                dispatch({
                  type: "setPreferredZoneId",
                  payload: location.location.id,
                });
                getRates(location);
              }}
            />
          </div>
        )}
      </WizardStep>
      <CustomModal
        visible={showModalForClosedDate}
        onOk={handleModalForClosedDate}
        onCancel={handleModalForClosedDate}
      >
        <ReactMarkdown
          source={intl.formatMessage({ id: "Kampaoh.Reservation.ClosedDates" })}
        />
      </CustomModal>
    </div>
  );
});

export default CTAButton;
