import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { allowedMethods } from '../../../../constants/ApServer/apServer';
import routes from '../../../../constants/ApServer/apRoutes';
import makeRequestToApServer from '../../../../services/ApServer/askToApServer';
import openNotification from '../../../../components/NotificacionMessage';
import {
  wrapper,
  wrapperHeader,
  form,
  formTitle,
  button,
  closeButton,
} from './index.module.scss';

const VerifyEmailForm = ({ email, onClose, setIsLoading }) => {
  const intl = useIntl();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email()
      .required(
        intl.formatMessage({ id: 'RegisterForm.Validation.Email.Required' }),
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setIsLoading(true);

      makeRequestToApServer(
        allowedMethods.post,
        `${routes.authEmailVerification}`,
        values,
        ({ statusCode }) => {
          setIsLoading(false);
          if (statusCode === 200)
            openNotification(
              'success',
              intl.formatMessage({
                id: 'VerifyEmailForm.Request.Success.Text',
              }),
              intl.formatMessage({
                id: 'VerifyEmailForm.Request.Success.Message',
              }),
              () => {
                onClose();
              },
              null,
            );
        },
        (err) => {
          setIsLoading(false);
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      ).finally(() => {
        setSubmitting(false);
        onClose();
      });
    },
  });

  /* eslint-disable */
  // slint disabled to prevent infinite re-renders
  useEffect(() => {
    formik.setFieldValue('email', email);
  }, [email]);
  /* eslint-enable */

  return (
    <div>
      <div className={wrapperHeader} role="menu">
        <div
          className={closeButton}
          onClick={() => {
            onClose();
          }}
          onKeyDown={() => {}}
          aria-label="Cerrar"
          role="button"
          tabIndex={-1}
        >
          &times;
        </div>
        <div className={formTitle}>
          {intl.formatMessage({ id: 'VerifyEmailForm.Header' })}
        </div>
      </div>
      <div className={wrapper}>
        <form onSubmit={formik.handleSubmit} className={form}>
          <span>
            {intl.formatMessage({ id: 'VerifyEmailForm.Message.Text' })}
          </span>
          <button
            type="submit"
            className={button}
            disabled={formik.isSubmitting}
          >
            {intl.formatMessage({ id: 'VerifyEmailForm.Button.Text' })}
          </button>
        </form>
      </div>
    </div>
  );
};

VerifyEmailForm.propTypes = {
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default VerifyEmailForm;
