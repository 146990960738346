import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import makeRequestToApServer from '../../../../../services/ApServer/askToApServer';
import { allowedMethods } from '../../../../../constants/ApServer/apServer';
import openNotification from '../../../../../components/NotificacionMessage';
import routes from '../../../../../constants/ApServer/apRoutes';
import { useAuthDispatch } from '../../../../../services/Auth/useAuth';
import actionsTypes from '../../../../../services/Auth/actionsTypes';
import {
  form,
  formRow,
  textAreaRow,
  radioContainer,
  checkmark,
  reason,
  formActions,
  textArea,
  error,
} from './index.module.scss';

const actions = {
  tooManyEmails: {
    route: routes.settingsUnsubscribe,
    method: allowedMethods.put,
    notificacionTitle: 'PrivateAreaSettings.Request.Success.Title.Text',
    notificationMessage: 'PrivateAreaSettings.Request.Success.Message.Text',
  },
  deleteAllInfo: {
    route: routes.settingsUser,
    method: allowedMethods.delete,
    notificacionTitle: 'DeleteAccountForm.DeleteAllInfo.Title.Text',
    notificationMessage: 'DeleteAccountForm.DeleteAllInfo.Message.Text',
  },
};

const DeleteAccountForm = ({ onSave, setIsLoading }) => {
  const dispatch = useAuthDispatch();
  const intl = useIntl();

  const formik = useFormik({
    initialValues: {
      deleteReason: '',
      reason: '',
    },
    validationSchema: Yup.object({
      deleteReason: Yup.string(),
      reason: Yup.string()
        .trim()
        .when('deleteReason', {
          is: 'deleteAllInfo',
          then: (schema) =>
            schema.required(
              intl.formatMessage({
                id: 'DeleteAccountForm.Validation.Reason.Required',
              }),
            ),
          otherwise: (schema) => schema.notRequired(),
        }),
    }),
    onSubmit: (values, { setSubmitting }) => {
      setIsLoading(true);
      makeRequestToApServer(
        actions[values?.deleteReason]?.method,
        actions[values?.deleteReason]?.route,
        values,
        (data) => {
          setIsLoading(false);
          if (
            data?.statusCode === 200 &&
            values?.deleteReason === 'tooManyEmails'
          )
            openNotification(
              'success',
              intl.formatMessage({
                id: actions[values?.deleteReason]?.notificacionTitle,
              }),
              intl.formatMessage({
                id: actions[values?.deleteReason]?.notificationMessage,
              }),
              () => {
                onSave(values);
              },
              null,
            );
          if (values?.deleteReason === 'deleteAllInfo')
            openNotification(
              'success',
              intl.formatMessage({
                id: actions[values?.deleteReason]?.notificacionTitle,
              }),
              intl.formatMessage({
                id: actions[values?.deleteReason]?.notificationMessage,
              }),
              () => {
                dispatch({ type: actionsTypes?.LOGOUT });
              },
              null,
            );
        },
        (err) => {
          setIsLoading(false);
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      ).finally(() => {
        setSubmitting(false);
      });
    },
  });

  const buttonLabel = useMemo(() => {
    switch (formik.values.deleteReason) {
      case 'tooManyEmails':
        return intl.formatMessage({
          id: 'DeleteAccountForm.Button.ToManyEmails.Text',
        });

      case 'deleteAllInfo':
        return intl.formatMessage({
          id: 'DeleteAccountForm.Button.DeleteAllInfo.Text',
        });

      default:
        return intl.formatMessage({ id: 'DeleteAccountForm.Button.Text' });
    }
  }, [formik.values.deleteReason, intl]);

  return (
    <form onSubmit={formik.handleSubmit} className={form}>
      <div className={formRow}>
        <span>
          {intl.formatMessage({ id: 'DeleteAccountForm.Information.Text' })}
        </span>
      </div>
      <div className={formRow}>
        <label className={radioContainer} htmlFor="tooManyEmails">
          <input
            type="radio"
            id="tooManyEmails"
            name="deleteReason"
            value="tooManyEmails"
            checked={formik.values.deleteReason === 'tooManyEmails'}
            onChange={() =>
              formik.setFieldValue('deleteReason', 'tooManyEmails')
            }
          />
          <span className={checkmark} />
          <span className={reason}>
            {intl.formatMessage({
              id: 'DeleteAccountForm.Reason.TooManyEmails.Text',
            })}
            <br />
            {intl.formatMessage({
              id: 'DeleteAccountForm.KeepAccountBenefits.Text',
            })}
          </span>
        </label>
      </div>
      <div className={formRow}>
        <label className={radioContainer} htmlFor="deleteAllInfo">
          <input
            type="radio"
            id="deleteAllInfo"
            name="deleteReason"
            value="deleteAllInfo"
            checked={formik.values.deleteReason === 'deleteAllInfo'}
            onChange={() =>
              formik.setFieldValue('deleteReason', 'deleteAllInfo')
            }
          />
          <span className={checkmark} />
          <span className={reason}>
            {intl.formatMessage({
              id: 'DeleteAccountForm.Reason.DeleteAllInfo.Text',
            })}
            <br />
            {intl.formatMessage({
              id: 'DeleteAccountForm.DeleteAccountConsequences.Text',
            })}
          </span>
        </label>
      </div>
      {formik.values.deleteReason === 'deleteAllInfo' && (
        <div className={textAreaRow}>
          <span>
            {intl.formatMessage({
              id: 'DeleteAccountForm.Reason.Text',
            })}
          </span>
          <textarea
            className={textArea}
            name="reason"
            value={formik.values.reason}
            onChange={formik.handleChange}
          />
          {formik.touched.reason && formik.errors.reason ? (
            <div className={error}>{formik.errors.reason}</div>
          ) : null}
        </div>
      )}
      <div className={formActions}>
        <button
          type="submit"
          disabled={formik.values.deleteReason === '' || formik.isSubmitting}
        >
          {buttonLabel}
        </button>
      </div>
    </form>
  );
};

DeleteAccountForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default DeleteAccountForm;
