import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import PastTripCard from '../../../../components/PastTripCard';
import makeRequestToApServer from '../../../../services/ApServer/askToApServer';
import routes from '../../../../constants/ApServer/apRoutes';
import { allowedMethods } from '../../../../constants/ApServer/apServer';
import Carousel from '../../../../components/Carousel';
import Spinner from '../../../../components/Spinner';
import {
  container,
  title,
  description,
  cardPlaceholder,
} from './index.module.scss';
import decideImageSize from '../../../../utils/decideImageSize';
import PastTripPlaceholder from '../../../../components/PastTripPlaceholder';

const PastTrip = () => {
  const intl = useIntl();
  const [trips, setTrips] = useState([
    /*
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: false,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    },
    {
      featuredImage: './images/kampaoh-camping-background.png',
      location: 'Paris',
      price: 9,
      isTribing: true,
      id: 1,
    }, */
  ]);
  const [loading, setLoading] = useState(true);
  const [decideImageSizeState, setDecideImageSizeState] = useState(
    decideImageSize(),
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');
    const handleOrientationChange = () => {
      setDecideImageSizeState(decideImageSize());
    };
    // Registry a Listener to detect changes in the orientation
    mediaQuery.addListener(handleOrientationChange);
    // Clean the listener when the component is umounted
    return () => {
      mediaQuery.removeListener(handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    makeRequestToApServer(
      allowedMethods.get,
      routes.pastTrip,
      undefined,
      ({ statusCode, data }) => {
        if (statusCode === 200) {
          setTrips(data);
        }
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
    );
  }, []);

  const content = useMemo(() => {
    if (loading) {
      return (
        <div className={cardPlaceholder}>
          <Spinner />
        </div>
      );
    }

    if (trips.length === 0) {
      return (
        <div className={cardPlaceholder}>
          <PastTripPlaceholder />
        </div>
      );
    }

    const tripCards = trips.map((trip, index) => (
      <PastTripCard
        travelObject={{
          ...trip,
          featuredImage:
            trip?.featuredImage[decideImageSizeState]?.url ||
            './images/kampaoh-camping-background.png',
        }}
        key={`${trip.id}-${index.toString()}`}
      />
    ));

    return <Carousel>{tripCards}</Carousel>;
  }, [loading, trips, decideImageSizeState]);

  const descriptionText = useMemo(
    () => (
      <p className={description}>
        {trips?.length > 0 &&
          intl.formatMessage({ id: 'PastTrip.Description.Text' })}
      </p>
    ),
    [trips, intl],
  );

  return (
    <div className={container}>
      <h2 className={title}>
        {intl.formatMessage({ id: 'PastTrip.Title.Text' })}
      </h2>
      {descriptionText}
      <div>{content}</div>
    </div>
  );
};

export default PastTrip;
