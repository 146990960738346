import { Form, Radio, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import rules from '../validationRules';
import {
  labelStyle,
  radioElementStyle,
  radioGroupStyle,
  whoWillYouComeWith,
} from './index.module.scss';

const WhoWillYouComeWith = () => {
  const intl = useIntl();
  const fieldTitle = intl.formatMessage({ id: 'UserForm.Validation.Company.Title', defaultMessage: '¿Con quién vienes?' });
  const fieldLabel = useMemo(
    () => <Typography.Text className={labelStyle} strong>{fieldTitle}</Typography.Text>,
    [fieldTitle],
  );

  return (
    <div className={whoWillYouComeWith}>
      <Form.Item name="COMP" rules={rules.COMP} label={fieldLabel}>
        <Radio.Group className={radioGroupStyle} data-cy="checkout-step3-guest-type">
          <Radio className={radioElementStyle} value="FAMILIA">{intl.formatMessage({ id: 'UserForm.Validation.Company.Family', defaultMessage: 'En familia' })}</Radio>
          <Radio className={radioElementStyle} value="Amigos">{intl.formatMessage({ id: 'UserForm.Validation.Company.Friends', defaultMessage: 'Con amigos' })}</Radio>
          <Radio className={radioElementStyle} value="PAREJA">{intl.formatMessage({ id: 'UserForm.Validation.Company.Partner', defaultMessage: 'En pareja' })}</Radio>
          <Radio className={radioElementStyle} value="OTRO">{intl.formatMessage({ id: 'UserForm.Validation.Company.Other', defaultMessage: 'Otro' })}</Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default WhoWillYouComeWith;
