import axios from 'axios';
import serverUrl from '../constants/config';
import { URL } from '../constants/cms';
import beAxios from '../interceptors/beAxios';
import backendCanaryLogic from '../backend_canary_logic.json';

const UNAUTHORIZED = 401;
const errorIs401 = (error) =>
  error.response && error.response.status === UNAUTHORIZED;
const makeRequestToServer = async (path) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${serverUrl}/api/v2${path}`, {
        headers: {
          'x-rare-header': 'AlfKam01.!983256t%&',
          'canary-backend': backendCanaryLogic.canary_backend,
          withCredentials: true,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        if (errorIs401(error)) {
          try {
            // const callAfterTwoSeconds = () => setTimeout(async () => {
            const secondResponse = await makeRequestToServer(path);
            resolve(secondResponse.data);
            // }, 2000);
            // callAfterTwoSeconds();
          } catch (errorAfterRetry) {
            reject(errorAfterRetry);
          }
        } else {
          reject(error);
        }
      });
  });

const makeRequestToServerV3 = async (path) =>
  new Promise((resolve, reject) => {
    beAxios
      .get(`${serverUrl}/api/v3${path}`, {
        headers: {
          'x-rare-header': 'AlfKam01.!983256t%&',
          'canary-backend': backendCanaryLogic.canary_backend,
          withCredentials: true,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        reject(error);
      });
  });

const makeRequestToCmsServer = async (path, maxAttempts = 3) => {
  let attempts = 0;

  const sendRequest = async () => {
    try {
      const response = await axios.get(`${URL}${path}`);
      return response.data.data;
    } catch (error) {
      attempts += 1;
      if (attempts < maxAttempts) {
        return sendRequest();
      }
      throw new Error('Maximum number of retries exceeded');
    }
  };

  return sendRequest();
};

const postRequestToServer = async (path, formData, retries = 3) =>
  axios({
    method: 'POST',
    url: `${serverUrl}/api/v2${path}`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'canary-backend': backendCanaryLogic.canary_backend,
      withCredentials: true,
    },
  })
    .then((response) => {
      if (response.status === 200 && response.data.success === true)
        return response.data;
      if (retries > 0) return postRequestToServer(path, formData, retries - 1);
      return { error: true, message: 'ulysesError', body: response.data };
    })
    .catch(async (error) => ({
      error: true,
      message: 'ulysesError',
      body: error,
    }));

const putRequestToServer = async (path, formData, retries = 3) =>
  axios({
    method: 'PUT',
    url: `${serverUrl}/api/v1.1${path}`,
    data: formData,
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      if (response.status === 200 && response.data.success === true)
        return response.data;
      if (retries > 0) return putRequestToServer(path, formData, retries - 1);
      return { error: true, message: 'ulysesError', body: response.data };
    })
    .catch(async (error) => ({
      error: true,
      message: 'ulysesError',
      body: error,
    }));

export default makeRequestToServer;
export {
  postRequestToServer,
  makeRequestToCmsServer,
  putRequestToServer,
  makeRequestToServerV3,
};
