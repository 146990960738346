let isRefreshing = false;
let refreshSubscribers = [];

const isRefreshRequest = (request, refreshRoute) =>
  request.url === refreshRoute;

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token) => {
  refreshSubscribers.forEach((cb) => cb(token));
  refreshSubscribers = [];
};
const onRefreshFailed = (error) => {
  refreshSubscribers.forEach((cb) => cb(Promise.reject(error)));
  refreshSubscribers = [];
};
const setRefreshing = (refreshing) => {
  isRefreshing = refreshing;
};

const getRefreshing = () => isRefreshing;
module.exports = {
  isRefreshRequest,
  subscribeTokenRefresh,
  onRefreshed,
  setRefreshing,
  onRefreshFailed,
  getRefreshing,
};
