import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { wrapper, block } from './index.module.scss';

const CustomSpin = ({ display, className, tip }) => {
  useEffect(() => {
    if (display === 'floating') { document.body.classList.add('withOverflowHidden'); }

    return () => {
      document.body.classList.remove('withOverflowHidden');
    };
  }, [display]);

  return (
    <Spin tip={tip} className={`${wrapper}${display === 'block' ? ` ${block}` : ''}${className ? ` ${className}` : ''}`} />
  );
};

CustomSpin.propTypes = {
  display: PropTypes.oneOf(['floating', 'block']),
  className: PropTypes.string,
  tip: PropTypes.string,
};

CustomSpin.defaultProps = {
  display: 'floating',
  className: undefined,
  tip: undefined,
};
export default CustomSpin;
