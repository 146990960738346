import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { useConditionsState } from '../../../../services/Conditions';
import ContactInfo from '../../../ContactInfo';
import CustomModal from '../../../CustomModal';

const ModalForGroups = ({
  visible, onOk, onCancel,
}) => {
  const { selectorGrupos } = useConditionsState();

  return (
    <CustomModal
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <>
        {selectorGrupos && <ReactMarkdown source={selectorGrupos.description} />}
        <ContactInfo whatssappMessage="Hola,%20estaba%20mirando%20vuestra%20web%20y%20quiero%20hacer%20una%20reserva%20de%20grupo." />
      </>
    </CustomModal>
  );
};

ModalForGroups.propTypes = {
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
};

ModalForGroups.defaultProps = {
  visible: false,
  onOk: () => {},
  onCancel: () => {},
};

export default ModalForGroups;
