import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as LocationIcon } from '../../assets/icons/location3.svg';
import { ReactComponent as KampaohUpconmingTripIcon } from '../../assets/icons/kampaoh-upcoming-trip-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import styles from './index.module.scss';
import decideImageSize from '../../utils/decideImageSize';
import Characteristics from '../../pages/Checkout/steps/OrderResume/components/Characteristics';
import { getCampCharacteristics } from '../../pages/Checkout/steps/OrderResume/data';

const Postal = ({ trip, onClose }) => {
  const intl = useIntl();
  const [decideImageSizeState, setDecideImageSizeState] = useState(
    decideImageSize(),
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');
    const handleOrientationChange = () => {
      setDecideImageSizeState(decideImageSize());
    };
    mediaQuery.addListener(handleOrientationChange);
    document.body.style.overflow = 'hidden';
    return () => {
      mediaQuery.removeListener(handleOrientationChange);
      document.body.style.overflow = 'auto';
    };
  }, []);

  const formattedDate = useCallback(
    (date) => {
      const momentDate = moment(date).locale(intl.locale);
      let formatedDate = momentDate.format('ddd, DD MMM');

      if (intl.locale.startsWith('pt')) {
        formatedDate = formatedDate.replace(/\./g, '');
      }

      return formatedDate;
    },
    [intl],
  );

  return (
    <div>
      <div
        className={styles.postalOverlay}
        onClick={() => onClose()}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        aria-label="Close"
      >
        <div
          className={styles.postalContent}
          role="none"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onKeyDown={() => {}}
        >
          <div className={styles.imageSection}>
            <img
              src={trip?.camping?.featuredImage[decideImageSizeState]?.url}
              alt=""
              className={styles.image}
            />
          </div>

          <div className={styles.infoSection}>
            <KampaohUpconmingTripIcon className={styles.kampaohIcon} />
            <div className={styles.reservationCode}>
              <h2>{trip?.reservationLocator}</h2>
            </div>
            <div className={styles.location}>
              <LocationIcon />
              <div>
                <h2>{trip?.camping?.campingName}</h2>
                <p>{trip?.camping?.location}</p>
              </div>
            </div>
            <div className={styles.details}>
              <div className={styles.checkInOutContainer}>
                <div className={styles.datesContainer}>
                  <div>
                    <span>
                      {intl.formatMessage({ id: 'Postal.StartDate.Text' })}
                    </span>
                    <span>{formattedDate(trip?.checkin)}</span>
                  </div>
                  <div>
                    <span>
                      {intl.formatMessage({ id: 'Postal.EndDate.Text' })}
                    </span>
                    <span>{formattedDate(trip?.checkout)}</span>
                  </div>
                </div>
                <div className={styles.hostsContainer}>
                  <UserIcon />
                  <div>
                    <span>
                      {intl.formatMessage({ id: 'Postal.Members.Text' })}
                    </span>
                    <div>
                      <FormattedMessage id="typeOfPerson.adults" />
                      {` x${trip?.people?.adult}`}
                      {trip?.people?.child !== 0 && (
                        <>
                          {', '}
                          <FormattedMessage id="typeOfPerson.children" />
                          {` x${trip?.people?.child}`}
                        </>
                      )}
                      {trip?.people?.infant !== 0 && (
                        <>
                          {', '}
                          <FormattedMessage id="typeOfPerson.babies" />
                          {` x${trip?.people?.infant}`}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.details}>
                <Characteristics
                  data={getCampCharacteristics(trip?.tent?.name, {
                    campingName: trip?.camping?.campingName,
                    freeParking: trip?.camping?.freeParking,
                  })}
                />
              </div>
            </div>
            <div className={styles.totalContainer}>
              <span>{intl.formatMessage({ id: 'Postal.Total.Text' })}</span>
              <div className={styles.priceContainer}>
                <span>{`${trip?.price}€`}</span>
                <span>
                  {intl.formatMessage({ id: 'Postal.TaxesIncluded.Text' })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Postal.defaultProps = {
  onClose: () => {},
};

Postal.propTypes = {
  onClose: PropTypes.func,
  trip: PropTypes.shape({
    reservationLocator: PropTypes.string.isRequired,
    checkin: PropTypes.string.isRequired,
    checkout: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    camping: PropTypes.shape({
      campingName: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      featuredImage: PropTypes.shape({
        large: PropTypes.shape({ url: PropTypes.string.isRequired }),
      }),
      freeParking: PropTypes.bool,
    }).isRequired,
    people: PropTypes.shape({
      adult: PropTypes.number.isRequired,
      child: PropTypes.number,
      infant: PropTypes.number,
    }).isRequired,
    tent: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Postal;
