import React from "react";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import {
  counterContainer,
  counterLabel,
  counterSelector,
  counterLabelSubtitle,
  disabled as _disabled,
  hidden,
  show,
  stripped,
  countWrapper,
  innerWrapper,
} from "./index.module.scss";
import { boys, babies, adults } from "../../constants/typeOfPersons";
import Services from "../../constants/services";

const Count = ({ count }) => (
  <div className={countWrapper}>
    <div className={innerWrapper} style={{ top: `-${17 * count}px` }}>
      {Array.from(Array(10).keys()).map((key) => (
        <span key={key}>{key}</span>
      ))}
    </div>
  </div>
);

Count.propTypes = {
  count: PropTypes.number,
};
Count.defaultProps = {
  count: 0,
};

const PersonCounter = injectIntl(
  ({
    typeOfPerson,
    count,
    intl,
    onClickAdd,
    onClickLess,
    isHidden,
    showLines,
    kampaohService,
    disabledMinus,
    disabledPlus,
  }) => (
    <div
      className={`${counterContainer} ${isHidden ? hidden : show} ${
        showLines ? stripped : ""
      }`}
    >
      <div className={counterLabel}>
        <span>
          <strong>
            {intl.formatMessage({
              id: `typeOfPerson.${typeOfPerson}`,
              defaultMessage: typeOfPerson,
            })}
          </strong>
        </span>
        {typeOfPerson === adults && (
          <span className={counterLabelSubtitle}>
            {intl.formatMessage({
              id: "typeOfPerson.adults.Description",
              defaultMessage: typeOfPerson,
            })}
          </span>
        )}
        {typeOfPerson === boys && (
          <span className={counterLabelSubtitle}>
            {intl.formatMessage({
              id: `#typeOfPerson.children.${kampaohService}.Description`,
              defaultMessage: typeOfPerson,
            })}
          </span>
        )}
        {typeOfPerson === babies && (
          <span className={counterLabelSubtitle}>
            {intl.formatMessage({
              id: "typeOfPerson.babies.Description",
              defaultMessage: typeOfPerson,
            })}
          </span>
        )}
      </div>
      <div className={counterSelector}>
        <Button
          shape="circle"
          icon={<MinusOutlined />}
          onClick={onClickLess}
          disabled={!count || disabledMinus}
          className={!count || disabledMinus ? _disabled : ""}
          data-cy={`remove-${typeOfPerson}-button`}
        />
        <Count count={count} />
        <Button
          shape="circle"
          icon={<PlusOutlined />}
          onClick={onClickAdd}
          disabled={disabledPlus}
          className={disabledPlus ? _disabled : ""}
          data-cy={`add-${typeOfPerson}-button`}
        />
      </div>
    </div>
  ),
);

PersonCounter.defaultProps = {
  typeOfPerson: "Adults",
  count: 0,
  onClickAdd: () => {},
  onClickLess: () => {},
  disabled: false,
  kampaohService: Services.CAMP,
};

PersonCounter.propTypes = {
  typeOfPerson: PropTypes.string,
  count: PropTypes.number,
  onClickAdd: PropTypes.func,
  onClickLess: PropTypes.func,
  disabledMinus: PropTypes.bool,
  disabledPlus: PropTypes.bool,
  kampaohService: PropTypes.string,
};

export default PersonCounter;
