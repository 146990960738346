import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Eye } from '../../assets/icons/icon_eye.svg';
import { ReactComponent as EyeSlash } from '../../assets/icons/icon_eye_slash.svg';
import {
  passwordInputContainer,
  passwordToggleButton,
  passwordInputWrapper,
} from './index.module.scss';

const PasswordInput = ({
  value,
  onChange,
  name,
  placeholder,
  onBlur,
  className,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={passwordInputWrapper}>
      <input
        type={isPasswordVisible ? 'text' : 'password'}
        className={`${className} ${passwordInputContainer}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        onBlur={onBlur}
      />
      <button
        type="button"
        className={passwordToggleButton}
        onClick={togglePasswordVisibility}
      >
        {isPasswordVisible ? <EyeSlash /> : <Eye />}
      </button>
    </div>
  );
};

PasswordInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  className: PropTypes.string,
};

PasswordInput.defaultProps = {
  placeholder: '',
  onBlur: () => {},
  className: '',
};

export default PasswordInput;
