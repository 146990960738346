const ROUTES = {
  home: '/dates',
  places: '/places',
  resume: '/resume',
  policies: '/policies',
  form: '/form',
  roomDetails: '/room',
  payment: '/payment',
  propertyDetails: '/property',
  confirmation: '/confirmation',
  checkin: '/checkin',
  book: '/book',
  language: '/language',
  host: '/host',
  guests: '/guests',
  reservationResume: '/reservationResume',
  finish: '/finish',
  auth: '/register',
  me: '/me',
  meSettings: '/me/settings',
  verifyEmail: '/register/email/verify',
  passwordReset: '/password/reset',
};

export default ROUTES;
