import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { URL } from '../../../../../../../../constants/cms';
import useCampings from '../../../../../../../../services/Campings/useCampings';
import { useConditionsState } from '../../../../../../../../services/Conditions';
import { useReservationState } from '../../../../../../../../services/Reservation';
import {
  FullConditionsContainer,
} from '../../../../index.module.scss';
import AcceptanceButton from '../../../AcceptanceButton';
import ConditionAcceptance from '../../../ConditionAcceptance';
import PdfLoader from './PdfLoader';

const ReservationConditionAcceptance = ({ checked, changeChecked }) => {
  const { KPReservationConditions } = useConditionsState();
  const { selectedHotelId } = useReservationState();
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const propertyFromURL = searchParams.get('property');
  const {
    state: {
      campings: { [selectedHotelId || propertyFromURL]: camping },
    },
  } = useCampings();

  const [showPolicy, setShowPolicy] = useState(false);

  // const pdfExample = 'https://cloudbeds-live.s3.us-west-2.amazonaws.com/uploads/27652/condiciones_reserva_cliente_-_el_palmar_v3~~5f3d1f6f07e46.pdf';
  const linkCondiciones = camping?.condition?.PDF?.url?.includes('http') ? camping?.condition?.PDF.url : `${URL}${camping?.condition?.PDF?.url}`;
  return (
    <>
      <ConditionAcceptance
        markdownSource={KPReservationConditions?.description}
        checked={checked}
        changeChecked={changeChecked}
        data-cy="checkout-step2-reservation"
      />
      <div className={FullConditionsContainer}>
        <AcceptanceButton type="condition" onClick={() => setShowPolicy(!showPolicy)} />
        {showPolicy && linkCondiciones && <PdfLoader document={linkCondiciones} />}
        {showPolicy && !linkCondiciones && (
          <div>
            Error: Escribe a/Contact to =
            {'>'}
            {' '}
            reservas@kampaoh.com
          </div>
        )}
      </div>
    </>
  );
};

ReservationConditionAcceptance.defaultProps = {
  checked: false,
  changeChecked: () => {},
};

ReservationConditionAcceptance.propTypes = {
  checked: PropTypes.bool,
  changeChecked: PropTypes.func,
};

export default ReservationConditionAcceptance;
