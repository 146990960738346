import { Modal } from 'antd';

const openNotification = (
  type, title, content, onOk, onCancel, okButtonProps, cancelButtonProps,
) => Modal[type]({
  title,
  centered: true,
  content,
  onOk,
  onCancel,
  okButtonProps,
  cancelButtonProps,
});

export default openNotification;
