import { gql } from '@apollo/client';

const locations = gql`
  query GetLocations {
    campings (pagination: { limit: -1 }) {
      data{
        id,
        attributes {
          location,
          region{
            data{
              id,
              attributes {
                name
              },
            },
          },
        }
      }
    }
  }
`;

export default locations;
