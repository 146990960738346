import React, { useCallback } from "react";
import { useNavigate } from "@reach/router";
import { useIntl } from "react-intl";
import ROUTES from "../../routes/routes";
// import { ReactComponent as PlantIcon } from "../../assets/icons/plant.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";

import {
  wrapper,
  content,
  textContainer,
  buttonsContainer,
  exploreButton,
  promotion,
} from "./index.module.scss";

const PastTripPlaceholder = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleLoginClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      navigate(`${ROUTES.home}`);
    },
    [navigate],
  );

  return (
    <div className={wrapper}>
      <div className={content}>
        <div className={textContainer}>
          {/* <h2 className={title}>
            {intl.formatMessage({ id: "PastTripPlaceholder.Title" })}
          </h2>
          <p className={description}>
            {intl.formatMessage({ id: "PastTripPlaceholder.Description" })}
          </p> */}
          <h2 className={promotion}>
            {intl.formatMessage({ id: "PastTripPlaceholder.Promotion.Text" })}
          </h2>
          <div className={buttonsContainer}>
            <button
              className={exploreButton}
              onClick={handleLoginClick}
              type="button"
            >
              <SearchIcon />
              {intl.formatMessage({
                id: "PastTripPlaceholder.ExploreButton.Text",
              })}
            </button>
          </div>
        </div>
      </div>
      {/*      <PlantIcon className={decorativeImage} /> */}
    </div>
  );
};

export default PastTripPlaceholder;
