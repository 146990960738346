import beAxios from '../interceptors/beAxios';
import serverUrl from '../constants/config';

const registerPayment = async (path, body) =>
  beAxios
    .post(`${serverUrl}${path}`, body, {
      timeout: 20000,
      // Tiempo en milisegundos para lanzar un mensaje de error al procesar el pago si no
    })
    .then((response) => response.data)
    .catch(async (error) => {
      if (error.response && error.response.status === 404) {
        return { error: true, message: 404, body: error };
      }
      if (error.response && error.response.status === 400) {
        return { error: true, message: 400, body: error.response.data.raw };
      }
      return { error: true, message: 500, body: error };
    });

export default registerPayment;
