// Maintenance.js
import React from 'react';
import {
  wrapper, logoHomeStyle,
} from './index.module.scss';
import { ReactComponent as KampaohIcon } from '../../assets/icons/kampaoh.svg';

const Maintenance = () => (
  <div className={wrapper}>
    <KampaohIcon className={logoHomeStyle} />
    <h1>Site Under Maintenance</h1>
    <p>We apologize for the inconveniences. Please check again later.</p>
  </div>
);

export default Maintenance;
