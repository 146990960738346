import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import initialState from './initialState';
import reducer from './reducer';

const UserStateContext = createContext();
const UserDispatchContext = createContext();

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserProvider, UserStateContext, UserDispatchContext };
