import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  memo,
} from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useLocation } from '@reach/router';
import { useAuthState } from '../../services/Auth/useAuth';
import { ReactComponent as SearchIcon } from '../../assets/icons/icon_search.svg';
import { ReactComponent as NoProfileImage } from '../../assets/icons/icon_no_profile_image.svg';
import ROUTES from '../../routes/routes';
import {
  mobileMenu,
  menuItem,
  activeMenuItem,
  searchIcon,
  noProfileImageIcon,
  hidden,
} from './index.module.scss';

const MobileMenu = memo(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken } = useAuthState();
  const [visible, setVisible] = useState(true);
  const lastScrollY = useRef(window.scrollY);
  const lastTime = useRef(Date.now());
  const intl = useIntl();
  const authPaths = useMemo(() => [ROUTES.auth, ROUTES.me], []);

  useEffect(() => {
    let requestId;

    const handleScroll = () => {
      if (!requestId) {
        requestId = requestAnimationFrame(() => {
          const currentScrollY = window.scrollY;
          const currentTime = Date.now();
          const speed =
            Math.abs(currentScrollY - lastScrollY.current) /
            (currentTime - lastTime.current);
          const speedThreshold = 1;
          if (speed > speedThreshold && currentScrollY > lastScrollY.current) {
            setVisible(false);
          } else if (currentScrollY < lastScrollY.current) {
            setVisible(true);
          }

          lastScrollY.current = currentScrollY;
          lastTime.current = currentTime;
          requestId = null;
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', handleScroll);
      cancelAnimationFrame(requestId);
    };
  }, []);
  const handleNavigation = useCallback(
    (path, event) => {
      event.stopPropagation();
      navigate(path);
    },
    [navigate],
  );

  const navigateToLogin = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const params = new URLSearchParams(window.location.search);
      params.delete('redirect');
      const redirect = `${window.location.pathname}${
        // eslint-disable-next-line prefer-template
        params.toString() ? '?' + params.toString() : ''
      }`;
      if (window.location.pathname !== ROUTES.auth) {
        navigate(`${ROUTES.auth}?redirect=${encodeURIComponent(redirect)}`);
      }
    },
    [navigate],
  );

  const authMenu = useMemo(
    () => (
      <div
        className={`${menuItem} ${
          authPaths.includes(location.pathname) ? activeMenuItem : ''
        }`}
        onClick={(e) => {
          if (accessToken != null) {
            handleNavigation(ROUTES.me, e);
          } else {
            navigateToLogin(e);
          }
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={-1}
      >
        <NoProfileImage className={noProfileImageIcon} />
        <span>
          {accessToken
            ? intl.formatMessage({ id: 'MobileMenu.Button.Profile.Text' })
            : intl.formatMessage({ id: 'MobileMenu.Button.Login.Text' })}
        </span>
      </div>
    ),
    [accessToken, handleNavigation, intl, location, authPaths, navigateToLogin],
  );

  return (
    <div className={`${mobileMenu} ${!visible ? hidden : ''}`}>
      <div
        className={`${menuItem} ${
          !authPaths.includes(location.pathname) ? activeMenuItem : ''
        }`}
        onClick={() => navigate(ROUTES.home)}
        onKeyDown={() => {}}
        role="button"
        tabIndex={-1}
      >
        <SearchIcon className={searchIcon} />

        <span>
          {intl.formatMessage({ id: 'MobileMenu.Button.Search.Text' })}
        </span>
      </div>
      {authMenu}
    </div>
  );
});

export default MobileMenu;
