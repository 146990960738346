import axios from 'axios';
import serverUrl from '../constants/config';

const logEmail = async (subject, text) => axios.post(`${serverUrl}/logEmail`, {
  subject,
  text,
})
  .then((response) => (response.data))
  .catch(async (error) => ({ error: true, message: '', body: error }));

export default logEmail;
