import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { navigate } from "@reach/router";
import { useIntl } from "react-intl";
import ROUTES from "../../routes/routes";
import { useReservationDispatch } from "../../services/Reservation";
import useCampings from "../../services/Campings/useCampings";
import { button } from "./index.module.scss";
import Services from "../../constants/services";

const RoomReservationButton = ({
  ratePlan,
  propertyID,
  disabled,
  onClick,
  type,
  pets,
  petsOnCamping,
  roomId,
  tent,
  selectedRate,
  confirmationUrl,
}) => {
  const {
    state: {
      campings: { [propertyID]: camping },
    },
  } = useCampings();
  const intl = useIntl();
  const dispatch = useReservationDispatch();
  const property = camping;
  const name = type === Services.CAMP ? "campingName" : "placeName";
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const adultsFromUrl = searchParams.get("adults");
  const childrenFromUrl = searchParams.get("children");
  const babiesFromUrl = searchParams.get("babies");
  const fpaFromUrl = searchParams.get("fpa");
  const startDateFromURL = searchParams.get("checkin");
  const endDateFromURL = searchParams.get("checkout");

  return (
    <Button
      disabled={disabled}
      className={button}
      onClick={() => {
        onClick();
        dispatch({ type: "setSelectedHotel", payload: property.propertyName });
        dispatch({ type: "setCampingName", payload: property[name] });
        dispatch({ type: "setSelectedHotelId", payload: propertyID });
        dispatch({
          type: "setSelectedRoomType",
          payload: tent.tent_model.name,
        });
        dispatch({ type: "setTotalPrice", payload: selectedRate?.totalPrice });
        dispatch({
          type: "setPriceByNight",
          payload: selectedRate?.roomRateDetailed,
        });
        dispatch({ type: "setRateId", payload: selectedRate?.rateId });
        dispatch({
          type: "setRatePropertyId",
          payload: ratePlan.ratePropertyId,
        });
        dispatch({
          type: "setRateOccupancyCode",
          payload: ratePlan.occupancyCode,
        });
        dispatch({ type: "setRateOccupancyId", payload: ratePlan.occupancyId });
        dispatch({
          type: "setSelectedRoomTypeId",
          payload: ratePlan.roomTypeID,
        });
        dispatch({
          type: "setSelectedRoomTypeCode",
          payload: ratePlan.roomTypeCode,
        });
        dispatch({ type: "setRatePlanPromoCode", payload: ratePlan.promoCode });
        dispatch({
          type: "setRatePlanNamePublic",
          payload: ratePlan.ratePlanNamePublic,
        });
        dispatch({
          type: "setRatePlanNamePrivate",
          payload: ratePlan.ratePlanNamePrivate,
        });
        dispatch({
          type: "setRatePlanPropertyPolicyId",
          payload: selectedRate.propertyPolicy.id,
        });
        dispatch({
          type: "setRatePlanPropertyPolicy",
          payload: ratePlan.propertyPolicy,
        });
        dispatch({ type: "setItAsReadyToBeUsed", payload: true });
        dispatch({ type: "setPets", payload: pets });
        dispatch({ type: "setPetsOnCamping", payload: petsOnCamping });
        dispatch({ type: "setRoomId", payload: parseInt(roomId, 10) });
        dispatch({ type: "setBoardId", payload: selectedRate?.boardId });
        dispatch({ type: "setOfferId", payload: selectedRate?.offer?.id });
        dispatch({ type: "setOfferInfo", payload: selectedRate?.offer });
        dispatch({
          type: "setPromotionInfo",
          payload: selectedRate?.promotion,
        });
        dispatch({
          type: "setPromotionId",
          payload: selectedRate?.promotion?.id,
        });
        dispatch({ type: "setRateName", payload: selectedRate?.rateName });
        dispatch({
          type: "setDeadlineDay",
          payload: selectedRate?.propertyPolicy?.deadlineDay,
        });
        dispatch({
          type: "setNonRefundable",
          payload: selectedRate?.propertyPolicy?.nonRefundable,
        });
        dispatch({
          type: "setDepositPercentage",
          payload: selectedRate?.propertyPolicy?.depositPercentage,
        });
        dispatch({ type: "setConfirmationUrl", payload: confirmationUrl });
        dispatch({
          type: "setPromotionId",
          payload: selectedRate?.promotion?.id,
        });
        return (
          navigate(
            `${ROUTES.book}${
              ROUTES.resume
            }?${`&checkin=${startDateFromURL}`}${`&checkout=${endDateFromURL}`}${
              selectedRate?.rateId && `&rate=${selectedRate?.rateId}`
            }${selectedRate?.boardId && `&board=${selectedRate?.boardId}`}${
              selectedRate?.offer !== null
                ? `&offer=${selectedRate?.offer?.id}`
                : ""
            }${`&adults=${adultsFromUrl}`}${`&children=${childrenFromUrl}`}${`&babies=${babiesFromUrl}`}${`&fpa=${fpaFromUrl}`}${
              selectedRate?.promotion !== null
                ? `&promotion=${selectedRate?.promotion?.id}`
                : ""
            }${propertyID ? `&property=${propertyID}` : ""}${
              roomId ? `&room=${roomId}` : ""
            }`,
          ) &&
          window.dataLayer.push({
            event: "addToCartBE",
            propertyName: property.propertyName,
            propertyID,
            roomTypeName: ratePlan.roomTypeName,
            roomTypeID: ratePlan.roomTypeID,
            totalPrice: ratePlan.totalPrice,
          })
        );
      }}
      data-cy="book-room-button"
    >
      {intl.formatMessage({ id: "Button.Book" })}
    </Button>
  );
};
RoomReservationButton.propTypes = {
  ratePlan: PropTypes.shape({
    roomTypeName: PropTypes.string,
    totalPrice: PropTypes.number,
    rateID: PropTypes.number,
    roomRateDetailed: PropTypes.shape(),
    occupancyCode: PropTypes.string,
    occupancyId: PropTypes.number,
    roomTypeID: PropTypes.number,
    roomTypeCode: PropTypes.string,
    promoCode: PropTypes.string,
    ratePlanNamePrivate: PropTypes.string,
    ratePlanNamePublic: PropTypes.string,
    propertyPolicy: PropTypes.shape(),
    ratePropertyId: PropTypes.number,
  }),
  propertyID: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(Object.values(Services)).isRequired,
  pets: PropTypes.bool,
  petsOnCamping: PropTypes.bool,
  tent: PropTypes.shape({
    tent_model: PropTypes.shape(),
  }),
  roomId: PropTypes.number,
  selectedRate: PropTypes.shape({
    rateId: PropTypes.number,
    boardId: PropTypes.number,
    offer: PropTypes.number,
    promotion: PropTypes.number,
    totalPrice: PropTypes.number,
    roomRateDetailed: PropTypes.shape({}),
    propertyPolicy: PropTypes.shape({
      id: PropTypes.number,
      deadlineDay: PropTypes.number,
      nonRefundable: PropTypes.number,
      depositPercentage: PropTypes.number,
    }),
    rateName: PropTypes.string,
  }),
  confirmationUrl: PropTypes.string.isRequired,
};
RoomReservationButton.defaultProps = {
  ratePlan: {
    roomTypeName: "",
    totalPrice: 0,
    rateID: 0,
    roomTypeID: 0,
    ratePropertyId: 0,
    roomId: 0,
  },
  propertyID: "",
  disabled: false,
  onClick: () => {},
  pets: false,
  petsOnCamping: false,
  tent: {},
  roomId: 0,
  selectedRate: {
    rateId: 0,
    boardId: 0,
    offer: 0,
    promotion: 0,
  },
};
export default RoomReservationButton;
