/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useIntl } from "react-intl";
import { wrapper, upArrow, downArrow } from "./index.module.scss";
import {
  useShowRoomsDispatch,
  useShowRoomsState,
} from "../../../../../../../../../services/ShowRooms";
import { ReactComponent as ArrowIcon } from "../../../../../../../../../assets/icons/return.svg";

const CardFooter = ({ propertyID, onClick, open: _open, cardContainerRef }) => {
  const intl = useIntl();
  const { showRooms } = useShowRoomsState();
  const dispatch = useShowRoomsDispatch();
  const [open, setOpen] = useState(_open);

  const handleOnClick = () => {
    if (showRooms[propertyID]) {
      dispatch({ type: "deactivateShowRoom", payload: { propertyID } });
      setOpen((prev) => !prev);
    } else {
      dispatch({ type: "activateShowRoom", payload: { propertyID } });
      setOpen((prev) => !prev);
    }
    onClick(!open);
  };

  useEffect(() => {
    if (_open) {
      setOpen(_open);
    }
  }, [_open]);

  useEffect(() => {
    if (!open) {
      dispatch({ type: "deactivateShowRoom", payload: { propertyID } });
    } else {
      dispatch({ type: "activateShowRoom", payload: { propertyID } });
    }
  }, [open, propertyID, dispatch]);

  return (
    <div
      className={wrapper}
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
      role="button"
      tabIndex={0}
      ref={cardContainerRef}
      data-cy="card-footer"
    >
      {open ? (
        <>
          <span>{intl.formatMessage({ id: "Card.Footer.Close" })}</span>
          <ArrowIcon className={upArrow} />
        </>
      ) : (
        <>
          <span>{intl.formatMessage({ id: "Card.Footer.ChooseATend" })}</span>
          <ArrowIcon className={downArrow} />
        </>
      )}
    </div>
  );
};

CardFooter.propTypes = {
  propertyID: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  cardContainerRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
  // eslint-disable-next-line react/forbid-prop-types, react/no-unused-prop-types
  ratePlans: PropTypes.arrayOf(PropTypes.any),
};

CardFooter.defaultProps = {
  onClick: () => {},
  open: false,
  cardContainerRef: () => {},
  ratePlans: [],
};
export default CardFooter;
