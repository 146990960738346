const allCampingsFetched = (state) =>
  Object.keys(state.campings).filter(
    (campingId) => state.campings[campingId].ratePlansAlreadyFetched === true,
  )?.length >=
  Object.keys(state.campings)?.length - 1;

const anyCampingStillFetching = (state) =>
  Object.keys(state.campings).filter(
    (campingId) => state.campings[campingId].isLoadingRatePlans === true,
  )?.length > 1;

const reducer = (state, action) => {
  switch (action.type) {
    case 'allCampingsFound':
      return {
        ...state,
        campings: action.payload,
      };

    case 'allLocationsFound':
      return {
        ...state,
        locations: action.payload,
      };
    case 'allTentsFound':
      return {
        ...state,
        tents: action.payload,
      };
    case 'allTentFeaturesFound':
      return {
        ...state,
        tentFeatures: action.payload,
      };
    case 'allCampingFeaturesFound':
      return {
        ...state,
        campingFeatures: action.payload,
      };
    case 'allConditionsFound':
      return {
        ...state,
        conditions: action.payload,
      };

    case 'setIsLoadingCampings':
      return {
        ...state,
        isLoadingCampings: action.payload,
      };

    case 'setRatePlansFetched':
      return {
        ...state,
        areRatePlansFetched: action.payload,
      };
    case 'setIsLoadingLocations':
      return {
        ...state,
        isLoadingLocations: action.payload,
      };

    case 'askingForCampingsRatePlans':
      return { ...state, isLoadingRatePlans: true };
    case 'startedAskingForCampingRatePlans':
      return {
        ...state,
        campings: {
          ...state.campings,
          [action.payload.campingId]: {
            ...state.campings[action.payload.campingId],
            isLoadingRatePlans: true,
            ratePlansAlreadyFetched: false,
          },
        },
      };
    case 'addRatePlanToCamping':
    case 'addRatePlanToProperty':
      return {
        ...state,
        isLoadingRatePlans: anyCampingStillFetching(state),
        ratePlansAlreadyFetched: allCampingsFetched(state),
        campings: {
          ...state.campings,
          [action.payload.campingId]: {
            ...state.campings[action.payload.campingId],
            ratePlans: action.payload.ratePlans
              ? action.payload.ratePlans.map((rp) => ({
                  ...rp,
                  campingId: action.payload.campingId,
                }))
              : [],
            isLoadingRatePlans: false,
            ratePlansAlreadyFetched: true,
          },
        },
      };

    case 'addErrorToCamping':
      return {
        ...state,
        isLoadingRatePlans: anyCampingStillFetching(state),
        ratePlansAlreadyFetched: allCampingsFetched(state),
        campings: {
          ...state.campings,
          [action.payload.campingId]: {
            ...state.campings[action.payload.campingId],
            ratePlans: undefined,
            isLoadingRatePlans: false,
            ratePlansAlreadyFetched: true,
            error: true,
          },
        },
      };

    case 'addValidRatePlansToCamping': {
      const campings = { ...state.campings };
      campings[action.payload.propertyID].validRatePlans =
        action.payload.validRates;
      return {
        ...state,
        campings,
      };
    }

    case 'addAlternativeRatePlansToCamping': {
      const campings = { ...state.campings };
      // eslint-disable-next-line max-len
      campings[action.payload.propertyID].alternativeRatePlans =
        action.payload.alternativeRatePlans;
      return {
        ...state,
        campings,
      };
    }

    case 'resetHotelsInitialState': {
      const campings = { ...state.campings };
      Object.keys(campings).map((campingId) => {
        campings[campingId].ratePlansAlreadyFetched = false;
        campings[campingId].isLoadingCampings = true;
        delete campings[campingId].validRatePlans;
        delete campings[campingId].ratePlans;
        delete campings[campingId].alternativeRatePlans;
        return null;
      });
      return { ...state, ratePlansAlreadyFetched: false, campings };
    }

    default:
      return { ...state };
  }
};

export default reducer;
