import isMobile from './isMobile';

const decideImageSize = () => {
  let imgFormat = '';
  if (isMobile()) {
    imgFormat = 'small';
  } else {
    imgFormat = 'medium';
  }
  return imgFormat;
};

export default decideImageSize;
