import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import initialState from './initialState';
import actionsTypes from './actionsTypes';
import reducer from './reducer';
import apAxios from '../../interceptors/apAxios';

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, () => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    return {
      ...initialState,
      accessToken: accessToken || null,
      refreshToken: refreshToken || null,
    };
  });

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'accessToken') {
        const newAccessToken = event.newValue;
        dispatch({
          type: actionsTypes.SET_ACCESSTOKEN,
          payload: {
            accessToken: newAccessToken,
          },
        });
        apAxios.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
      } else if (event.key === 'refreshToken') {
        const newRefreshToken = event.newValue;
        dispatch({
          type: actionsTypes.SET_REFRESHTOKEN,
          payload: {
            refreshToken: newRefreshToken,
          },
        });
      }
    };
    const handleTokenUpdate = (event) => {
      dispatch({ type: actionsTypes.SET_TOKENS, payload: { ...event.detail } });
    };

    const handleTokenClear = () => {
      dispatch({ type: actionsTypes.LOGOUT });
    };

    window.addEventListener('token-updated', handleTokenUpdate);
    window.addEventListener('token-cleared', handleTokenClear);
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('token-updated', handleTokenUpdate);
      window.removeEventListener('token-cleared', handleTokenClear);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider, AuthStateContext, AuthDispatchContext };
