import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const MOBILE_MAX_WIDTH = 768;
const TABLET_MAX_WIDTH = 1024;

const useDeviceDetect = () => {
  const [device, setDevice] = useState({
    isMobile: window.innerWidth < MOBILE_MAX_WIDTH,
    isTablet:
      window.innerWidth >= MOBILE_MAX_WIDTH &&
      window.innerWidth <= TABLET_MAX_WIDTH,
    isDesktop: window.innerWidth > TABLET_MAX_WIDTH,
  });

  const handleResize = useDebouncedCallback(() => {
    setDevice({
      isMobile: window.innerWidth < MOBILE_MAX_WIDTH,
      isTablet:
        window.innerWidth >= MOBILE_MAX_WIDTH &&
        window.innerWidth <= TABLET_MAX_WIDTH,
      isDesktop: window.innerWidth > TABLET_MAX_WIDTH,
    });
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return device;
};

export default useDeviceDetect;
