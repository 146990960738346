/* eslint-disable no-underscore-dangle */
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as GoogleIcon } from '../../assets/icons/icon_google.svg';
import openNotification from '../NotificacionMessage';
import { socialButton, icon, text } from './index.module.scss';
import { URL } from '../../constants/ApServer/apServer';
import { updateTokens } from '../../utils/tokenHelper';
import routes from '../../constants/ApServer/apRoutes';

const prefix = '/api';
const GoogleLoginButton = () => {
  const intl = useIntl();

  const handleGoogleLogin = useCallback(() => {
    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    window.open(
      `${URL}${prefix}${routes.socialLoginGoogle}`,
      'googleAuth',
      `width=${width},height=${height},top=${top},left=${left}`,
    );

    let messageReceived = false;

    const receiveMessage = (event) => {
      if (messageReceived) return;
      if (event.origin !== URL) return;

      const { data } = event;
      if (data.access_token && data.refresh_token) {
        messageReceived = true;
        updateTokens(data.access_token, data.refresh_token);
        window.removeEventListener('message', receiveMessage);

        if (data.error) {
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            data.error.message,
            () => {},
            null,
          );
        }
      }
    };

    window.addEventListener('message', receiveMessage);
  }, [intl]);

  return (
    <button className={socialButton} type="button" onClick={handleGoogleLogin}>
      <GoogleIcon className={icon} />
      <span className={text}>
        {intl.formatMessage({
          id: 'SocialLoginButtons.ContinueWithGoogle.Text',
        })}
      </span>
    </button>
  );
};

export default GoogleLoginButton;
