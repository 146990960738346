import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { allowedMethods } from '../../../../constants/ApServer/apServer';
import routes from '../../../../constants/ApServer/apRoutes';
import makeRequestToApServer from '../../../../services/ApServer/askToApServer';
import openNotification from '../../../../components/NotificacionMessage';
import { updateTokens } from '../../../../utils/tokenHelper';
import GoogleLoginButton from '../../../../components/GoogleLoginButton';
import FacebookLoginButton from '../../../../components/FacebookLoginButton';
import PasswordInput from '../../../../components/PasswordInput';
import DividerWithText from '../../../../components/DividerWithText';
import {
  wrapper,
  wrapperHeader,
  formStyle,
  formTitle,
  input,
  button,
  error,
  closeButton,
  forgotPasswordLink,
  inputHidden,
} from './index.module.scss';

const socialTypes = {
  kampaoh: 'kampaoh',
  google: 'google',
  facebook: 'facebook',
};

const LoginForm = ({
  email,
  onClose,
  onOpenRecoverPassword,
  socialProviders,
  hasKampaohAccount,
  setIsLoading,
}) => {
  const intl = useIntl();

  const validationSchema = Yup.object({
    password: Yup.string().required(
      intl.formatMessage({ id: 'LoginForm.Validation.Password.Required' }),
    ),
    email: Yup.string()
      .trim()
      .email()
      .required(
        intl.formatMessage({ id: 'RegisterForm.Validation.Email.Required' }),
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setIsLoading(true);
      makeRequestToApServer(
        allowedMethods.post,
        `${routes.authLogin}`,
        values,
        (response) => {
          setIsLoading(false);
          /* eslint-disable camelcase */
          const { access_token, refresh_token } = response.data;
          updateTokens(access_token, refresh_token);
          /* eslint-enable camelcase */
        },
        (err) => {
          setIsLoading(false);
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      ).finally(() => {
        setSubmitting(false);
        onClose();
      });
    },
  });

  /* eslint-disable */
  // slint disabled to prevent infinite re-renders
  useEffect(() => {
    formik.setFieldValue('email', email);
  }, [email]);
  /* eslint-enable */

  const form = useMemo(
    () => (
      <div className={wrapper}>
        {hasKampaohAccount && (
          <>
            <form onSubmit={formik.handleSubmit} className={formStyle}>
              <input
                type="email"
                name="email"
                className={inputHidden}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              <PasswordInput
                name="password"
                placeholder={intl.formatMessage({
                  id: 'LoginForm.Validation.Password.Text',
                })}
                className={input}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <span className={error}>{formik.errors.password}</span>
              ) : null}
              <button
                type="submit"
                className={button}
                disabled={formik.isSubmitting}
              >
                {intl.formatMessage({ id: 'LoginForm.Button.Text' })}
              </button>
            </form>
            <button
              className={forgotPasswordLink}
              onClick={onOpenRecoverPassword}
              type="button"
            >
              {intl.formatMessage({ id: 'LoginForm.Anchor.RecoverPassword' })}
            </button>
          </>
        )}
        {hasKampaohAccount && socialProviders.length > 1 && <DividerWithText />}
        {socialProviders.includes(socialTypes.google) && (
          <>
            <GoogleLoginButton />
          </>
        )}
        {socialProviders.includes(socialTypes.facebook) && (
          <>
            <FacebookLoginButton />
          </>
        )}
      </div>
    ),
    [formik, intl, onOpenRecoverPassword, socialProviders, hasKampaohAccount],
  );

  return (
    <div>
      <div className={wrapperHeader} role="menu">
        <div
          className={closeButton}
          onClick={() => {
            onClose();
          }}
          onKeyDown={() => {}}
          aria-label="Cerrar"
          role="button"
          tabIndex={-1}
        >
          &times;
        </div>
        <div className={formTitle}>
          {intl.formatMessage({ id: 'LoginForm.Header' })}
        </div>
      </div>
      {form}
    </div>
  );
};

LoginForm.propTypes = {
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpenRecoverPassword: PropTypes.func.isRequired,
  socialProviders: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasKampaohAccount: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default LoginForm;
