import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import { Modal } from 'antd';
import moment from 'moment';
import { defaultTimeLimitForTodaysBooking } from '../constants/config';

export const calcTimeLimitForTodaysBooking = (_property = {}) => {
  const time = _property.timeLimitForBooking || defaultTimeLimitForTodaysBooking;
  const parsedTime = time.split(':');
  return moment().tz('Europe/Madrid').hour(parsedTime[0]).minute(parsedTime[1]);
};

export const showNotReservationAllowedConfirmModal = (route, property, intl) => {
  const time = calcTimeLimitForTodaysBooking(property).format('HH:mm');

  Modal.confirm({
    title: intl.formatMessage({ id: 'Checkout.NotReservationAllowedConfirmModal.title' }),
    icon: <ExclamationCircleOutlined />,
    content: intl.formatMessage({ id: 'Checkout.NotReservationAllowedConfirmModal.text' }, { time }),
    onOk() {
      navigate(route);
    },
    cancelButtonProps: { style: { display: 'none' } },
  });
};
