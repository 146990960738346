import { useEffect } from 'react';
import moment from 'moment';
import { MAX_BOOKING_NIGHTS } from '../constants/defaultsValues';

const useDateRangeValidator = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const startDateFromURL = searchParams.get('checkin');
    const endDateFromURL = searchParams.get('checkout');

    const checkinDate = moment(startDateFromURL);
    const checkoutDate = moment(endDateFromURL);

    const differentDays = checkoutDate.diff(checkinDate, 'days');

    // if to set the maximun booking days in urlParams
    if (differentDays > MAX_BOOKING_NIGHTS) {
      const newCheckOutDate = checkinDate.add(MAX_BOOKING_NIGHTS, 'days');
      const newCheckOutDateString = newCheckOutDate.format('YYYY-MM-DD');

      searchParams.set('checkout', newCheckOutDateString);
      window.location.search = searchParams.toString();
    } else {
      // Nothing to do
    }
  }, []);
};

export default useDateRangeValidator;
