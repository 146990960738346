import React, { useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import moment from 'moment';
import { navigate } from '@reach/router';
import PersonalInfo from './components/PersonalInfo';
import AddressInfo from './components/AddressInfo';
import HowDidYouMeetUs from './components/HowDidYouMeetUs';
import WhoWillYouComeWith from './components/WhoWillYouComeWith';
import { useReservationState } from '../../../../services/Reservation';
import { formWrapper, spanWrapper } from './index.module.scss';
import { CheckoutContext } from '../..';
import ROUTES from '../../../../routes/routes';
import STEP from '../steps';

const UserForm = () => {
  const { setCurrentStep, policiesDone, userInfo } =
    useContext(CheckoutContext);
  setCurrentStep(STEP.form);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const propertyFromURL = parseInt(searchParams.get('property'), 10);
  const roomFromURL = parseInt(searchParams.get('room'), 10);
  const startDateFromURL = searchParams.get('checkin');
  const endDateFromURL = searchParams.get('checkout');
  const adultsFromURL = parseInt(searchParams.get('adults'), 10);
  const childrenFromURL = parseInt(searchParams.get('children'), 10);
  const babiesFromURL = parseInt(searchParams.get('babies'), 10);
  const boardFromURL = parseInt(searchParams.get('board'), 10);
  const rateFromURL = parseInt(searchParams.get('rate'), 10);
  const promotionFromURL = parseInt(searchParams.get('promotion'), 10);
  const offerFromURL = parseInt(searchParams.get('offer'), 10);

  const intl = useIntl();
  const {
    guestFirstName: Name,
    guestLastName: Surname,
    guestGender: Genre,
    guestCountry: Country,
    guestZip: PostalCode,
    guestCity: City,
    guestEmail: Email,
    guestPhoneFormField: Phone,
    guestCompany: COMP,
    guestMeetUsBy: FUENTE,
    guestIdentity: DNI,
    guestClub: CLUB,
  } = useReservationState();

  const { form } = useContext(CheckoutContext);

  useEffect(() => {
    if (!policiesDone) {
      navigate(
        `${ROUTES?.book + ROUTES?.policies}?checkin=${moment(
          startDateFromURL,
        )?.format('YYYY-MM-DD')}&checkout=${moment(endDateFromURL)?.format(
          'YYYY-MM-DD',
        )}${rateFromURL && `&rate=${rateFromURL}`}${
          boardFromURL && `&board=${boardFromURL}`
        }${
          offerFromURL !== null ? `&offer=${offerFromURL}` : ''
        }${`&adults=${adultsFromURL}`}${`&children=${childrenFromURL}`}${`&babies=${babiesFromURL}`}${
          promotionFromURL !== null ? `&promotion=${promotionFromURL}` : ''
        }${propertyFromURL ? `&property=${propertyFromURL}` : ''}${
          roomFromURL ? `&room=${roomFromURL}` : ''
        }`,
      );
    }
  }, [
    policiesDone,
    adultsFromURL,
    babiesFromURL,
    boardFromURL,
    childrenFromURL,
    endDateFromURL,
    offerFromURL,
    promotionFromURL,
    propertyFromURL,
    rateFromURL,
    roomFromURL,
    startDateFromURL,
  ]);

  const disabledFields = useMemo(
    () => ({
      Name: !!userInfo?.name,
      Surname: !!userInfo?.surname,
      Genre: !!userInfo?.genre,
      Country: !!userInfo?.country,
      PostalCode: !!userInfo?.postalCode,
      Email: !!userInfo?.email?.email,
      DNI: !!userInfo?.identityDocument?.documentNumber,
    }),
    [userInfo],
  );

  return (
    <Form
      className={formWrapper}
      layout="vertical"
      form={form}
      name="UserForm"
      initialValues={{
        prefix: '+34',
        Name: userInfo?.name || Name,
        Surname: userInfo?.surname || Surname,
        Genre: userInfo?.genre || Genre,
        Country: userInfo?.country || Country || 'ES',
        PostalCode: userInfo?.postalCode || PostalCode,
        City,
        Email: userInfo?.email?.email || Email,
        Phone,
        COMP,
        FUENTE,
        DNI: userInfo?.identityDocument?.documentNumber || DNI,
        CLUB,
      }}
    >
      <span className={spanWrapper}>
        {intl.formatMessage({
          id: 'UserForm.AllFieldsRequired.Text',
        })}
      </span>
      <PersonalInfo form={form} disabledFields={disabledFields} />
      <AddressInfo disabledFields={disabledFields} />
      <WhoWillYouComeWith />
      <HowDidYouMeetUs />
    </Form>
  );
};

UserForm.propTypes = {};

export default UserForm;
