import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from "react";
import moment from "moment";
import { wrapper, withScroll, loginBannerWrapper } from "./index.module.scss";
import CTAButton from "./components/CTAButton/index";
// import PromoCodeButton from '../../components/PromoCodeButton';
import CheckingUrlParams from "../../services/CheckUrlParamsAndPassThemToReservation";
import {
  useReservationState,
  useReservationDispatch,
} from "../../services/Reservation";
import { useAuthState } from "../../services/Auth/useAuth";
import ReservationWizard from "./components/ReservationWizard";
// import DestinationsSelector from './components/DestinationsSelector';
import DatesSelector from "./components/DatesSelector";
import { LanguageContext } from "../../locale/contexts/Language";
import CountersSelector from "./components/CountersSelector";
import {
  endDateGraterThanStartDate,
  startDateAndEndDateAreValid,
} from "../../services/checkValidURLParams";
import {
  defaultAdults,
  defaultChildren,
  defaultBabies,
} from "../../constants/defaultsValues";
import PromotionBanner from "../../components/PromotionBanner";

const Home = () => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const promoCodeFromURL = searchParams.get("includePromoCode");
  const startDateFromURL = searchParams.get("checkin");
  const endDateFromURL = searchParams.get("checkout");
  const adultsFromURL = parseInt(searchParams.get("adults"), 10);
  const childrenFromURL = parseInt(searchParams.get("children"), 10);
  const babiesFromURL = parseInt(searchParams.get("babies"), 10);
  const fpaFromURl = searchParams.get("fpa");
  const langFromUrl = searchParams.get("lang");
  const hasScroll = useMemo(
    () => document.body.scrollHeight > document.body.clientHeight,
    [],
  );
  const [showWizard, setShowWizard] = useState(false);
  const [wizardStep, setWizardStep] = useState(1);
  const {
    adults: adultsCount,
    children: boysCount,
    babies: babiesCount,
  } = useReservationState();
  const { accessToken } = useAuthState();
  const [personCounters, setPersonCounters] = useState({
    adults: adultsFromURL || adultsCount,
    children: childrenFromURL || boysCount,
    babies: babiesFromURL || babiesCount,
  });
  const [fpaFilter, setFpaFilter] = useState("none");
  const dispatch = useReservationDispatch();
  const reservation = useReservationState();
  const { currentLanguage } = useContext(LanguageContext);

  const formattedStartDateFromURL = startDateFromURL
    ? moment(startDateFromURL, ["DD-MM-YYYY", "YYYY-MM-DD"]).format(
        "YYYY-MM-DD",
      )
    : null;
  const formattedEndDateFromURL = endDateFromURL
    ? moment(endDateFromURL, ["DD-MM-YYYY", "YYYY-MM-DD"]).format("YYYY-MM-DD")
    : null;

  const maxBabies = 3;
  const maxAdults = 6;
  const maxGuess = 9;

  useEffect(() => {
    dispatch({
      type: "setOnlyPromoCode",
      payload: {
        promoCode: promoCodeFromURL,
      },
    });

    // If dates are valid and endDate is greater than startDate
    if (
      startDateAndEndDateAreValid(
        formattedStartDateFromURL,
        formattedEndDateFromURL,
      ) &&
      endDateGraterThanStartDate(
        formattedStartDateFromURL,
        formattedEndDateFromURL,
      )
    ) {
      dispatch({
        type: "setStartDate",
        payload: moment(formattedStartDateFromURL),
      });
      dispatch({
        type: "setEndDate",
        payload: moment(formattedEndDateFromURL),
      });
    }

    // If dates are valid and endDate is not greater than startDate
    if (
      startDateAndEndDateAreValid(
        formattedStartDateFromURL,
        formattedEndDateFromURL,
      ) &&
      !endDateGraterThanStartDate(
        formattedStartDateFromURL,
        formattedEndDateFromURL,
      )
    ) {
      dispatch({
        type: "setStartDate",
        payload: moment(formattedStartDateFromURL),
      });
      dispatch({
        type: "setEndDate",
        payload: moment(formattedStartDateFromURL).add(2, "days"),
      });
    }
    // If endDate does not exist on queryParmas but startDate exists
    if (formattedStartDateFromURL && !formattedEndDateFromURL) {
      dispatch({
        type: "setStartDate",
        payload: moment(formattedStartDateFromURL),
      });
      dispatch({
        type: "setEndDate",
        payload: moment(formattedStartDateFromURL).add(2, "days"),
      });
    }

    // If startDate does not exist on queryParmas but endDate exists
    if (!formattedStartDateFromURL && formattedEndDateFromURL) {
      dispatch({
        type: "setStartDate",
        payload: moment(formattedEndDateFromURL).add(-2, "days"),
      });
      dispatch({
        type: "setEndDate",
        payload: moment(formattedEndDateFromURL),
      });
    }

    // Check if valid numbers
    if (
      !Number.isNaN(adultsFromURL) &&
      !Number.isNaN(childrenFromURL) &&
      !Number.isNaN(babiesFromURL)
    ) {
      dispatch({ type: "setadults", payload: parseInt(adultsFromURL, 10) });
      dispatch({ type: "setchildren", payload: parseInt(childrenFromURL, 10) });
      dispatch({ type: "setbabies", payload: parseInt(babiesFromURL, 10) });
      dispatch({ type: "setItAsReadyToBeUsed" });
    }
  }, [
    dispatch,
    formattedStartDateFromURL,
    formattedEndDateFromURL,
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
    promoCodeFromURL,
  ]);

  const formatUrlPaxParams = useCallback(() => {
    if (adultsFromURL < 0 || childrenFromURL < 0 || babiesFromURL < 0) {
      searchParams.set("adults", defaultAdults);
      searchParams.set("babies", defaultBabies);
      searchParams.set("children", defaultChildren);
      window.location.search = searchParams.toString();
    }
  }, [searchParams, adultsFromURL, babiesFromURL, childrenFromURL]);
  formatUrlPaxParams();

  useEffect(() => {
    setPersonCounters({
      adults: adultsFromURL || adultsCount,
      children: childrenFromURL || boysCount,
      babies: babiesFromURL || babiesCount,
    });
  }, [
    adultsCount,
    boysCount,
    babiesCount,
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
  ]);

  const url = new window.URL(window.location);
  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };

  const updateQueryParams = useCallback(
    (key, value) => {
      searchParams.set(key, value);

      const urlSearchParams = searchParams.toString();
      const currentParams = new URLSearchParams(url.search).toString();
      if (currentParams !== urlSearchParams) {
        updateUrlWithoutNavigation(
          `${url.pathname}?${searchParams.toString()}`,
        );
      }
    },
    [searchParams, url],
  );

  const normalizeLanguage = (lang) => (lang ? lang.substring(0, 2) : null);

  useEffect(() => {
    let newAdults = adultsFromURL || defaultAdults;
    let newChildren = childrenFromURL || defaultChildren;
    let newBabies = babiesFromURL || defaultBabies;

    if (newBabies > maxBabies) {
      newBabies = maxBabies;
    }

    let totalPeople = newAdults + newChildren;
    if (totalPeople > maxAdults) {
      if (newAdults >= maxAdults) {
        newAdults = maxAdults;
        newChildren = defaultChildren;
      } else {
        newChildren = maxAdults - newAdults;
      }
      totalPeople = newAdults + newChildren;
    }

    const totalCombined = totalPeople + newBabies;
    if (totalCombined > maxGuess) {
      const maxPeople = maxAdults - newBabies;
      if (newAdults > maxPeople) {
        newAdults = maxPeople;
        newChildren = defaultChildren;
      } else {
        newChildren = maxPeople - newAdults;
      }
    }
    const normalizedLanguageFromURL = normalizeLanguage(langFromUrl);

    updateQueryParams("lang", normalizedLanguageFromURL);
    if (newAdults !== adultsFromURL) {
      updateQueryParams("adults", newAdults);
    }
    if (newChildren !== childrenFromURL) {
      updateQueryParams("children", newChildren);
    }
    if (newBabies !== babiesFromURL) {
      updateQueryParams("babies", newBabies);
    }
    if (!startDateFromURL) {
      updateQueryParams(
        "checkin",
        reservation?.startDate?.format("YYYY-MM-DD"),
      );
    }
    if (!endDateFromURL) {
      updateQueryParams("checkout", reservation?.endDate?.format("YYYY-MM-DD"));
    }
    if (!fpaFromURl) {
      updateQueryParams("fpa", fpaFilter);
    }
    if (!langFromUrl) {
      updateQueryParams("lang", currentLanguage);
    }
    // eslint-disable-next-line max-len
  }, [
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
    updateQueryParams,
    maxBabies,
    maxAdults,
    maxGuess,
    startDateFromURL,
    endDateFromURL,
    reservation,
    fpaFilter,
    fpaFromURl,
    langFromUrl,
    currentLanguage,
  ]);

  return (
    <div className={`${wrapper} ${hasScroll ? withScroll : ""}`}>
      {/** <DestinationsSelector onClick={() => { setShowWizard(true); setWizardStep(1); }} /> */}
      <DatesSelector
        onClick={() => {
          setShowWizard(true);
          setWizardStep(2);
        }}
      />
      <CountersSelector
        onClick={() => {
          setShowWizard(true);
          setWizardStep(3);
        }}
      />
      <CTAButton personCounters={personCounters} fpaFilter={fpaFilter} />
      {accessToken === null && (
        <div className={loginBannerWrapper}>
          <PromotionBanner
            titleKey="PromotionBanner.Home.Text"
            descriptionKey="PromotionBanner.Home.Description"
          />
        </div>
      )}
      {/* <PromoCodeButton /> */}

      <ReservationWizard
        visible={showWizard}
        onClose={() => setShowWizard(false)}
        step={wizardStep}
        fpaFilter={fpaFilter}
        setFpaFilter={setFpaFilter}
      />
    </div>
  );
};
export default CheckingUrlParams(Home);
