import React from 'react';
import PropTypes from 'prop-types';
import { detail } from './index.module.scss';

const Detail = ({ counter = '0', description }) => (
  <div className={detail}>
    <span>{counter}</span>
    <span>{description}</span>
  </div>
);

Detail.propTypes = {
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.string.isRequired,
};

export default Detail;
