import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import moment from 'moment-timezone';
import { useReservationState as getReservation } from './Reservation';
import { adults, boys, babies } from '../constants/typeOfPersons';
import routes from '../routes/routes';
import useRefreshToken from '../utils/useRefreshToken';
import CheckingUrlParams from './CheckUrlParamsAndPassThemToReservation';

const { search } = window.location;
const searchParams = new URLSearchParams(search);
const adultsFromURL = parseInt(searchParams.get('adults'), 10);
const childrenFromURL = parseInt(searchParams.get('children'), 10);
const babiesFromURL = parseInt(searchParams.get('babies'), 10);

const datesExists = (reservation) => {
  const { startDate, endDate } = reservation;
  return !startDate || !endDate;
};

const startDateIsGreaterThanEndDate = (reservation) => {
  const { startDate, endDate } = reservation;
  return startDate >= endDate;
};

const endDateIsAtLeastOneDayMajorThanStartDate = (reservation) => {
  const { startDate, endDate } = reservation;
  const difference = Math.round(moment(endDate).diff(startDate, 'days', true));
  return difference >= 1;
};

function isDateEqualOrLater(startDate) {
  // Create moment objects for today's date and the start date
  const today = moment();
  const start = moment(startDate, 'YYYY-MM-DD');

  // Compare the two dates using isSameOrAfter()
  return start.isSameOrAfter(today, 'day');
}
const startDateIsMajorThanToday = (reservation) => {
  const { startDate } = reservation;
  const difference = isDateEqualOrLater(startDate);
  return difference;
};

const thereIsAdult = (reservation) => {
  const { [adults]: adultsCuantity } = reservation;
  if (adultsCuantity) return adultsCuantity >= 1;
  return adultsFromURL >= 1;
};

const maxBabiesCapacityAreOk = (reservation) => {
  const {
    [adults]: adultsCuantity,
    [boys]: boysCuantity,
    [babies]: babiesCuantity,
  } = reservation;
  if (adultsCuantity && boysCuantity && babiesCuantity) {
    if (adultsCuantity < 1) return false;
    if (adultsCuantity + boysCuantity <= 2 && babiesCuantity > 1) return false;
    if (adultsCuantity + boysCuantity <= 4 && babiesCuantity > 2) return false;
    if (adultsCuantity + boysCuantity <= 6 && babiesCuantity > 3) return false;
  } else {
    if (adultsFromURL < 1) return false;
    if (adultsFromURL + childrenFromURL <= 2 && babiesFromURL > 1) return false;
    if (adultsFromURL + childrenFromURL <= 4 && babiesFromURL > 2) return false;
    if (adultsFromURL + childrenFromURL <= 6 && babiesFromURL > 3) return false;
  }

  return true;
};

const maxCapacityIsOverreached = (reservation) => {
  const {
    [adults]: adultsCuantity,
    [boys]: boysCuantity,
    [babies]: babiesCuantity,
  } = reservation;
  if (
    adultsCuantity !== undefined &&
    boysCuantity !== undefined &&
    babiesCuantity !== undefined
  ) {
    return (
      adultsCuantity >= 1 &&
      adultsCuantity + boysCuantity <= 6 &&
      babiesCuantity <= 3
    );
  }
  return (
    adultsFromURL >= 1 &&
    adultsFromURL + childrenFromURL <= 6 &&
    babiesFromURL <= 3
  );
};

export const datesAreValid = (reservation) => {
  if (datesExists(reservation)) return false;
  if (startDateIsGreaterThanEndDate(reservation)) return false;
  if (!endDateIsAtLeastOneDayMajorThanStartDate(reservation)) return false;
  if (!startDateIsMajorThanToday(reservation)) return false;
  return true;
};

export const peopleAreValid = (reservation) => {
  if (!thereIsAdult(reservation)) return false;
  if (!maxBabiesCapacityAreOk(reservation)) return false;
  // if (!maxCapacityIsOverreached(reservation)) return false;
  return true;
};

export const reservationValidationErrorMessages = (reservation) => {
  const errors = [];
  if (datesExists(reservation)) errors.push('Kampaoh.Reservation.Dates');
  if (startDateIsGreaterThanEndDate(reservation))
    errors.push('Kampaoh.Reservation.Dates.StartBeforeEnd');
  if (!endDateIsAtLeastOneDayMajorThanStartDate(reservation))
    errors.push(
      'Kampaoh.Reservation.Dates.CheckOutMustBeOneDayMajorThanCheckIn',
    );
  if (!startDateIsMajorThanToday(reservation))
    errors.push('Kampaoh.Reservation.Dates.StartMajorThanToday');
  if (!thereIsAdult(reservation))
    errors.push('Kampaoh.Reservation.OneAdultAtLeast');
  if (!maxBabiesCapacityAreOk(reservation))
    errors.push('Kampaoh.Reservation.TooManyBabies');
  if (!maxCapacityIsOverreached(reservation))
    errors.push('Kampaoh.Reservation.MaxCapacity');
  return errors;
};

const CheckValidReservation = ({
  component: Component,
  path,
  location,
  ...rest
}) => {
  const tokensExpired = useRefreshToken();

  if (tokensExpired) {
    return <Redirect noThrow="true" to={routes.home} />;
  }

  const reservation = getReservation();
  return (
    <>
      {(datesAreValid(reservation) && peopleAreValid(reservation)) ||
      reservation.updatingDates ||
      reservation.updatingAvailability ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...rest} path={path} location={location} />
      ) : (
        <Redirect noThrow="true" to={routes.home} />
      )}
    </>
  );
};

CheckValidReservation.defaultProps = {
  component: null,
  path: '/',
  location: {},
};

CheckValidReservation.propTypes = {
  component: PropTypes.func,
  path: PropTypes.string,
  location: PropTypes.shape({
    key: PropTypes.string,
    search: PropTypes.string,
  }),
};

export default CheckingUrlParams(CheckValidReservation);
