// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export const useCountries = () => {
  const [options, setOptions] = useState([]);
  const intl = useIntl();
  const { locale } = intl;

  useEffect(() => {
    async function loadCountries() {
      try {
        const module = await import(`../locale/countries/countries_${locale}.json`);
        const countries = module.default;
        if (countries) {
          const formattedOptions = Object.entries(countries).map(([value, label]) => ({
            label, value,
          }));
          setOptions(formattedOptions);
        } else {
          console.error('No countries json found');
        }
      } catch (error) {
        console.error('Error loading the countriesList', error);
      }
    }

    loadCountries();
  }, [locale]);

  return options;
};

export default useCountries;
