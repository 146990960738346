import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from '@reach/router';
import moment from 'moment';
import Header from './components/Header';
import EditableField from './components/EditableField';
import NameForm from './components/Forms/NameForm';
import NationalityForm from './components/Forms/NationalityForm';
import GenreForm from './components/Forms/GenreForm';
import IdentifierForm from './components/Forms/IdentifierForm';
import BirthDateForm from './components/Forms/BirthDateForm';
import DirectionForm from './components/Forms/DirectionForm';
import ResetPasswordForm from './components/Forms/ResetPasswordForm';
import DeleteAccountForm from './components/Forms/DeleteAccountForm';
import CustomSpin from '../../components/CustomSpin';
import makeRequestToApServer from '../../services/ApServer/askToApServer';
import { allowedMethods } from '../../constants/ApServer/apServer';
import routes from '../../constants/ApServer/apRoutes';
import { useCountries } from '../../utils/useCountries';
import { ReactComponent as ArrowIcon } from '../../assets/icons/return.svg';
import {
  wrapper,
  infoSection,
  infoSectionHeader,
  securitySectionHeader,
  buttonContainer,
  backBtn,
} from './index.module.scss';
import ROUTES from '../../routes/routes';

const PrivateAreaSettings = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const countriesArray = useCountries();
  const [editField, setEditField] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    makeRequestToApServer(
      allowedMethods.get,
      routes.authMe,
      undefined,
      ({ statusCode, data }) => {
        if (statusCode === 200) {
          setFormData(data);
        }
      },
      () => {},
    );
  }, []);

  const getCountryLabelByValue = useCallback(
    (value) => {
      const country = countriesArray.find((e) => e.value === value);
      return country ? country.label : null;
    },
    [countriesArray],
  );

  const getGenderLabelByValue = useCallback(
    (value) => {
      switch (value) {
        case 'M':
          return intl.formatMessage({ id: 'GenreForm.Validation.Genre.M' });
        case 'F':
          return intl.formatMessage({ id: 'GenreForm.Validation.Genre.W' });
        case 'O':
          return intl.formatMessage({ id: 'GenreForm.Validation.Genre.O' });
        case 'N':
          return intl.formatMessage({ id: 'GenreForm.Validation.Genre.N' });
        default:
          return undefined;
      }
    },
    [intl],
  );

  const maskEmail = useMemo(() => {
    const email = formData?.email?.email;
    if (!email) return '';

    const [localPart, domainPart] = email.split('@');
    if (localPart.length <= 2) {
      return email;
    }

    const maskedLocalPart =
      localPart[0] +
      '*'.repeat(localPart.length - 2) +
      localPart[localPart.length - 1];
    return `${maskedLocalPart}@${domainPart}`;
  }, [formData]);

  const handleEdit = useCallback(
    (field) => {
      setEditField(field);
    },
    [setEditField],
  );

  const handleSave = useCallback(
    (values) => {
      setFormData({ ...formData, ...values });
      setEditField(null);
    },
    [formData],
  );

  const handleCancel = useCallback(() => {
    setEditField(null);
  }, []);

  const passwordLabel = useMemo(() => {
    if (editField === 'password') {
      return intl.formatMessage({
        id: 'PrivateAreaSettings.Button.Cancel.Text',
      });
    }
    if (formData?.hasPasswordSet) {
      return intl.formatMessage({
        id: 'PrivateAreaSettings.Button.UpgradeNow.Text',
      });
    }
    return intl.formatMessage({
      id: 'PrivateAreaSettings.Button.CreatePassword.Text',
    });
  }, [editField, formData, intl]);

  const form = useMemo(
    () => (
      <>
        <div className={infoSection}>
          <div className={infoSectionHeader}>
            <h2>
              {intl.formatMessage({
                id: 'PrivateAreaSettings.PersonalInformation.Text',
              })}
            </h2>
            <span>
              {intl.formatMessage({
                id: 'PrivateAreaSettings.PersonalInformationDescription.Text',
              })}
            </span>
          </div>
        </div>
        <EditableField
          label={intl.formatMessage({
            id: 'PrivateAreaSettings.Name.Text',
          })}
          value={`${formData?.name || ''} ${formData?.surname || ''}`}
          buttonLabel={
            editField === 'name'
              ? intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Cancel.Text',
                })
              : intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Edit.Text',
                })
          }
          isActive={editField === 'name'}
          isDisabled={editField !== null && editField !== 'name'}
          onEdit={() => handleEdit('name')}
          onCancel={handleCancel}
        >
          <NameForm
            initialValues={{ name: formData?.name, surname: formData?.surname }}
            onSave={(values) => handleSave(values)}
          />
        </EditableField>
        <EditableField
          label={intl.formatMessage({
            id: 'PrivateAreaSettings.Nationality.Text',
          })}
          value={
            getCountryLabelByValue(formData?.nationality) ||
            intl.formatMessage({
              id: 'PrivateAreaSettings.NotProvided.Text',
            })
          }
          buttonLabel={
            editField === 'nationality'
              ? intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Cancel.Text',
                })
              : intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Edit.Text',
                })
          }
          isActive={editField === 'nationality'}
          isDisabled={editField !== null && editField !== 'nationality'}
          onEdit={() => handleEdit('nationality')}
          onCancel={handleCancel}
        >
          <NationalityForm
            initialValues={{ nationality: formData?.nationality }}
            onSave={(values) => handleSave(values)}
          />
        </EditableField>
        <EditableField
          label={intl.formatMessage({
            id: 'PrivateAreaSettings.Genre.Text',
          })}
          value={
            getGenderLabelByValue(formData?.genre) ||
            intl.formatMessage({
              id: 'PrivateAreaSettings.NotProvided.Text',
            })
          }
          buttonLabel={
            editField === 'genre'
              ? intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Cancel.Text',
                })
              : intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Edit.Text',
                })
          }
          isActive={editField === 'genre'}
          isDisabled={editField !== null && editField !== 'genre'}
          onEdit={() => handleEdit('genre')}
          onCancel={handleCancel}
        >
          <GenreForm
            initialValues={{ genre: formData?.genre }}
            onSave={(values) => handleSave(values)}
          />
        </EditableField>
        <EditableField
          label={intl.formatMessage({
            id: 'PrivateAreaSettings.Passport.Text',
          })}
          value={
            formData?.identityDocument?.documentNumber ||
            intl.formatMessage({
              id: 'PrivateAreaSettings.NotProvided.Text',
            })
          }
          buttonLabel={
            editField === 'passport'
              ? intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Cancel.Text',
                })
              : intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Edit.Text',
                })
          }
          isActive={editField === 'passport'}
          isDisabled={editField !== null && editField !== 'passport'}
          onEdit={() => handleEdit('passport')}
          onCancel={handleCancel}
        >
          <IdentifierForm
            initialValues={{
              name: formData?.name,
              surname: formData?.surname,
              issuingCountry: formData?.identityDocument?.issuingCountry,
              documentNumber: formData?.identityDocument?.documentNumber,
              expirationDate: formData?.identityDocument?.expirationDate
                ? moment(
                    formData?.identityDocument?.expirationDate,
                    'YYYY-MM-DD',
                  )?.format('YYYY-MM-DD')
                : null,
              content: formData?.identityDocument?.content,
            }}
            onSave={(values) => handleSave(values)}
          />
        </EditableField>
        <EditableField
          label={intl.formatMessage({
            id: 'PrivateAreaSettings.BirthDate.Text',
          })}
          value={
            formData?.birthDate
              ? moment(formData?.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
              : intl.formatMessage({
                  id: 'PrivateAreaSettings.Birthdate.NotProvided.Text',
                })
          }
          buttonLabel={
            editField === 'birthDate'
              ? intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Cancel.Text',
                })
              : intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Edit.Text',
                })
          }
          isActive={editField === 'birthDate'}
          isDisabled={editField !== null && editField !== 'birthDate'}
          onEdit={() => handleEdit('birthDate')}
          onCancel={handleCancel}
        >
          <BirthDateForm
            initialValues={{
              birthDate: formData?.birthDate
                ? moment(formData?.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
                : null,
            }}
            onSave={(values) => handleSave(values)}
          />
        </EditableField>
        <EditableField
          label={intl.formatMessage({
            id: 'PrivateAreaSettings.Direction.Text',
          })}
          value={
            formData?.direction ||
            intl.formatMessage({
              id: 'PrivateAreaSettings.NotProvided.Text',
            })
          }
          buttonLabel={
            editField === 'direction'
              ? intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Cancel.Text',
                })
              : intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Edit.Text',
                })
          }
          isActive={editField === 'direction'}
          isDisabled={editField !== null && editField !== 'direction'}
          onEdit={() => handleEdit('direction')}
          onCancel={handleCancel}
        >
          <DirectionForm
            initialValues={{
              direction: formData?.direction,
              postalCode: formData?.postalCode,
              country: formData?.country,
            }}
            onSave={(values) => handleSave(values)}
          />
        </EditableField>
        <div className="security-section">
          <div className={securitySectionHeader}>
            <h2>
              {intl.formatMessage({
                id: 'PrivateAreaSettings.Security.Text',
              })}
            </h2>
            <span>
              {intl.formatMessage({
                id: 'PrivateAreaSettings.SecurityDescription.Text',
              })}
            </span>
          </div>
        </div>
        <EditableField
          label={intl.formatMessage({
            id: 'PrivateAreaSettings.Password.Text',
          })}
          value={formData?.password}
          buttonLabel={passwordLabel}
          isActive={editField === 'password'}
          isDisabled={editField !== null && editField !== 'password'}
          onEdit={() => handleEdit('password')}
          onCancel={handleCancel}
        >
          <ResetPasswordForm
            onSave={() => handleCancel()}
            email={formData?.email?.email}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </EditableField>
        <EditableField
          label={intl.formatMessage({
            id: 'PrivateAreaSettings.DeleteAccount.Text',
          })}
          value={formData?.deleteAccount}
          buttonLabel={
            editField === 'deleteAccount'
              ? intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Cancel.Text',
                })
              : intl.formatMessage({
                  id: 'PrivateAreaSettings.Button.Disable.Text',
                })
          }
          isActive={editField === 'deleteAccount'}
          isDisabled={editField !== null && editField !== 'deleteAccount'}
          onEdit={() => handleEdit('deleteAccount')}
          onCancel={handleCancel}
        >
          <DeleteAccountForm
            onSave={() => handleSave()}
            setIsLoading={setIsLoading}
          />
        </EditableField>
      </>
    ),
    [
      intl,
      formData,
      handleEdit,
      handleSave,
      handleCancel,
      editField,
      getCountryLabelByValue,
      getGenderLabelByValue,
      setIsLoading,
      passwordLabel,
      isLoading,
    ],
  );

  const Spinner = useMemo(() => isLoading && <CustomSpin />, [isLoading]);

  return (
    <div className={wrapper}>
      {Spinner}
      <div className={buttonContainer}>
        <button
          className={backBtn}
          type="button"
          onClick={() => navigate(ROUTES.me)}
        >
          <ArrowIcon />
          <span>
            {intl.formatMessage({ id: 'PrivateAreaSettings.Button.Text' })}
          </span>
        </button>
      </div>
      <Header
        name={`${formData?.name || ' '} ${formData?.surname || ' '}`}
        email={maskEmail || ' '}
      />
      {form}
    </div>
  );
};
export default PrivateAreaSettings;
