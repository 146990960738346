/* eslint-disable no-underscore-dangle */
const URL = `${window._env_.REACT_APP_PRIVATE_SERVER_URL}`;
/* eslint-enable no-underscore-dangle */

const allowedMethods = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
  patch: 'patch',
};

export { URL, allowedMethods };
