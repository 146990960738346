// eslint-disable-next-line no-underscore-dangle
const serverUrl = window._env_.REACT_APP_SERVER_URL || 'https://reservas.kampaoh.com';

const contactData = {
  email: 'reservas@kampaoh.com',
  whatsappNumber: '684 151 459',
  phoneNumber: '+(34) 955 252 440',
  whatsappGroupNumber: '644 725 313',
};

const defaultTimeLimitForTodaysBooking = '20:00:00.000';

export { contactData, defaultTimeLimitForTodaysBooking };
export default serverUrl;
