import React from 'react';
import GoogleLoginButton from '../GoogleLoginButton';
import FacebookLoginButton from '../FacebookLoginButton';
import { buttonsContainer } from './index.module.scss';

const SocialLoginButtons = () => (
  <div className={buttonsContainer}>
    <GoogleLoginButton />
    <FacebookLoginButton />
  </div>
);

export default SocialLoginButtons;
