import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from '@reach/router';
import { ReactComponent as KampaohIcon } from '../../assets/icons/kampaoh.svg';
import { container, title, loadingStyle, button } from './index.module.scss';
import makeRequestToApServer from '../../services/ApServer/askToApServer';
import { allowedMethods } from '../../constants/ApServer/apServer';
import routes from '../../constants/ApServer/apRoutes';
import ROUTES from '../../routes/routes';

const VerifyEmailPage = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const getTokenFromURL = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('accessToken');
  }, [location.search]);

  const verifyEmail = useCallback(
    (accessToken) => {
      makeRequestToApServer(
        allowedMethods.get,
        routes.emailVerify,
        { accessToken },
        ({ statusCode }) => {
          if (statusCode === 200) {
            setLoading(false);
            setSuccess(true);
          } else {
            setLoading(false);
            setError(
              intl.formatMessage({
                id: 'PrivateArea.Request.Error.Default.Text',
              }),
            );
          }
        },
        (err) => {
          setLoading(false);
          setError(err?.response?.data?.message);
        },
      );
    },
    [intl],
  );

  useEffect(() => {
    const accessToken = getTokenFromURL();
    if (accessToken) {
      verifyEmail(accessToken);
    } else {
      setLoading(false);
      setError(intl.formatMessage({ id: 'VerifyEmail.TokenNotFound.Text' }));
    }
  }, [getTokenFromURL, intl, verifyEmail]);

  return (
    <div className={container}>
      <KampaohIcon />
      {loading && (
        <p className={loadingStyle}>
          {intl.formatMessage({ id: 'VerifyEmail.Verifying.Text' })}
        </p>
      )}
      {error && (
        <p className={title}>
          {intl.formatMessage({ id: 'PrivateArea.Request.Error.Default.Text' })}
          : {error}
        </p>
      )}
      {success && (
        <p className={title}>
          {intl.formatMessage({ id: 'VerifyEmail.Success.Text' })}
        </p>
      )}
      {success || error ? (
        <button
          className={button}
          onClick={() => navigate(ROUTES.home)}
          type="button"
        >
          {intl.formatMessage({ id: 'VerifyEmail.HomeButton.Text' })}
        </button>
      ) : null}
    </div>
  );
};

export default VerifyEmailPage;
