import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { useAuthState } from '../../../../../../services/Auth/useAuth';
import { ReactComponent as KampaohIcon } from '../../../../../../assets/icons/kampaoh.svg';

import { wrapper, kampaohIcon } from './index.module.scss';

const Conditions = ({ data }) => {
  const intl = useIntl();
  const { accessToken } = useAuthState();

  const tribeCondition = useMemo(
    () => (
      <div>
        <KampaohIcon className={kampaohIcon} />
        <div>
          <p>
            {intl.formatMessage({
              id:
                accessToken === null
                  ? 'Conditions.NoTribeCondition.Title.Text'
                  : 'Conditions.TribeCondition.Title.Text',
            })}
          </p>
          <p>
            {intl.formatMessage({
              id:
                accessToken === null
                  ? 'Conditions.NoTribeCondition.Message.Text'
                  : 'Conditions.TribeCondition.Message.Text',
            })}
          </p>
        </div>
      </div>
    ),
    [intl, accessToken],
  );

  return (
    <div className={wrapper}>
      {data.map(({ icon: Icon, title, message }) => (
        <div key={title}>
          <Icon />
          <div>
            <p>{title}</p>
            <p>{message}</p>
          </div>
        </div>
      ))}
      {tribeCondition}
    </div>
  );
};

Conditions.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      message: PropTypes.string,
    }),
  ).isRequired,
};

Conditions.defaultProps = {};
export default Conditions;
