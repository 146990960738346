/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Layout } from 'antd';
import { customLayout } from './index.module.scss';
import ReservationInfoAndFilteringHeader from '../../components/Header/ReservationInfoAndFilteringHeader';
import TopBanner from '../../components/TopBanner';
import Footer from '../../components/Footer';
import { ShowRoomsProvider } from '../../services/ShowRooms';
import CampingPlacesProvider from '../../services/Campings/DataProvider';
import SearchProvider from '../../contexts/SearchContext';
import CampingFeaturesProvider from '../../services/CampingFeatures/Provider';
import ResponsiveMenu from '../../components/ResponsiveMenu/ResponsiveMenu';

const { Content } = Layout;
const PlacesLayout =
  (Component, type) =>
  ({ ...props }) =>
    (
      <CampingPlacesProvider>
        <CampingFeaturesProvider>
          <ShowRoomsProvider>
            <SearchProvider type={type}>
              <Layout
                className={customLayout}
                style={{ minHeight: '100%', position: 'relative' }}
              >
                <TopBanner />
                <ResponsiveMenu />
                <ReservationInfoAndFilteringHeader />
                <Content>
                  <Component {...props} />
                </Content>
                <Footer />
              </Layout>
            </SearchProvider>
          </ShowRoomsProvider>
        </CampingFeaturesProvider>
      </CampingPlacesProvider>
    );

PlacesLayout.propTypes = {};

PlacesLayout.defaultProps = {};
export default PlacesLayout;
