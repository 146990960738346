const initialState = {
  conditionKPFooterES: '',
  conditionKPFooterEN: '',
  conditionKPNoiseConditionsES: '',
  conditionKPNoiseConditionsEN: '',
  conditionKPReservationConditionsES: '',
  conditionKPReservationConditionsEN: '',
};

export default initialState;
