import moment from 'moment';

export const startDateAndEndDateAreValid = (_startDate, _endDate) => {
  if (_startDate && _endDate) return true;
  return false;
};

export const endDateGraterThanStartDate = (_startDate, _endDate) => {
  const startDate = moment(_startDate);
  const endDate = moment(_endDate);

  if (endDate.isAfter(startDate)) {
    return true;
  }
  if (startDate.isAfter(endDate)) {
    return false;
  }
  return true;
};
