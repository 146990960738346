/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import {
  roomPropertyCardsContainer,
  roomPropertyCards,
  roomWrapper,
  notAvailableWarning,
  showModalClass,
} from './index.module.scss';
import {
  useReservationState,
  useReservationDispatch,
} from '../../../../services/Reservation';
import RoomCard from './Room';
import useCampings from '../../../../services/Campings/useCampings';
import Services from '../../../../constants/services';
import { useFilterState } from '../../../../services/Filter';
import PersonSelectorDrawer from '../../../../components/PersonSelectorDrawer';

const CardShowRoom = ({
  ratePlans,
  propertyID,
  property,
  type,
  priceFormat,
  askingForNewRatePlans,
}) => {
  const {
    tentState: { tents },
  } = useCampings();
  const { adults, children, babies } = useReservationState();
  const units = tents;
  const typeOfProperty = 'camping';
  const petsOfProperty = 'pets';
  const intl = useIntl();
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const adultsFromURL = parseInt(searchParams.get('adults'), 10);
  const childrenFromURL = parseInt(searchParams.get('children'), 10);
  const [fpaFilter, setFpaFilter] = useState(searchParams.get('fpa') || 'none');
  const fpa = searchParams.get('fpa');
  const { activeCharacteristicsFilters } = useFilterState();
  const privateWcFromURL = searchParams.get('filters');
  const filtersString = decodeURIComponent(privateWcFromURL);
  const filtersArray = filtersString.split(',').map(Number);
  const maxPaxWarning = 3;
  const [showPersonsModal, setShowPersonsModal] = useState(false);
  const dispatchReservation = useReservationDispatch();
  const { dispatch: dispatchCampings } = useCampings();
  const dispatch = dispatchCampings;

  const wcPrivateIdsByLang = {
    es: '7',
    en: '43',
    fr: '44',
    pt: '76',
    it: '77',
  };
  const currentLang = localStorage.getItem('countryCode');
  const WCPRIVATE =
    wcPrivateIdsByLang[currentLang] || parseInt(searchParams.get('lang'), 10);
  const activeWcFilter = activeCharacteristicsFilters
    .filter((filter) => filter.id === WCPRIVATE)
    .map((filter) => Number(filter.id));
  const includesPrivateBathroomFilter = activeWcFilter.some((id) =>
    filtersArray.includes(id),
  );

  const unitsByRoomTypeId = useMemo(
    () =>
      Object.keys(units).reduce((acc, key) => {
        const unit = units[key];
        if (!unit) {
          return acc;
        }

        const unitType = unit[typeOfProperty];
        const unitPets = unit[petsOfProperty];
        const ulysesId = unitType?.ulysesId;
        const privateBathroom = unit.privateBathroom === true;

        if (
          fpa === 'pets' &&
          ulysesId === propertyID &&
          unitPets === true &&
          (!includesPrivateBathroomFilter || privateBathroom)
        ) {
          return { ...acc, [key]: unit };
        }
        if (
          fpa === 'allergy' &&
          ulysesId === propertyID &&
          (unitPets === false || unitPets === null) &&
          (!includesPrivateBathroomFilter || privateBathroom)
        ) {
          return { ...acc, [key]: unit };
        }
        if (
          fpa === 'none' &&
          ulysesId === propertyID &&
          (!includesPrivateBathroomFilter || privateBathroom)
        ) {
          return { ...acc, [key]: unit };
        }
        if (
          (!fpa || (fpa !== 'none' && fpa !== 'pets' && fpa !== 'allergy')) &&
          ulysesId === propertyID &&
          (!includesPrivateBathroomFilter || privateBathroom)
        ) {
          return { ...acc, [key]: unit };
        }

        return acc;
      }, {}),
    [units, typeOfProperty, propertyID, fpa, includesPrivateBathroomFilter],
  );

  const ratePlansById = ratePlans.reduce((acc, current) => {
    acc[current.id] = current;
    return acc;
  }, {});

  const unitdBelongingToPropertyByRateId = useMemo(() => {
    const findOrphanUnits = (unitsByRateId) => {
      const orphans = Object.keys(unitsByRoomTypeId);
      const orphanUnits = {};
      Object.keys(unitsByRateId).forEach((key) => {
        const currentOrphanIndex = orphans.indexOf(unitsByRateId[key].id);
        if (currentOrphanIndex > -1) {
          orphans.splice(currentOrphanIndex, 1);
        }
      });
      if (orphans.length > 0) {
        orphans.forEach((key) => {
          orphanUnits[`${key}`] = unitsByRoomTypeId[key];
        });
      }
      return orphanUnits;
    };

    const unitsByRateId = Object.keys(ratePlans).reduce((acc, key) => {
      const currentRatePlan = ratePlans[key];
      if (unitsByRoomTypeId[currentRatePlan.roomTypeID]) {
        return { ...acc, [key]: unitsByRoomTypeId[ratePlans[key].id] };
      }
      return acc;
    }, {});

    const orphanUnits = findOrphanUnits(unitsByRateId);
    return { ...unitsByRateId, ...orphanUnits };
  }, [ratePlans, unitsByRoomTypeId]);

  const orderedUnitByAvailability = useMemo(
    () =>
      Object.keys(unitdBelongingToPropertyByRateId).sort((a, b) => {
        const currentRatePlanA = ratePlansById[a];
        const currentRatePlanB = ratePlansById[b];

        if (!currentRatePlanA) return 1;
        if (!currentRatePlanB) return -1;

        const validA = currentRatePlanA.boards?.some((board) =>
          board.plans?.some((plan) =>
            plan.roomRateDetailed?.every((room) => room.available),
          ),
        );
        const validB = currentRatePlanB.boards?.some((board) =>
          board.plans?.some((plan) =>
            plan.roomRateDetailed?.every((room) => room.available),
          ),
        );

        if (validA && !validB) {
          return -1;
        }
        if (!validA && validB) {
          return 1;
        }
        return 0;
      }),
    [unitdBelongingToPropertyByRateId, ratePlansById],
  );

  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };

  return (
    <div className={roomPropertyCardsContainer}>
      <div className={roomPropertyCards}>
        {orderedUnitByAvailability.map((key) => (
          <div className={roomWrapper} key={key}>
            <RoomCard
              ratePlan={ratePlansById[key]}
              unit={unitdBelongingToPropertyByRateId[key]}
              property={property}
              type={type}
              priceFormat={priceFormat}
              ratePlans={ratePlans}
              askingForNewRatePlans={askingForNewRatePlans}
            />
          </div>
        ))}
      </div>
      {adultsFromURL + childrenFromURL > maxPaxWarning && (
        <div className={notAvailableWarning}>
          <span>{intl.formatMessage({ id: 'Card.MorePaxWarning' })}</span>
          <Button
            className={showModalClass}
            onClick={() => setShowPersonsModal(true)}
          >
            {intl.formatMessage({ id: 'Card.MorePaxWarning.Button' })}
          </Button>
        </div>
      )}
      {showPersonsModal && (
        <PersonSelectorDrawer
          initCountersValues={{ children, adults, babies }}
          visible={2}
          onClose={() => {
            setShowPersonsModal(false);
          }}
          onOk={(counter) => {
            const url = new URL(window.location);
            const params = new URLSearchParams(url.search);
            Object.keys(counter).forEach((key) => {
              dispatchReservation({ type: `set${key}`, payload: counter[key] });
              params.set(key, counter[key]);
            });
            params.set('fpa', fpaFilter);
            dispatch({ type: 'setItAsReadyToBeUsed' });
            dispatchCampings({ type: 'resetHotelsInitialState' });
            dispatch({ type: 'setRatePlansAsNotFetched' });
            url.search = params.toString();
            updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
            window.window.scrollTo(0, 0);
            askingForNewRatePlans(true);
            setShowPersonsModal(false);
          }}
          fpaFilter={fpaFilter}
          setFpaFilter={setFpaFilter}
        />
      )}
      {/* {
        <div className={notAvailableWarning}>
          <span>
            {intl.formatMessage({ id: 'Card.MoreDatesWarning' })}
          </span>
        </div>
      } */}
    </div>
  );
};

CardShowRoom.propTypes = {
  ratePlans: PropTypes.arrayOf(
    PropTypes.shape({
      roomTypeID: PropTypes.number,
      id: PropTypes.number,
    }),
  ),
  propertyID: PropTypes.string.isRequired,
  property: PropTypes.shape({
    alternativeRatePlans: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  priceFormat: PropTypes.oneOf(['byTotalPrice', 'byPricePerNightAndTotal']),
  type: PropTypes.oneOf(Object.values(Services)),
  askingForNewRatePlans: PropTypes.func,
};

CardShowRoom.defaultProps = {
  ratePlans: [],
  property: {},
  priceFormat: 'byTotalPrice',
  type: Services.CAMP,
  askingForNewRatePlans: () => {},
};
export default CardShowRoom;
