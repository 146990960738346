/* eslint-disable max-len */
import React, { useMemo, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PropTypes } from "prop-types";
import {
  placesPage,
  searchResumeWrapper,
  otherSuggestedProperties,
  noResultsWrapper,
  giftWrapper,
} from "./index.module.scss";
import useCampings from "../../../../../services/Campings/useCampings";
import { useAuthState } from "../../../../../services/Auth/useAuth";
import ResumeSearch from "../../../components/ResumeSearch";
import { useReservationState } from "../../../../../services/Reservation";
import Services from "../../../../../constants/services";
import Cards from "../Cards";
import { SearchContext } from "../../../../../contexts/SearchContext";
import CustomSpin from "../../../../../components/CustomSpin";
import validatePropertyRatePlansV3 from "../../../../../services/validatePropertyRatePlansV3";
import ContactButton from "../../../../../components/ContactButton";
import FaqButton from "../../../../../components/FaqButton";
import CampingCard from "../Cards/components/Card/index";
import { usePrevious } from "../../../../../services/usePrevious";
import NoResults from "../../../components/NotResults";
import useDeviceDetect from "../../../../../utils/useDeviceDetect";
import PromotionBanner from "../../../../../components/PromotionBanner";

const filtersHeaderHeight = 130;

const MainSection = ({ scrollRef, regions }) => {
  const isSame = (array1, array2) =>
    array1.length === array2.length &&
    array1.every((element, index) => element === array2[index]);

  const intl = useIntl();
  const { accessToken } = useAuthState();
  const { isMobile } = useDeviceDetect();
  const {
    askForCampingsRatePlans,
    state: {
      campings,
      isLoadingRatePlans,
      areRatePlansFetched,
      isLoadingCampings,
      ratePlansAlreadyFetched,
    },
  } = useCampings();
  const campingKeys = usePrevious(Object.keys(campings));
  const { preferredLocation, startDate, endDate } = useReservationState();
  const reservation = useReservationState();
  const {
    propertiesToShow,
    sorter,
    setSorter,
    setFilteredRatePlans,
    setFilteredStatus,
  } = useContext(SearchContext);

  const suggestedCampings = useMemo(
    () =>
      Object.keys(propertiesToShow).reduce((acc, key) => {
        const propertyId = propertiesToShow[key];
        if (
          campings[propertyId] &&
          campings[propertyId].campings_related &&
          campings[propertyId].campings_related.length &&
          preferredLocation
        ) {
          campings[propertyId].campings_related.forEach((cRelated) => {
            if (cRelated.location !== preferredLocation.location) {
              acc.push(campings[cRelated.ulysesId]);
            }
          });
        }
        return acc;
      }, []),
    [campings, preferredLocation, propertiesToShow],
  );

  useEffect(() => {
    if (areRatePlansFetched) {
      const filterRatePlans = {};
      const filterStatus = {};

      Object.keys(campings).forEach((key) => {
        if (campings[key].ratePlans !== undefined) {
          const rates =
            campings[key].ratePlans.length > 0
              ? campings[key].ratePlans
              : [
                  {
                    roomRateDetailed: [{ date: startDate }, { date: endDate }],
                  },
                ];
          const boardRate = rates.flatMap((rate) => {
            if (rate?.boards && rate?.boards.length > 0) {
              return rate?.boards?.flatMap((board) =>
                board?.plans?.map((plan) => {
                  const { roomRateDetailed, name, totalPrice } = plan;
                  const available = roomRateDetailed.map(
                    (detail) => detail.available,
                  )[0];
                  // eslint-disable-next-line no-shadow
                  const minPrice = board.plans.reduce((min, plan) => {
                    if (plan.totalPrice !== undefined) {
                      return min === undefined
                        ? plan.totalPrice
                        : Math.min(min, plan.totalPrice);
                    }
                    return min;
                  }, undefined);
                  return {
                    available,
                    campingId: rate?.campingId,
                    id: board?.id,
                    rateName: name,
                    roomRateDetailed,
                    roomTypeName: rate?.name,
                    roomTypeID: rate?.id,
                    roomRate: rate?.id,
                    roomTypeCode: rate?.code,
                    totalRate: totalPrice,
                    plans: rate?.boards,
                    minPrice,
                  };
                }),
              );
            }
            return [
              {
                roomRateDetailed: rate.roomRateDetailed || [
                  { date: startDate },
                  { date: endDate },
                ],
              },
            ];
          });

          const { ratePlans, status } = validatePropertyRatePlansV3(
            boardRate,
            campings[key],
          );
          filterRatePlans[campings[key].ulysesId] = ratePlans;
          filterStatus[campings[key].ulysesId] = status;
        }
      });

      setFilteredRatePlans(() => ({ ...filterRatePlans }));
      setFilteredStatus(() => ({ ...filterStatus }));
    }
  }, [
    areRatePlansFetched,
    endDate,
    campings,
    setFilteredRatePlans,
    setFilteredStatus,
    startDate,
  ]);
  const [isAccesTokenNull, setIsAccesTokenNull] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (
      campingKeys &&
      (!isSame(campingKeys, Object.keys(campings)) ||
        (!isLoadingCampings && !isLoadingRatePlans)) &&
      !ratePlansAlreadyFetched
    ) {
      askForCampingsRatePlans(reservation, undefined, regions);
      setIsAccesTokenNull(accessToken === null);
      setIsLoaded(true);
    }
  }, [
    askForCampingsRatePlans,
    campingKeys,
    campings,
    isLoadingCampings,
    isLoadingRatePlans,
    ratePlansAlreadyFetched,
    reservation,
    regions,
    accessToken,
  ]);

  useEffect(() => {
    const reload = true;

    if (isLoaded) {
      if (accessToken !== null && isAccesTokenNull === true) {
        askForCampingsRatePlans(reservation, undefined, regions, reload);
        setIsAccesTokenNull(accessToken === null);
      }

      if (accessToken === null && isAccesTokenNull === false) {
        askForCampingsRatePlans(reservation, undefined, regions, reload);
        setIsAccesTokenNull(accessToken === null);
      }
    }
    // eslint-disable-next-line
  }, [accessToken]);

  if (!areRatePlansFetched || isLoadingRatePlans) {
    return (
      <div className={noResultsWrapper}>
        <CustomSpin />
      </div>
    );
  }

  if (Object.keys(propertiesToShow).length === 0) {
    window.dataLayer.push({
      event: "zeroDispoBE",
      zone_name: reservation.preferredZoneName.toLowerCase(),
    });
  }

  const onScroll = () => {
    const topHeader = isMobile ? 0 : 70;
    const totalDivHeight = topHeader + filtersHeaderHeight;
    const scrollTargetPosition =
      scrollRef.current.getBoundingClientRect().top + window.scrollY;
    const adjustedScrollPosition = scrollTargetPosition - totalDivHeight;
    window.scrollTo({
      top: adjustedScrollPosition,
      behavior: "smooth",
    });
  };

  return (
    <>
      {Object.keys(propertiesToShow).length === 0 ? (
        <NoResults />
      ) : (
        <>
          <div className={placesPage}>
            <div className={searchResumeWrapper}>
              <ResumeSearch
                activeSorter={sorter}
                onChange={setSorter}
                campingsFound={Object.keys(propertiesToShow).length}
              />
            </div>
            {accessToken === null && (
              <div className={giftWrapper}>
                {/*   <GiftBaner /> */}
                <PromotionBanner
                  titleKey="PromotionBanner.Places.Text"
                  descriptionKey="PromotionBanner.Places.Description"
                />
              </div>
            )}

            <Cards />
            <FaqButton onScroll={onScroll} />
            <ContactButton />
          </div>
          {preferredLocation && Object.keys(suggestedCampings).length > 0 && (
            <div className={otherSuggestedProperties}>
              <h3>
                {intl.formatMessage({
                  id: "MainSection.OtherSuggestedProperties",
                })}
              </h3>
              <div className={placesPage}>
                {suggestedCampings.map((relatedCamping) => (
                  <CampingCard
                    key={relatedCamping.ulysesId}
                    propertyID={relatedCamping.ulysesId}
                    type={Services.CAMP}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

MainSection.defaultProps = {
  scrollRef: () => {},
  regions: undefined,
};

MainSection.propTypes = {
  scrollRef: PropTypes.shape({
    current: PropTypes.objectOf(PropTypes.Object),
  }),
  regions: PropTypes.shape({}),
};
export default MainSection;
