import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl, FormattedPlural } from 'react-intl';
import SearchDrawer from '../SearchDrawer';
import sorters from '../../../../constants/sorters';
import { ReactComponent as SortByIcon } from '../../../../assets/icons/sortBy.svg';
import { filtersWrapper } from './index.module.scss';
import { useReservationState } from '../../../../services/Reservation';

const ResumeSearch = ({ campingsFound, activeSorter, onChange }) => {
  const intl = useIntl();
  const [showSearchingDrawer, setShowSearchingDrawer] = useState(false);
  const { kampaohService } = useReservationState();
  return (
    <>
      <div>
        {campingsFound}{' '}
        <FormattedPlural
          value={campingsFound}
          one={intl.formatMessage({
            id: `#Places.MainSection.ResumeSearch.${kampaohService}.foundProperty`,
          })}
          other={intl.formatMessage({
            id: `#Places.MainSection.ResumeSearch.${kampaohService}.foundProperties`,
          })}
        />
      </div>
      <div
        onClick={() => setShowSearchingDrawer(true)}
        onKeyPress={() => setShowSearchingDrawer(true)}
        role="button"
        tabIndex={0}
        className={filtersWrapper}
      >
        {intl.formatMessage({ id: 'Places.MainSection.ResumeSearch.orderBy' })}{' '}
        <SortByIcon />
      </div>
      <SearchDrawer
        activeSorter={activeSorter}
        visible={showSearchingDrawer}
        onChange={onChange}
        onClose={() => setShowSearchingDrawer(false)}
      />
    </>
  );
};

ResumeSearch.propTypes = {
  campingsFound: PropTypes.number,
  activeSorter: PropTypes.oneOf(
    Object.keys(sorters).map((key) => sorters[key]),
  ),
  onChange: PropTypes.func,
};

ResumeSearch.defaultProps = {
  campingsFound: 0,
  onChange: () => {},
  activeSorter: sorters.RELEVANCY,
};
export default ResumeSearch;
