import React, { useState, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as KampaohIcon } from '../../../../assets/icons/kampaoh.svg';
import {
  accordionItem,
  accordionButton,
  open,
  accordionIcon,
  accordionContent,
  accordionContainer,
  wrapper,
} from './index.module.scss';

const Faq = () => {
  const intl = useIntl();
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = useCallback(
    (index) => {
      setOpenIndex(index === openIndex ? null : index);
    },
    [openIndex],
  );

  const renderAccordionItem = useCallback(
    (index, question, answer) => (
      <div key={index} className={accordionItem}>
        <button
          className={`${accordionButton} ${openIndex === index && open}`}
          onClick={() => handleToggle(index)}
          type="button"
        >
          <strong>{question}</strong>
          <span className={`${accordionIcon} ${openIndex === index && open}`}>
            {openIndex === index ? '×' : '+'}
          </span>
        </button>
        <div className={`${accordionContent} ${openIndex === index && open}`}>
          <p>{answer}</p>
        </div>
      </div>
    ),
    [openIndex, handleToggle],
  );

  const data = useMemo(
    () => [
      {
        question: intl.formatMessage({ id: 'Faq.Item1.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item1.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item2.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item2.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item3.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item3.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item4.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item4.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item5.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item5.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item6.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item6.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item7.Question.Text' }),
        answer: (
          <>
            {intl.formatMessage({ id: 'Faq.Item7.Answer.Text' })}
            <ul>
              <li>{intl.formatMessage({ id: 'Faq.Item7.Benefit1.Text' })}</li>
              <li>{intl.formatMessage({ id: 'Faq.Item7.Benefit2.Text' })}</li>
              <li>{intl.formatMessage({ id: 'Faq.Item7.Benefit3.Text' })}</li>
            </ul>
          </>
        ),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item8.Question.Text' }),
        answer: (
          <>
            {intl.formatMessage({ id: 'Faq.Item8.Answer.Text' })}
            <ul>
              <li>{intl.formatMessage({ id: 'Faq.Item8.Benefit1.Text' })}</li>
              <li>{intl.formatMessage({ id: 'Faq.Item8.Benefit2.Text' })}</li>
              <li>{intl.formatMessage({ id: 'Faq.Item8.Benefit3.Text' })}</li>
              <li>{intl.formatMessage({ id: 'Faq.Item8.Benefit4.Text' })}</li>
              <li>{intl.formatMessage({ id: 'Faq.Item8.Benefit5.Text' })}</li>
            </ul>
          </>
        ),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item9.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item9.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item10.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item10.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item11.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item11.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item12.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item12.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item13.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item13.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item14.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item14.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item15.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item15.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item16.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item16.Answer.Text' }),
      },
      {
        question: intl.formatMessage({ id: 'Faq.Item17.Question.Text' }),
        answer: intl.formatMessage({ id: 'Faq.Item17.Answer.Text' }),
      },
    ],
    [intl],
  );

  const accordion = useMemo(
    () =>
      data.map((item, index) =>
        renderAccordionItem(index, item.question, item.answer),
      ),
    [data, renderAccordionItem],
  );

  return (
    <div className={wrapper}>
      <h2>
        <KampaohIcon />
        {intl.formatMessage({ id: 'Faq.Title' })}
      </h2>
      <div className={accordionContainer}>{accordion}</div>
    </div>
  );
};

export default Faq;
