import sendEmail from '../../../../../services/EmailAPI';

const stripeTokenError = (reservation, payload) => sendEmail(
  reservation.guestFirstName.concat(' ', reservation.guestLastName, ' - NO RESERVA, NO COBRO, FALLO EN STRIPE'),
  'Revisa los datos de tu tarjeta, algo ha ido mal realizando el pago. \n'.concat(
    'RESERVATION: \n',
    JSON.stringify(reservation, null, 4), '\n',
    'FALLO STRIPE: \n',
    JSON.stringify(payload, null, 4),
  ),
);

export default stripeTokenError;
