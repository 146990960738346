import React, { useEffect, useState, memo, useMemo } from "react";
import { useNavigate } from "@reach/router";
import { useIntl } from "react-intl";
import UserAvatar from "../../../../components/UserAvatar";
import Detail from "../../../../components/Detail";
import useDeviceDetect from "../../../../utils/useDeviceDetect";
import ROUTES from "../../../../routes/routes";
import makeRequestToApServer from "../../../../services/ApServer/askToApServer";
import routes from "../../../../constants/ApServer/apRoutes";
import { ReactComponent as InfoIcon } from "../../../../assets/icons/icon_info.svg";
import { allowedMethods } from "../../../../constants/ApServer/apServer";
import {
  mainContainer,
  avatarContainer,
  infoContainer,
  userInfoContainer,
  userInfo,
  userInfoText,
  detailsContainer,
  buttonContainer,
  editButton,
  largeAvatar,
  tooltip,
  tooltiptext,
} from "./index.module.scss";

const UserSummary = memo(() => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect();
  const [userSummary, setUserSummary] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    makeRequestToApServer(
      allowedMethods.get,
      routes.userSummary,
      undefined,
      ({ statusCode, data }) => {
        if (statusCode === 200) {
          setUserSummary(data);
        }
      },
      () => {},
    );
  }, []);

  useEffect(() => {
    makeRequestToApServer(
      allowedMethods.get,
      routes.authMe,
      undefined,
      ({ statusCode, data }) => {
        if (statusCode === 200) {
          setUserData(data);
        }
      },
      () => {},
    );
  }, []);

  const tribeLevel = useMemo(() => {
    if (userSummary?.tribeLevel === undefined) {
      return "";
    }
    if (userSummary?.tribeLevel !== 0) {
      return (
        <>
          {intl.formatMessage(
            {
              id: "UserSummary.TribeLevel",
            },
            { level: userSummary?.tribeLevel },
          )}
          <span className={tooltip}>
            <InfoIcon />
            <span className={tooltiptext}>
              <b>
                {intl.formatMessage({
                  id: "UserSummary.Info.TribeLevel.Title",
                })}
              </b>
              {intl.formatMessage({ id: "UserSummary.Info.TribeLevel.Body" })}
            </span>
          </span>
        </>
      );
    }
    return (
      <>
        {intl.formatMessage({ id: "UserSummary.MissingReservation" })}
        <span className={tooltip}>
          <InfoIcon />
          <span className={tooltiptext}>
            <b>
              {intl.formatMessage({
                id: "UserSummary.Info.MissingReservation.Title",
              })}
            </b>
            {intl.formatMessage({
              id: "UserSummary.Info.MissingReservation.Body",
            })}
          </span>
        </span>
      </>
    );
  }, [userSummary, intl]);

  return (
    <div className={mainContainer}>
      {!isMobile && (
        <div className={avatarContainer}>
          <UserAvatar className={largeAvatar} />
        </div>
      )}

      <div className={infoContainer}>
        <div className={userInfoContainer}>
          <div className={userInfo}>
            {isMobile && (
              <div className={avatarContainer}>
                <UserAvatar />
              </div>
            )}
            <div className={userInfoText}>
              <h2>{`${userData?.name || " "} ${userData?.surname || " "}`}</h2>
              <p>{tribeLevel}</p>
            </div>
          </div>
          {isMobile && (
            <div className={buttonContainer}>
              <button
                className={editButton}
                type="button"
                onClick={() => navigate(ROUTES.meSettings)}
              >
                {intl.formatMessage({ id: "UserSummary.EditProfile" })}
              </button>
            </div>
          )}
        </div>
        <div className={detailsContainer}>
          <Detail
            counter={
              userSummary?.since !== undefined ? userSummary?.since : "-"
            }
            description={intl.formatMessage({
              id: "UserSummary.Details.DaysWithUs",
            })}
          />
          <Detail
            counter={
              userSummary?.travelsCount !== undefined
                ? userSummary?.travelsCount
                : "-"
            }
            description={intl.formatMessage({
              id: "UserSummary.Details.VisitsToKampaoh",
            })}
          />
          <Detail
            counter={
              userSummary?.countriesCount !== undefined
                ? userSummary?.countriesCount
                : "-"
            }
            description={intl.formatMessage({
              id: "UserSummary.Details.CountriesVisited",
            })}
          />
        </div>
      </div>
      {!isMobile && (
        <div className={buttonContainer}>
          <button
            className={editButton}
            type="button"
            onClick={() => navigate(ROUTES.meSettings)}
          >
            {intl.formatMessage({ id: "UserSummary.EditProfile" })}
          </button>
        </div>
      )}
    </div>
  );
});

UserSummary.propTypes = {};

export default UserSummary;
