import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import {
  ConditionAcceptanceCheckboxStyle,
  ConditionAcceptanceStyle,
  markdownStyle,
  paragraph,
} from '../index.module.scss';

const ConditionAcceptance = ({
  markdownSource,
  checked,
  changeChecked,
  subtitle,
  "data-cy": dataCy
}) => (
  <div className={ConditionAcceptanceStyle}>
    <Checkbox
      className={ConditionAcceptanceCheckboxStyle}
      checked={checked}
      onChange={() => changeChecked(!checked)}
      data-cy={dataCy}
    />
    <div className={markdownStyle}>
      {markdownSource && <ReactMarkdown source={markdownSource} />}
      {subtitle && <p className={paragraph}>{subtitle}</p>}
    </div>
  </div>
);

ConditionAcceptance.defaultProps = {
  markdownSource: null,
  checked: false,
  changeChecked: () => {},
  subtitle: null,
  "data-cy": undefined
};

ConditionAcceptance.propTypes = {
  markdownSource: PropTypes.string,
  checked: PropTypes.bool,
  changeChecked: PropTypes.func,
  subtitle: PropTypes.string,
  "data-cy": PropTypes.string
};

export default ConditionAcceptance;
