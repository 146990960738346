import React, {
  useMemo, useState, useEffect,
} from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { reservationZone, price } from '../../../index.module.scss';
import FloatingBar from '../../../../../components/FloatingBar';
import { datesAreValid, peopleAreValid } from '../../../../../services/CheckValidReservation';
import calcDaysBetweenCheckInAndCheckOut from '../../../../../utils/daysBetweenCheckInAndCheckOut';
import validatePropertyRatePlansV2 from '../../../../../services/validatePropertyRatePlansV2';
import Status from '../../../../../constants/status';
import FormattedPriceByTotalPrice from '../../../../../components/FormattedPrices/FormattedPriceByTotalPrice';
import RoomReservationButton from '../../../../../components/RoomReservationButton';
import Services from '../../../../../constants/services';
import UnitReservationWizard from '../../../shared/UnitReservationWizard';
import AvailabilityDrawer from '../../../../../components/AvailabilityDrawer';
import AvailabilityActionButton from '../../../shared/AvailabilityActionButton';
import { useReservationDispatch } from '../../../../../services/Reservation';
import ActionButton from '../../../../../components/ActionButton';

const ActionBar = ({
  reservation, filteredRatePlans, roomId, camping, propertyID, tent, selectedRate,
}) => {
  const {
    startDate, endDate, readyToBeUsed,
  } = reservation;
  const dispatch = useReservationDispatch();
  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
    endDate: moment.utc(endDate),
    startDate: moment.utc(startDate),
  });
  const [showAvailabilityDrawer, setShowAvailabilityDrawer] = useState(false);
  const [showUnitReservationWizard, setShowUnitReservationWizard] = useState(false);
  const [ratePlanState, setRatePlanState] = useState();
  let rateId;
  const MINPRICE = 0;

  const hasContext = readyToBeUsed;
  const ratePlanForThisTent = useMemo(() => {
    const searchForAValidRatePlan = () => {
      if (filteredRatePlans.length > 0 && camping) {
        let lowestRate = { totalPrice: Number.MAX_VALUE, ratePlan: null };

        filteredRatePlans.forEach((ratePlan) => {
          ratePlan.plans.forEach((rate) => {
            if (rate.totalPrice && (rate.totalPrice < lowestRate.totalPrice)
            && rate.available === true) {
              lowestRate = { totalPrice: rate.totalPrice, ratePlan: rate };
            }
          });
        });

        if (lowestRate.ratePlan) {
          const validatedRatePlan = validatePropertyRatePlansV2([lowestRate.ratePlan], camping);
          if (validatedRatePlan.status === Status.AVAILABLE
            && validatedRatePlan.ratePlans.length > 0) {
            return validatedRatePlan.ratePlans[0];
          }
        }
      }
      return undefined;
    };
    return searchForAValidRatePlan();
  }, [filteredRatePlans, camping]);

  useEffect(() => {
    const newRatePlan = ratePlanForThisTent;
    if (newRatePlan) {
      setRatePlanState(newRatePlan);
    } else (setRatePlanState());
    if (selectedRate) {
      setRatePlanState(selectedRate);
    }
  }, [selectedRate, ratePlanForThisTent]);

  return (
    <>
      <FloatingBar>
        {datesAreValid(reservation)
          && peopleAreValid(reservation)
          && ratePlanState
          && selectedRate?.totalPrice > MINPRICE ? (
            <div className={reservationZone}>
              <div className={price}>
                <FormattedPriceByTotalPrice
                  price={selectedRate?.totalPrice}
                  daysBetweenCheckInAndCheckOut={daysBetweenCheckInAndCheckOut}
                  startDate={startDate}
                  endDate={endDate}
                />
                <RoomReservationButton
                  type={Services.CAMP}
                  roomId={roomId}
                  propertyID={propertyID}
                  ratePlan={ratePlanState}
                  onClick={() => dispatch({ type: 'setKampaohService', payload: Services.CAMP })}
                  pets={tent.pets}
                  petsOnCamping={camping?.tents?.filter((tentIndex) => tentIndex.pets).length > 0}
                  tent={tent}
                  selectedRate={selectedRate}
                  confirmationUrl={camping?.confirmationUrl}
                />
              </div>
            </div>
          ) : (
            <ActionButton
              disabled={false}
              onClick={() => {
                if (!hasContext) {
                  setShowUnitReservationWizard(true);
                } else if (hasContext) {
                  setShowAvailabilityDrawer(true);
                }
              }}
            >
              {ratePlanForThisTent ? <></> : <FormattedMessage id="DetailsPage.Button.NotAvailable" />}

            </ActionButton>
          )}
      </FloatingBar>
      {!hasContext && showUnitReservationWizard && (
      <UnitReservationWizard
        visible={showUnitReservationWizard}
        onClose={() => setShowUnitReservationWizard(false)}
        property={tent.camping}
        unitId={tent.roomTypeId}
        service={Services.CAMP}
        initialRateId={rateId}
      />
      )}
      {hasContext && (!ratePlanState || datesAreValid(reservation) || peopleAreValid(reservation))
      && showAvailabilityDrawer && (
      <AvailabilityDrawer
        visible={showAvailabilityDrawer}
        onClose={() => setShowAvailabilityDrawer(false)}
        ratePlans={camping.ratePlans}
        property={tent.camping}
        rateId={rateId}
        unitId={roomId}
        kampaohService={Services.CAMP}
        singleMode
        customButton={({ disabled, currentDates, selectedUnitRatePlan }) => (
          <AvailabilityActionButton
            disabled={disabled}
            currentDates={currentDates}
            selectedUnitRatePlan={selectedUnitRatePlan}
            propertyId={camping.ulysesId}
            initialRateId={rateId}
            onClose={() => setShowAvailabilityDrawer(false)}
            unitId={roomId}
          />
        )}
      />
      )}
    </>
  );
};

ActionBar.propTypes = {
  reservation: PropTypes.shape({
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
    adults: PropTypes.number,
    children: PropTypes.number,
    babies: PropTypes.number,
    promoCode: PropTypes.string,
    readyToBeUsed: PropTypes.bool,
  }).isRequired,
  filteredRatePlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  roomId: PropTypes.string.isRequired,
  camping: PropTypes.shape({
    ulysesId: PropTypes.string,
    ratePlans: PropTypes.arrayOf(PropTypes.shape({})),
    tents: PropTypes.arrayOf(PropTypes.shape({})),
    confirmationUrl: PropTypes.string.isRequired,
  }).isRequired,
  propertyID: PropTypes.string.isRequired,
  tent: PropTypes.shape({
    camping: PropTypes.objectOf(PropTypes.shape({})),
    roomTypeId: PropTypes.string,
    pets: PropTypes.bool,
  }).isRequired,
  selectedRate: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ActionBar.defaultProps = {
};
export default ActionBar;
