import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const showModal = ({
  title, content, handleOk, handleCancel,
}) => {
  Modal.confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    onOk() {
      handleOk();
    },
    onCancel() {
      handleCancel();
    },
  });
};

const getButtonMessage = (currentStep, intl) => {
  switch (`${currentStep}`) {
    case '0':
      return intl.formatMessage({ id: 'Checkout.Resume.Button' });
    case '1':
      return intl.formatMessage({ id: 'Checkout.Policies.Button' });
    case '2':
      return intl.formatMessage({ id: 'Checkout.UserForm.Button' });
    default:
      return intl.formatMessage({ id: 'Checkout.Resume.Button' });
  }
};

export { showModal, getButtonMessage };
