export const updateUrlWithoutNavigation = (newUrl) => {
  const currentState = window.history.state;
  const currentTitle = document.title;
  window.history.replaceState(currentState, currentTitle, newUrl);
};

export const updateQueryParams = (key, value) => {
  const url = new window.URL(window.location);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  searchParams.set(key, value);

  const urlSearchParams = searchParams.toString();
  const currentParams = new URLSearchParams(url.search).toString();
  if (currentParams !== urlSearchParams) {
    updateUrlWithoutNavigation(`${url.pathname}?${searchParams.toString()}`);
  }
};
