import React from 'react';
import { PropTypes } from 'prop-types';
import { List } from 'antd';
import { useIntl } from 'react-intl';
import { CheckOutlined } from '@ant-design/icons';
import CustomDrawer from '../../../../components/CustomDrawer';
import sorters from '../../../../constants/sorters';
import {
  searchListWrapper,
  bullet,
  active,
  inactive,
  listItem,
} from './index.module.scss';

const Bullet = ({ isActive }) => (
  <div className={`${bullet} ${isActive ? active : inactive}`}>
    {isActive && <CheckOutlined />}
  </div>
);

const SearchDrawer = ({ visible, activeSorter, onChange, onClose }) => {
  const intl = useIntl();
  return (
    <CustomDrawer
      visible={visible}
      height="40vh"
      onClose={onClose}
      titleless
      mask
      subTitle={null}
    >
      <div className={searchListWrapper}>
        <List>
          {Object.keys(sorters)
            .filter((sorter) => sorters[sorter] !== sorters.RATING)
            .map((key) => (
              <List.Item
                className={listItem}
                onClick={() => {
                  onChange(sorters[key]);
                  onClose();
                }}
                key={sorters[key]}
              >
                <Bullet isActive={sorters[key] === activeSorter} />{' '}
                {intl.formatMessage({
                  id: `#Places.MainSection.ResumeSearch.Sorter.${sorters[key]}`,
                })}
              </List.Item>
            ))}
        </List>
      </div>
    </CustomDrawer>
  );
};

SearchDrawer.propTypes = {
  visible: PropTypes.bool,
  activeSorter: PropTypes.oneOf(
    Object.keys(sorters).map((key) => sorters[key]),
  ),
  onChange: PropTypes.func,
  onClose: PropTypes.func,
};

SearchDrawer.defaultProps = {
  visible: false,
  activeSorter: sorters.RELEVANCY,
  onChange: () => {},
  onClose: () => {},
};

Bullet.propTypes = {
  isActive: PropTypes.bool,
};

Bullet.defaultProps = {
  isActive: false,
};
export default SearchDrawer;
