import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { jwtDecode } from 'jwt-decode';
import { useLocation, useNavigate } from '@reach/router';
import { ReactComponent as KampaohIcon } from '../../assets/icons/kampaoh.svg';
import { container, title, loadingStyle, button } from './index.module.scss';
import PasswordForm from './components/PasswordForm';
import ROUTES from '../../routes/routes';

const PasswordResetPage = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');

  const getTokenFromURL = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('accessToken');
  }, [location.search]);

  const isTokenExpired = useCallback((token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      setEmail(decoded.email);
      return decoded.exp < currentTime;
    } catch (e) {
      return true;
    }
  }, []);

  useEffect(() => {
    const accessToken = getTokenFromURL();
    if (accessToken) {
      if (isTokenExpired(accessToken)) {
        setError(
          intl.formatMessage({ id: 'PasswordResetPage.Error.TokenExpired' }),
        );
      }
    } else {
      setError(
        intl.formatMessage({ id: 'PasswordResetPage.Error.TokenNotFound' }),
      );
    }
    setLoading(false);
  }, [getTokenFromURL, isTokenExpired, intl]);

  const handleResult = (response) => {
    if (response.success) {
      setSuccess(true);
      setError('');
    } else {
      setError(
        response.error ||
          intl.formatMessage({ id: 'PasswordResetPage.Error.General' }),
      );
      setSuccess(false);
    }
  };

  return (
    <div className={container}>
      <KampaohIcon />
      {!loading && !error && !success && (
        <PasswordForm
          accessToken={getTokenFromURL()}
          email={email}
          onResult={handleResult}
        />
      )}
      {loading && (
        <p className={loadingStyle}>
          {intl.formatMessage({ id: 'PasswordResetPage.Loading' })}
        </p>
      )}
      {error && (
        <p className={title}>
          {intl.formatMessage({ id: 'PasswordResetPage.Error' })}: {error}
        </p>
      )}
      {success && (
        <p className={title}>
          {intl.formatMessage({ id: 'PasswordResetPage.Success' })}
        </p>
      )}
      {(success || error) && (
        <button
          className={button}
          onClick={() => navigate(ROUTES.home)}
          type="button"
        >
          {intl.formatMessage({ id: 'PasswordResetPage.Button.Home' })}
        </button>
      )}
    </div>
  );
};

export default PasswordResetPage;
