import React from 'react';
import { PropTypes } from 'prop-types';
import { Button, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { wrapper } from './index.module.scss';

const CustomModal = ({
  visible, onOk, onCancel, children,
}) => (
  <Modal
    visible={visible}
    onOk={onOk}
    onCancel={onCancel}
    wrapClassName={wrapper}
    closable={false}
    footer={null}
    mask={false}
  >
    {children}
    <Button
      onClick={onOk}
    >
      <FormattedMessage tagName="span" id="Button.Ok" />
    </Button>
  </Modal>
);

CustomModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.element.isRequired,
};

CustomModal.defaultProps = {
  onOk: () => {},
  onCancel: () => {},
};
export default CustomModal;
