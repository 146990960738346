import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Necesitas instalar este paquete con `npm install jwt-decode`
import { URL } from '../constants/ApServer/apServer';
import routes from '../constants/ApServer/apRoutes';
import { updateTokens, clearTokens } from '../utils/tokenHelper';
import ROUTES from '../routes/routes';
import {
  isRefreshRequest,
  subscribeTokenRefresh,
  onRefreshed,
  setRefreshing,
  getRefreshing,
  onRefreshFailed,
} from './tokenManager';

const prefix = '/api';

const allowedlang = ['es', 'en', 'fr', 'it', 'pt'];
const defaultLang = 'es';

const excludedRoutes = [
  routes.authLogin,
  routes.authVerify,
  routes.authRegister,
  routes.authRefresh,
  routes.authPasswordReset,
  routes.authPassword,
  routes.emailVerify,
  routes.authEmailVerification,
];
const captchatRoutes = [
  routes.authLogin,
  routes.authVerify,
  routes.authRegister,
  routes.authPasswordReset,
  routes.authEmailVerification,
];
const captchatActions = {
  [routes.authVerify]: 'email_verify',
  [routes.authLogin]: 'login',
  [routes.authRegister]: 'signup',
  [routes.authPasswordReset]: 'password_reset',
  [routes.authEmailVerification]: 'email_validation',
};

const apAxios = axios.create({
  baseURL: `${URL}${prefix}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const goToHome = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete('redirect');
  const redirect = `${window.location.pathname}${
    // eslint-disable-next-line prefer-template
    params.toString() ? '?' + params.toString() : ''
  }`;

  window.location.href = `${ROUTES.auth}?redirect=${encodeURIComponent(
    redirect,
  )}`;
};

const handleRecaptcha = (config) =>
  new Promise((resolve, reject) => {
    const action = captchatActions[config.url] || 'defaultAction';

    window.grecaptcha.ready(() => {
      window.grecaptcha
        // eslint-disable-next-line no-underscore-dangle
        .execute(window._env_.REACT_APP_GOOGLE_CAPTCHA_ID, { action })
        .then((token) => {
          // eslint-disable-next-line no-param-reassign
          config.params = {
            ...config.params,
            recaptchaToken: token,
          };
          resolve(config);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });

const isTokenExpired = (token) => {
  try {
    const { exp } = jwtDecode(token);
    return Date.now() >= exp * 1000;
  } catch (e) {
    return true;
  }
};

const requestInterceptorQueue = (config) => {
  if (getRefreshing() && !isRefreshRequest(config, routes.authRefresh)) {
    return new Promise((resolve) => {
      subscribeTokenRefresh((token) => {
        const newConfig = { ...config };
        newConfig.headers.Authorization = `Bearer ${token}`;
        resolve(newConfig);
      });
    });
  }

  if (getRefreshing() && isRefreshRequest(config, routes.authRefresh)) {
    return config;
  }

  if (!getRefreshing() && isRefreshRequest(config, routes.authRefresh)) {
    setRefreshing(true);
    return config;
  }

  return config;
};

const requestInterceptor = async (config) => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken && isTokenExpired(refreshToken)) {
    clearTokens();
    goToHome();
    return Promise.reject(new Error('Refresh token expired'));
  }
  if (getRefreshing() && isRefreshRequest(config, routes.authRefresh)) {
    return config;
  }
  let accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    if (isTokenExpired(accessToken)) {
      if (!getRefreshing()) {
        setRefreshing(true);
        const refresh = localStorage.getItem('refreshToken');

        try {
          const response = await apAxios.post(routes.authRefresh, {
            refresh_token: refresh,
          });

          // eslint-disable-next-line camelcase
          const { access_token, refresh_token } = response.data.data;
          updateTokens(access_token, refresh_token);
          setRefreshing(false);
          onRefreshed(access_token);
          // eslint-disable-next-line camelcase
          accessToken = access_token;

          // eslint-disable-next-line camelcase, no-param-reassign
          config.headers.Authorization = `Bearer ${access_token}`;
          return Promise.resolve(config);
        } catch (refreshError) {
          clearTokens();
          onRefreshFailed(refreshError);
          window.location.href = ROUTES.auth;
          return Promise.reject(refreshError);
        }
      } else {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            const newConfig = { ...config };
            newConfig.headers.Authorization = `Bearer ${token}`;
            resolve(newConfig);
          });
        });
      }
    }

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  const countryCode = localStorage.getItem('countryCode');
  // eslint-disable-next-line no-param-reassign
  config.params = {
    ...config.params,
    lang: allowedlang.includes(countryCode) ? countryCode : defaultLang,
  };
  if (captchatRoutes.includes(config.url)) {
    return handleRecaptcha(config);
  }

  return config;
};

const responseInterceptor = async (response) => {
  if (
    getRefreshing() &&
    isRefreshRequest(response.config, routes.authRefresh)
  ) {
    setRefreshing(false);

    // eslint-disable-next-line camelcase
    const { access_token, refresh_token } = response.data.data;
    updateTokens(access_token, refresh_token);
    onRefreshed(access_token);
  }
  return response;
};

const errorInterceptor = async (error) => {
  const originalRequest = error?.config;
  if (originalRequest && !originalRequest?.retryCount) {
    originalRequest.retryCount = 0;
  }
  const isExcludedRoute = excludedRoutes.includes(originalRequest.url);

  if (
    (error.response.status === 401 || error.response.status === 403) &&
    !isExcludedRoute
  ) {
    if (!getRefreshing()) {
      setRefreshing(true);
      const refresh = localStorage.getItem('refreshToken');

      try {
        const response = await apAxios.post(routes.authRefresh, {
          refresh_token: refresh,
        });

        // eslint-disable-next-line camelcase
        const { access_token, refresh_token } = response.data.data;
        updateTokens(access_token, refresh_token);
        setRefreshing(false);
        onRefreshed(access_token);

        // eslint-disable-next-line camelcase
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return apAxios(originalRequest);
      } catch (refreshError) {
        clearTokens();
        onRefreshFailed(refreshError);
        goToHome();
        return Promise.reject(refreshError);
      }
    }

    return new Promise((resolve) => {
      subscribeTokenRefresh((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(apAxios(originalRequest));
      });
    });
  }
  if (error?.response?.status >= 500 && originalRequest?.retryCount < 3) {
    originalRequest.retryCount += 1;
    return apAxios(originalRequest);
  }

  return Promise.reject(error);
};

apAxios.interceptors.request.use(requestInterceptorQueue, (error) =>
  Promise.reject(error),
);
apAxios.interceptors.request.use(requestInterceptor, (error) =>
  Promise.reject(error),
);
apAxios.interceptors.response.use(responseInterceptor, errorInterceptor);

export default apAxios;
