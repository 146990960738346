/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Layout } from 'antd';
import Footer from '../../components/Footer';
import ResponsiveMenu from '../../components/ResponsiveMenu/ResponsiveMenu';
import TopBanner from '../../components/TopBanner';

const { Content } = Layout;

const PrivateAreaLayout =
  (Component) =>
  ({ ...props }) =>
    (
      <Layout style={{ minHeight: '100vh' }}>
        <TopBanner />
        <ResponsiveMenu />
        <Content>
          <Component {...props} />
        </Content>
        <Footer />
      </Layout>
    );

PrivateAreaLayout.propTypes = {};

PrivateAreaLayout.defaultProps = {};
export default PrivateAreaLayout;
