import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import featuresQuery from '../GraphQL/campingFeaturesQuery';
import sanitizeApiResponse from '../../utils/sanitizeApiResponse';
import { LanguageContext } from '../../locale/contexts/Language';
import useCampings from '../Campings/useCampings';

export const CampingFeaturesContext = React.createContext();

const CampingFeaturesProvider = ({ children }) => {
  const { currentLanguage } = useContext(LanguageContext);
  const [features, setFeatures] = useState([]);
  const { loading: loadingFeatures, data: dataFeatures } = useQuery(featuresQuery, {
    variables: { lang: currentLanguage || 'es' },
    skip: !currentLanguage || features.length,
  });

  const {
    dispatch,
  } = useCampings();

  useEffect(() => {
    const fetchCampingFeatures = async () => {
      try {
        if (!loadingFeatures && !features.length) {
          const campingFeatures = dataFeatures;
          setFeatures(sanitizeApiResponse(campingFeatures.features));
          dispatch({ type: 'allTentFeaturesFound', payload: sanitizeApiResponse(campingFeatures.features) });
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    fetchCampingFeatures();
  }, [loadingFeatures, dataFeatures, dispatch, features]);
  return (
    <CampingFeaturesContext.Provider value={features}>
      {children}
    </CampingFeaturesContext.Provider>
  );
};

CampingFeaturesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CampingFeaturesProvider;
