import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedNumber, FormattedPlural, useIntl,
} from 'react-intl';
import moment from 'moment';
import s from './index.module.scss';

const FormattedPriceByTotalPrice = ({
  price: _price,
  daysBetweenCheckInAndCheckOut,
  endDate,
  startDate,
}) => {
  const intl = useIntl();

  return (
    <div className={s.priceWrapper}>
      <span className={s.infoText}>
        Total
      </span>
      <span className={s.fullPrice}>
        <span className={s.price}>
          {/* eslint-disable-next-line react/style-prop-object */}
          <FormattedNumber value={_price} style="currency" currency="EUR" />
        </span>
      </span>
      <span className={s.infoText}>
        {moment(startDate).locale(intl.locale).format('DD MMM')}
        {' → '}
        {moment(endDate).locale(intl.locale).format('DD MMM')}
        {' ('}
        {daysBetweenCheckInAndCheckOut}
        {' '}
        <FormattedPlural
          value={daysBetweenCheckInAndCheckOut}
          one={intl.formatMessage({ id: 'Card.Button.Night' })}
          other={intl.formatMessage({ id: 'Card.Button.Nights' })}
        />
        )
      </span>
    </div>
  );
};

FormattedPriceByTotalPrice.propTypes = {
  price: PropTypes.number.isRequired,
  daysBetweenCheckInAndCheckOut: PropTypes.number.isRequired,
  endDate: PropTypes.instanceOf(moment).isRequired,
  startDate: PropTypes.instanceOf(moment).isRequired,
};

export default FormattedPriceByTotalPrice;
