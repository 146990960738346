/* eslint react/prop-types: 0 */
import React, {
  createContext, useReducer, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import initialState from './initialState';
import sanitizeApiResponse from '../../utils/sanitizeApiResponse';
import genericTextsQuery from '../GraphQL/Home/genericTextsQuery';
import { LanguageContext } from '../../locale/contexts/Language';

const ConditionsState = createContext();
const ConditionsDispatch = createContext();

const Conditionsreducer = (state, action) => {
  switch (action.type) {
    case 'storeCondition':
      return {
        ...state,
        [`${action.payload.FieldName}`.replace(' ', '')]: {
          ES: action.payload.ES,
          EN: action.payload.EN,
          description: action.payload.description,
        },
      };

    default:
      return { ...state };
  }
};

const ConditionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Conditionsreducer, initialState);
  const { currentLanguage } = useContext(LanguageContext);
  const { loading, data } = useQuery(genericTextsQuery, {
    variables: { lang: currentLanguage || 'es' },
  });
  useEffect(() => {
    const fetchConditions = async () => {
      try {
        if (!loading) {
          const genericTexts = await sanitizeApiResponse(data.genericTexts);
          genericTexts.map((conditionTextResponse) => {
            dispatch({
              payload: conditionTextResponse,
              type: 'storeCondition',
            });
            return null;
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    fetchConditions();
  }, [loading, data]);
  return (
    <ConditionsState.Provider value={state}>
      <ConditionsDispatch.Provider value={dispatch}>
        {children}
      </ConditionsDispatch.Provider>
    </ConditionsState.Provider>
  );
};

ConditionsProvider.ProtoTypes = {
  children: PropTypes.element.isRequired,
};

export const useConditionsState = () => {
  const context = React.useContext(ConditionsState);
  if (context === undefined) {
    throw new Error('Condition context must be used within a provider');
  }
  return context;
};

export default ConditionsProvider;
