import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { wrapper, footer, btn } from './index.module.scss';
import UserSummary from './components/UserSummary';
import PastTrip from './components/PastTrip';
import UpcomingTrip from './components/UpcomingTrip';
import useDeviceDetect from '../../utils/useDeviceDetect';
import { useAuthDispatch } from '../../services/Auth/useAuth';
import { ReactComponent as LogOutIcon } from '../../assets/icons/logout.svg';
import actionsTypes from '../../services/Auth/actionsTypes';
import Faq from './components/Faq';

const PrivateAreaPage = () => {
  const intl = useIntl();
  const dispatch = useAuthDispatch();
  const { isMobile } = useDeviceDetect();
  const handleLogout = useCallback(() => {
    dispatch({ type: actionsTypes.LOGOUT });
  }, [dispatch]);

  return (
    <div className={wrapper}>
      <UserSummary />
      <UpcomingTrip />
      <PastTrip />
      <Faq />
      {isMobile && (
        <div className={footer}>
          <button className={btn} type="button" onClick={() => handleLogout()}>
            <LogOutIcon />
            <span>
              {intl.formatMessage({ id: 'PrivateArea.Button.Logout.Text' })}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
export default PrivateAreaPage;
