/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import PartyConditionAcceptance from './components/PartyConditionAcceptance';
import ReservationParticularConditionAcceptance from './components/ReservationParticularConditionAccepted';
import PrivacyConditionAcceptance from './components/PrivacyConditionAcceptance';
import ReservationConditionAcceptance from './components/ReservationConditionAccepted';
import { useAuthState } from '../../../../../../services/Auth/useAuth';
import PetsConditionAccepted from './components/PetsConditionAccepted';
import PetsNotAvailableConditionAccepted from './components/PetsNotAvailableConditionAccepted';
import AllConditionAcceptance from './components/AllConditionAcceptance';
import KampaohClubConditionAcceptance from './components/KampaohClubConditionAcceptance';
import {
  useReservationState,
  useReservationDispatch,
} from '../../../../../../services/Reservation';
import { CheckoutContext } from '../../../..';
import STEP from '../../../steps';

const PoliciesAcceptance = injectIntl(({ onComplete }) => {
  const { setCurrentStep, setPoliciesDone } = useContext(CheckoutContext);
  setCurrentStep(STEP.policies);
  const { pets, petsOnCamping } = useReservationState();
  const {
    acceptPartyPolicy,
    acceptReservationParticularPolicy,
    acceptReservationPolicy,
    acceptPrivacyPolicy,
    acceptPetsPolicy,
    guestClub,
  } = useReservationState();
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const fpaFromURL = searchParams.get('fpa');
  const [partyAccepted, setPartyAcceptance] = useState(acceptPartyPolicy);
  const [reservationParticularAccepted, setReservationParticularAcceptance] =
    useState(acceptReservationParticularPolicy);
  const [privacyAccepted, setPrivacyAccepted] = useState(acceptPrivacyPolicy);
  const [reservationAccepted, setReservationAcceptance] = useState(
    acceptReservationPolicy,
  );
  const [petsAccepted, setPetsAcceptance] = useState(!pets);
  const [allAccepted, setAllAccepted] = useState(false);
  const [kampaohClubAccepted, setKampaohClubAcceptance] = useState(guestClub);
  const [petsNotAvailableAccepted, setPetsNotAvailableAcceptance] = useState(
    petsOnCamping ? pets && petsOnCamping : true,
  );
  const dispatch = useReservationDispatch();
  const { accessToken } = useAuthState();

  useEffect(() => {
    setPartyAcceptance(acceptPartyPolicy);
    setReservationParticularAcceptance(acceptReservationParticularPolicy);
    setPrivacyAccepted(acceptPrivacyPolicy);
    setReservationAcceptance(acceptReservationPolicy);
    setPetsAcceptance(!pets);
  }, [
    acceptPartyPolicy,
    acceptReservationParticularPolicy,
    acceptPrivacyPolicy,
    acceptReservationPolicy,
    acceptPetsPolicy,
    pets,
  ]);

  useEffect(() => {
    dispatch({ type: 'acceptPartyPolicy', payload: false });
    dispatch({ type: 'acceptReservationParticularPolicy', payload: false });
    dispatch({ type: 'acceptReservationPolicy', payload: false });
    dispatch({ type: 'acceptPrivacyPolicy', payload: false });
    dispatch({ type: 'acceptAllPolicy', payload: false });
  }, [dispatch]);

  const allConditionsAccepted = !(
    partyAccepted &&
    reservationAccepted &&
    privacyAccepted &&
    reservationParticularAccepted &&
    petsAccepted &&
    petsNotAvailableAccepted
  );

  useEffect(() => {
    onComplete(allConditionsAccepted);
    setPoliciesDone(true);
  }, [allConditionsAccepted, onComplete, setPoliciesDone]);

  useEffect(() => {
    if (allAccepted) {
      setPartyAcceptance(true);
      setReservationParticularAcceptance(true);
      setPrivacyAccepted(true);
      setReservationAcceptance(true);
      setKampaohClubAcceptance(true);
      if (pets === true) {
        setPetsAcceptance(true);
      }
      if (!pets && petsOnCamping) {
        setPetsNotAvailableAcceptance(true);
      }
    } else {
      setPartyAcceptance(false);
      setReservationParticularAcceptance(false);
      setPrivacyAccepted(false);
      setReservationAcceptance(false);
      setKampaohClubAcceptance(false);
      if (pets === true) {
        setPetsAcceptance(false);
      }
      if (!pets && petsOnCamping) {
        setPetsNotAvailableAcceptance(false);
      }
    }
  }, [allAccepted, pets, petsOnCamping]);

  useEffect(() => {
    dispatch({ type: 'guestClub', payload: kampaohClubAccepted });
  }, [dispatch, kampaohClubAccepted]);
  return (
    <>
      {((!pets && petsOnCamping) || fpaFromURL === 'allergy') && (
        <PetsNotAvailableConditionAccepted
          checked={petsNotAvailableAccepted}
          changeChecked={setPetsNotAvailableAcceptance}
        />
      )}
      <PartyConditionAcceptance
        checked={partyAccepted}
        changeChecked={setPartyAcceptance}
      />
      <ReservationParticularConditionAcceptance
        checked={reservationParticularAccepted}
        changeChecked={setReservationParticularAcceptance}
      />
      <ReservationConditionAcceptance
        checked={reservationAccepted}
        changeChecked={setReservationAcceptance}
      />
      <PrivacyConditionAcceptance
        checked={privacyAccepted}
        changeChecked={setPrivacyAccepted}
      />
      {(pets === true || fpaFromURL === 'pets') && (
        <PetsConditionAccepted
          checked={petsAccepted}
          changeChecked={setPetsAcceptance}
        />
      )}
      {accessToken === null && (
        <KampaohClubConditionAcceptance
          checked={kampaohClubAccepted}
          changeChecked={setKampaohClubAcceptance}
        />
      )}
      <AllConditionAcceptance
        checked={allAccepted}
        changeChecked={setAllAccepted}
      />
    </>
  );
});

PoliciesAcceptance.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default PoliciesAcceptance;
