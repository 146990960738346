import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ReturnIcon } from '../../../assets/icons/return.svg';
import { wrapper } from './index.module.scss';
import CustomDrawer from '../../CustomDrawer';
import DestinationsPicker from '../../../pages/Home/components/ReservationWizard/components/DestinationsPicker';

const DestinationsDrawer = ({
  visible,
  onDestinationPick,
  onClose,
}) => (
  <CustomDrawer
    title={<FormattedMessage id="DestinationsDrawer.title" />}
    visible={visible}
    icon={<ReturnIcon />}
    onClose={onClose}
    mask
    subTitle={null}
  >
    <div className={wrapper}>
      {/* TO-DO: This component may be refactorized and isolated on main components folder */}
      <DestinationsPicker onClick={onDestinationPick} />
    </div>
  </CustomDrawer>
);

DestinationsDrawer.propTypes = {
  onDestinationPick: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

DestinationsDrawer.defaultProps = {
  onDestinationPick: () => {},
  onClose: () => {},
  visible: false,
};

export default DestinationsDrawer;
