import React, { useContext } from 'react';
import CampingCard from './components/Card';
import { placesList } from './index.module.scss';
import { SearchContext } from '../../../../../contexts/SearchContext';
import Services from '../../../../../constants/services';

const Cards = () => {
  const { propertiesToShow, filteredStatus } = useContext(SearchContext);
  const priority = {
    available: 1, minStayWarning: 2, notavailable: 3, closed: 4,
  };

  const sortProperties = () => {
    const propertiesArray = Object.keys(propertiesToShow).map((key) => propertiesToShow[key]);

    return propertiesArray.sort((a, b) => {
      const priorityA = priority[filteredStatus[a]] || priority.notavailable;
      const priorityB = priority[filteredStatus[b]] || priority.notavailable;
      return priorityA - priorityB || propertiesArray.indexOf(a) - propertiesArray.indexOf(b);
    });
  };
  const sortedProperties = sortProperties();
  return (
    <div className={placesList}>
      {sortedProperties
        .map((key) => (
          <CampingCard
            key={key}
            propertyID={key}
            type={Services.CAMP}
          />
        ))}
    </div>
  );
};

Cards.propTypes = {};

Cards.defaultProps = {};

export default Cards;
