import { Form, Input, Typography } from 'antd';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import rules from '../validationRules';
import {
  formItemStyle,
  inputStyle,
  textFieldWrapper,
} from './index.module.scss';

const TextField = ({ field, disabled, "data-cy": dataCy }) => {
  const intl = useIntl();
  const fieldTitle = intl.formatMessage({
    id: `#UserForm.Validation.${field}.Title`,
    defaultMessage: field,
  });
  const fieldPlaceholder = intl.formatMessage({
    id: `#UserForm.Validation.${field}.Placeholder`,
    defaultMessage: field,
  });
  const fieldLabel = useMemo(
    () => <Typography.Text strong>{fieldTitle}</Typography.Text>,
    [fieldTitle],
  );

  return (
    <div className={textFieldWrapper}>
      <Form.Item
        className={formItemStyle}
        name={field}
        rules={rules[field]}
        label={fieldLabel}
        normalize={(e) => (field === 'Email' ? e.trim() : e)}
        validateTrigger="onBlur"
      >
        <Input
          className={inputStyle}
          placeholder={fieldPlaceholder}
          disabled={disabled}
          data-cy={dataCy}
        />
      </Form.Item>
    </div>
  );
};
TextField.defaultProps = {
  disabled: false,
  "data-cy": undefined
};
TextField.propTypes = {
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  "data-cy": PropTypes.string
};

export default TextField;
