import React from 'react';
import ReactDOM from 'react-dom';
import { overlay, drawer, open } from './index.module.scss';

const Drawer = ({ isOpen, children }) =>
  ReactDOM.createPortal(
    <div className={isOpen ? overlay : undefined}>
      <div className={`${drawer} ${isOpen ? open : ''}`}>{children}</div>
    </div>,
    document.getElementById('drawer'),
  );

export default Drawer;
