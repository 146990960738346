import { PropTypes } from "prop-types";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { useReservationState } from "../../services/Reservation";
import initialState from "../../services/Reservation/initialState";
import ActionButton from "../ActionButton";
import Counters from "../Counters";
import CustomDrawer from "../CustomDrawer";
import {
  deleteZone,
  footer,
  footerWrapper,
  wrapper,
} from "./index.module.scss";

const PersonSelectorDrawer = ({
  visible,
  onClose,
  onOk,
  maxPax,
  initCountersValues,
  fpaFilter,
  setFpaFilter,
  petsAllowed,
}) => {
  const countersInitialState = {
    adults: initialState().adults,
    children: initialState().children,
    babies: initialState().babies,
  };
  const [counters, setCounters] = useState(
    initCountersValues ?? countersInitialState,
  );

  const { kampaohService } = useReservationState();

  const handleOnReset = () => {
    setFpaFilter("none");
    setCounters(initCountersValues ?? countersInitialState);
  };

  return (
    <CustomDrawer
      title={<FormattedMessage id="PersonSelector.title" />}
      visible={visible}
      onClose={onClose}
      icon={<CloseIcon />}
      mask
      subTitle={null}
    >
      <div className={wrapper}>
        <Counters
          service={kampaohService}
          showBackground={false}
          showLines
          values={counters}
          onChange={setCounters}
          maxCount={maxPax}
          fpaFilter={fpaFilter}
          setFpaFilter={setFpaFilter}
          petsAllowed={petsAllowed}
        />

        <div className={footerWrapper}>
          <div className={footer}>
            <div className={deleteZone}>
              <CloseIcon />
              <span
                onClick={handleOnReset}
                onKeyPress={handleOnReset}
                role="button"
                tabIndex={0}
              >
                <FormattedMessage id="PersonSelector.deleteCounters" />
              </span>
            </div>
            <div>
              <ActionButton
                disabled={false}
                size="sm"
                onClick={() => onOk(counters)}
                data-cy="person-selector-ok-button"
              >
                OK
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
};

PersonSelectorDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  maxPax: PropTypes.number,
  initCountersValues: PropTypes.shape({
    children: PropTypes.number,
    adults: PropTypes.number,
    babies: PropTypes.number,
  }),
  fpaFilter: PropTypes.string,
  setFpaFilter: PropTypes.func,
  petsAllowed: PropTypes.bool,
};

PersonSelectorDrawer.defaultProps = {
  maxPax: undefined,
  initCountersValues: undefined,
  fpaFilter: "none",
  setFpaFilter: () => {},
  petsAllowed: undefined,
};
export default PersonSelectorDrawer;
