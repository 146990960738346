/* eslint-disable no-underscore-dangle */
import { Form, Input } from "antd";
import { PropTypes } from "prop-types";
import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDebouncedCallback } from "use-debounce";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/lens.svg";
import { LanguageContext } from "../../../../locale/contexts/Language";
import { makeRequestToServerV3 } from "../../../../services/API";
import {
  formItemStyle,
  inputStyle,
  optionalText,
  textFieldWrapper,
} from "./index.module.scss";

const SearchBar = ({
  setProperties,
  properties,
  inputValue,
  setInputValue,
}) => {
  const intl = useIntl();
  const { currentLanguage } = useContext(LanguageContext);

  const fetchProperties = useDebouncedCallback(async () => {
    try {
      const data = await makeRequestToServerV3(
        `/properties?search=${inputValue}&lang=${currentLanguage}`,
      );
      setProperties(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, 500);

  useEffect(() => {
    if (inputValue?.length > 0) {
      fetchProperties();
    } else {
      setProperties();
    }

    return () => {
      if (fetchProperties.isPending()) {
        fetchProperties.cancel();
      }
    };
  }, [inputValue, setProperties, fetchProperties]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className={textFieldWrapper}>
      <Form.Item className={formItemStyle} name="search">
        <Input
          data-cy="search-bar"
          className={inputStyle}
          placeholder={intl.formatMessage({ id: "SearchInput.placeholder" })}
          value={inputValue}
          onChange={handleInputChange}
          prefix={<SearchIcon />}
        />
      </Form.Item>
      {!properties && (
        <span className={optionalText}>
          {intl.formatMessage({ id: "SearchInput.optionalText" })}
        </span>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  setProperties: PropTypes.func.isRequired,
  properties: PropTypes.instanceOf(Array).isRequired,
  inputValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
};

export default SearchBar;
