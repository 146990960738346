import React from 'react';
import { PropTypes } from 'prop-types';
import { wrapper, iconWc } from './index.module.scss';
import { ReactComponent as WcIcon } from '../../assets/icons/toilet-icon.svg';

const PrivateWcTag = ({ children, className }) => (
  <div className={`${wrapper} ${className}`}>
    <WcIcon className={iconWc} />
    <span>
      {children}
    </span>
  </div>
);

PrivateWcTag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

PrivateWcTag.defaultProps = {
  className: '',
};
export default PrivateWcTag;
