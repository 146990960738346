import axios from "axios";
import { jwtDecode } from "jwt-decode";
import serverUrl from "../constants/config";
import { updateTokens, clearTokens } from "../utils/tokenHelper";
import apAxios from "./apAxios";
import ROUTES from "../routes/routes";
import apRoutes from "../constants/ApServer/apRoutes";
import {
  isRefreshRequest,
  subscribeTokenRefresh,
  onRefreshed,
  setRefreshing,
  onRefreshFailed,
  getRefreshing,
} from "./tokenManager";

const prefix = "/api/v3";

const excludedRoutes = [`${serverUrl}${prefix}/reservation`];

const beAxios = axios.create({
  baseURL: `${serverUrl}${prefix}`,
  headers: {
    "Content-Type": "application/json",
  },
});

const goToHome = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete("redirect");
  const redirect = `${window.location.pathname}${
    // eslint-disable-next-line prefer-template
    params.toString() ? "?" + params.toString() : ""
  }`;

  window.location.href = `${ROUTES.auth}?redirect=${encodeURIComponent(
    redirect
  )}`;
};

const isTokenExpired = (token) => {
  try {
    const { exp } = jwtDecode(token);
    return Date.now() >= exp * 1000;
  } catch (e) {
    return true;
  }
};

const requestInterceptorQueue = (config) => {
  if (getRefreshing() && !isRefreshRequest(config, apRoutes.authRefresh)) {
    return new Promise((resolve) => {
      subscribeTokenRefresh((token) => {
        const newConfig = { ...config };
        newConfig.headers.Authorization = `Bearer ${token}`;
        resolve(newConfig);
      });
    });
  }

  if (getRefreshing() && isRefreshRequest(config, apRoutes.authRefresh)) {
    return config;
  }

  if (!getRefreshing() && isRefreshRequest(config, apRoutes.authRefresh)) {
    setRefreshing(true);
    return config;
  }

  return config;
};

const requestInterceptor = async (config) => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken && isTokenExpired(refreshToken)) {
    clearTokens();
    goToHome();
    return Promise.reject(new Error("Refresh token expired"));
  }
  if (getRefreshing() && isRefreshRequest(config, apRoutes.authRefresh)) {
    return config;
  }
  let accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    if (isTokenExpired(accessToken)) {
      if (!getRefreshing()) {
        setRefreshing(true);
        const refresh = localStorage.getItem("refreshToken");

        try {
          const response = await apAxios.post(apRoutes.authRefresh, {
            refresh_token: refresh,
          });

          // eslint-disable-next-line camelcase
          const { access_token, refresh_token } = response.data.data;
          updateTokens(access_token, refresh_token);
          setRefreshing(false);
          onRefreshed(access_token);
          // eslint-disable-next-line camelcase
          accessToken = access_token;

          // eslint-disable-next-line camelcase, no-param-reassign
          config.headers.Authorization = `Bearer ${access_token}`;
          return Promise.resolve(config);
        } catch (refreshError) {
          clearTokens();
          onRefreshFailed(refreshError);
          const params = new URLSearchParams(window.location.search);
          params.delete("redirect");
          const redirect = `${window.location.pathname}${
            // eslint-disable-next-line prefer-template
            params.toString() ? "?" + params.toString() : ""
          }`;

          window.location.href = `${ROUTES.auth}?redirect=${encodeURIComponent(
            redirect
          )}`;
          return Promise.reject(refreshError);
        }
      } else {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            const newConfig = { ...config };
            newConfig.headers.Authorization = `Bearer ${token}`;
            resolve(newConfig);
          });
        });
      }
    }

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
};

const responseInterceptor = async (response) => {
  if (
    getRefreshing() &&
    isRefreshRequest(response.config, apRoutes.authRefresh)
  ) {
    setRefreshing(false);

    // eslint-disable-next-line camelcase
    const { access_token, refresh_token } = response.data.data;
    updateTokens(access_token, refresh_token);
    onRefreshed(access_token);
  }
  return response;
};

const errorInterceptor = async (error) => {
  const originalRequest = error.config;

  if (!originalRequest.retryCount) {
    originalRequest.retryCount = 0;
  }
  const isExcludedRoute = excludedRoutes.includes(originalRequest.url);
  if (error.response.status === 401 || error.response.status === 403) {
    if (!getRefreshing()) {
      setRefreshing(true);
      const refresh = localStorage.getItem("refreshToken");

      try {
        const response = await apAxios.post(apRoutes.authRefresh, {
          refresh_token: refresh,
        });

        // eslint-disable-next-line camelcase
        const { access_token, refresh_token } = response.data.data;
        updateTokens(access_token, refresh_token);
        setRefreshing(false);
        onRefreshed(access_token);

        // eslint-disable-next-line camelcase
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return beAxios(originalRequest);
      } catch (refreshError) {
        clearTokens();
        onRefreshFailed(refreshError);
        goToHome();
        return Promise.reject(refreshError);
      }
    }

    return new Promise((resolve) => {
      subscribeTokenRefresh((token) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(beAxios(originalRequest));
      });
    });
  }
  if (
    error?.response?.status >= 500 &&
    originalRequest?.retryCount < 3 &&
    !isExcludedRoute
  ) {
    originalRequest.retryCount += 1;
    return beAxios(originalRequest);
  }

  return Promise.reject(error);
};

beAxios.interceptors.request.use(requestInterceptorQueue, (error) =>
  Promise.reject(error)
);
beAxios.interceptors.request.use(requestInterceptor, (error) =>
  Promise.reject(error)
);
beAxios.interceptors.response.use(responseInterceptor, errorInterceptor);

export default beAxios;
