import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ReactComponent as KampaohIcon } from '../../assets/icons/kampaoh.svg';
import {
  card,
  imageStyle,
  textContainer,
  destination,
  priceStyle,
  carouselItem,
  tribeWhite,
  tribeBlack,
  kampaohLogo,
} from './index.module.scss';

const PastTripCard = ({ travelObject }) => {
  const intl = useIntl();
  const { featuredImage, location, priceTotal, isTribing, nights } =
    travelObject;

  return (
    <div className={carouselItem}>
      <div className={card}>
        <div className={`${isTribing ? tribeWhite : tribeBlack}`}>
          {isTribing && <KampaohIcon className={kampaohLogo} />}
          {`${
            isTribing
              ? intl.formatMessage({ id: 'PastTripCard.isTribing.Text' })
              : intl.formatMessage({ id: 'PastTripCard.isNotTribing.Text' })
          }`}
        </div>
        <img src={featuredImage} alt={location} className={imageStyle} />
        <div className={textContainer}>
          <h3 className={destination}>{location}</h3>
          <p className={priceStyle}>
            {intl.formatMessage(
              { id: 'PastTripCard.price.Text' },
              { price: priceTotal / nights },
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

PastTripCard.propTypes = {
  travelObject: PropTypes.shape({
    featuredImage: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    priceTotal: PropTypes.number.isRequired,
    isTribing: PropTypes.bool.isRequired,
    nights: PropTypes.number.isRequired,
  }).isRequired,
};

export default PastTripCard;
