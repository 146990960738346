import moment from 'moment';

const setItemWithExpiryTime = (key, value, ttl = 30 * 24 * 60 * 60 * 1000) => {
  const now = new Date();

  const item = {
    value: moment(value).format('YYYY-MM-DD'),
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getItemWithExpiryTime = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (!item.expiry && Number.isNaN(item) && (now.getTime() > item.expiry)) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export {
  setItemWithExpiryTime,
  getItemWithExpiryTime,
};
