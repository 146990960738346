import moment from 'moment-timezone';

moment.tz.setDefault('Etc/UTC');
const LIMIT_HOUR_IN_24_FORMAT = 19;
// TODO: Timezone is not global and when the func is called outside it doesn't work without the +2
const nowIn24hFormat = () => parseInt(moment().format('HH'), 10) + 2;
const hourIsAboveOrEqualLimitForToday = () => nowIn24hFormat() >= LIMIT_HOUR_IN_24_FORMAT;

export default hourIsAboveOrEqualLimitForToday;
export {
  hourIsAboveOrEqualLimitForToday,
  nowIn24hFormat,
  LIMIT_HOUR_IN_24_FORMAT,
};
