import React, { useState } from 'react';
// import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import ContactInfo from '../ContactInfo';
import { drawerWrapper } from './index.module.scss';
import CustomDrawer from '../CustomDrawer';

const ContactButton = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <>
      {/* <div className={wrapper}>
        <Button className={inner} shape="circle" icon={<WhatsappIcon />} onClick={() => setShowDrawer(true)} />
      </div> */}
      <CustomDrawer
        visible={showDrawer}
        onClose={() => setShowDrawer(false)}
        title={<FormattedMessage id="ContactButton.Drawer.MainTitle" />}
        icon={<CloseIcon />}
        mask
        subTitle={null}
      >
        <div className={drawerWrapper}>
          <FormattedMessage id="ContactButton.Drawer.Title" tagName="h1" />
          <FormattedMessage id="ContactButton.Drawer.Text" tagName="p" />
          <ContactInfo />
        </div>
      </CustomDrawer>
    </>
  );
};

ContactButton.propTypes = {};

ContactButton.defaultProps = {};
export default ContactButton;
