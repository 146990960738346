/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import {
  wrapperSubZone,
  locationSpan,
  locationButton,
  zoneModalBlock,
  zoneModalNone,
} from "./index.module.scss";
import WizardStep from "../../../pages/Home/components/ReservationWizard/components/WizardStep";
import { ReactComponent as ReturnIcon } from "../../../assets/icons/return.svg";

const ZoneModal = ({ visible, onClose, zones, onChange }) => {
  const intl = useIntl();
  const handleClose = () => {
    onClose();
  };

  const handleOnDestinationPick = useCallback(
    (location) => {
      if (location?.campingsCount === 0) return null;
      return onChange({ location });
    },
    [onChange],
  );

  const zoneCountry = zones && zones?.find((zone) => zone?.regions?.length > 1);

  const getLocations = () => {
    if (zoneCountry) {
      const sortedRegions = zoneCountry?.regions?.sort((a, b) =>
        a?.name?.localeCompare(b?.name),
      );

      return sortedRegions.map((region) => {
        const zoneID = region?.zone?.find(
          (item) => item?.internalID !== zoneCountry?.internalID,
        );
        const nameByInternalID = region?.zone?.find(
          (item) => item?.internalID !== zoneCountry?.internalID,
        )?.Name;

        return (
          <>
            <div className={locationButton} type="button">
              <strong
                data-cy="property-card-strong"
                onClick={() =>
                  handleOnDestinationPick({
                    name: region?.name,
                    id: zoneID?.internalID,
                    campingsCount: region?.campings?.length,
                  })
                }
              >
                {nameByInternalID}
              </strong>
              <span>{region?.campings?.length}</span>
            </div>
            <span className={locationSpan}>
              {Array.from(
                new Set(
                  region?.campings
                    .map((camp) => {
                      if (camp?.location && camp.location.trim()) {
                        return camp.location.trim().split(", ").pop();
                      }
                      return null;
                    })
                    .filter(
                      (location) =>
                        location != null && location !== region.name,
                    ),
                ),
              )
                .sort()
                .join(", ")}
            </span>
          </>
        );
      });
    }
    return null;
  };

  return (
    <div className={visible ? zoneModalBlock : zoneModalNone}>
      <WizardStep
        visible={visible} // Use visibleWrapper state instead of the 'visible' prop directly
        onClose={handleClose}
        title={intl.formatMessage({ id: "DestinationsSpainTitle" })}
        footer={null}
        icon={<ReturnIcon />}
        mask
      >
        <div className={wrapperSubZone}>{getLocations()}</div>
      </WizardStep>
    </div>
  );
};

ZoneModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // zones: PropTypes.map({}).isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      campingsCount: PropTypes.number,
    }),
  ),
};
export default ZoneModal;
