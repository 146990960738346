import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import * as s from './index.module.scss';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as PassengerIcon } from '../../assets/icons/passenger.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/return.svg';
import Services from '../../constants/services';

const UnitResumeTable = ({
  type,
  onDatePickerClick,
  onPersonSelectorClick,
  data,
  destinationInfo,
  fpa,
}) => {
  const intl = useIntl();

  const formattedDate = (date) => {
    const momentDate = moment(date).locale(intl.locale);
    let formatedDate = momentDate.format('ddd, DD MMM');

    if (intl.locale.startsWith('pt')) {
      formatedDate = formatedDate.replace(/\./g, '');
    }

    return formatedDate;
  };

  return (
    <div className={s.reservationInfo}>
      <div
        className={s.datePicker}
        onClick={onDatePickerClick}
        onKeyPress={onDatePickerClick}
        role="button"
        tabIndex={0}
      >
        <div className={s.startDate}>
          <CalendarIcon />
          <div>
            <p className={s.title}>
              <FormattedMessage
                id={`#DetailsPage.TentSelector.${type}.StartDate`}
              />
            </p>
            <p>{data.startDate ? formattedDate(data.startDate) : '––'}</p>
            {destinationInfo && (
              <p className={s.destinationInfo}>{destinationInfo.arrival}</p>
            )}
          </div>
        </div>
        <div className={s.endDate}>
          <div>
            <p className={s.title}>
              <FormattedMessage
                id={`#DetailsPage.TentSelector.${type}.EndDate`}
              />
            </p>
            <p>{data.endDate ? formattedDate(data.endDate) : '––'}</p>
            {destinationInfo && (
              <p className={s.destinationInfo}>{destinationInfo.arrival}</p>
            )}
          </div>
        </div>
      </div>
      <div
        className={s.personSelector}
        onClick={onPersonSelectorClick}
        onKeyPress={onPersonSelectorClick}
        role="button"
        tabIndex={0}
      >
        {type === Services.CAMP ? <UserIcon /> : <PassengerIcon />}
        <div>
          <p className={s.title}>
            <FormattedMessage
              id={`#DetailsPage.TentSelector.${type}.Members`}
            />
          </p>
          <FormattedMessage id="typeOfPerson.adults" />
          {` x${data.adults}`}
          {data.children !== 0 && (
            <>
              {', '}
              <FormattedMessage id="typeOfPerson.children" />
              {` x${data.children}`}
            </>
          )}
          {data.babies !== 0 && (
            <>
              {', '}
              <FormattedMessage id="typeOfPerson.babies" />
              {` x${data.babies}`}
            </>
          )}
          {fpa === 'pets' && (
            <>
              {', '}
              <FormattedMessage id="ModalForPets.title" />
            </>
          )}
          {fpa === 'allergy' && (
            <>
              {', '}
              <FormattedMessage id="ModalForAllergy.title" />
            </>
          )}
        </div>
        <ArrowDownIcon className={s.arrowDownIcon} />
      </div>
    </div>
  );
};

UnitResumeTable.propTypes = {
  type: PropTypes.oneOf(Object.values(Services)).isRequired,
  onDatePickerClick: PropTypes.func,
  onPersonSelectorClick: PropTypes.func,
  data: PropTypes.shape({
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
    adults: PropTypes.number,
    children: PropTypes.number,
    babies: PropTypes.number,
  }).isRequired,
  destinationInfo: PropTypes.shape({
    arrival: PropTypes.string,
    departure: PropTypes.string,
  }),
  fpa: PropTypes.string,
};

UnitResumeTable.defaultProps = {
  onDatePickerClick: () => {},
  onPersonSelectorClick: () => {},
  destinationInfo: undefined,
  fpa: 'none',
};
export default UnitResumeTable;
