/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import { useContext } from 'react';
import moment from 'moment';
import { CampingOnPlacesDispatch, CampingOnPlacesState } from './DataProvider';
import { TentOnPlacesState } from './TentDataProvider';
import { LanguageContext } from '../../locale/contexts/Language';
import { makeRequestToCmsServer, makeRequestToServerV3 } from '../API';
import { adults, boys } from '../../constants/typeOfPersons';
import { routes } from '../../constants/cms';

const useCamping = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const state = useContext(CampingOnPlacesState);
  const tentState = useContext(TentOnPlacesState);

  if (state === undefined) {
    throw new Error('Campings state context must be used within a provider');
  }

  const dispatch = useContext(CampingOnPlacesDispatch);
  if (dispatch === undefined) {
    throw new Error('Campings dispatch context must be used within a provider');
  }

  const addRatePlansToCamping = (campingId, ratePlans) => {
    dispatch({
      type: 'addRatePlanToCamping',
      payload: {
        campingId,
        ratePlans,
      },
    });
  };

  const addErrorToCamping = (campingId) => {
    dispatch({
      type: 'addErrorToCamping',
      payload: {
        campingId,
      },
    });
  };

  const askingForCampingsRatePlans = () =>
    dispatch({ type: 'askingForCampingsRatePlans' });

  const askForCampingsRatePlans = async (reservation, propertyID, regions, reload) => {
    askingForCampingsRatePlans();
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const zoneFromURL = parseInt(searchParams.get('zone'), 10);

    const regionsData =
      regions || (await makeRequestToCmsServer(routes.regions));

    const campingsByPreferredLocation = async () =>
      Object.keys(state.campings).reduce((acc, key) => {
        if (propertyID) {
          acc[propertyID] = state.campings[propertyID];
        } else if (regionsData && Object.keys(regionsData)?.length > 0) {
          const filteredRegions = regionsData.filter((itm) => {
            const filteredZones = itm.zone.filter(
              (zone) => zone.internalID === zoneFromURL,
            );
            return filteredZones?.length > 0; // Include regions with at least one matching zone
          });
          const campingInternalID = state.campings[key].region.zone.find(
            (zoneCamping) => zoneCamping.internalID === zoneFromURL,
          );
          if (
            campingInternalID &&
            state.campings[key].region &&
            filteredRegions.some((region) =>
              region.zone.some(
                (zone) =>
                  zone.internalID ===
                  parseInt(campingInternalID.internalID, 10),
              ),
            )
          ) {
            acc[key] = state.campings[key];
          }
        }
        return acc;
      }, {});

    const campingsToRequestRatePlans = await campingsByPreferredLocation();
    Object.keys(campingsToRequestRatePlans).forEach(async (campingId) => {
      try {
        if (
          !campingsToRequestRatePlans[campingId].isLoadingRatePlans &&
          !campingsToRequestRatePlans[campingId].ratePlansAlreadyFetched
        ) {
          dispatch({
            type: 'startedAskingForCampingRatePlans',
            payload: { campingId },
          });
          const { data: ratePlans } = await makeRequestToServerV3(
            `/properties/${campingId}/rooms/boards/plans?startDate=${moment(
              reservation.startDate,
            )?.format('YYYY-MM-DD')}&endDate=${moment(
              reservation.endDate,
            )?.format('YYYY-MM-DD')}&adults=${reservation[adults]}&children=${
              reservation[boys]
            }&${
              reservation.promoCode !== ''
                ? 'promoCode='.concat(reservation.promoCode)
                : 'includePromoCode=false'
            }&lang=${currentLanguage}`,
          );
          if (!ratePlans && reservation.promoCode !== '') {
            const { data: altenativeRatePlans } = await makeRequestToServerV3(
              `/properties/${campingId}/rooms/boards/plans?startDate=${moment(
                reservation.startDate,
              )?.format('YYYY-MM-DD')}&endDate=${moment(
                reservation.endDate,
              )?.format('YYYY-MM-DD')}&adults=${reservation[adults]}&children=${
                reservation[boys]
              }&${
                reservation.promoCode !== ''
                  ? 'promoCode='.concat(reservation.promoCode)
                  : 'includePromoCode=false'
              }&lang=${currentLanguage}`,
            );
            addRatePlansToCamping(campingId, altenativeRatePlans);
          } else {
            addRatePlansToCamping(campingId, ratePlans);
          }
        }
        if (reload){
          dispatch({
            type: 'startedAskingForCampingRatePlans',
            payload: { campingId },
          });
          const { data: ratePlans } = await makeRequestToServerV3(
            `/properties/${campingId}/rooms/boards/plans?startDate=${moment(
              reservation.startDate,
            ).format('YYYY-MM-DD')}&endDate=${moment(
              reservation.endDate,
            ).format('YYYY-MM-DD')}&adults=${reservation[adults]}&children=${
              reservation[boys]
            }&${
              reservation.promoCode !== ''
                ? 'promoCode='.concat(reservation.promoCode)
                : 'includePromoCode=false'
            }&lang=${currentLanguage}`,
          );
          if (!ratePlans && reservation.promoCode !== '') {
            const { data: altenativeRatePlans } = await makeRequestToServerV3(
              `/properties/${campingId}/rooms/boards/plans?startDate=${moment(
                reservation.startDate,
              ).format('YYYY-MM-DD')}&endDate=${moment(
                reservation.endDate,
              ).format('YYYY-MM-DD')}&adults=${reservation[adults]}&children=${
                reservation[boys]
              }&${
                reservation.promoCode !== ''
                  ? 'promoCode='.concat(reservation.promoCode)
                  : 'includePromoCode=false'
              }&lang=${currentLanguage}`,
            );
            addRatePlansToCamping(campingId, altenativeRatePlans);
          } else {
            addRatePlansToCamping(campingId, ratePlans);
          }
        }
      } catch (error) {
        addErrorToCamping(campingId);
      }
    });
    dispatch({ type: 'setRatePlansFetched', payload: true });
  };

  const askForOneCampingRatePlans = async (reservation, campingId) => {
    askingForCampingsRatePlans();
    try {
      const { data: ratePlans } = await makeRequestToServerV3(
        `/properties/${campingId}/rooms/boards/plans?startDate=${moment(
          reservation.startDate,
        ).format('YYYY-MM-DD')}&endDate=${moment(reservation.endDate).format(
          'YYYY-MM-DD',
        )}&adults=${reservation[adults]}&children=${reservation[boys]}&${
          reservation.promoCode !== ''
            ? 'promoCode='.concat(reservation.promoCode)
            : 'includePromoCode=false'
        }&lang=${currentLanguage}`,
      );
      if (!ratePlans && reservation.promoCode !== '') {
        const { data: altenativeRatePlans } = await makeRequestToServerV3(
          `/properties/${campingId}/rooms/boards/plans?startDate=${moment(
            reservation.startDate,
          ).format('YYYY-MM-DD')}&endDate=${moment(reservation.endDate).format(
            'YYYY-MM-DD',
          )}&adults=${reservation[adults]}&children=${
            reservation[boys]
          }&includePromoCode=false&lang=${currentLanguage}`,
        );
        addRatePlansToCamping(campingId, altenativeRatePlans);
      } else {
        addRatePlansToCamping(campingId, ratePlans);
      }
    } catch (error) {
      addErrorToCamping(campingId);
    }
  };

  return {
    state,
    tentState,
    dispatch,
    askForCampingsRatePlans,
    askForOneCampingRatePlans,
  };
};

export default useCamping;
