/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
const getIconsForEachFeature = (features, tentFeatures) => {
  Object.filter = (obj, predicate) => Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => (res[key] = obj[key], res), {});

  const featuresWithIcons = [];
  tentFeatures.map((tentFeature) => (
    featuresWithIcons.push(Object.values(
      Object.values(Object.filter(features, (itm) => itm.id === tentFeature.id)),
    ))
  ));

  return featuresWithIcons;
};

export default getIconsForEachFeature;
