import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import makeRequestToApServer from '../../../../../services/ApServer/askToApServer';
import { allowedMethods } from '../../../../../constants/ApServer/apServer';
import routes from '../../../../../constants/ApServer/apRoutes';
import openNotification from '../../../../../components/NotificacionMessage';
import {
  form,
  formRow,
  error,
  formActions,
  saveButton,
} from './index.module.scss';

const NameForm = ({ initialValues, onSave }) => {
  const intl = useIntl();
  const formik = useFormik({
    initialValues: {
      name: initialValues.name || '',
      surname: initialValues.surname || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'NameForm.Validation.Name.Required',
          }),
        ),
      surname: Yup.string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'NameForm.Validation.Surname.Required',
          }),
        ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      makeRequestToApServer(
        allowedMethods.put,
        routes.authMe,
        values,
        ({ statusCode }) => {
          if (statusCode === 200)
            openNotification(
              'success',
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Title.Text',
              }),
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Message.Text',
              }),
              () => {
                onSave(values);
              },
              null,
            );
        },
        (err) => {
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      ).finally(() => {
        setSubmitting(false);
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={form}>
        <div className={formRow}>
          <span>
            {intl.formatMessage({
              id: 'NameForm.Validation.Name.Text',
            })}
          </span>
          <input
            id="name"
            name="name"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className={error}>{formik.errors.name}</div>
          ) : null}
        </div>

        <div className={formRow}>
          <span>
            {intl.formatMessage({
              id: 'NameForm.Validation.Surname.Text',
            })}
          </span>
          <input
            id="surname"
            name="surname"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.surname}
          />
          {formik.touched.surname && formik.errors.surname ? (
            <div className={error}>{formik.errors.surname}</div>
          ) : null}
        </div>
      </div>

      <div className={formActions}>
        <button
          type="submit"
          className={saveButton}
          disabled={formik.isSubmitting}
        >
          {intl.formatMessage({
            id: 'NameForm.Button.Text',
          })}
        </button>
      </div>
    </form>
  );
};

NameForm.propTypes = {
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default NameForm;
