/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useReservationDispatch } from '../../../../../../../../../services/Reservation';
import {
  cardImage,
  downCenterPositionContainer,
  roomRatePlanImage,
} from './index.module.scss';

import useCampings from '../../../../../../../../../services/Campings/useCampings';
import CustomCarousel from '../../../../../../../../../components/CustomCarousel';
import { URL } from '../../../../../../../../../constants/cms';
import decideImageSize from '../../../../../../../../../utils/decideImageSize';

const CardImage = ({
  propertyID, isRoomRatePlanImage, type, onClick,
}) => {
  const dispatchReservation = useReservationDispatch();
  const { state: { campings: { [propertyID]: camping } } } = useCampings();
  useEffect(() => {
    dispatchReservation({ type: 'setKampaohService', payload: type });
  }, [dispatchReservation, type]);

  const [decideImageSizeState, setDecideImageSizeState] = useState(decideImageSize());
  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');
    const handleOrientationChange = () => {
      setDecideImageSizeState(decideImageSize());
    };
    // Registry a Listener to detect changes in the orientation
    mediaQuery.addListener(handleOrientationChange);
    // Clean the listener when the component is umounted
    return () => {
      mediaQuery.removeListener(handleOrientationChange);
    };
  }, []);
  return (
    <div
      className={isRoomRatePlanImage ? roomRatePlanImage : cardImage}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <div className={downCenterPositionContainer}>
        {camping && (
        <CustomCarousel
          photos={camping.photos
            ? camping.photos.map((img) => (img.formats[decideImageSizeState] !== undefined
              ? img.formats[decideImageSizeState].url.includes('https')
                ? img.formats[decideImageSizeState].url
                : (`${URL}/uploads/${decideImageSizeState}_${img.hash}${img.ext}`)
              : (`${URL}${img.url}`)
            )) : []}
          photosPreview={camping.photos
            ? camping.photos.map((img) => (img.formats.large !== undefined
              ? img.formats.large.url.includes('https')
                ? img.formats.large.url
                : (`${URL}/uploads/large_${img.hash}${img.ext}`)
              : (`${URL}${img.url}`)
            )) : []}
        />
        )}
      </div>
    </div>
  );
};

CardImage.propTypes = {
  type: PropTypes.string,
  propertyID: PropTypes.string,
  isRoomRatePlanImage: PropTypes.bool,
  onClick: PropTypes.func,
};

CardImage.defaultProps = {
  type: '',
  propertyID: '',
  isRoomRatePlanImage: false,
  onClick: () => {},
};

export default CardImage;
