import React, { useContext, useEffect, useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { navigate } from '@reach/router';
import { useReservationState } from '../../../../services/Reservation';
import { useAuthState } from '../../../../services/Auth/useAuth';
import {
  wrapper,
  location,
  resumeTable,
  total,
  taxesInfo,
  specialConditions,
  priceWithoutDiscount,
  priceContainer,
  tribeContainer,
  tribeRow,
} from './index.module.scss';
import { ReactComponent as LocationIcon } from '../../../../assets/icons/location3.svg';
import useCampings from '../../../../services/Campings/useCampings';
import Services from '../../../../constants/services';
import UnitResumeTable from '../../../../components/UnitResumeTable';
import Conditions from './components/Conditions';
import { getCampCharacteristics, conditionsData } from './data';
import Characteristics from './components/Characteristics';
import { CheckoutContext } from '../..';
import ROUTES from '../../../../routes/routes';
import STEP from '../steps';
import TribeTag from '../../../../components/TribeTag';
import LoginBanner from '../../../../components/LoginBanner';

// Moment configuration
require('moment/locale/es');

const OrderResume = () => {
  const { setCurrentStep, filteredRatePlans, tent } =
    useContext(CheckoutContext);
  setCurrentStep(STEP.resume);

  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const propertyFromURL = searchParams.get('property');
  const roomFromURL = searchParams.get('room');

  const reservation = useReservationState();
  const { selectedHotelId, kampaohService } = useReservationState();
  const { accessToken } = useAuthState();
  const {
    state: {
      campings: { [selectedHotelId || propertyFromURL]: camping },
    },
  } = useCampings();

  const isACamping = Services.CAMP;
  const property = camping;
  const planInfo = filteredRatePlans && filteredRatePlans[0]?.plans[0];

  const Icon = () => <LocationIcon />;
  const tentName = tent?.tent_model?.name;

  const tribePromotion = useMemo(
    () =>
      reservation?.promotionInfo?.tagList?.length > 0 ||
      planInfo?.promotion?.tagList?.length > 0,
    [reservation, planInfo],
  );

  const getDiscountedPrice = useMemo(() => {
    let discountedPrice = reservation?.totalPrice || planInfo?.totalPrice;

    if (
      reservation?.promotionInfo &&
      reservation?.promotionInfo?.percentDiscount > 0
    ) {
      discountedPrice /= 1 - reservation?.promotionInfo?.percentDiscount / 100;
    } else if (planInfo?.promotion?.percentDiscount) {
      discountedPrice /= 1 - planInfo?.promotion?.percentDiscount / 100;
    }
    if (
      reservation?.promotionInfo &&
      reservation?.promotionInfo?.amountDiscount > 0
    ) {
      discountedPrice += reservation?.promotionInfo?.amountDiscount;
    } else if (planInfo?.promotion?.amountDiscount) {
      discountedPrice += planInfo?.promotion?.amountDiscount;
    }

    return discountedPrice;
  }, [reservation, planInfo]);

  const tagName = useMemo(() => {
    const tagList =
      reservation?.promotionInfo?.tagList || planInfo?.promotion?.tagList;
    if (tagList && tagList.length > 0) {
      return tagList[0]?.name;
    }
    return undefined;
  }, [reservation, planInfo]);

  useEffect(() => {
    if(filteredRatePlans?.length < 1){
       navigate(`${ROUTES?.roomDetails}/${propertyFromURL}/${roomFromURL}/?checkin=${reservation?.startDate?.format('YYYY-MM-DD')}&checkout=${reservation?.endDate?.format('YYYY-MM-DD')}&adults=${reservation?.adults}&children=${reservation?.children}&babies=${reservation?.babies}`);
    }
  }, [filteredRatePlans, roomFromURL, propertyFromURL, reservation])

  return (
    <div className={wrapper}>
      <div className={location}>
        <Icon />
        <div>
          <p>{property?.propertyName}</p>
          <p>{`${property?.location}`}</p>
        </div>
      </div>
      <div className={resumeTable}>
        <UnitResumeTable
          data={reservation}
          type={kampaohService}
          destinationInfo={
            !isACamping
              ? {
                  arrival: `${property.location}, ${property.placeName}`,
                  departure: `${property.location}, ${property.placeName}`,
                }
              : undefined
          }
        />
      </div>
      <Characteristics data={getCampCharacteristics(tentName, property)} />
      {accessToken === null && <LoginBanner />}
      <div className={total}>
        {/* eslint-disable react/style-prop-object */}
        <span>Total</span>
        <div className={tribeContainer}>
          {tribePromotion && (
            <div className={tribeRow}>
              {planInfo && planInfo?.rateName}
              <TribeTag tagName={tagName} />
            </div>
          )}
          <div className={priceContainer}>
            {tribePromotion && (
              <span className={priceWithoutDiscount}>
                <FormattedNumber
                  value={getDiscountedPrice}
                  style="currency"
                  currency="EUR"
                />
              </span>
            )}
            <span>
              <FormattedNumber
                value={reservation?.totalPrice || planInfo?.totalPrice}
                style="currency"
                currency="EUR"
              />
            </span>
          </div>
        </div>

        {/* eslint-enable react/style-prop-object */}
      </div>
      <div className={taxesInfo}>
        {property.ModalidadAgencia ? (
          <FormattedMessage id="Resume.taxesInfo.AgencyMode.taxesIncluded" />
        ) : (
          <FormattedMessage
            id="Resume.taxesInfo.taxesIncluded"
            values={{ tax: 10 }}
          />
        )}
      </div>
      <div className={specialConditions}>
        <h2>
          <FormattedMessage id="Resume.specialConditions" />
        </h2>
        <Conditions data={conditionsData(reservation, planInfo)} />
      </div>
    </div>
  );
};

OrderResume.defaultProps = {};

OrderResume.propTypes = {};

export default OrderResume;
