import { Col, Input, InputNumber, Row, Slider, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDebouncedCallback, useThrottledCallback } from 'use-debounce';
import {
  useFilterDispatch,
  useFilterState,
} from '../../../../../../services/Filter';
import { useReservationState } from '../../../../../../services/Reservation';
import daysBetweenCheckInAndCheckOut from '../../../../../../utils/daysBetweenCheckInAndCheckOut';
import '../../sliderStyle.css';
import { filterPriceContainer } from './index.module.scss';
import updateQueryParams from './utils';

const PricesFilter = ({ onClick, filteredRatePlans }) => {
  const intl = useIntl();
  const { pricesToFilter } = useFilterState();
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const { startDate, endDate } = useReservationState();
  const [minTotalRate, setMinTotalRate] = useState(0);
  const [maxTotalRate, setMaxTotalRate] = useState(0);

  /* eslint-disable */
  useEffect(() => {
    const totalRates = [];

    Object.entries(filteredRatePlans).forEach(([key, plans]) => {
      plans.forEach((plan) => {
        if (Object.prototype.hasOwnProperty.call(plan, 'totalRate')) {
          totalRates.push(plan.totalRate);
        }
      });
    });

    const daysBetween = daysBetweenCheckInAndCheckOut({ startDate, endDate });
    const MIN_TOTALRATE =
      totalRates.length > 0
        ? Math.floor(Math.min(...totalRates) / daysBetween)
        : 0;

    const MAX_TOTALRATE =
      totalRates.length > 0
        ? Math.floor(Math.max(...totalRates) / daysBetween)
        : 0;

    setMinPrice(pricesToFilter[0] ?? MIN_TOTALRATE);
    setMaxPrice(pricesToFilter[1] ?? MAX_TOTALRATE);
    setMinTotalRate(MIN_TOTALRATE);
    setMaxTotalRate(MAX_TOTALRATE);
  }, []);
  /* eslint-enable */

  const filterDispatch = useFilterDispatch();

  const updateFilters = useDebouncedCallback((prices) => {
    filterDispatch({ type: "addPricesToFilter", payload: prices });
    updateQueryParams(prices[0], prices[1]);
    onClick();
  }, 400);

  const handlePriceChange = useThrottledCallback((prices) => {
    if (
      prices[0] < prices[1] &&
      prices[1] <= maxTotalRate &&
      prices[0] >= minTotalRate
    ) {
      setMinPrice(prices[0]);
      setMaxPrice(prices[1]);
      updateFilters(prices);
    }
  }, 50);

  const formatValue = useCallback(
    (value) => {
      const formattedValue = intl.formatNumber(value, {
        style: 'currency',
        currency: 'EUR',
      });
      return formattedValue.replace(',', '.');
    },
    [intl],
  );

  return (
    <div className={filterPriceContainer}>
      <Input.Group size="default">
        <Row gutter={12}>
          <Col span={12}>
            <Typography.Text>
              <FormattedMessage id="Kampaoh.MinPrice" defaultMessage="FILTRO" />
            </Typography.Text>
            <InputNumber
              formatter={(value) => formatValue(value)}
              value={minPrice}
              onChange={(value) => handlePriceChange([value, maxPrice])}
              min={minTotalRate}
              max={maxPrice - 1}
            />
          </Col>
          <Col span={12}>
            <Typography.Text>
              <FormattedMessage id="Kampaoh.MaxPrice" defaultMessage="FILTRO" />
            </Typography.Text>

            <InputNumber
              formatter={(value) => formatValue(value)}
              value={maxPrice}
              onChange={(value) => handlePriceChange([minPrice, value])}
              max={maxTotalRate}
              min={minPrice + 1}
            />
          </Col>
        </Row>
      </Input.Group>
      <Slider
        value={[minPrice, maxPrice]}
        range
        defaultValue={[minPrice, maxPrice]}
        min={minTotalRate}
        max={maxTotalRate}
        tooltipVisible={false}
        disabled={false}
        onChange={(prices) => handlePriceChange(prices)}
      />
    </div>
  );
};

PricesFilter.propTypes = {
  onClick: PropTypes.func,
  filteredRatePlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

PricesFilter.defaultProps = {
  onClick: () => {},
};
export default PricesFilter;
