import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useCountries } from '../../../../../utils/useCountries';
import makeRequestToApServer from '../../../../../services/ApServer/askToApServer';
import { allowedMethods } from '../../../../../constants/ApServer/apServer';
import routes from '../../../../../constants/ApServer/apRoutes';
import openNotification from '../../../../../components/NotificacionMessage';
import {
  form,
  formRow,
  error,
  formActions,
  saveButton,
} from './index.module.scss';

const NationalityForm = ({ initialValues, onSave }) => {
  const intl = useIntl();
  const countriesArray = useCountries();

  const formik = useFormik({
    initialValues: {
      nationality: initialValues.nationality || '',
    },
    validationSchema: Yup.object({
      nationality: Yup.string().required(
        intl.formatMessage({
          id: 'NationalityForm.Validation.Nationality.Required',
        }),
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      makeRequestToApServer(
        allowedMethods.put,
        routes.authMe,
        values,
        ({ statusCode }) => {
          if (statusCode === 200)
            openNotification(
              'success',
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Title.Text',
              }),
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Message.Text',
              }),
              () => {
                onSave(values);
              },
              null,
            );
        },
        (err) => {
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      ).finally(() => {
        setSubmitting(false);
      });
    },
  });

  const sortedCountriesList = useMemo(
    () =>
      countriesArray
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((country) => (
          <option
            key={country.value}
            value={country.value}
            label={country.label}
          >
            {country.label}
          </option>
        )),
    [countriesArray],
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={form}>
        <div className={formRow}>
          <span>
            {intl.formatMessage({
              id: 'NationalityForm.Validation.Nationality.Text',
            })}
          </span>
          <select
            id="nationality"
            name="nationality"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nationality}
          >
            <option
              value=""
              label={intl.formatMessage({
                id: 'NationalityForm.Validation.Nationality.Default',
              })}
            >
              {intl.formatMessage({
                id: 'NationalityForm.Validation.Nationality.Default',
              })}
            </option>
            {sortedCountriesList}
          </select>
          {formik.touched.nationality && formik.errors.nationality ? (
            <div className={error}>{formik.errors.nationality}</div>
          ) : null}
        </div>
      </div>

      <div className={formActions}>
        <button
          type="submit"
          className={saveButton}
          disabled={formik.isSubmitting}
        >
          {intl.formatMessage({
            id: 'NationalityForm.Button.Text',
          })}
        </button>
      </div>
    </form>
  );
};

NationalityForm.propTypes = {
  initialValues: PropTypes.shape({
    nationality: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default NationalityForm;
