/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Layout } from 'antd';
import { header } from './index.module.scss';
import GoingBackButton from '../../components/GoingBackButton';
import Footer from '../../components/Footer';
import { useTopBannerState } from '../../services/TopBanner/Provider';
import { ShowRoomsProvider } from '../../services/ShowRooms';
import SearchProvider from '../../contexts/SearchContext';
import CampingPlacesProvider from '../../services/Campings/DataProvider';
import TentPlacesProvider from '../../services/Campings/TentDataProvider';
import CampingFeaturesProvider from '../../services/CampingFeatures/Provider';

function WithGoingBackButton(Component) {
  return function WrappedComponent(props) {
    const { status } = useTopBannerState();
    return (
      <CampingPlacesProvider>
        <CampingFeaturesProvider>
          <TentPlacesProvider>
            <ShowRoomsProvider>
              <SearchProvider>
                <Layout>
                  <Layout.Header className={header}>
                    <GoingBackButton status={status} fromHeader />
                  </Layout.Header>
                  <Component {...props} />
                  <Footer />
                </Layout>
              </SearchProvider>
            </ShowRoomsProvider>
          </TentPlacesProvider>
        </CampingFeaturesProvider>
      </CampingPlacesProvider>
    );
  };
}

WithGoingBackButton.propTypes = {

};

WithGoingBackButton.defaultProps = {

};
export default WithGoingBackButton;
