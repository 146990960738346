import React from 'react';
import { useIntl } from 'react-intl';
import { Layout } from 'antd';
import {
  navBarHome,
  logoHomeStyle,
  textStyle,
} from './index.module.scss';
import { ReactComponent as KampaohIcon } from '../../../assets/icons/kampaoh.svg';

const HomeHeader = () => {
  const { Header } = Layout;
  const intl = useIntl();
  return (
    <Header
      className={navBarHome}
    >
      <KampaohIcon className={logoHomeStyle} />
      <div className={textStyle}>
        <p>
          {intl.formatMessage({ id: 'Kampaoh.Home.Header.Text' })}
        </p>
      </div>
    </Header>
  );
};

HomeHeader.defaultProps = {
};

HomeHeader.propTypes = {
};

export default HomeHeader;
