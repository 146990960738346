/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Layout } from 'antd';
import Footer from '../../components/Footer';
import CampingPlacesProvider from '../../services/Campings/DataProvider';
import TentPlacesProvider from '../../services/Campings/TentDataProvider';
import ConditionsProvider from '../../services/Conditions';

const BasicLayout =
  (Component) =>
  ({ ...props }) =>
    (
      <CampingPlacesProvider>
        <TentPlacesProvider>
          <ConditionsProvider>
            <Layout>
              <Component {...props} />
              <Footer />
            </Layout>
          </ConditionsProvider>
        </TentPlacesProvider>
      </CampingPlacesProvider>
    );

BasicLayout.propTypes = {};

BasicLayout.defaultProps = {};
export default BasicLayout;
