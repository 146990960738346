/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
import { Redirect } from '@reach/router';
import { Popover, Spin } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as AreaIcon } from '../../../assets/icons/area.svg';
import { ReactComponent as CapabilityIcon } from '../../../assets/icons/capability.svg';
import { ReactComponent as HeightIcon } from '../../../assets/icons/height.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { ReactComponent as TentIcon } from '../../../assets/icons/tent.svg';
import CustomCarousel from '../../../components/CustomCarousel';
import YoutubeEmbed from '../../../components/YoutubeVideo';
import { URL } from '../../../constants/cms';
import { LanguageContext } from '../../../locale/contexts/Language';
import useCampings from '../../../services/Campings/useCampings';
import {
  useReservationDispatch,
  useReservationState,
} from '../../../services/Reservation';
import {
  body,
  campingDescriptionWrapper,
  campingFeaturesWrapper,
  camps,
  carouselWrapper,
  characteristic,
  characteristicsWrapper,
  descriptionWrapper,
  divider,
  featuresWrapper,
  icon,
  infoIcon,
  innerCharacteristic,
  locationText,
  mapWrapper,
  otherSuggestedProperties,
  placesPage,
  reservationInfo,
  scoreWrapper,
  SpinnerContainerStyle,
  title,
  titleWrapper,
  tooltip,
  videoWrapper,
  wrapper,
} from '../index.module.scss';
// import ShareButton from '../../../components/ShareButton';
import { ReactComponent as LocateIcon } from '../../../assets/icons/location.svg';
import FeaturesListWithEllipsis from '../../../components/FeaturesListWithEllipsis';
import HighDemandWarning from '../../../components/HighDemandWarning/index';
import MarkdownWithEllipsis from '../../../components/MarkdownWithEllipsis';
import PetsBanner from '../../../components/PetsBanner';
import ResponsiveMenu from '../../../components/ResponsiveMenu/ResponsiveMenu';
import TopBanner from '../../../components/TopBanner';
import routes from '../../../constants/ApServer/apRoutes';
import Services from '../../../constants/services';
import { makeRequestToServerV3 } from '../../../services/API';
import { useAuthState } from '../../../services/Auth/useAuth';
import {
  endDateGraterThanStartDate,
  startDateAndEndDateAreValid,
} from '../../../services/checkValidURLParams';
import { useTopBannerState } from '../../../services/TopBanner/Provider';
import calcDaysBetweenCheckInAndCheckOut from '../../../utils/daysBetweenCheckInAndCheckOut';
import decideImageSize from '../../../utils/decideImageSize';
import getIconsForEachFeature from '../../../utils/getIconsForEachFeature';
import useRefreshToken from '../../../utils/useRefreshToken';
import CampingCard from '../../Places/Camp/components/Cards/components/Card';
import CampingRating from '../shared/CampingRating';
import SimpleMap from '../shared/Map';
import ReservationInfo from '../shared/ReservationInfo';
import UnitSelector from '../shared/UnitSelector';
import ActionBar from './components/ActionBar';
import data from './data';

const Divider = () => <div className={divider} />;

const RoomDetails = ({ roomId, propertyID }) => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const startDateFromURL = searchParams.get('checkin');
  const endDateFromURL = searchParams.get('checkout');
  const adultsFromURL = parseInt(searchParams.get('adults'), 10);
  const childrenFromURL = parseInt(searchParams.get('children'), 10);
  const babiesFromURL = parseInt(searchParams.get('babies'), 10);
  const fpaFromUrl = searchParams.get('fpa');
  const rateFromURL = parseInt(searchParams.get('rate'), 10);
  const boardFromURL = parseInt(searchParams.get('board'), 10);
  const offerFromURL = parseInt(searchParams.get('offer'), 10);
  const promotionFromURL = parseInt(searchParams.get('promotion'), 10);
  const langFromUrl = searchParams.get('lang');
  const [fpaFilter, setFpaFilter] = useState('none');
  const [selectedRate, setSelectedRate] = useState();
  const [catchRate, setCatchRate] = useState(false);
  const [dataLayerSent, setDataLayerSent] = useState(false);
  const tokensExpired = useRefreshToken();

  const formattedStartDateFromURL = startDateFromURL
    ? moment(startDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(
        'YYYY-MM-DD',
      )
    : null;
  const formattedEndDateFromURL = endDateFromURL
    ? moment(endDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD')
    : null;

  const url = new window.URL(window.location);

  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };

  const updateQueryParams = useCallback(
    (key, value) => {
      if (value || typeof value === 'number') {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }

      const urlSearchParams = searchParams.toString();
      const currentParams = new URLSearchParams(url.search).toString();
      if (currentParams !== urlSearchParams) {
        updateUrlWithoutNavigation(
          `${url.pathname}?${searchParams.toString()}`,
        );
      }
    },
    [searchParams, url],
  );

  const {
    state: {
      tentFeatures,
      campings: { [propertyID]: camping },
      isLoadingRatePlans,
      isLoadingCampings,
    },
    tentState: {
      tents: { [roomId]: tent },
    },
  } = useCampings();

  const property = camping;
  const { currentLanguage } = useContext(LanguageContext);
  const reservation = useReservationState();
  const dispatch = useReservationDispatch();
  const {
    endDate: endDateState,
    startDate: startDateState,
    kampaohService,
    adults,
    children,
  } = useReservationState();
  const isACamping = kampaohService === Services.CAMP;
  const { status } = useTopBannerState();
  const { accessToken } = useAuthState();

  const [filteredRatePlans, setFilteredRatePlans] = useState();

  const intl = useIntl();
  const mapRef = useRef();

  const { location, score, reviews, nature, party, relax, family, region } =
    camping || {};

  const defaultAdults = 2;
  const defaultChildren = 0;
  const defaultBabies = 0;
  const isZeroAdults = 0;
  const isZeroChildren = 0;
  const isZeroBabies = 0;
  const petsAllowed = tent?.pets;
  const isThereScoreInfo = () =>
    reviews && score && nature && party && relax && family;

  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
    endDate: moment.utc(formattedEndDateFromURL),
    startDate: moment.utc(formattedStartDateFromURL),
  });

  useEffect(() => {
    // If dates are valid and endDate is not greater than startDate
    if (
      startDateAndEndDateAreValid(
        formattedStartDateFromURL,
        formattedEndDateFromURL,
      ) &&
      !endDateGraterThanStartDate(
        formattedStartDateFromURL,
        formattedEndDateFromURL,
      )
    ) {
      dispatch({
        type: 'setStartDate',
        payload: moment(formattedStartDateFromURL),
      });
      dispatch({
        type: 'setEndDate',
        payload: moment(formattedStartDateFromURL).add(2, 'days'),
      });
    }
    if (formattedStartDateFromURL)
      dispatch({
        type: 'setStartDate',
        payload: moment(formattedStartDateFromURL),
      });
    if (formattedEndDateFromURL)
      dispatch({
        type: 'setEndDate',
        payload: moment(formattedEndDateFromURL),
      });
    if (formattedStartDateFromURL && !formattedEndDateFromURL)
      dispatch({
        type: 'setEndDate',
        payload: moment(formattedStartDateFromURL).add(2, 'days'),
      });
    if (!formattedStartDateFromURL && formattedEndDateFromURL)
      dispatch({
        type: 'setStartDate',
        payload: moment(formattedEndDateFromURL).add(-2, 'days'),
      });
    if (
      !Number.isNaN(adultsFromURL) &&
      !Number.isNaN(childrenFromURL) &&
      !Number.isNaN(babiesFromURL)
    ) {
      dispatch({ type: 'setadults', payload: parseInt(adultsFromURL, 10) });
      dispatch({ type: 'setchildren', payload: parseInt(childrenFromURL, 10) });
      dispatch({ type: 'setbabies', payload: parseInt(babiesFromURL, 10) });
      dispatch({ type: 'setItAsReadyToBeUsed' });
    }
  }, [
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
    formattedStartDateFromURL,
    formattedEndDateFromURL,
    dispatch,
  ]);

  const [isAccesTokenNull, setIsAccesTokenNull] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    async function askForOnePropertyRatePlan() {
      let startDateParam = formattedStartDateFromURL;
      let endDateParam = formattedEndDateFromURL;
      // If dates are valid and endDate is not greater than startDate
      if (
        startDateAndEndDateAreValid(startDateParam, endDateParam) &&
        !endDateGraterThanStartDate(startDateParam, endDateParam)
      ) {
        startDateParam = formattedStartDateFromURL;
        endDateParam = moment(formattedStartDateFromURL).add(2, 'days');
      }
      const { data: dateRatePlan } = await makeRequestToServerV3(
        `/properties/${propertyID}/rooms/${roomId}/boards/plans?startDate=${moment(
          startDateParam || startDateState,
        ).format('YYYY-MM-DD')}&endDate=${moment(
          endDateParam || endDateState,
        ).format('YYYY-MM-DD')}&adults=${adultsFromURL || adults}&children=${
          childrenFromURL || children
        }&lang=${currentLanguage}`,
      );
      if (!dateRatePlan && reservation.promoCode !== '') {
        const { data: altenativeRatePlans } = await makeRequestToServerV3(
          `/properties/${propertyID}/rooms/${roomId}/boards/plans?startDate=${moment(
            startDateParam || startDateState,
          ).format('YYYY-MM-DD')}&endDate=${moment(
            endDateParam || endDateState,
          ).format('YYYY-MM-DD')}&adults=${adultsFromURL || adults}&children=${
            childrenFromURL || children
          }&lang=${currentLanguage}`,
        );
        return setFilteredRatePlans(altenativeRatePlans);
      }
      const dataRate = dateRatePlan.map((ratePlan) => ({
        code: ratePlan.code,
        id: ratePlan.id,
        name: ratePlan.name,
        plans: ratePlan.plans.filter((plan) => plan.available),
      }));
      return setFilteredRatePlans(dataRate);
    }
    askForOnePropertyRatePlan();
    setIsAccesTokenNull(accessToken === null);
    setIsLoaded(true);
    // eslint-disable-next-line
  }, [
    formattedEndDateFromURL,
    formattedStartDateFromURL,
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
    currentLanguage,
    propertyID,
    adults,
    children,
    endDateState,
    roomId,
    startDateState,
    reservation.promoCode,
  ]);

  const askForOnePropertyRatePlanReloaded = useCallback(async () => {
    let startDateParam = formattedStartDateFromURL;
    let endDateParam = formattedEndDateFromURL;

    if (
      startDateAndEndDateAreValid(startDateParam, endDateParam) &&
      !endDateGraterThanStartDate(startDateParam, endDateParam)
    ) {
      startDateParam = formattedStartDateFromURL;
      endDateParam = moment(formattedStartDateFromURL).add(2, 'days');
    }

    const { data: dateRatePlan } = await makeRequestToServerV3(
      `/properties/${propertyID}/rooms/${roomId}/boards/plans?startDate=${moment(
        startDateParam || startDateState,
      )?.format('YYYY-MM-DD')}&endDate=${moment(
        endDateParam || endDateState,
      )?.format('YYYY-MM-DD')}&adults=${adultsFromURL || adults}&children=${
        childrenFromURL || children
      }&lang=${currentLanguage}`,
    );

    if (!dateRatePlan && reservation?.promoCode !== '') {
      const { data: altenativeRatePlans } = await makeRequestToServerV3(
        `/properties/${propertyID}/rooms/${roomId}/boards/plans?startDate=${moment(
          startDateParam || startDateState,
        )?.format('YYYY-MM-DD')}&endDate=${moment(
          endDateParam || endDateState,
        )?.format('YYYY-MM-DD')}&adults=${adultsFromURL || adults}&children=${
          childrenFromURL || children
        }&lang=${currentLanguage}`,
      );
      return setFilteredRatePlans(altenativeRatePlans);
    }

    const dataRate = dateRatePlan?.map((ratePlan) => ({
      code: ratePlan?.code,
      id: ratePlan?.id,
      name: ratePlan?.name,
      plans: ratePlan?.plans?.filter((plan) => plan?.available),
    }));

    return setFilteredRatePlans(dataRate);
  }, [
    formattedStartDateFromURL,
    formattedEndDateFromURL,
    propertyID,
    roomId,
    startDateState,
    endDateState,
    adultsFromURL,
    adults,
    childrenFromURL,
    children,
    currentLanguage,
    reservation,
  ]);

  useEffect(() => {
    if (isLoaded) {
      if (accessToken !== null && isAccesTokenNull === true) {
        askForOnePropertyRatePlanReloaded();
        setIsAccesTokenNull(accessToken === null);
      }
      if (accessToken === null && isAccesTokenNull === false) {
        askForOnePropertyRatePlanReloaded();
        setIsAccesTokenNull(accessToken === null);
      }
    }
    // eslint-disable-next-line
  }, [accessToken, askForOnePropertyRatePlanReloaded]);

  const enableHighDemandWarning = useMemo(() => {
    const { readyToBeUsed } = reservation;
    if (!readyToBeUsed) return true;

    if (tent && filteredRatePlans && filteredRatePlans.length) {
      // TO-DO: Review this. RoomTypeID is not unique, so it could exist ratePlans
      // with the same roomTypeID
      const { roomsAvailable } =
        filteredRatePlans.find(
          (ratePlan) => `${ratePlan.roomTypeID}` === roomId,
        ) || {};
      let { roomsAvailableWarning } = tent;
      roomsAvailableWarning = roomsAvailableWarning ?? 5;
      return roomsAvailable < roomsAvailableWarning;
    }
    return false;
  }, [filteredRatePlans, roomId, tent, reservation]);
  const enablePetsBanner = useMemo(() => {
    if (tent && tent.pets) {
      return true;
    }
    return false;
  }, [tent]);

  const enablePetsNotAvailableBanner = useMemo(() => {
    if (
      camping &&
      camping.tents &&
      camping.tents.filter((elm) => elm.pets).length > 0 &&
      tent &&
      !tent.pets
    ) {
      return true;
    }

    return false;
  }, [tent, camping]);
  // const handleOnWhatsappClick = () => {
  //   window.open(`https://api.whatsapp.com/send?text=He encontrado esta preciosa ❤ tienda ${tent.tent_model.name} 🏕 en ${tent.camping.propertyName}%0ATiene capacidad para ${tent.tent_model.maxGuests} y está completamente equipada%0AÉchale un ojo en ${encodeURIComponent(window.location.href)}`);
  // };

  const petsFeatures = {
    featureWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'flex-start',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    icon: {
      height: '40px',
      marginRight: '5px',
      maxWidth: '40px',
    },
  };
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // eslint-disable-next-line no-useless-escape
  const dateRegex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
  const dateRegexDateFormat =
    /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/;

  useEffect(() => {
    if (filteredRatePlans) {
      if (
        selectedRate?.rateId !== null &&
        selectedRate?.rateId &&
        selectedRate?.rateId !== rateFromURL
      ) {
        updateQueryParams('rate', selectedRate?.rateId);
      }
      if (
        selectedRate?.offer !== null &&
        selectedRate?.offer &&
        selectedRate?.offer !== offerFromURL
      ) {
        updateQueryParams('offer', selectedRate?.offer?.id);
      }
      if (
        selectedRate?.promotion !== null &&
        selectedRate?.promotion &&
        selectedRate?.promotion !== promotionFromURL
      ) {
        updateQueryParams('promotion', selectedRate?.promotion?.id);
      }
      if (
        selectedRate?.boardId !== null &&
        selectedRate?.boardId &&
        selectedRate?.boardId !== boardFromURL
      ) {
        updateQueryParams('board', selectedRate?.boardId);
      }
      if (
        !startDateFromURL ||
        (!dateRegex.test(endDateFromURL) &&
          !dateRegexDateFormat.test(endDateFromURL))
      ) {
        updateQueryParams(
          'checkin',
          reservation.startDate.format('YYYY-MM-DD'),
        );
      }
      if (
        !endDateFromURL ||
        (!dateRegex.test(endDateFromURL) &&
          !dateRegexDateFormat.test(endDateFromURL))
      ) {
        updateQueryParams('checkout', reservation.endDate.format('YYYY-MM-DD'));
      }
      if (!adultsFromURL || parseInt(adultsFromURL, 10) < isZeroAdults) {
        updateQueryParams('adults', defaultAdults);
      }
      if (!childrenFromURL || parseInt(childrenFromURL, 10) < isZeroChildren) {
        updateQueryParams('children', defaultChildren);
      }
      if (!babiesFromURL || parseInt(babiesFromURL, 10) < isZeroBabies) {
        updateQueryParams('babies', defaultBabies);
      }

      if (fpaFromUrl === 'pets' && petsAllowed === true) {
        updateQueryParams('fpa', 'pets');
      } else {
        if (fpaFromUrl === 'allergy' && petsAllowed === false) {
          updateQueryParams('fpa', 'allergy');
        } else if (fpaFromUrl === 'pets' && petsAllowed === true) {
          updateQueryParams('fpa', 'pets');
        }
        if (
          (fpaFromUrl === 'none' || fpaFromUrl === null) &&
          (petsAllowed === false || petsAllowed === true)
        ) {
          updateQueryParams('fpa', 'none');
        }
      }
      if (isFirstLoad === false) {
        if (catchRate === true) {
          updateQueryParams('rate', selectedRate?.rateId);
          updateQueryParams('offer', selectedRate?.offer?.id);
          updateQueryParams('promotion', selectedRate?.promotion?.id);
          updateQueryParams('board', selectedRate?.boardId);
        } else {
          updateUrlWithoutNavigation(
            `${url.pathname}?${searchParams.toString()}`,
          );
          setCatchRate(true);
        }
      } else {
        updateUrlWithoutNavigation(
          `${url.pathname}?${searchParams.toString()}`,
        );
      }
    }
  }, [
    selectedRate,
    searchParams,
    isFirstLoad,
    catchRate,
    filteredRatePlans,
    url.pathname,
    url.search,
    startDateFromURL,
    dateRegexDateFormat,
    dateRegex,
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
    endDateFromURL,
    reservation,
    updateQueryParams,
    boardFromURL,
    offerFromURL,
    promotionFromURL,
    rateFromURL,
    fpaFromUrl,
    petsAllowed,
  ]);

  useEffect(() => {
    if (
      !startDateFromURL ||
      (!dateRegex.test(startDateFromURL) &&
        !dateRegexDateFormat.test(startDateFromURL))
    ) {
      searchParams.set('checkin', moment().format('YYYY-MM-DD'));
    }
    if (
      !endDateFromURL ||
      (!dateRegex.test(endDateFromURL) &&
        !dateRegexDateFormat.test(endDateFromURL))
    ) {
      searchParams.set(
        'checkout',
        moment(startDateFromURL).add(2, 'days').format('YYYY-MM-DD'),
      );
    }
    if (!adultsFromURL || !parseInt(adultsFromURL, 10) < 0) {
      searchParams.set('adults', defaultAdults);
    }
    if (!childrenFromURL || !parseInt(childrenFromURL, 10) < 0) {
      searchParams.set('children', defaultChildren);
    } else {
      searchParams.set('children', childrenFromURL);
    }
    if (!babiesFromURL || !parseInt(babiesFromURL, 10) < 0) {
      searchParams.set('babies', defaultBabies);
    }
    if (!langFromUrl) {
      searchParams.set('lang', currentLanguage);
    }
    if (moment(startDateFromURL).isSameOrAfter(endDateFromURL)) {
      searchParams.set(
        'checkout',
        moment(startDateFromURL).add(2, 'days').format('YYYY-MM-DD'),
      );
    }

    if (parseInt(babiesFromURL, 10) > 0) {
      const maxAllowedBabiesPerAdult = Math.round(
        (parseInt(adultsFromURL, 10) + parseInt(childrenFromURL, 10)) / 2
      );
      if (parseInt(babiesFromURL, 10) > maxAllowedBabiesPerAdult) {
        searchParams.set('babies', maxAllowedBabiesPerAdult);
        window.location.href = url.toString();
      }
    }
    const actualUrl = window.location;
    const actualSearchParams = new URLSearchParams(actualUrl.search);
    if (actualSearchParams.toString() !== searchParams.toString())
      updateUrlWithoutNavigation(`${url.pathname}?${searchParams.toString()}`);
  }, [
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
    dateRegex,
    endDateFromURL,
    startDateFromURL,
    dateRegexDateFormat,
    url,
    searchParams,
    langFromUrl,
    currentLanguage,
  ]);

  useEffect(() => {
    if (tent && !isLoadingRatePlans && selectedRate && !dataLayerSent) {
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          currency: 'EUR',
          items: [
            {
              quantity: 1,
              item_brand: 'Kampaoh',
              item_name: property?.propertyName,
              zoneName: property?.region?.name,
              zone: property?.region?.zone[0]?.internalID,
              item_id: propertyID,
              item_category: tent?.tent_model?.name,
              roomTypeID: parseInt(roomId, 10),
              bookingNights: daysBetweenCheckInAndCheckOut,
              checkinDate: formattedStartDateFromURL,
              checkoutDate: formattedEndDateFromURL,
              numAdults: adultsFromURL,
              numKids: childrenFromURL,
              numBabies: babiesFromURL,
              rateId: selectedRate?.rateId,
              offerId: selectedRate?.offer?.id ? selectedRate?.offer?.id : 0,
              promoId: selectedRate?.promotion?.id
                ? selectedRate?.promotion?.id
                : 0,
              price: selectedRate?.totalPrice,
            },
          ],
        },
      });
      setDataLayerSent(true);
    }
  }, [
    adultsFromURL,
    babiesFromURL,
    childrenFromURL,
    formattedStartDateFromURL,
    formattedEndDateFromURL,
    daysBetweenCheckInAndCheckOut,
    selectedRate,
    isLoadingRatePlans,
    property,
    propertyID,
    roomId,
    tent,
    dataLayerSent,
    offerFromURL,
    boardFromURL,
    rateFromURL,
    promotionFromURL,
  ]);

  const [decideImageSizeState, setDecideImageSizeState] = useState(
    decideImageSize(),
  );
  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');
    const handleOrientationChange = () => {
      setDecideImageSizeState(decideImageSize());
    };

    // Registry a Listener to detect changes in the orientation
    mediaQuery.addListener(handleOrientationChange);
    // Clean the listener when the component is umounted
    return () => {
      mediaQuery.removeListener(handleOrientationChange);
    };
  }, []);
  if (tokensExpired) {
    return <Redirect noThrow="true" to={routes.home} />;
  }
  return tent &&
    !isLoadingRatePlans &&
    filteredRatePlans &&
    !isLoadingCampings ? (
    <>
      <TopBanner />
      <ResponsiveMenu />

      <div className={wrapper}>
        <div
          className={carouselWrapper}
          style={
            status
              ? { borderRadius: '0px' }
              : { borderRadius: '12px 12px 0px 0px' }
          }
        >
          <CustomCarousel
            photos={tent.images.map((image) =>
              image.formats[decideImageSizeState] !== undefined
                ? image.formats[decideImageSizeState].url.includes('https')
                  ? image.formats[decideImageSizeState].url
                  : `${URL}/uploads/${decideImageSizeState}_${image.hash}${image.ext}`
                : `${URL}${image.url}`,
            )}
            photosPreview={tent.images.map((image) =>
              image.formats.large !== undefined
                ? image.formats.large.url.includes('https')
                  ? image.formats.large.url
                  : `${URL}/uploads/large_${image.hash}${image.ext}`
                : `${URL}${image.url}`,
            )}
          />
          {/* TODO: This text should be obtained from the default list text in Strapi.
              /*        Although it is not implemented yet */}
          <Popover
            overlayStyle={{ paddingBottom: '0px', top: '100px' }}
            overlayClassName={tooltip}
            content={intl.formatMessage({
              id: 'DetailsPage.photosNotLocation',
            })}
            trigger="click"
          >
            <InfoIcon className={infoIcon} />
          </Popover>
        </div>
        <div className={body}>
          <div className={`${titleWrapper} ${camps}`}>
            <div className={icon}>
              <TentIcon />
            </div>
            <div className={title}>
              <h2>{tent.tent_model.name}</h2>
              {/* <p>{tent.camping.propertyName}</p> */}
            </div>
          </div>
          {/* <ShareButton className={shareButton} onWhatsappClick={handleOnWhatsappClick} /> */}
          {enableHighDemandWarning && (
            <>
              <HighDemandWarning
                titleLabel="DetailsPage.HurryUp.Text1"
                subtitleLabel="DetailsPage.HurryUp.Text2"
              />
            </>
          )}
          {enablePetsBanner && (
            <>
              <PetsBanner warning />
            </>
          )}
          {tent.tent_description && (
            <>
              <div className={featuresWrapper}>
                <h2>
                  {intl.formatMessage({ id: 'DetailsPage.Features.Title' })}
                </h2>
                {/* {enablePetsNotAvailableBanner && (
                      <ul>
                        <li style={petsFeatures.featureWrapper}>
                          <img src="https://prod-strapi-507053028649-london.s3.eu-west-2.amazonaws.com/icon_not_pets_c828b59803.svg?updated_at=2023-07-26T11:07:29.851Z" alt="" style={petsFeatures.icon} />
                          {' '}
                          {intl.formatMessage({ id: 'DetailsPage.TentFeatures.PetsNotAvailable' })}
                        </li>
                      </ul>
                      )} */}
                <FeaturesListWithEllipsis
                  features={tent.features.map((element) => [element])}
                  language={currentLanguage}
                  enablePetsNotAvailableBanner={enablePetsNotAvailableBanner}
                />
              </div>
              <Divider />
            </>
          )}
          <div className={characteristicsWrapper}>
            <div className={characteristic}>
              <div className={icon}>
                <AreaIcon />
              </div>
              <div className={innerCharacteristic}>
                <p>
                  {intl.formatMessage({
                    id: 'DetailsPage.Characteristic.Area',
                  })}
                </p>
                <p>{`${tent.tent_model.area}㎡`}</p>
              </div>
            </div>
            <div className={characteristic}>
              <div className={icon}>
                <HeightIcon />
              </div>
              <div className={innerCharacteristic}>
                <p>
                  {intl.formatMessage({
                    id: 'DetailsPage.Characteristic.Height',
                  })}
                </p>
                <p>{`${tent.tent_model.height}m`}</p>
              </div>
            </div>
            <div className={characteristic}>
              <div className={icon}>
                <CapabilityIcon />
              </div>
              <div className={innerCharacteristic}>
                <p>
                  {intl.formatMessage({
                    id: 'DetailsPage.Characteristic.Capability',
                  })}
                </p>
                <p>{`${tent.tent_model.maxGuests} ${intl.formatMessage({
                  id: 'DetailsPage.Characteristic.Capability.persons',
                })}`}</p>
              </div>
            </div>
            {tent &&
              tent.features &&
              tent.features
                .filter((tentFeature) => tentFeature.outstanding)
                .map((filtered) => (
                  <div className={characteristic} key={filtered.id}>
                    <div className={icon}>
                      {tentFeatures[filtered.id].icon && (
                        <img
                          src={`${URL}${tentFeatures[filtered.id].icon.url}`}
                          alt={tentFeatures[filtered.id].icon.alternativeText}
                          loading="lazy"
                        />
                      )}
                    </div>
                    <div className={innerCharacteristic}>
                      <p>{filtered.title}</p>
                      <p>{filtered.description}</p>
                    </div>
                  </div>
                ))}
          </div>
          <Divider />
          <div className={reservationInfo}>
            <h2>
              {intl.formatMessage({
                id: 'DetailsPage.ReservationInfo.Camping.Title',
              })}
            </h2>
            <ReservationInfo data={data(filteredRatePlans)} />
            <UnitSelector
              reservation={reservation}
              propertyID={propertyID}
              unitId={roomId}
              service={Services.CAMP}
              maxPax={tent.tent_model.maxGuests}
              filteredRatePlans={filteredRatePlans}
              rateFromURL={rateFromURL}
              camping={camping}
              fpaFilter={fpaFilter}
              setFpaFilter={setFpaFilter}
              setSelectedRate={setSelectedRate}
              selectedRate={selectedRate}
              setIsFirstLoad={setIsFirstLoad}
              petsAllowed={petsAllowed}
            />
          </div>
          <Divider />
          {tent && tent.tent_description && (
            <>
              <div className={descriptionWrapper}>
                <h2>
                  {intl.formatMessage({ id: 'DetailsPage.Description.Title' })}
                </h2>
                <MarkdownWithEllipsis
                  source={tent?.tent_description?.description}
                />
                {tent.youtubeId && (
                  <div className={videoWrapper}>
                    <YoutubeEmbed embedId={tent.youtubeId} />
                  </div>
                )}
              </div>
              <Divider />
            </>
          )}
          {camping && (
            <>
              <div className={campingDescriptionWrapper}>
                <h2>
                  {intl.formatMessage({
                    id: 'DetailsPage.CampingDescription.Title',
                  })}
                </h2>
                <MarkdownWithEllipsis
                  source={camping?.description?.description}
                />

                {camping.youtubeVideoId && (
                  <div className={videoWrapper}>
                    <YoutubeEmbed embedId={camping.youtubeVideoId} />
                  </div>
                )}
              </div>
              <Divider />
              {camping.features && (
                <>
                  <div className={campingFeaturesWrapper}>
                    <h2>
                      {intl.formatMessage({
                        id: 'DetailsPage.CampingFeatures.Title',
                      })}
                    </h2>
                    {enablePetsNotAvailableBanner && (
                      <ul>
                        <li style={petsFeatures.featureWrapper}>
                          <img
                            src="https://prod-strapi-507053028649-london.s3.eu-west-2.amazonaws.com/icon_not_pets_c828b59803.svg?updated_at=2023-07-26T11:07:29.851Z"
                            alt=""
                            style={petsFeatures.icon}
                          />{' '}
                          {intl.formatMessage({
                            id: 'DetailsPage.CampingFeatures.PetsNotAvailable',
                          })}
                        </li>
                      </ul>
                    )}
                    <FeaturesListWithEllipsis
                      features={getIconsForEachFeature(
                        camping.features,
                        camping.features,
                      )}
                      language={currentLanguage}
                      enablePetsNotAvailableBanner={
                        enablePetsNotAvailableBanner
                      }
                    />
                  </div>
                </>
              )}
              <Divider />

              <div className={mapWrapper} ref={mapRef}>
                <h2>{intl.formatMessage({ id: 'DetailsPage.Map.Title' })}</h2>
                <SimpleMap
                  zoom={10}
                  center={{
                    lat: camping.latitude,
                    lng: camping.longitude,
                  }}
                />
                <div className={locationText}>
                  <LocateIcon />
                  <p>{`${location ? `${location}, ` : ''} ${
                    region ? region.name : ''
                  }`}</p>
                </div>
              </div>
              <Divider />
              {isThereScoreInfo() && (
                <div className={scoreWrapper}>
                  <h2>
                    {intl.formatMessage({ id: 'DetailsPage.Score.Title' })}
                  </h2>
                  <CampingRating
                    rating={{
                      reviews,
                      score,
                      nature,
                      party,
                      relax,
                      family,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {isACamping &&
          property &&
          property.campings_related &&
          property.campings_related.length > 0 && (
            <div className={otherSuggestedProperties}>
              <h3>
                {intl.formatMessage({
                  id: 'DetailsPage.OtherSuggestedProperties',
                })}
              </h3>
              <div className={placesPage}>
                {property.campings_related.map((relatedCamping) => (
                  <CampingCard
                    key={relatedCamping.ulysesId}
                    propertyID={relatedCamping.ulysesId}
                    type={Services.CAMP}
                  />
                ))}
              </div>
            </div>
          )}
      </div>
      <ActionBar
        reservation={reservation}
        filteredRatePlans={filteredRatePlans}
        rateFromURL={rateFromURL}
        roomId={roomId}
        camping={camping}
        propertyID={propertyID}
        tent={tent}
        selectedRate={selectedRate}
      />
    </>
  ) : (
    <div className={SpinnerContainerStyle}>
      <Spin size="large" />
    </div>
  );
};

RoomDetails.defaultProps = {
  roomId: '',
  propertyID: '',
  location: {
    state: {
      rateID: 0,
    },
  },
};

RoomDetails.propTypes = {
  roomId: PropTypes.string,
  propertyID: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.shape({
      rateID: PropTypes.number,
    }),
  }),
};

export default RoomDetails;
