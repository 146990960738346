import {
  ApolloClient, InMemoryCache, HttpLink,
} from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { createHash } from 'crypto';

import { routes, URL } from '../constants/cms';

const sha256 = (data) => {
  const hash = createHash('sha256');
  hash.update(data);
  return hash.digest('hex');
};

const linkChain = createPersistedQueryLink({ sha256 }).concat(
  new HttpLink({ uri: `${URL}${routes.graphql}` }),
);

const client = new ApolloClient({
  uri: `${URL}${routes.graphql}`,
  cache: new InMemoryCache(),
  link: linkChain,
});

export default client;
