/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { WarningOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as ReturnIcon } from '../../../assets/icons/return.svg';
import Status from '../../../constants/status';
import { SearchContext } from '../../../contexts/SearchContext';
import { useFilterDispatch, useFilterState } from '../../../services/Filter';
import { usePrevious } from '../../../services/usePrevious';
import ActionButton from '../../ActionButton';
import CustomDrawer from '../../CustomDrawer';
import Filters from './Filters';
import {
  deleteZone,
  footer, footerWrapper,
  title,
  warningAlert,
  wrapper,
} from './index.module.scss';

const FiltersDrawer = ({
  visible,
  onFilter,
  onShowMoreDestinations,
  onClose,
}) => {
  const alertRef = useRef(null);
  const filterDispatch = useFilterDispatch();
  const filterState = useFilterState();
  const {
    propertiesToShow, filteredRatePlans, filteredStatus, isLoading,
  } = useContext(SearchContext);
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState(false);
  const prevFilterState = usePrevious(filterState);
  const [showFiltersLoader, setShowFiltersLoader] = useState(false);
  const loaderTimeoutRef = useRef(null);

  const onReset = () => {
    filterDispatch({ type: 'resetCharacteristicsFilter' });
    filterDispatch({ type: 'resetRegionsFilter' });
    filterDispatch({ type: 'resetPricesToFilter' });
    const url = new URL(window.location.href);
    url.searchParams.delete('filters');
    window.history.replaceState({}, document.title, url);
  };

  const onOk = () => {
    onFilter();
  };

  const handleOnClose = () => {
    if (errors) {
      onReset();
      onClose();
    }
    onClose();
  };

  const propertiesLength = propertiesToShow.length;

  useEffect(() => {
    if (filterState !== prevFilterState && !isLoading) {
      // eslint-disable-next-line max-len
      const arePropertiesAndFilteredRatePlansDifferent = propertiesLength !== Object.keys(filteredRatePlans).length;
      const thereAreProperties = propertiesToShow.length > 0;
      const AreAllPropertiesNotAvailable = propertiesToShow.every((key) => filteredRatePlans[key]
    && filteredRatePlans[key].length === 0 && filteredStatus[key] !== Status.AVAILABLE);
      if (
        propertiesLength !== undefined
      && arePropertiesAndFilteredRatePlansDifferent
      && (!thereAreProperties
      || AreAllPropertiesNotAvailable)) {
        setShowAlert(true);
        setErrors(true);
      } else {
        setShowAlert(false);
        setErrors(false);
      }

      loaderTimeoutRef.current = setTimeout(
        () => setShowFiltersLoader(false),
        400
      );
    };
    
  }, [
    propertiesLength,
    filteredRatePlans,
    propertiesToShow,
    filteredStatus,
    filterState,
    prevFilterState,
    isLoading,
  ]);

  useEffect(
    () => () => {
      if (loaderTimeoutRef.current != null) {
        clearTimeout(loaderTimeoutRef);
      }
    },
    []
  );

  useLayoutEffect(() => {
    if (showAlert && alertRef.current) {
      alertRef.current.focus();
    }
  }, [showAlert]);

  return (
    <CustomDrawer
      title={<FormattedMessage id="FiltersDrawer.title" />}
      visible={visible}
      onClose={handleOnClose}
      icon={<ReturnIcon />}
      mask
      subTitle={null}
    >
      {showAlert && (
        <div
          tabIndex={0}
          className={warningAlert}
          ref={alertRef}
          // onBlur={() => { setShowAlert(false); }}
        >
          <WarningOutlined />
          <div>
            <p className={title}><FormattedMessage id="FiltersDrawer.Alert.NoResultsFound.Title" /></p>
            <p><FormattedMessage id="FiltersDrawer.Alert.NoResultsFound.Text" values={{ br: <br /> }} /></p>
          </div>
        </div>
      )}
      <div className={wrapper} name="wrapper">
        <div>
          <Filters
            onShowMoreDestinations={onShowMoreDestinations}
            onClick={() => { setShowFiltersLoader(true); }}
            filteredRatePlans={filteredRatePlans}
          />
        </div>
        <div className={footerWrapper}>
          <div className={footer}>
            <div className={deleteZone}>
              <CloseIcon />
              {' '}
              <span
                onClick={onReset}
                onKeyPress={onReset}
                role="button"
                tabIndex={0}
              >
                <FormattedMessage id="ReservationWizard.DeleteFilters" />
              </span>
            </div>
            <div>
              <ActionButton
                onClick={onOk}
                disabled={errors}
                loading={showFiltersLoader}
              >
                OK
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
};

FiltersDrawer.propTypes = {
  onFilter: PropTypes.func,
  onShowMoreDestinations: PropTypes.func,
  onClose: PropTypes.func,
  visible: PropTypes.bool,
};

FiltersDrawer.defaultProps = {
  onFilter: () => {},
  onClose: () => {},
  onShowMoreDestinations: () => {},
  visible: false,
};

export default FiltersDrawer;
