import React, { useEffect, useState, useMemo } from 'react';
import UpcomingTripCard from '../../../../components/UpcomingTripCard';
import makeRequestToApServer from '../../../../services/ApServer/askToApServer';
import routes from '../../../../constants/ApServer/apRoutes';
import { allowedMethods } from '../../../../constants/ApServer/apServer';
import { tripContainer } from './index.module.scss';
import Postal from '../../../../components/Postal';

const UpcomingTrip = () => {
  const [trips, setTrips] = useState([]);
  const [openPostal, setOpenPostal] = useState(false);
  const [selectTrip, setSelectTrip] = useState(null);
  useEffect(() => {
    makeRequestToApServer(
      allowedMethods.get,
      routes.upconmingTrip,
      undefined,
      ({ statusCode, data }) => {
        if (statusCode === 200) {
          setTrips(data);
        }
      },
      () => {},
    );
  }, []);
  const tripCards = useMemo(
    () =>
      trips.map((trip, index) => (
        <UpcomingTripCard
          key={`${trip.location}-${trip.checkin}-${
            trip.checkout
          }-${index.toString()}`}
          location={trip?.camping?.campingName}
          checkin={trip.checkin}
          checkout={trip.checkout}
          onClick={() => {
            setSelectTrip(trip);
            setOpenPostal(true);
          }}
        />
      )),
    [trips],
  );

  return (
    <>
      {openPostal && (
        <Postal
          trip={selectTrip}
          onClose={() => {
            setOpenPostal(false);
            setSelectTrip(null);
          }}
        />
      )}
      <div className={tripContainer}>{tripCards}</div>
    </>
  );
};

export default React.memo(UpcomingTrip);
