import React, {
  createContext,
  useEffect,
  useReducer,
  useContext,
  useState,
} from 'react';
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useQuery } from '@apollo/client';
import { useLocation, navigate } from '@reach/router';
import reducer from './reducer';
import initialState from './initialState';
import sanitizeApiResponse from '../../utils/sanitizeApiResponse';
import campingsOnPlacesQuery from '../GraphQL/Places/Camping/campingsOnPlacesQuery';
import { useReservationState } from '../Reservation';
import { LanguageContext } from '../../locale/contexts/Language';
import ROUTES from '../../routes/routes';

export const CampingOnPlacesState = createContext();
export const CampingOnPlacesDispatch = createContext();

const campingsAsHashedObject = (result, camping) => ({
  ...result,
  [camping.ulysesId]: {
    ...camping,
    openDates: {
      from: moment(camping.openFrom).hour(12).minutes(0).seconds(0),
      to: moment(camping.openTo).hour(12).minutes(0).seconds(0),
    },
    ratePlansAlreadyFetched: false,
    isLoadingRatePlans: false,
  },
});

const CampingPlacesProvider = ({ children }) => {
  const reservation = useReservationState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  const { currentLanguage } = useContext(LanguageContext);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const propertyFromURL = searchParams.get('property');
  const isBookResume = window.location.href.includes(ROUTES.book + ROUTES.resume);
  const isBookPolicies = window.location.href.includes(ROUTES.policies);
  const isBookForm = window.location.href.includes(ROUTES.form);
  const isBookPayment = window.location.href.includes(ROUTES.payment);

  let campingId;
  // eslint-disable-next-line prefer-destructuring
  campingId = location.pathname.split('/')[2];
   if (isBookResume || isBookPolicies || isBookForm || isBookPayment) {
    campingId = propertyFromURL;
  }

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const zoneId = parseInt(urlParams.get('zone'), 10);

  const isPlacesPage = window.location.href.includes('/places');
  const isHotelSelected = reservation.selectedHotelId
    ? [reservation.selectedHotelId]
    : undefined;
  const isCampingSelected =
    campingId && campingId !== 'resume' ? [campingId] : undefined;
  const propertyId = isPlacesPage
    ? undefined
    : isHotelSelected || isCampingSelected;
  const [campings, setCampings] = useState();
  const [hasError, setHasError] = useState(false);

  const { loading: loadingCampings, data: dataCampings } = useQuery(
    campingsOnPlacesQuery,
    {
      variables: {
        filters: propertyId,
        zone: zoneId || undefined,
        lang: currentLanguage || 'es',
      },
      skip: campings,
    },
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!campings || !campings.length) {
          dispatch({ type: 'setIsLoadingCampings', payload: true });
          if (!loadingCampings) {
            const sanitizedCampings = sanitizeApiResponse(
              dataCampings.campings,
            );
            setCampings(sanitizedCampings);
            dispatch({
              type: 'allCampingsFound',
              payload: sanitizedCampings.reduce(campingsAsHashedObject, {}),
            });
            dispatch({ type: 'setIsLoadingCampings', payload: false });

            if (!dataCampings?.campings?.data.length > 0) {
              navigate(`${ROUTES.home}?checkin=${reservation.startDate.format('YYYY-MM-DD')}&checkout=${reservation.endDate.format('YYYY-MM-DD')}&adults=${reservation.adults}&children=${reservation.children}&babies=${reservation.babies}`);
            }
          }
        }
      } catch (error) {
        console.error(error);
        setHasError(true);
        dispatch({ type: 'setIsLoadingCampings', payload: false });
      }
    };

    fetchData();
  }, [dataCampings, campings, loadingCampings, reservation]);

  useEffect(() => {
    if (hasError && window.location.href.includes(ROUTES.propertyDetails)) {
      navigate(ROUTES.home);
    }
  }, [hasError]);

  return (
    <CampingOnPlacesState.Provider value={state}>
      <CampingOnPlacesDispatch.Provider value={dispatch}>
        {children}
      </CampingOnPlacesDispatch.Provider>
    </CampingOnPlacesState.Provider>
  );
};

CampingPlacesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default CampingPlacesProvider;
