/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
import React, {
  useContext, useRef, useEffect, useState,
} from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import moment from 'moment';
import { PulseLoader } from 'react-spinners';
import * as s from './index.module.scss';
import NightTotalNumberTooltip from './components/NightTotalNumberTooltip';
import AvailabilityContext from '../../../../../../contexts/AvailabilityContext';
import calcDaysBetweenCheckInAndCheckOut from '../../../../../../../../utils/daysBetweenCheckInAndCheckOut';
import { LanguageContext, localesWithFirstDay } from '../../../../../../../../locale/contexts/Language';
import { defaultTimeLimitForTodaysBooking } from '../../../../../../../../constants/config';

const calcTimeLimitForTodaysBooking = () => {
  const time = defaultTimeLimitForTodaysBooking;
  const parsedTime = time.split(':');
  return moment().tz('Europe/Madrid').hour(parsedTime[0]).minute(parsedTime[1]);
};

const hasReachedTimeLimit = () => {
  const duration = moment.duration(calcTimeLimitForTodaysBooking().diff(moment()));
  const hours = duration.asHours();
  return hours > 0;
};

const Calendar = ({
  loading, days, startDateAvailable, endDateAvailable, setEndDateAvailable, setStartDateAvailable,
  openFrom,
}) => {
  const firstTry = useRef(true);
  const messagesContainerRef = useRef();
  const {
    onError,
    setAreAnyError,
    selectedUnit,
    filteredReferences,
    resetErrors,
  } = useContext(AvailabilityContext);
  const { currentLanguage } = useContext(LanguageContext);
  const [startDate, setStartDate] = useState(startDateAvailable);
  const [endDate, setEndDate] = useState(endDateAvailable);
  const [startCalendarDate, setStartCalendarDate] = useState(startDate);

  useEffect(() => {
    setStartDate(startDateAvailable);
    setEndDate(endDateAvailable);
  }, [setStartDate, startDateAvailable, endDateAvailable, setEndDate]);

  const DAYS_OF_THE_WEEK = [];
  const todayWeek = moment().startOf('week').day(localesWithFirstDay[currentLanguage]);
  const startMonth = (calendarStartDate) => moment(calendarStartDate).locale(currentLanguage).day(localesWithFirstDay[currentLanguage])
    .format('MMMM');
  const endMonth = (calendarEndDate) => moment(calendarEndDate).locale(currentLanguage).day(localesWithFirstDay[currentLanguage])
    .format('MMMM YYYY');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 7; i++) {
    DAYS_OF_THE_WEEK.push(todayWeek.format('ddd'));
    todayWeek.add(1, 'day');
  }
  const getDaysOfTheWeek = () => DAYS_OF_THE_WEEK.map((day) => <div key={day}>{day}</div>);
  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
    endDate: moment.utc(endDate),
    startDate: moment.utc(startDate),
  });

  const [referenceRates, setReferenceRates] = useState();

  useEffect(() => {
    if (selectedUnit && filteredReferences) {
      setReferenceRates(filteredReferences);
    }
  }, [selectedUnit, filteredReferences]);

  const getClasses = (day, filteredReference) => {
    const today = moment();
    const isClosedDates = () => startDate && endDate;
    const isToday = () => day.isSame(today, 'date');
    const isStartDate = () => day.isSame(startDate, 'day');
    const isEndDate = () => day.isSame(endDate, 'day');
    const isADayBetweenDates = () => day.isBetween(startDate, endDate);
    const isANotSelectablePreviousDate = () => moment(day).isBefore(moment(), 'day');
    const areThereAvailableRooms = () => filteredReference && filteredReference.quantityAvailable > 0;
    const isClosedToArrival = () => filteredReference && filteredReference.closedToArrival === true;
    const isClosedToDeparture = () => filteredReference && filteredReference.closedToDeparture === true;
    const isClosedRate = () => filteredReference && filteredReference.closed === true;
    const amountIsGreaterThanZero = () => filteredReference && filteredReference.amountAfterTax > 0;

    const isRatePlanAvailable = isClosedRate() ? false : (!isClosedToArrival() && !isClosedToDeparture() && areThereAvailableRooms() && amountIsGreaterThanZero());

    let classes = '';
    if (isRatePlanAvailable) {
      classes += `${s.closedDates} `;
    } else {
      classes += `${s.availableToArriveOrDeparture} `;
    }
    if (isStartDate()) {
      classes += `${s.startDay} `;
      if (isClosedDates()) {
        classes += `${s.closedDates} `;
      }

      if (!isRatePlanAvailable || !filteredReference) {
        classes += `${s.notAvailable} `;
      }
      classes += `${s.startDay} `;
    } else if (isEndDate()) {
      classes += `${s.endDay} `;
      if (isClosedDates()) {
        classes += `${s.closedDates} `;
      }
      if (!isRatePlanAvailable || !filteredReference) {
        classes += `${s.notAvailable} `;
      }
    } else {
      if (startDate && endDate && isADayBetweenDates()) {
        classes += `${s.dayBetween} `;
      }
      if (isToday() && !hasReachedTimeLimit()) {
        classes += `${s.todayDay} `;
        if (!isRatePlanAvailable || !filteredReference) {
          classes += `${s.notAvailable} `;
        }
      }

      if (!isRatePlanAvailable || !filteredReference) {
        classes += `${s.notAvailable} `;
      }

      if (isANotSelectablePreviousDate() && !filteredReference) {
        classes += `${s.notSelectable} `;
      }
    }
    return classes;
  };

  /* In other words: there are not invalid dates beetween start and end dates */
  const isDateRangeValid = (start, end) => {
    let currentDate = start;
    let ocurrency = false;
    let attempts = 0;
    const maxAttempts = 100;

    const checkStartDatePlan = (date) => {
      const startDatePlan = referenceRates.find((rate) => moment(rate.date).isSame(date, 'day'));
      if (startDatePlan && startDatePlan.quantityAvailable && startDatePlan.quantityAvailable > 0) {
        ocurrency = true;
      }
    };

    while (!currentDate.isSame(end) && !ocurrency && attempts < maxAttempts) {
      checkStartDatePlan(currentDate);
      currentDate = currentDate.clone().add(1, 'days');
      attempts += 1;
    }
    return ocurrency;
  };

  const calendarRestrictions = (day, rate) => {
    const isClosedToArrival = () => rate && rate.closedToArrival;
    const isClosedToDeparture = () => rate && rate.closedToDeparture;
    const areThereAvailableRooms = () => rate && rate.quantityAvailable > 0;
    const amountIsGreaterThanZero = () => rate && rate.amountAfterTax > 0;
    const openFromDate = moment(openFrom);
    const isBeforeOpenFrom = () => day.isBefore(openFromDate, 'day');

    if (firstTry.current && areThereAvailableRooms && amountIsGreaterThanZero && !isBeforeOpenFrom()) {
      setStartDate(day.clone().add(12, 'hours'));
      setStartDateAvailable(day.clone().add(12, 'hours'));
      setEndDate(null);
      setEndDateAvailable(null);
      firstTry.current = false;
    } else {
      if (startDate && endDate && areThereAvailableRooms && amountIsGreaterThanZero) {
        if (day.isBefore(startDate, 'day') && !isBeforeOpenFrom()) {
          setStartDate(day.clone().add(12, 'hours'));
          setStartDateAvailable(day.clone().add(12, 'hours'));
          setEndDateAvailable(null);
          setEndDate(null);
        }
      }
      if (startDate) {
        if (day.isAfter(startDate, 'day') && areThereAvailableRooms && amountIsGreaterThanZero) {
          if (!isDateRangeValid(startDate, day.clone().add(12, 'hours'))) {
            onError(<FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidRange" />);
          }
          if (isClosedToDeparture()) {
            onError(<FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidCheckout" />);
          }
          setEndDate(day.clone().add(12, 'hours'));
          setEndDateAvailable(day.clone().add(12, 'hours'));
        }
        if (day.isAfter(startDateAvailable, 'day') && (!areThereAvailableRooms && !amountIsGreaterThanZero) && isDateRangeValid(startDateAvailable, day.clone().add(12, 'hours'))) {
          setEndDate(day.clone().add(12, 'hours'));
          setEndDateAvailable(day.clone().add(12, 'hours'));
        }
      }
      if (!startDate && !endDate && areThereAvailableRooms && amountIsGreaterThanZero && !isBeforeOpenFrom()) {
        if (isClosedToArrival()) {
          onError(<FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidCheckin" />);
        }

        setStartDate(day.clone().add(12, 'hours'));
        setStartDateAvailable(day.clone().add(12, 'hours'));
        setEndDate(null);
        setEndDateAvailable(null);
      }
    }
  };

  const handleOnDayClick = (day, rate) => {
    if (day.isSameOrBefore(startCalendarDate)) {
      setStartCalendarDate(day.format('YYYY-MM-DD'));
    }

    if (day.isSameOrAfter(moment(), 'day') && hasReachedTimeLimit()) {
      calendarRestrictions(day, rate);
      resetErrors();
      setAreAnyError(false);
    } else if (day.isAfter(moment(), 'day')) {
      calendarRestrictions(day, rate);
    } else {
      setAreAnyError(true);
    }
  };

  const calendar = {
    startDate: days && (days[0] || { days: [] }).days[0],
    endDate: days && (days[days.length - 1] || { days: Array.of(7) }).days[6],
  };

  return referenceRates ? (
    <>
      <div ref={messagesContainerRef} />
      <div className={s.headerNamesOfTheWeek}>
        {getDaysOfTheWeek()}
      </div>
      <div className={s.allCalendars}>
        <div className={s.monthCalendar} key={calendar?.startDate?.format('MMMM YYYY')}>
          <div
            className={s.calendarTitle}
          >
            {`${startMonth(calendar.startDate)} - ${endMonth(calendar.endDate)}`}
          </div>
          {days && days.map((daysOnAWeek, index) => (
          // eslint-disable-next-line react/no-array-index-key
            <div className={s.week} key={index}>
              {daysOnAWeek.days.map((day) => {
                const startDateRatePlan = startDate ? referenceRates.find((rate) => moment(rate.date).format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD')) : null;
                const filteredReference = referenceRates.find((rate) => moment(rate.date).format('YYYY-MM-DD') === day.format('YYYY-MM-DD'));

                return (
                  <div
                    className={getClasses(day, filteredReference)}
                    onClick={() => handleOnDayClick(day, filteredReference)}
                    onKeyPress={() => handleOnDayClick(day, filteredReference)}
                    role="button"
                    tabIndex={0}
                    key={day.toString()}
                  >
                    <NightTotalNumberTooltip
                      visible={!loading && day.isSame(endDate, 'day')}
                      daysBetweenCheckInAndCheckOut={daysBetweenCheckInAndCheckOut}
                      startDateRatePlan={startDateRatePlan}
                      width={messagesContainerRef.current
                        ? messagesContainerRef.current.offsetWidth / 7
                        : undefined}
                    >
                      <div className={loading ? s.loading : ''}>
                        <div className={s.dayNumber}>{day.format('D')}</div>
                        {loading && <PulseLoader size={4} />}
                        {!loading
                          && (
                          <div
                            className={s.dayRate}
                          >
                            <FormattedNumber value={filteredReference ? filteredReference?.amountAfterTax : ''} style="currency" currency="EUR" minimumFractionDigits={0} maximumFractionDigits={0} />
                          </div>
                          )}
                      </div>
                    </NightTotalNumberTooltip>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </>
  ) : null;
};

Calendar.propTypes = {
  days: PropTypes.arrayOf(PropTypes.shape({
    days: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
  })),
  loading: PropTypes.bool,
  startDateAvailable: PropTypes.string.isRequired,
  endDateAvailable: PropTypes.string.isRequired,
  setEndDateAvailable: PropTypes.func.isRequired,
  setStartDateAvailable: PropTypes.func.isRequired,
  openFrom: PropTypes.instanceOf(moment).isRequired,
};

Calendar.defaultProps = {
  loading: false,
  days: [],
};
export default Calendar;
