import React from 'react';
// import PropTypes from 'prop-types';
import { wrapper } from './index.module.scss';
import { contactData } from '../../constants/config';
// import { ReactComponent as WhatsappIcon } from '../../assets/icons/whatsapp.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';

const ContactInfo = () => (
  /* {
     whatssappMessage
  }, */

  /*   const urlWhatsapp = `https://api.whatsapp.com/send?phone=34${contactData.whatsappNumber.replace(
    / /g,
    '',
  )}&text=${whatssappMessage}`; */

  <div className={wrapper}>
    {/* <div>
        <WhatsappIcon />
        <p><a href={urlWhatsapp} target="_blank" rel="noopener noreferrer">{contactData.whatsappGroupNumber}</a></p>
      </div> */}
    <div>
      <PhoneIcon />
      <p>
        <a href={`tel:${contactData.phoneNumber.replace(/(\(|\)| )/g, '')}`}>
          {contactData.phoneNumber}
        </a>
      </p>
    </div>
  </div>
);

/* ContactInfo.propTypes = {
   whatssappMessage: PropTypes.string,
};

ContactInfo.defaultProps = {
   whatssappMessage: 'Hola!',
}; */
export default ContactInfo;
