/* eslint-disable max-len */
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import Status from '../../../../constants/status';
import { SearchContext } from '../../../../contexts/SearchContext';
import { LanguageContext } from '../../../../locale/contexts/Language';
import makeRequestToServer, {
  makeRequestToServerV3,
} from '../../../../services/API';
import useCampings from '../../../../services/Campings/useCampings';
import { useReservationState } from '../../../../services/Reservation';
import calcDaysBetweenCheckInAndCheckOut from '../../../../utils/daysBetweenCheckInAndCheckOut';

const AvailabilityContext = React.createContext({});

const AvailabilityProvider = ({ children, property, unitId }) => {
  const initialCharge = useRef(false);
  const [ratePlans, setRatePlans] = useState([]);
  const { currentLanguage } = useContext(LanguageContext);
  const {
    filteredStatus: { [property?.ulysesId]: status },
    campings: { [property?.ulysesId]: camping },
  } = useContext(SearchContext);
  const {
    tentState: { tents },
    dispatch: dispatchCampings,
  } = useCampings();
  const [areAnyError, setAreAnyError] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [checkedErrors, setCheckedErrors] = useState(false);
  const [filteredReferences, setFilteredReferences] = useState([]);
  const [filteredRates, setFilteredRates] = useState([]);
  const { startDate, endDate } = useReservationState();
  const [reservationInitialDates, setReservationInitialDates] = useState({
    startDate,
    endDate,
  });
  const [loadingRatePlans, setLoadingRatePlans] = useState(false);
  const reservation = useReservationState();
  const [currentDates, setCurrentDates] = useState({ startDate, endDate });
  const [error, setError] = useState(null);
  const [showMoreDatesDrawer, setShowMoreDatesDrawer] = useState(false);
  const units = tents;
  const dispatch = dispatchCampings;
  const daysBetweenCheckInAndCheckOut = calcDaysBetweenCheckInAndCheckOut({
    endDate: moment.utc(currentDates.endDate),
    startDate: moment.utc(currentDates.startDate),
  });
  const openFrom = camping?.openFrom;
  const isCampingClosed = status === Status.CLOSED;
  const [additionalRequestsCount, setAdditionalRequestsCount] = useState(0);
  const [showCalendarUnavailable, setShowCalendarUnavailable] = useState(false);
  const [relaunchGetReference, setRelaunchGetReference] = useState(true);
  const [isLoadingPlans, setIsLoadingPlans] = useState(false);
  const maxCallGetReference = 4;
  const maxCallDays = 90;
  const maxLoop = 3;
  const [startDateAvailable, setStartDateAvailable] = useState();
  const [endDateAvailable, setEndDateAvailable] = useState();

  const availableUnits = useMemo(() => {
    if (camping && camping.tents && camping.tents > 0) {
      return camping.tents
        .map(({ roomTypeId }) => ({
          ...units[roomTypeId],
        }))
        .filter((unit) => unit.id);
    }
    if (tents && Object.keys(tents).length > 0) {
      return Object.values(tents)
        .map(({ roomTypeId }) => ({
          ...units[roomTypeId],
        }))
        .filter((unit) => unit.roomTypeId);
    }
    return [];
  }, [tents, units, camping]);

  const selectedUnitRatePlan = useMemo(() => {
    if (filteredReferences && filteredReferences.length > 0) {
      if (selectedUnit) {
        setSelectedUnit(selectedUnit);
        return filteredReferences.find(
          (rate) => rate.productId === parseInt(selectedUnit.roomTypeId, 10),
        );
      }

      const unit = Object.values(tents).find(
        (room) =>
          parseInt(room.roomTypeId, 10) === filteredReferences[0].productId,
        10,
      );
      setSelectedUnit(unit);
      return unit;
    }
    return null;
  }, [filteredReferences, selectedUnit, tents]);

  const getDays = useCallback(
    ({ startDate: start, endDate: end } = { startDate, endDate }) => {
      const calendar = [];
      const startDay = start?.clone()?.subtract(1, 'weeks').startOf('week');
      const endDay = end?.clone()?.add(1, 'weeks').endOf('week');

      const date = startDay?.clone()?.subtract(1, 'day');

      while (date?.isBefore(endDay, 'day')) {
        calendar.push({
          days: Array(7)
            .fill(0)
            ?.map(() => date?.add(1, 'day')?.clone()),
        });
      }
      return calendar;
    },
    [startDate, endDate],
  );

  const haveDatesChanged = async (
    currentStartDate,
    currentEndDate,
    newStart,
    newEnd,
  ) => {
    if (currentStartDate !== newStart && currentEndDate !== newEnd) return true;
    return false;
  };

  const askForOnePropertyRatePlan = useCallback(
    async ({ startDate: start, endDate: end } = { startDate, endDate }) => {
      const dates = getDays({ startDate: start, endDate: end });
      const { adults, children: boys } = reservation;
      const findFirstValidDate = () =>
        dates.reduce((acc, { days }) => {
          const foundDay =
            days && days?.find((d) => d.isSameOrAfter(moment(), 'day'));
          if (Object.keys(acc).length === 0 && foundDay) {
            return foundDay;
          }
          return acc;
        }, {});
      const findLastValidDate = () =>
        dates[dates.length - 1]?.days[
          dates[dates.length - 1]?.days?.length - 1
        ];

      const datesChanged = await haveDatesChanged(
        startDate?.format('YYYY-MM-DD'),
        endDate?.format('YYYY-MM-DD'),
        start?.format('YYYY-MM-DD'),
        end?.format('YYYY-MM-DD'),
      );

      if (datesChanged) {
        const { data } = await makeRequestToServerV3(
          `/properties/${
            property.ulysesId
          }/rooms/boards/plans?startDate=${moment(findFirstValidDate()).format(
            'YYYY-MM-DD',
          )}&endDate=${moment(findLastValidDate().clone().add(1, 'day')).format(
            'YYYY-MM-DD',
          )}&adults=${adults}&children=${boys}&lang=${currentLanguage}`,
        );
        setRatePlans(data);
        setLoadingRatePlans(false);
        initialCharge.current = true;
      } else {
        const { data } = await makeRequestToServerV3(
          `/properties/${property.ulysesId}/rooms/boards/plans?startDate=${
            isCampingClosed
              ? moment(openFrom).format('YYYY-MM-DD')
              : moment(findFirstValidDate()).format('YYYY-MM-DD')
          }&endDate=${
            isCampingClosed
              ? moment(openFrom).add(2, 'day').format('YYYY-MM-DD')
              : moment(findLastValidDate().clone().add(1, 'day')).format(
                  'YYYY-MM-DD',
                )
          }&adults=${adults}&children=${boys}&lang=${currentLanguage}`,
        );
        setRatePlans(data);
        setLoadingRatePlans(false);
        initialCharge.current = true;
      }
    },
    [
      reservation,
      property,
      currentLanguage,
      getDays,
      startDate,
      endDate,
      openFrom,
      isCampingClosed,
    ],
  );

  const createDatesArray = useCallback(async (startDateParam, endDateParam) => {
    const datesArray = [];
    const currentDate = moment(startDateParam);
    const lastDate = moment(endDateParam);

    while (currentDate <= lastDate) {
      datesArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'day');
    }

    return datesArray;
  }, []);

  const getReferenceRate = useCallback(
    async (
      { startDate: start, endDate: end } = { startDate, endDate },
      selectedRoom,
    ) => {
      const dates = getDays({ startDate: start, endDate: end });
      const { adults, children: boys } = reservation;
      const findFirstValidDate = () =>
        dates.reduce((acc, { days }) => {
          const foundDay =
            days && days?.find((d) => d.isSameOrAfter(moment(), 'day'));
          if (Object.keys(acc).length === 0 && foundDay) {
            return foundDay;
          }
          return acc;
        }, {});
      const datesChanged = await haveDatesChanged(
        startDate?.format('YYYY-MM-DD'),
        endDate?.format('YYYY-MM-DD'),
        start?.format('YYYY-MM-DD'),
        end?.format('YYYY-MM-DD'),
      );

      const lastDateObj = dates[dates?.length - 1];
      const lastCalendarWeek = lastDateObj?.days;
      const lastMomentObj = lastCalendarWeek[lastCalendarWeek?.length - 1];
      const endDateDay = moment(lastMomentObj)?.add(1, 'days');

      if (selectedRoom || selectedUnit) {
        const currentSelectedRoom =
          typeof selectedRoom === 'number'
            ? selectedRoom
            : (selectedRoom && selectedRoom.roomTypeId) ||
              selectedUnit.roomTypeId;
        if (
          currentSelectedRoom &&
          (currentSelectedRoom ||
            (typeof selectedRoom === 'number' && Number.isFinite(selectedRoom)))
        ) {
          if (datesChanged && !isLoadingPlans) {
            const reference = await makeRequestToServer(
              `/getReferenceRate?propertyID=${
                property.ulysesId
              }&startDate=${moment(dates[0]?.days[0]).format(
                'YYYY-MM-DD',
              )}&endDate=${moment(endDateDay)
                .add(1, 'days')
                .format(
                  'YYYY-MM-DD',
                )}&adults=${adults}&children=${boys}&productID=${currentSelectedRoom}`,
            );
            const filteredRate =
              reference[0]?.productOccupancySet[0]?.rateProductOccupancySet[0]
                ?.rateProductOccupancyAvailabilitySet;
            // referenceRate is ordered by ID instead date, we sort it manually
            const sortedRate = filteredRate?.sort(
              (a, b) => new Date(a.date) - new Date(b.date),
            );
            setFilteredReferences(sortedRate);
            initialCharge.current = true;
          } else if (datesChanged && isLoadingPlans === true) {
            const reference = await makeRequestToServer(
              `/getReferenceRate?propertyID=${property.ulysesId}&startDate=${
                isCampingClosed
                  ? moment(openFrom).format('YYYY-MM-DD')
                  : moment(findFirstValidDate()).format('YYYY-MM-DD')
              }&adults=${adults}&children=${boys}&productID=${currentSelectedRoom}`,
            );
            const filteredRate =
              reference[0]?.productOccupancySet[0]?.rateProductOccupancySet[0]
                ?.rateProductOccupancyAvailabilitySet;
            // referenceRate is ordered by ID instead date, we sort it manually
            const sortedRate = filteredRate?.sort(
              (a, b) => new Date(a.date) - new Date(b.date),
            );
            setFilteredReferences(sortedRate);
            initialCharge.current = true;
            const currentUnit = availableUnits.find(
              (unit) => unit.roomTypeId === unitId,
            );
            setSelectedUnit(currentUnit);
          } else {
            const reference = await makeRequestToServer(
              `/getReferenceRate?propertyID=${property.ulysesId}&startDate=${
                isCampingClosed
                  ? moment(openFrom).format('YYYY-MM-DD')
                  : moment(findFirstValidDate()).format('YYYY-MM-DD')
              }&adults=${adults}&children=${boys}&productID=${currentSelectedRoom}`,
            );
            const filteredRate =
              reference[0]?.productOccupancySet[0]?.rateProductOccupancySet[0]
                ?.rateProductOccupancyAvailabilitySet;
            // referenceRate is ordered by ID instead date, we sort it manually
            const sortedRate = filteredRate?.sort(
              (a, b) => new Date(a.date) - new Date(b.date),
            );
            setFilteredReferences(sortedRate);
            initialCharge.current = true;
            const currentUnit = camping.tents.find(
              (unit) => parseInt(unit.roomTypeId, 10) === parseInt(unitId, 10),
            );
            setSelectedUnit(currentUnit);
          }
        }
      }
    },
    [
      startDate,
      endDate,
      getDays,
      isCampingClosed,
      property,
      reservation,
      openFrom,
      selectedUnit,
      setFilteredReferences,
      availableUnits,
      unitId,
      isLoadingPlans,
      camping,
    ],
  );

  useEffect(() => {
    if (error && !areAnyError) {
      setAreAnyError(true);
    } else if (!error) {
      setAreAnyError(false);
    }
  }, [error, areAnyError]);

  useEffect(() => {
    const rateAvailable = filteredReferences?.find(
      (rate) =>
        rate?.closed === false &&
        rate?.closedToArrival === false &&
        rate?.quantityAvailable > 0,
    );
    if (rateAvailable && relaunchGetReference) {
      setFilteredRates(rateAvailable);
      setIsLoadingPlans(false);
    } else if (!relaunchGetReference) {
      const matchingRate = filteredReferences?.find(
        (rate) => rate?.date === currentDates.startDate.format('YYYY-MM-DD'),
      );
      setFilteredRates(matchingRate);
      setIsLoadingPlans(false);
    } else {
      if (additionalRequestsCount < maxCallGetReference) {
        setIsLoadingPlans(true);
        setAdditionalRequestsCount((count) => count + 1);
        const newStartDate = moment(startDate).add(
          maxCallDays * additionalRequestsCount,
          'days',
        );
        const newEndDate = moment(endDate).add(
          maxCallDays * additionalRequestsCount,
          'days',
        );

        if (!filteredReferences) {
          setShowCalendarUnavailable(true);
          setIsLoadingPlans(false);
        } else {
          getReferenceRate(
            {
              startDate: newStartDate,
              endDate: newEndDate,
            },
            parseInt(unitId, 10),
          );
        }
      }
      if (additionalRequestsCount > maxLoop) {
        setShowCalendarUnavailable(true);
        setIsLoadingPlans(false);
      }
    }
    /* eslint-disable-next-line */
  }, [filteredReferences]);

  useEffect(() => {
    if (endDate === null) {
      return;
    }

    const newStartDate = moment(property?.openFrom);
    const numberNights = endDate.diff(startDate, 'days');
    const newEndDate = newStartDate.clone().add(numberNights, 'days');
    const formattedNewEndDate = moment(newEndDate.format('YYYY-MM-DD'));
    if (status === Status.CLOSED) {
      setCurrentDates({
        startDate: newStartDate,
        endDate: formattedNewEndDate,
      });
    } else {
      setCurrentDates({ startDate, endDate });
    }
  }, [startDate, endDate, status, property.openFrom]);

  useEffect(() => {
    if (endDate === null) {
      return;
    }
    const newStartDate = moment(property?.openFrom);
    const numberNights = endDate.diff(startDate, 'days');
    const newEndDate = newStartDate.clone().add(numberNights, 'days');
    const formattedNewEndDate = moment(newEndDate.format('YYYY-MM-DD'));
    const fetchData = async () => {
      try {
        if (selectedUnit && filteredReferences) {
          /* In other words: there are not invalid dates beetween start and end dates */
          const isDateRangeValid = (startDay, endDay) => {
            let currentDate = startDay.clone();
            let ocurrency = false;
            while (
              !currentDate.isSame(endDay) &&
              currentDate.isBefore(endDay.clone().add(1, 'day'))
            ) {
              /* eslint-disable */
              const roomRateDetail =
                selectedUnitRatePlan?.roomRateDetailed.find(
                  (rate) => rate.date === currentDate.format('YYYY-MM-DD'),
                );
              /* eslint-enable */
              if (roomRateDetail && roomRateDetail.roomsAvailable < 1) {
                ocurrency = true;
              }
              currentDate = currentDate.clone().add(1, 'days');
            }
            return !ocurrency;
          };
          const { startDate: startDay, endDate: endDay } = currentDates;
          if (startDay && endDay) {
            setAreAnyError(false);
            let startRoomRateDetail;
            let endRoomRateDetail;
            if (isCampingClosed) {
              startRoomRateDetail = filteredReferences.find(
                (rate) => rate.date === moment(openFrom).format('YYYY-MM-DD'),
              );
              endRoomRateDetail = filteredReferences.find(
                (rate) =>
                  rate.date ===
                  moment(openFrom).add(14, 'days').format('YYYY-MM-DD'),
              );
            } else {
              startRoomRateDetail = filteredReferences.find(
                (rate) => rate.date === startDay.format('YYYY-MM-DD'),
              );
              endRoomRateDetail = filteredReferences.find(
                (rate) => rate.date === endDay.format('YYYY-MM-DD'),
              );
            }

            let startDateRates;
            let endDateRates;

            if (status === Status.CLOSED) {
              startDateRates = newStartDate && startRoomRateDetail;
              endDateRates = formattedNewEndDate && endRoomRateDetail;
            } else {
              startDateRates = startDay && startRoomRateDetail;
              endDateRates = endDay && endRoomRateDetail;
            }
            let areThereAnyError = false;

            if (!startDay || !endDay) {
              areThereAnyError = true;
            }

            if (startDateRates && startDateRates.closedToArrival) {
              setError(
                <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidCheckin" />,
              );
              areThereAnyError = true;
            }

            if (endDateRates && endDateRates.closedToDeparture) {
              setError(
                <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidCheckout" />,
              );
              areThereAnyError = true;
            }

            const arrayDates = await createDatesArray(
              startDateAvailable,
              endDateAvailable,
            );
            const isClosed = arrayDates.some((date) => {
              const rateForDate = filteredReferences.find(
                (item) => item.date === date,
              );
              return rateForDate && rateForDate.closed;
            });

            if (isClosed) {
              areThereAnyError = true;
            } else {
              const maxMinStay = arrayDates
                .map((date) =>
                  filteredReferences.find((item) => item.date === date),
                )
                .filter((rateDetail) => rateDetail && !rateDetail.closed) // Asegurarse de excluir tasas cerradas en este paso también
                .reduce(
                  (maxStay, rateDetail) =>
                    Math.max(maxStay, rateDetail.minStay),
                  0,
                );

              const daysBetween = calcDaysBetweenCheckInAndCheckOut({
                startDate: moment.utc(startDateAvailable),
                endDate: moment.utc(endDateAvailable),
              });
              if (maxMinStay > daysBetween) {
                setError(
                  <FormattedMessage
                    id="AvailabilityDrawer.AvailabilityCalendar.MinNight"
                    values={{ count: maxMinStay, br: ' ' }}
                  />,
                );
                areThereAnyError = true;
              } else {
                setError(null);
                areThereAnyError = false;
              }
            }
            if (startDay && endDay && !isDateRangeValid(startDay, endDay)) {
              setError(
                <FormattedMessage id="AvailabilityDrawer.AvailabilityCalendar.Error.NoValidRange" />,
              );
              areThereAnyError = true;
            }

            setCheckedErrors(true);
            setAreAnyError(areThereAnyError);
          }
        }
      } catch (error2) {
        // eslint-disable-next-line no-console
        console.error(error2);
      }
    };

    fetchData();
  }, [
    reservation,
    startDate,
    property,
    currentDates,
    selectedUnit,
    setAreAnyError,
    selectedUnitRatePlan,
    daysBetweenCheckInAndCheckOut,
    property.openFrom,
    status,
    isCampingClosed,
    endDate,
    openFrom,
    createDatesArray,
    filteredReferences,
    startDateAvailable,
    endDateAvailable,
  ]);

  return (
    <AvailabilityContext.Provider
      value={{
        areAnyError,
        askForOnePropertyRatePlan,
        getReferenceRate,
        availableUnits,
        checkedErrors,
        currentDates,
        dispatch,
        error,
        fetchingUnits:
          !initialCharge.current &&
          filteredReferences &&
          filteredReferences.length === 0,
        initialCharge,
        loadingRatePlans,
        onError: setError,
        property,
        ratePlans,
        reservationInitialDates,
        resetErrors: () => {
          setAreAnyError(false);
          setError(null);
        },
        selectedUnit,
        selectedUnitRatePlan,
        filteredReferences,
        setAreAnyError,
        setCurrentDates,
        setLoadingRatePlans,
        setReservationInitialDates,
        setSelectedUnit,
        setShowMoreDatesDrawer,
        showMoreDatesDrawer,
        filteredRates,
        showCalendarUnavailable,
        relaunchGetReference,
        setRelaunchGetReference,
        isLoadingPlans,
        startDateAvailable,
        setStartDateAvailable,
        endDateAvailable,
        setEndDateAvailable,
      }}
    >
      {children}
    </AvailabilityContext.Provider>
  );
};

AvailabilityProvider.propTypes = {
  children: PropTypes.element.isRequired,
  property: PropTypes.oneOfType([PropTypes.object]).isRequired,
  unitId: PropTypes.string,
};

AvailabilityProvider.defaultProps = {
  unitId: undefined,
};

export { AvailabilityProvider };

export default AvailabilityContext;
