/* eslint-disable */
import { navigate } from '@reach/router';
import { Button, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as KampaohIcon } from '../../assets/icons/kampaoh.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/left-arrow.svg';
import { ReactComponent as ReturnIcon } from '../../assets/icons/return.svg';
import ActionButton from '../../components/ActionButton';
import CustomSpin from '../../components/CustomSpin';
import FloatingBar from '../../components/FloatingBar';
import FooterCondition from '../../components/Footer/FooterCondition';
import routes from '../../constants/ApServer/apRoutes';
import { allowedMethods } from '../../constants/ApServer/apServer';
import { LanguageContext } from '../../locale/contexts/Language';
import ROUTES from '../../routes/routes';
import makeRequestToApServer from '../../services/ApServer/askToApServer';
import { useAuthState } from '../../services/Auth/useAuth';
import useCampings from '../../services/Campings/useCampings';
import {
  endDateGraterThanStartDate,
  startDateAndEndDateAreValid,
} from '../../services/checkValidURLParams';
import {
  useReservationDispatch,
  useReservationState,
} from '../../services/Reservation';
import useFetchDateRatePlan from '../../utils/useFetchDateRatePlan';
import Stepper from './components/Stepper';
import { handleOnClick, onGoBack, onGoBackToStep, steps } from './components/Stepper/utils';
import { backButton, header, nextStep, title } from './index.module.scss';
import { getButtonMessage, showModal } from './utils';

export const CheckoutContext = React.createContext();

const Checkout = ({ children }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [disabledButton, setDisabledButton] = useState(true);
  const [allConditionsAccepted, setAllConditionsAccepted] = useState(false);
  const [checkingReservationUpdates, setCheckingReservationUpdates] =
    useState(false);
  const dispatch = useReservationDispatch();
  const reservation = useReservationState();
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const propertyFromURL = searchParams.get('property');
  const roomFromURL = searchParams.get('room');
  const startDateFromURL = searchParams.get('checkin');
  const endDateFromURL = searchParams.get('checkout');
  const adultsFromURL = parseInt(searchParams.get('adults'), 10);
  const childrenFromURL = parseInt(searchParams.get('children'), 10);
  const babiesFromURL = parseInt(searchParams.get('babies'), 10);
  const boardFromURL = parseInt(searchParams.get('board'), 10);
  const rateFromURL = parseInt(searchParams.get('rate'), 10);
  const promotionFromURL = parseInt(searchParams.get('promotion'), 10);
  const offerFromURL = parseInt(searchParams.get('offer'), 10);
  const fpaFromURL = searchParams.get('fpa');
  const { currentLanguage } = useContext(LanguageContext);
  const [filteredRatePlans, setFilteredRatePlans] = useState();
  const [policiesDone, setPoliciesDone] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const { accessToken } = useAuthState();
  const buttonMessage = useMemo(
    () => getButtonMessage(currentStep, intl),
    [intl, currentStep],
  );

  const formattedStartDateFromURL = startDateFromURL
    ? moment(startDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD'])?.format(
        'YYYY-MM-DD',
      )
    : null;
  const formattedEndDateFromURL = endDateFromURL
    ? moment(endDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD'])?.format('YYYY-MM-DD')
    : null;

  const { selectedHotelId } = reservation;
  const {
    state: {
      campings: { [selectedHotelId || propertyFromURL]: camping },
    },
    askForOneCampingRatePlans,
    tentState: {
      tents: { [roomFromURL]: tent },
    },
  } = useCampings();

  const isPaymentStepOrAfter = currentStep >= 3;
  const isResume = currentStep === 0;
  const property = camping;
  const askForOneRatePlan = askForOneCampingRatePlans;

  useEffect(() => {
    if (accessToken !== null) {
      makeRequestToApServer(
        allowedMethods.get,
        routes.authMe,
        undefined,
        (response) => {
          if (response.statusCode === 200) setUserInfo(response.data);
        },
        () => {},
      );
    }
  }, [accessToken]);

  useEffect(() => {
    const isDisabled = async () => {
      switch (`${currentStep}`) {
        case '0': {
          setDisabledButton(false);
          break;
        }
        case '1': {
          setDisabledButton(allConditionsAccepted);
          break;
        }
        case '2': {
          setDisabledButton(false);
          break;
        }

        case '4': {
          setDisabledButton(false);
          break;
        }

        default: {
          setDisabledButton(false);
          break;
        }
      }
    };

    isDisabled();
  }, [currentStep, allConditionsAccepted, form]);

  useEffect(() => {
    if (currentStep) {
      window.scrollTo(0, 0);
    }
  }, [currentStep]);

  useEffect(() => {
    if (checkingReservationUpdates) {
      askForOneRatePlan(reservation, property?.ulysesId);
    }
  }, [checkingReservationUpdates]);

  useEffect(() => {
    if (!propertyFromURL || !roomFromURL) {
      return;
    }
    let isMounted = true;
    async function askForOnePropertyRatePlan() {
      let startDateParam = formattedStartDateFromURL;
      let endDateParam = formattedEndDateFromURL;
      if (
        startDateAndEndDateAreValid(startDateParam, endDateParam) &&
        !endDateGraterThanStartDate(startDateParam, endDateParam)
      ) {
        startDateParam = formattedStartDateFromURL;
        endDateParam = moment(formattedStartDateFromURL)?.add(2, 'days');
      }
      const dateRatePlan = await useFetchDateRatePlan({
        propertyFromURL,
        roomFromURL,
        startDateParam: formattedStartDateFromURL,
        endDateParam: formattedEndDateFromURL,
        adultsFromURL,
        childrenFromURL,
        currentLanguage,
      });
      if (!isMounted) return;
      const dataPlan = dateRatePlan?.map((ratePlan) => ({
        code: ratePlan?.code,
        id: ratePlan?.id,
        name: ratePlan?.name,
        plans: ratePlan?.plans?.filter((plan) => plan?.available),
      }));
      const filteredPlan = dataPlan
        ?.filter((board) => board?.id === boardFromURL)
        .map((board) => ({
          ...board,
          plans: board?.plans?.filter(
            (plan) =>
              plan?.rateId === rateFromURL &&
              ((!plan?.offer && !offerFromURL) ||
                (plan?.offer &&
                  plan?.offer?.id === offerFromURL &&
                  offerFromURL)) &&
              ((!plan?.promotion && !promotionFromURL) ||
                (plan?.promotion &&
                  plan?.promotion?.id === promotionFromURL &&
                  promotionFromURL)),
          ),
        }))
        .filter((board) => board?.plans?.length > 0);
      setFilteredRatePlans(filteredPlan);
    }
    askForOnePropertyRatePlan();
    return () => {
      isMounted = false;
    };
  }, [
    formattedEndDateFromURL,
    formattedStartDateFromURL,
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
    currentLanguage,
    children,
    roomFromURL,
    propertyFromURL,
  ]);

  const planInfo = filteredRatePlans && filteredRatePlans[0]?.plans[0];

  useEffect(() => {
    let startDateParam = formattedStartDateFromURL;
    let endDateParam = formattedEndDateFromURL;
    if (checkingReservationUpdates) {
      if (checkingReservationUpdates) {
        if (!planInfo) {
          showModal({
            title: intl.formatMessage({
              id: 'Checkout.AvailabilityUpdatedModal.Title',
            }),
            content: intl.formatMessage({
              id: 'Checkout.AvailabilityUpdatedModal.Content',
            }),
            handleOk: () => {
              navigate(ROUTES.home);
            },
            handleCancel: () => {
              onGoBack(
                currentStep,
                setCurrentStep,
                reservation,
                propertyFromURL,
                roomFromURL,
                promotionFromURL,
                offerFromURL,
                rateFromURL,
                boardFromURL,
                childrenFromURL,
                babiesFromURL,
                adultsFromURL,
                startDateFromURL,
                endDateFromURL,
                fpaFromURL,
              );
            },
          });
        } else if (
          planInfo &&
          planInfo?.totalPrice &&
          filteredRatePlans[0]?.plans[0]?.totalPrice !== planInfo?.totalPrice
        ) {
          dispatch({ type: 'setTotalPrice', payload: planInfo?.totalPrice });
          showModal({
            title: intl.formatMessage({
              id: 'Checkout.PriceUpdatedModal.Title',
            }),
            content: intl.formatMessage(
              { id: 'Checkout.PriceUpdatedModal.Content' },
              {
                previousPrice: reservation.totalPrice,
                actualPrice: planInfo?.totalPrice,
              },
            ),
            handleOk: () => {
              steps[currentStep].actions(reservation);
              setCurrentStep((step) => step + 1);
              navigate(ROUTES.book + steps[currentStep + 1].route);
            },
            handleCancel: () => {
              onGoBack(
                currentStep,
                setCurrentStep,
                reservation,
                propertyFromURL,
                roomFromURL,
                promotionFromURL,
                offerFromURL,
                rateFromURL,
                boardFromURL,
                childrenFromURL,
                babiesFromURL,
                adultsFromURL,
                startDateFromURL,
                endDateFromURL,
                fpaFromURL,
              );
              // navigate(`${ROUTES.roomDetails}/${property.ulysesId}/${reservation.selectedRoomTypeId}/${reservation.selectedRateId}?checkin=${reservation.startDate.format('YYYY-MM-DD')}&checkout=${reservation.endDate.format('YYYY-MM-DD')}&adults=${reservation.adults}&children=${reservation.children}&babies=${reservation.babies}`);
            },
          });
        } else if (
          planInfo &&
          planInfo?.roomRateDetailed?.every(
            (element) => element.available !== true,
          )
        ) {
          showModal({
            title: intl.formatMessage({
              id: 'Checkout.AvailabilityUpdatedModal.Title',
            }),
            content: intl.formatMessage({
              id: 'Checkout.AvailabilityUpdatedModal.Content',
            }),
            handleOk: () => {
              navigate(ROUTES.home);
            },
            handleCancel: () => {
              navigate(ROUTES.home);
            },
          });
        } else {
          steps[currentStep].actions(reservation);
          setCurrentStep((step) => step + 1);
          navigate(
            ROUTES?.book +
              steps[currentStep + 1].route +
              `?checkin=${moment(startDateParam)?.format(
                'YYYY-MM-DD',
              )}&checkout=${moment(endDateParam)?.format('YYYY-MM-DD')}${
                rateFromURL && `&rate=${rateFromURL}`
              }${boardFromURL && `&board=${boardFromURL}`}${
                offerFromURL !== null &&
                offerFromURL !== undefined &&
                !Number.isNaN(offerFromURL)
                  ? `&offer=${offerFromURL}`
                  : ''
              }${`&adults=${adultsFromURL}`}${`&children=${childrenFromURL}`}${`&babies=${babiesFromURL}`}${
                promotionFromURL !== null
                  ? `&promotion=${promotionFromURL}`
                  : ''
              }${propertyFromURL ? `&property=${propertyFromURL}` : ''}${
                roomFromURL ? `&room=${roomFromURL}` : ''
              }${fpaFromURL ? `&fpa=${fpaFromURL}` : ''}`,
          );
        }
        setCheckingReservationUpdates(false);
      }
    }
  }, [property, filteredRatePlans]);

  return camping ? (
    <>
      <Button
        className={backButton}
        shape="circle"
        icon={<ReturnIcon />}
        onClick={() =>
          onGoBack(
            currentStep,
            setCurrentStep,
            reservation,
            propertyFromURL,
            roomFromURL,
            promotionFromURL,
            offerFromURL,
            rateFromURL,
            boardFromURL,
            childrenFromURL,
            babiesFromURL,
            adultsFromURL,
            startDateFromURL,
            endDateFromURL,
            fpaFromURL
          )
        }
        data-cy="checkout-back-button"
      />
      <div className={header}>
        <KampaohIcon />
      </div>
      <div className={title}>
        <h1>{steps[currentStep].title}</h1>
      </div>

      <Stepper
        currentStep={currentStep}
        stepsTotalNumber={steps.length}
        onClick={(newStep) =>
          onGoBackToStep(
            currentStep,
            setCurrentStep,
            reservation,
            propertyFromURL,
            roomFromURL,
            promotionFromURL,
            offerFromURL,
            rateFromURL,
            boardFromURL,
            childrenFromURL,
            babiesFromURL,
            adultsFromURL,
            startDateFromURL,
            endDateFromURL,
            fpaFromURL,
            newStep
          )
        }
      />

      <CheckoutContext.Provider
        value={{
          onComplete: setAllConditionsAccepted,
          form,
          onFinish: () => navigate(ROUTES.confirmation),
          setCheckingReservationUpdates,
          setCurrentStep,
          currentStep,
          filteredRatePlans,
          tent,
          setPoliciesDone,
          policiesDone,
          userInfo,
          promotionFromURL,
          offerFromURL,
        }}
      >
        {children}
      </CheckoutContext.Provider>

      {isResume ? <FooterCondition /> : null}

      {!isPaymentStepOrAfter && (
        <div className={nextStep}>
          <FloatingBar>
            <ActionButton
              onClick={() =>
                handleOnClick({
                  currentStep,
                  reservation,
                  property,
                  dispatch,
                  setCurrentStep,
                  setCheckingReservationUpdates,
                  form,
                  intl,
                  filteredRatePlans,
                  tent,
                  propertyFromURL,
                  roomFromURL,
                  promotionFromURL,
                  offerFromURL,
                  rateFromURL,
                  boardFromURL,
                  childrenFromURL,
                  babiesFromURL,
                  adultsFromURL,
                  startDateFromURL,
                  endDateFromURL,
                  camping,
                  fpaFromURL,
                })
              }
              loading={checkingReservationUpdates}
              icon={<ArrowIcon />}
              disabled={disabledButton}
              data-cy="checkout-next-button"
            >
              {buttonMessage}
            </ActionButton>
          </FloatingBar>
        </div>
      )}
    </>
  ) : (
    <CustomSpin />
  );
};

Checkout.propTypes = {
  children: PropTypes.element.isRequired,
};

Checkout.defaultProps = {};
export default Checkout;
