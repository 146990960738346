/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Button } from 'antd';
import { navigate } from '@reach/router';
import { useIntl } from 'react-intl';
import {
  navigationBackButtonStyle,
  navigationBackButtonStyleProperty,
} from './index.module.scss';
import {
  useReservationDispatch,
  useReservationState,
} from '../../services/Reservation';
import { ReactComponent as ReturnIcon } from '../../assets/icons/return.svg';
import useCampings from '../../services/Campings/useCampings';
import useDeviceDetect from '../../utils/useDeviceDetect';
import ROUTES from '../../routes/routes';
import { LanguageContext } from '../../locale/contexts/Language';

const GoingBackButton = ({ onClick, status, fromHeader }) => {
  const dispatchReservation = useReservationDispatch();
  const reservation = useReservationState();
  const { dispatch: dispatchCampings } = useCampings();
  const { pathname } = window.location;
  const parts = pathname.split('/');
  const propertyId = window.location.pathname.replace('/property/', '');
  const propertyRoomId = parts[2];
  const {
    state: {
      campings: { [propertyRoomId || propertyId]: camping },
    },
  } = useCampings();
  const property = camping;
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const fpa = searchParams.get('fpa');
  const filters = searchParams.get('filters');
  const intl = useIntl();
  const isPropertyRoute = window.location.href.includes(ROUTES.propertyDetails);
  const isRoomRoute = window.location.href.includes(ROUTES.roomDetails);
  const isPlacesRoute = window.location.href.includes(ROUTES.places);
  const { isMobile } = useDeviceDetect();
  const { currentLanguage } = useContext(LanguageContext);

  return (
    <Button
      // eslint-disable-next-line max-len
      className={
        isPropertyRoute || isRoomRoute
          ? navigationBackButtonStyleProperty
          : navigationBackButtonStyle
      }
      style={
        status
          ? isMobile
            ? { top: '80px' }
            : { top: '150px' }
          : fromHeader
          ? isMobile
            ? { top: '25px' }
            : { top: '87px' }
          : {}
      }
      icon={<ReturnIcon />}
      shape="circle"
      onClick={async () => {
        dispatchReservation({ type: 'setItAsReadyToBeUsed' });
        onClick();
        if (isRoomRoute) {
          dispatchReservation({ type: 'setRatePlansAsNotFetched' });
          dispatchCampings({ type: 'resetHotelsInitialState' });
          dispatchCampings({ type: 'setRatePlansFetched', payload: false });
          navigate(`${ROUTES.propertyDetails}/${propertyRoomId}?checkin=${reservation.startDate.format('YYYY-MM-DD')}&checkout=${reservation.endDate.format('YYYY-MM-DD')}&adults=${reservation.adults}&children=${reservation.children}&babies=${reservation.babies}&fpa=${fpa}${filters ? `&filters=${filters}` : ''}${reservation.preferredZoneId ? `&zone=${reservation.preferredZoneId}` : ''}&lang=${currentLanguage}`);
        } else if (isPlacesRoute) {
          navigate(
            `${ROUTES.home}?checkin=${reservation.startDate.format(
              'YYYY-MM-DD',
            )}&checkout=${reservation.endDate.format('YYYY-MM-DD')}&adults=${
              reservation.adults
            }&children=${reservation.children}&babies=${reservation.babies}`,
          );
          dispatchReservation({
            type: 'setPreferredZoneName',
            payload: undefined,
          });
          dispatchReservation({
            type: 'setPreferredZoneId',
            payload: undefined,
          });
        } else if (isPropertyRoute) {
          dispatchCampings({ type: 'setItAsReadyToBeUsed' });
          dispatchCampings({ type: 'setRatePlansAsNotFetched' });
          navigate(`${ROUTES.places}?checkin=${reservation.startDate.format('YYYY-MM-DD')}&checkout=${reservation.endDate.format('YYYY-MM-DD')}&adults=${reservation.adults}&children=${reservation.children}&babies=${reservation.babies}&fpa=${fpa}&lang=${currentLanguage}${filters ? `&filters=${filters}` : ''}&zone=${property?.region?.zone[0]?.internalID}`);
        } else {
          dispatchReservation({ type: 'setRatePlansAsNotFetched' });
          dispatchCampings({ type: 'resetHotelsInitialState' });
          navigate(-1, { replace: true });
        }
      }}
    >
      {isPropertyRoute ? (
        <span>
          {intl.formatMessage({ id: 'Property.BackButton.Text' })}
          {property?.region?.name}
        </span>
      ) : (
        isRoomRoute && <span>{property?.propertyName}</span>
      )}
    </Button>
  );
};

GoingBackButton.propTypes = {
  onClick: PropTypes.func,
  status: PropTypes.bool.isRequired,
  fromHeader: PropTypes.bool.isRequired,
};

GoingBackButton.defaultProps = {
  onClick: () => {},
};
export default GoingBackButton;
