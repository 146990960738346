import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import reducer from './reducer';
import initialState from './initialState';
import sanitizeApiResponse from '../../utils/sanitizeApiResponse';
import locationsQuery from '../GraphQL/Home/locationsQuery';

export const LocationsState = createContext();
export const LocationsDispatch = createContext();

const LocationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading: loadingLocations, data: dataLocations } = useQuery(locationsQuery);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        dispatch({ type: 'setIsLoadingLocations', payload: true });
        if (!loadingLocations) {
          const locations = await dataLocations;
          dispatch({
            type: 'allLocationsFound',
            payload: sanitizeApiResponse(locations.campings),
          });
          dispatch({ type: 'setIsLoadingLocations', payload: false });
        }
      } catch (error) {
        dispatch({ type: 'setIsLoadingLocations', payload: false });
        throw new Error(error);
      }
    };
    fetchLocations();
  }, [loadingLocations, dataLocations]);

  return (
    <LocationsState.Provider value={state}>
      <LocationsDispatch.Provider value={dispatch}>
        {children}
      </LocationsDispatch.Provider>
    </LocationsState.Provider>
  );
};

LocationsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LocationsProvider;
