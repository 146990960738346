import React from 'react';
import PropTypes from 'prop-types';
// import GoogleMapReact from 'google-map-react';
// import { ReactComponent as Pointer } from '../../../assets/icons/mapmarker.svg';
// import { iconMapStyle } from './index.module.scss';

// const AnyReactComponent = () => <Pointer className={iconMapStyle} />;

const SimpleMap = ({ zoom, center }) => (

  <iframe
    title="Test"
    width="100%"
    height="500px"
    frameBorder="0"
    style={{
      border: 0,
      marginBottom: '40px',
      borderRadius: '6px',
      // {{ height: '500px', width: '100%', marginBottom: '40px' }}
    }}
    src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyBnAfKXrXbEsc9DAYxayUT_IP3ob5inmGI&q='.concat(center.lat, ',', center.lng, '&zoom=', zoom)}
  />
);

SimpleMap.defaultProps = {
  center: {
    lat: 59.95,
    lng: 30.33,
  },
  zoom: 11,
};

SimpleMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
};

export default SimpleMap;
