/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Popover, Spin } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import CustomCarousel from '../../../components/CustomCarousel';
import YoutubeEmbed from '../../../components/YoutubeVideo';
import { URL } from '../../../constants/cms';
import { LanguageContext } from '../../../locale/contexts/Language';
import useCampings from '../../../services/Campings/useCampings';
import {
  SpinnerContainerStyle,
  body,
  campingDescriptionWrapper,
  campingFeaturesWrapper,
  cardScore,
  carouselWrapper,
  closedTag,
  divider,
  filtersWrapper,
  infoIcon,
  locationText,
  mapWrapper,
  otherSuggestedProperties,
  placesPage,
  property as propertyStyle,
  reservationZone,
  scoreWrapper,
  tagsClosedWrapper,
  tagsWrapper,
  tentSelectorWrapper,
  title,
  titleWrapper,
  tooltip,
  videoWrapper,
  wrapper,
} from '../index.module.scss';
import SimpleMap from '../shared/Map';
// import ShareButton from '../../../components/ShareButton';
import { ReactComponent as LocateIcon } from '../../../assets/icons/location.svg';
import CommonLabels from '../../../components/CommonLabels';
import FeaturesListWithEllipsis from '../../../components/FeaturesListWithEllipsis';
import FeaturesTags from '../../../components/FeaturesTags';
import FloatingBar from '../../../components/FloatingBar';
import MarkdownWithEllipsis from '../../../components/MarkdownWithEllipsis';
import ResponsiveMenu from '../../../components/ResponsiveMenu/ResponsiveMenu';
import Score from '../../../components/Score';
import Tag from '../../../components/Tag';
import TopBanner from '../../../components/TopBanner';
import {
  defaultAdults,
  defaultBabies,
  defaultChildren,
} from '../../../constants/defaultsValues';
import Services from '../../../constants/services';
import ROUTES from '../../../routes/routes';
import {
  useReservationDispatch,
  useReservationState,
} from '../../../services/Reservation';
import { useTopBannerState } from '../../../services/TopBanner/Provider';
import {
  endDateGraterThanStartDate,
  startDateAndEndDateAreValid,
} from '../../../services/checkValidURLParams';
import decideImageSize from '../../../utils/decideImageSize';
import getIconsForEachFeature from '../../../utils/getIconsForEachFeature';
import useDateRangeValidator from '../../../utils/useDateRangeValidator';
import CampingCard from '../../Places/Camp/components/Cards/components/Card/index';
import CampingRating from '../shared/CampingRating';
import TentSelector from './components/TentSelector';

const Divider = () => <div className={divider} />;

const PropertyDetails = ({ propertyId }) => {
  const url = window.location;
  const searchParams = new URLSearchParams(url.search);
  const startDateFromURL = searchParams.get('checkin');
  const endDateFromURL = searchParams.get('checkout');
  const adultsFromURL = parseInt(searchParams.get('adults'), 10);
  const childrenFromURL = parseInt(searchParams.get('children'), 10);
  const babiesFromURL = parseInt(searchParams.get('babies'), 10);
  const zoneId = parseInt(searchParams.get('zone'), 10);
  const langFromUrl = searchParams.get('lang');
  const fpaFromUrl = searchParams.get('fpa');

  const intl = useIntl();
  const tentSelectorRef = useRef();
  const {
    state: {
      campings: { [propertyId]: camping },
    },
  } = useCampings();
  const { currentLanguage } = useContext(LanguageContext);
  const reservation = useReservationState();
  const mapRef = useRef();
  const { kampaohService } = useReservationState();
  const dispatch = useReservationDispatch();
  const { status } = useTopBannerState();
  const [fpaFilter, setFpaFilter] = useState('none');
  const property = camping;
  const isACamping = kampaohService === Services.CAMP;
  const openFrom = property?.openFrom;
  const openTo = property?.openTo;
  const isPropertyRoute = window.location.href.includes(ROUTES.propertyDetails);

  const maxBabies = 3;
  const maxAdults = 6;
  const maxGuess = 9;

  const {
    photos,
    mainLabel,
    labels,
    propertyName,
    region,
    location,
    score,
    features,
    youtubeVideoId,
    reviews,
    nature,
    party,
    relax,
    family,
  } = property || {};

  const isThereScoreInfo = () =>
    reviews && score && nature && party && relax && family;

  // TODO
  // const handleOnWhatsappClick = () => {
  //   window.open(`https://api.whatsapp.com/send?text=He encontrado este precioso ❤ property. Échale un ojo en ${encodeURIComponent(window.location.href)}`);
  // };

  const formatUrlPaxParams = useCallback(() => {
    if (adultsFromURL < 0 || childrenFromURL < 0 || babiesFromURL < 0) {
      searchParams.set('adults', defaultAdults);
      searchParams.set('babies', defaultBabies);
      searchParams.set('children', defaultChildren);
      window.location.search = searchParams.toString();
    }
  }, [searchParams, adultsFromURL, babiesFromURL, childrenFromURL]);
  formatUrlPaxParams();

  const formattedStartDateFromURL = startDateFromURL
    ? moment(startDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(
        'YYYY-MM-DD',
      )
    : null;
  const formattedEndDateFromURL = endDateFromURL
    ? moment(endDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD')
    : null;

  useEffect(() => {
    searchParams.set(
      'checkin',
      moment(startDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD']).format(
        'YYYY-MM-DD',
      ),
    );
    searchParams.set(
      'checkout',
      moment(endDateFromURL, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD'),
    );
  }, [startDateFromURL, endDateFromURL, searchParams]);

  useEffect(() => {
    const service = Services.CAMP;
    if (service !== kampaohService) {
      dispatch({ type: 'setKampaohService', payload: service });
    }
  }, [camping, kampaohService, dispatch]);

  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };
  useEffect(() => {
    // If dates are valid and endDate is not greater than startDate
    if (
      startDateAndEndDateAreValid(
        formattedStartDateFromURL,
        formattedEndDateFromURL,
      ) &&
      !endDateGraterThanStartDate(
        formattedStartDateFromURL,
        formattedEndDateFromURL,
      )
    ) {
      dispatch({
        type: 'setStartDate',
        payload: moment(formattedStartDateFromURL),
      });
      dispatch({
        type: 'setEndDate',
        payload: moment(formattedStartDateFromURL).add(2, 'days'),
      });
    }
    if (formattedStartDateFromURL)
      dispatch({
        type: 'setStartDate',
        payload: moment(formattedStartDateFromURL),
      });
    if (formattedEndDateFromURL)
      dispatch({
        type: 'setEndDate',
        payload: moment(formattedEndDateFromURL),
      });
    if (formattedStartDateFromURL && !formattedEndDateFromURL)
      dispatch({
        type: 'setEndDate',
        payload: moment(formattedStartDateFromURL).add(2, 'days'),
      });
    if (!formattedStartDateFromURL && formattedEndDateFromURL)
      dispatch({
        type: 'setStartDate',
        payload: moment(formattedEndDateFromURL).add(-2, 'days'),
      });
    if (
      !Number.isNaN(adultsFromURL) &&
      !Number.isNaN(childrenFromURL) &&
      !Number.isNaN(babiesFromURL)
    ) {
      dispatch({ type: 'setadults', payload: parseInt(adultsFromURL, 10) });
      dispatch({ type: 'setchildren', payload: parseInt(childrenFromURL, 10) });
      dispatch({ type: 'setbabies', payload: parseInt(babiesFromURL, 10) });
      setFpaFilter(fpaFromUrl);
      dispatch({ type: 'setItAsReadyToBeUsed' });
    }
  }, [
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
    formattedStartDateFromURL,
    formattedEndDateFromURL,
    fpaFromUrl,
    dispatch,
  ]);

  function getMonthName(openfrom) {
    const urlMonth = new URLSearchParams(window.location.href);
    const monthNameTranslation = urlMonth?.searchParams?.get('lang');

    const locale = monthNameTranslation || 'es-ES';
    const date = new Date(openfrom);
    return date.toLocaleString(locale, { month: 'long' });
  }

  function openSoonDate(openfrom, opento) {
    const openFromdate = new Date(openfrom);
    const openFromDay = openFromdate.getDate();
    const openFromNameMonth = getMonthName(openfrom);

    const openTodate = new Date(opento);

    const now = new Date();

    if (openFromdate < now && openTodate < now) {
      return `${intl.formatMessage({ id: 'Card.Button.Closed' })}`;
      
    }
    if (openFromdate > now && openTodate > now) {
      
      return `${intl.formatMessage({ id: 'Card.Button.openFrom' })} ${openFromDay}  ${intl.formatMessage({ id: 'Card.Button.midCharacterOpenSoon' })} ${openFromNameMonth}`;
    }


    return `${intl.formatMessage({ id: 'Card.Button.Closed' })}`;
  }

  const checkIfCampingIsClosed = useCallback(() => {
    const openFromDate = moment(openFrom, 'YYYY-MM-DD');
    const startDate = formattedStartDateFromURL;
    if (openFromDate && startDate && openFromDate.isSameOrAfter(startDate)) {
      return true;
    }
    return false;
  }, [openFrom, formattedStartDateFromURL]);

  // eslint-disable-next-line no-useless-escape
  const dateRegex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
  const dateRegexDateFormat =
    /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/;
  useEffect(() => {
    if (
      !startDateFromURL ||
      (!dateRegex.test(startDateFromURL) &&
        !dateRegexDateFormat.test(startDateFromURL))
    ) {
      searchParams.set('checkin', moment().format('YYYY-MM-DD'));
    }
    if (
      !endDateFromURL ||
      (!dateRegex.test(endDateFromURL) &&
        !dateRegexDateFormat.test(endDateFromURL))
    ) {
      searchParams.set(
        'checkout',
        moment(startDateFromURL)?.add(2, 'days')?.format('YYYY-MM-DD'),
      );
    }
    if (!adultsFromURL || !parseInt(adultsFromURL, 10) < 0) {
      searchParams.set('adults', defaultAdults);
    }
    if (!childrenFromURL || !parseInt(childrenFromURL, 10) < 0) {
      searchParams.set('children', defaultChildren);
    } else {
      searchParams.set('children', childrenFromURL);
    }
    if (!babiesFromURL || !parseInt(babiesFromURL, 10) < 0) {
      searchParams.set('babies', defaultBabies);
    }
    if (!langFromUrl) {
      searchParams.set('lang', currentLanguage);
    }
    if (!fpaFromUrl) {
      searchParams.set('fpa', 'none');
    }
    if (parseInt(babiesFromURL, 10) > 0) {
      const maxAllowedBabiesPerAdult = Math.round(
        (parseInt(adultsFromURL, 10) + parseInt(childrenFromURL, 10)) / 2,
      );
      if (parseInt(babiesFromURL, 10) > maxAllowedBabiesPerAdult) {
        searchParams.set('babies', maxAllowedBabiesPerAdult);
        window.location.href = url.toString();
      }
    }
    if (moment(startDateFromURL).isSameOrAfter(endDateFromURL)) {
      searchParams.set(
        'checkout',
        moment(startDateFromURL)?.add(2, 'days')?.format('YYYY-MM-DD'),
      );
    }
    const actualUrl = window.location;
    const actualSearchParams = new URLSearchParams(actualUrl.search);
    if (actualSearchParams.toString() !== searchParams.toString())
      updateUrlWithoutNavigation(`${url.pathname}?${searchParams.toString()}`);
  }, [
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
    dateRegex,
    endDateFromURL,
    startDateFromURL,
    fpaFromUrl,
    dateRegexDateFormat,
    url,
    searchParams,
    langFromUrl,
    currentLanguage,
  ]);

  const [decideImageSizeState, setDecideImageSizeState] = useState(
    decideImageSize(),
  );
  useEffect(() => {
    const mediaQuery = window.matchMedia('(orientation: portrait)');
    const handleOrientationChange = () => {
      setDecideImageSizeState(decideImageSize());
    };
    // Registry a Listener to detect changes in the orientation
    mediaQuery.addListener(handleOrientationChange);
    // Clean the listener when the component is umounted
    return () => {
      mediaQuery.removeListener(handleOrientationChange);
    };
  }, []);

  useDateRangeValidator();

  useEffect(() => {
    if (zoneId && isPropertyRoute) {
      searchParams.delete('zone');
      window.location.search = searchParams.toString();
    }
  }, [zoneId, searchParams, isPropertyRoute]);

  const updateQueryParams = useCallback(
    (key, value) => {
      if (value || typeof value === 'number') {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
      const urlSearchParams = searchParams.toString();
      const currentParams = new URLSearchParams(url.search).toString();
      if (currentParams !== urlSearchParams) {
        updateUrlWithoutNavigation(
          `${url.pathname}?${searchParams.toString()}`,
        );
      }
    },
    [searchParams, url],
  );
  useEffect(() => {
    let newAdults = adultsFromURL || defaultAdults;
    let newChildren = childrenFromURL || defaultChildren;
    let newBabies = babiesFromURL || defaultBabies;
    if (newBabies > maxBabies) {
      newBabies = defaultBabies;
    }
    let totalPeople = newAdults + newChildren;
    if (totalPeople > maxAdults) {
      if (newAdults >= maxAdults) {
        newAdults = maxAdults;
        newChildren = defaultChildren;
      } else {
        newChildren = maxAdults - newAdults;
      }
      totalPeople = newAdults + newChildren;
    }
    const totalCombined = totalPeople + newBabies;
    if (totalCombined > maxGuess) {
      const maxPeople = maxAdults - newBabies;
      if (newAdults > maxPeople) {
        newAdults = maxPeople;
        newChildren = defaultChildren;
      } else {
        newChildren = maxPeople - newAdults;
      }
    }
    if (newAdults !== adultsFromURL) {
      updateQueryParams('adults', defaultAdults);
    }
    if (newChildren !== childrenFromURL) {
      updateQueryParams('children', defaultChildren);
    }
    if (newBabies !== babiesFromURL) {
      updateQueryParams('babies', defaultBabies);
    }
    // eslint-disable-next-line max-len
  }, [
    adultsFromURL,
    childrenFromURL,
    babiesFromURL,
    updateQueryParams,
    maxBabies,
    maxAdults,
    maxGuess,
  ]);
  return property ? (
    <>
      <TopBanner />
      <ResponsiveMenu />
      <div className={wrapper}>
        <div
          className={carouselWrapper}
          style={
            status
              ? { borderRadius: '0px' }
              : { borderRadius: '12px 12px 0px 0px' }
          }
        >
          {photos.length > 0 && (
            <>
              <CustomCarousel
                photos={photos.map((image) =>
                  image.formats[decideImageSizeState] !== undefined
                    ? image.formats[decideImageSizeState].url.includes('https')
                      ? image.formats[decideImageSizeState].url
                      : `${URL}/uploads/${decideImageSizeState}_${image.hash}${image.ext}`
                    : `${URL}${image.url}`,
                )}
                photosPreview={photos.map((image) =>
                  image.formats.large !== undefined
                    ? image.formats.large.url.includes('https')
                      ? image.formats.large.url
                      : `${URL}/uploads/large_${image.hash}${image.ext}`
                    : `${URL}${image.url}`,
                )}
              />
              {/* TODO: This text should be obtained from the default list text in Strapi.
              /*        Although it is not implemented yet */}
              <Popover
                overlayStyle={{ paddingBottom: '0px', top: '100px' }}
                overlayClassName={tooltip}
                content={intl.formatMessage({
                  id: 'DetailsPage.photosNotLocation',
                })}
                trigger="click"
              >
                <InfoIcon className={infoIcon} />
              </Popover>
            </>
          )}
        </div>
        <div className={tagsClosedWrapper}>
          {openTo < formattedStartDateFromURL ? (
            <Tag className={closedTag}>
              {intl.formatMessage({ id: 'Card.Button.Closed' })}
            </Tag>
          ) : checkIfCampingIsClosed() === true ? (
            <Tag className={closedTag}>
              {openSoonDate(camping.openFrom, camping.openTo)}
            </Tag>
          ) : (
            <></>
          )}
        </div>
        <div className={body} style={{ paddingTop: '25px' }}>
          <div className={tagsWrapper}>
            {mainLabel && <Tag>{mainLabel}</Tag>}
            {labels && <CommonLabels labels={labels} />}
          </div>
          <div className={titleWrapper}>
            <div className={`${title} ${propertyStyle}`}>
              <h2>{propertyName}</h2>
              <p
                onClick={() =>
                  mapRef.current.scrollIntoView({ behavior: 'smooth' })
                }
              >
                {`${location}, ${region?.name}`}
              </p>
            </div>
            <div className={filtersWrapper}>
              <FeaturesTags features={features || []} />
            </div>
            <Score
              score={score || 5}
              className={`${cardScore} ${propertyStyle}`}
            />
          </div>
          {/* <ShareButton className={`${shareButton} ${propertyStyle}`} onWhatsappClick={handleOnWhatsappClick} /> */}
          <div className={campingDescriptionWrapper}>
            <MarkdownWithEllipsis source={property?.description?.description} />
          </div>
          {isACamping && property?.tents && property?.tents?.length > 0 && (
            <div className={tentSelectorWrapper} ref={tentSelectorRef}>
              <TentSelector
                reservation={reservation}
                propertyID={propertyId}
                type={kampaohService}
                fpaFilter={fpaFilter}
                setFpaFilter={setFpaFilter}
              />
            </div>
          )}
          <Divider />
          {features && (
            <div className={campingFeaturesWrapper}>
              <h2>
                {intl.formatMessage({
                  id: 'DetailsPage.CampingFeatures.Title',
                })}
              </h2>
              <FeaturesListWithEllipsis
                features={getIconsForEachFeature(
                  camping.features,
                  camping.features,
                )}
                language={currentLanguage}
              />
            </div>
          )}

          {youtubeVideoId && (
            <div className={videoWrapper}>
              <YoutubeEmbed embedId={youtubeVideoId} />
            </div>
          )}

          <Divider />

          {isThereScoreInfo() && (
            <div className={scoreWrapper}>
              <h2>{intl.formatMessage({ id: 'DetailsPage.Score.Title' })}</h2>
              <CampingRating
                rating={{
                  reviews,
                  score,
                  nature,
                  party,
                  relax,
                  family,
                }}
              />
            </div>
          )}
          <Divider />
          <div className={mapWrapper} ref={mapRef}>
            <h2>{intl.formatMessage({ id: 'DetailsPage.Map.Title' })}</h2>
            <SimpleMap
              zoom={10}
              center={{
                lat: property.latitude,
                lng: property.longitude,
              }}
            />
            <div className={locationText}>
              <LocateIcon />
              <p>{`${location ? `${location}, ` : ''} ${
                region ? region.name : ''
              }`}</p>
            </div>
          </div>
        </div>
        {isACamping &&
          property.campings_related &&
          property.campings_related.length > 0 && (
            <div className={otherSuggestedProperties}>
              <h3>
                {intl.formatMessage({
                  id: 'DetailsPage.OtherSuggestedProperties',
                })}
              </h3>
              <div className={placesPage}>
                {property.campings_related.map((relatedCamping) => (
                  <CampingCard
                    key={relatedCamping.ulysesId}
                    propertyID={relatedCamping.ulysesId}
                    type={Services.CAMP}
                  />
                ))}
              </div>
            </div>
          )}
      </div>
      <FloatingBar>
        <div className={reservationZone}>
          <Button
            onClick={() =>
              tentSelectorRef.current.scrollIntoView({ behavior: 'smooth' })
            }
          >
            {intl.formatMessage({
              id: `#DetailsPage.Button.${kampaohService}.Choose`,
            })}
          </Button>
        </div>
      </FloatingBar>
    </>
  ) : (
    <div className={SpinnerContainerStyle}>
      <Spin size="large" />
    </div>
  );
};

PropertyDetails.defaultProps = {
  propertyId: '',
};

PropertyDetails.propTypes = {
  propertyId: PropTypes.string,
};

export default PropertyDetails;
