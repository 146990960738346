import PropTypes from 'prop-types';
import React from 'react';
import useCampings from '../../../../../../../../services/Campings/useCampings';
import { useReservationState } from '../../../../../../../../services/Reservation';
import ConditionAcceptance from '../../../ConditionAcceptance';

const ReservationParticularConditionAcceptance = ({ checked, changeChecked }) => {
  const { selectedHotelId } = useReservationState();
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const propertyFromURL = searchParams.get('property');

  const {
    state: {
      campings: {
        [selectedHotelId || propertyFromURL]: {
          condition,
        },
      },
    },
  } = useCampings();

  return (
    <>
      {condition && (
      <ConditionAcceptance
        markdownSource={condition?.description}
        checked={checked}
        changeChecked={changeChecked}
        data-cy="checkout-step2-camping"
      />
      )}
    </>
  );
};

ReservationParticularConditionAcceptance.defaultProps = {
  checked: false,
  changeChecked: () => {},
};

ReservationParticularConditionAcceptance.propTypes = {
  checked: PropTypes.bool,
  changeChecked: PropTypes.func,
};

export default ReservationParticularConditionAcceptance;
