const data = (ratePlan) => {
  if (ratePlan[0]) {
    // const { nonRefundable, name, description } = ratePlan[0].propertyPolicy;
    return [
      /*
      {
        id: 1,
        icon: nonRefundable ? NonReenfundableIcon : FreeCancellationIcon,
        title: name,
        message: description,
      },
    {
      id: 2,
      icon: InsuranceIcon,
      title: 'ReservationInfo.CovidInsurance.title',
      message: 'ReservationInfo.CovidInsurance.message',
    },
    */
    ];
  }
  return [];
};

export default data;
