const MODES = {
  bottom: ({ bottom, innerHeight }) => (
    { isBottomIn: (bottom >= 0) && (bottom < innerHeight), bottomOffset: innerHeight - bottom }
  ),
  top: ({ top, innerHeight }) => (
    { isTopIn: (top >= 0) && (top < innerHeight), topOffset: top }
  ),
  full: ({ bottom, innerHeight, top }) => (
    { ...MODES.bottom({ bottom, innerHeight }), ...MODES.top({ top }) }
  ),
};

const isInViewport = (ref, mode = 'full') => {
  const { top, bottom } = ref.current.getBoundingClientRect();
  const { innerHeight } = window;
  return MODES[mode]({ top, bottom, innerHeight });
};

export default isInViewport;
