import actionsTypes from "./actionsTypes";

const reducer = (state, action) => {
  switch (action.type) {
    case actionsTypes.SET_TOKENS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case actionsTypes.SET_ACCESSTOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };
    case actionsTypes.SET_REFRESHTOKEN:
      return {
        ...state,
        refreshToken: action.payload.refreshToken,
      };
    case actionsTypes.LOGOUT:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
      };
    default:
      return state;
  }
};

export default reducer;
