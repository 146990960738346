import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useCountries } from '../../../../../utils/useCountries';
import makeRequestToApServer from '../../../../../services/ApServer/askToApServer';
import { allowedMethods } from '../../../../../constants/ApServer/apServer';
import routes from '../../../../../constants/ApServer/apRoutes';
import openNotification from '../../../../../components/NotificacionMessage';
import {
  form,
  formRow,
  formColumn,
  error,
  formActions,
  saveButton,
  customInput,
  customSelect,
} from './index.module.scss';

const IdentifierForm = ({ initialValues, onSave }) => {
  const intl = useIntl();
  const countriesArray = useCountries();
  const today = React.useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  const formik = useFormik({
    initialValues: {
      name: initialValues.name || '',
      surname: initialValues.surname || '',
      issuingCountry: initialValues.issuingCountry || '',
      documentNumber: initialValues.documentNumber || '',
      expirationDate: initialValues.expirationDate || '',
      content: initialValues.content || false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'IdentifierForm.Validation.Name.Required',
          }),
        ),
      surname: Yup.string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'IdentifierForm.Validation.Surname.Required',
          }),
        ),
      issuingCountry: Yup.string().required(
        intl.formatMessage({
          id: 'IdentifierForm.Validation.IssuingCountry.Required',
        }),
      ),
      documentNumber: Yup.string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'IdentifierForm.Validation.DocumentNumber.Required',
          }),
        ),
      expirationDate: Yup.date()
        .min(
          today,
          intl.formatMessage({
            id: 'IdentifierForm.Validation.ExpirationDate.Expired',
          }),
        )
        .required(
          intl.formatMessage({
            id: 'IdentifierForm.Validation.ExpirationDate.Required',
          }),
        ),
      content: Yup.bool().oneOf(
        [true],
        intl.formatMessage({
          id: 'IdentifierForm.Validation.Content.Required',
        }),
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      makeRequestToApServer(
        allowedMethods.put,
        routes.authMe,
        {
          name: values.name,
          surname: values.surname,
          identityDocument: {
            issuingCountry: values.issuingCountry,
            documentNumber: values.documentNumber,
            expirationDate: values.expirationDate,
            content: values.content,
          },
        },
        ({ statusCode }) => {
          if (statusCode === 200)
            openNotification(
              'success',
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Title.Text',
              }),
              intl.formatMessage({
                id: 'PrivateAreaSettings.Request.Success.Message.Text',
              }),
              () => {
                onSave({
                  name: values.name,
                  surname: values.surname,
                  identityDocument: {
                    issuingCountry: values.issuingCountry,
                    documentNumber: values.documentNumber,
                    expirationDate: values.expirationDate,
                    content: values.content,
                  },
                });
              },
              null,
            );
        },
        (err) => {
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      ).finally(() => {
        setSubmitting(false);
      });
    },
  });

  const sortedCountriesList = useMemo(
    () =>
      countriesArray
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((country) => (
          <option
            key={country.value}
            value={country.value}
            label={country.label}
          >
            {country.label}
          </option>
        )),
    [countriesArray],
  );

  const handleContentChange = (event) => {
    const isChecked = event.target.checked;
    formik.handleChange(event);
    formik.setFieldValue('content', isChecked);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={form}>
        <div className={formRow}>
          <label htmlFor="name">
            {intl.formatMessage({
              id: 'IdentifierForm.Validation.Name.Text',
            })}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className={error}>{formik.errors.name}</div>
          ) : null}
        </div>
        <div className={formRow}>
          <label htmlFor="surname">
            {intl.formatMessage({
              id: 'IdentifierForm.Validation.Surname.Text',
            })}
          </label>
          <input
            type="text"
            id="surname"
            name="surname"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.surname}
          />
          {formik.touched.surname && formik.errors.surname ? (
            <div className={error}>{formik.errors.surname}</div>
          ) : null}
        </div>
        <div className={formRow}>
          <span>
            {intl.formatMessage({
              id: 'IdentifierForm.Validation.IssuingCountry.Text',
            })}
          </span>
          <select
            className={customSelect}
            id="issuingCountry"
            name="issuingCountry"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.issuingCountry}
          >
            <option
              value=""
              label={intl.formatMessage({
                id: 'IdentifierForm.Validation.Country.Default',
              })}
            >
              {intl.formatMessage({
                id: 'IdentifierForm.Validation.Country.Default',
              })}
            </option>
            {sortedCountriesList}
          </select>
          {formik.touched.issuingCountry && formik.errors.issuingCountry ? (
            <div className={error}>{formik.errors.issuingCountry}</div>
          ) : null}
        </div>
        <div className={formRow}>
          <label htmlFor="documentNumber">
            {intl.formatMessage({
              id: 'IdentifierForm.Validation.DocumentNumber.Text',
            })}
          </label>
          <input
            type="text"
            id="documentNumber"
            name="documentNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.documentNumber}
          />
          {formik.touched.documentNumber && formik.errors.documentNumber ? (
            <div className={error}>{formik.errors.documentNumber}</div>
          ) : null}
        </div>
        <div className={formRow}>
          <label htmlFor="expirationDate">
            {intl.formatMessage({
              id: 'IdentifierForm.Validation.ExpirationDate.Text',
            })}
          </label>
          <input
            className={customInput}
            type="date"
            id="expirationDate"
            name="expirationDate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.expirationDate}
          />
          {formik.touched.expirationDate && formik.errors.expirationDate ? (
            <div className={error}>{formik.errors.expirationDate}</div>
          ) : null}
        </div>
      </div>
      <div className={form}>
        <div className={formColumn}>
          <input
            type="checkbox"
            id="content"
            name="content"
            onChange={handleContentChange}
            onBlur={formik.handleBlur}
            checked={formik.values.content}
          />
          <span>
            {intl.formatMessage({
              id: 'IdentifierForm.Validation.Content.Text',
            })}
          </span>
        </div>
      </div>
      {formik.touched.content && formik.errors.content ? (
        <div className={error}>{formik.errors.content}</div>
      ) : null}
      <div className={formActions}>
        <button
          type="submit"
          className={saveButton}
          disabled={formik.isSubmitting}
        >
          Guardar
        </button>
      </div>
    </form>
  );
};

IdentifierForm.propTypes = {
  initialValues: PropTypes.shape({
    gender: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    issuingCountry: PropTypes.string,
    documentNumber: PropTypes.string,
    expirationDate: PropTypes.string,
    content: PropTypes.bool,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default IdentifierForm;
