/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
// import { PropTypes } from 'prop-types';
import { Layout } from 'antd';
// import * as Styles from './index.module.scss';
import HomeHeader from '../../components/Header/HomeHeader';
import Home from '../../pages/Home';
import Footer from '../../components/Footer';
import TopBanner from '../../components/TopBanner';
import ResponsiveMenu from '../../components/ResponsiveMenu/ResponsiveMenu';
import { content } from './index.module.scss';

const { Content } = Layout;

const HomeLayout = ({ ...props }) => (
  <Layout style={{ minHeight: '100vh' }}>
    <TopBanner />
    <ResponsiveMenu />
    <HomeHeader />
    <Content className={content}>
      <Home {...props} />
    </Content>
    <Footer />
  </Layout>
);

HomeLayout.propTypes = {};

HomeLayout.defaultProps = {};
export default HomeLayout;
