import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { useIntl } from 'react-intl';
import {
  collapsed, uncollapsed, collapseArrow, collapseButton,
  collapseArrowCollapsed, collapseArrowUncollapsed,
} from './index.module.scss';
import { ReactComponent as CollapseArrow } from '../../assets/icons/return.svg';

const MarkdownWithEllipsis = ({ source }) => {
  const [ellipsis, setEllipsis] = useState(true);
  const intl = useIntl();

  return (
    <>
      <div className={ellipsis ? collapsed : uncollapsed}>
        <ReactMarkdown
          source={source}
        />
      </div>
      <button className={collapseButton} type="button" onClick={() => { setEllipsis((prev) => !prev); }}>
        <strong>
          {intl.formatMessage({
            id: ellipsis
              ? 'MarkdownWithEllipsis.ReadMore'
              : 'MarkdownWithEllipsis.ReadLess',
          })}
          <CollapseArrow className={`${collapseArrow} ${ellipsis ? collapseArrowCollapsed : collapseArrowUncollapsed}`} />
        </strong>
      </button>
    </>
  );
};

MarkdownWithEllipsis.propTypes = {
  source: PropTypes.string.isRequired,
};

export default MarkdownWithEllipsis;
