import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import DividerWithText from '../../../../components/DividerWithText';
import SocialLoginButtons from '../../../../components/SocialLoginsButtons';
import {
  wrapper,
  wrapperHeader,
  form,
  formTitle,
  input,
  button,
  error,
} from './index.module.scss';

const WelcomeForm = memo(({ onSubmit }) => {
  const intl = useIntl();

  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .email(
        intl.formatMessage({
          id: 'WelcomeForm.Validation.Email.EmailRequired',
        }),
      )
      .required(
        intl.formatMessage({ id: 'WelcomeForm.Validation.Email.Required' }),
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,

    onSubmit: (values, { setSubmitting }) => {
      onSubmit({
        ...values,
      });
      setSubmitting(false);
    },
    enableReinitialize: true,
  });

  return (
    <div className={wrapper}>
      <div className={wrapperHeader}>
        {intl.formatMessage({ id: 'WelcomeForm.Header' })}
      </div>
      <div className={formTitle}>
        {intl.formatMessage({ id: 'WelcomeForm.Title' })}
      </div>
      <form onSubmit={formik.handleSubmit} className={form}>
        <input
          type="email"
          name="email"
          placeholder={intl.formatMessage({
            id: 'WelcomeForm.Validation.Email.Text',
          })}
          className={input}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className={error}>{formik.errors.email}</div>
        ) : null}
        <button type="submit" className={button} disabled={formik.isSubmitting}>
          {intl.formatMessage({ id: 'WelcomeForm.Button.Text' })}
        </button>
      </form>
      <DividerWithText />
      <SocialLoginButtons />
    </div>
  );
});

WelcomeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default WelcomeForm;
