import PropTypes from 'prop-types';
import React from 'react';
import { useConditionsState } from '../../../../../../../../services/Conditions';
import ConditionAcceptance from '../../../ConditionAcceptance';

const PetsConditionAccepted = ({ checked, changeChecked }) => {
  const { KPPetsConditions } = useConditionsState();
  return (
    <ConditionAcceptance
      markdownSource={KPPetsConditions?.description}
      checked={checked}
      changeChecked={changeChecked}
      data-cy="checkout-step2-pets"
    />
  );
};

PetsConditionAccepted.defaultProps = {
  checked: false,
  changeChecked: () => {},
};

PetsConditionAccepted.propTypes = {
  checked: PropTypes.bool,
  changeChecked: PropTypes.func,
};

export default PetsConditionAccepted;
