import { useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useAuthState } from '../services/Auth/useAuth';
import {
  useUserState,
  useUserDispatch,
} from '../services/UserDetails/useUserDetails';
import actionsTypes from '../services/UserDetails/actionsTypes';

const useSyncUserWithToken = () => {
  const { accessToken } = useAuthState();
  const { user } = useUserState();
  const dispatch = useUserDispatch();

  const hasUserInfoChanged = useCallback(
    (oldUserInfo, newUserInfo) =>
      JSON.stringify(oldUserInfo) !== JSON.stringify(newUserInfo),
    [],
  );

  useEffect(() => {
    try {
      if (accessToken) {
        const newUser = jwtDecode(accessToken);
        if (newUser && hasUserInfoChanged(user, newUser)) {
          dispatch({ type: actionsTypes.SET_USER, payload: newUser });
        }
      } else {
        dispatch({ type: actionsTypes.CLEAR_USER });
      }
    } catch (error) {
      dispatch({ type: actionsTypes.CLEAR_USER });
    }
  }, [accessToken, user, dispatch, hasUserInfoChanged]);

  return null;
};

export default useSyncUserWithToken;
