import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as FreeCancellation } from '../../../../assets/icons/free-cancellation.svg';
import { ReactComponent as NonReenfundableIcon } from '../../../../assets/icons/nonReenfundable.svg';
import { ReactComponent as TentIcon } from '../../../../assets/icons/tent.svg';
import { ReactComponent as SizeIcon } from '../../../../assets/icons/area.svg';
import { ReactComponent as CarIcon } from '../../../../assets/icons/car.svg';

const conditionsData = (reservation, filteredRatePlan) => ([
  {
    icon: reservation?.ratePlanPropertyPolicy?.nonRefundable
      || filteredRatePlan?.propertyPolicy?.nonRefundable
      ? NonReenfundableIcon : FreeCancellation,
    title: reservation?.ratePlanPropertyPolicy?.name
      || filteredRatePlan?.propertyPolicy?.name,
    message: reservation?.ratePlanPropertyPolicy?.description
      || filteredRatePlan?.propertyPolicy?.description,
  },
  /*
    {
      icon: FreeCancellation,
      title: 'Resume.covidInsurance.title',
      message: 'Resume.covidInsurance.text',
    },
    */
]);

const getCampCharacteristics = (selectedRoomType, property) => {
  const campCharacteristics = [{
    icon: TentIcon,
    title: `X1 ${selectedRoomType}`,
    message: <FormattedMessage id="Resume.characteristic.fullyEquipped" />,
  },
  {
    icon: SizeIcon,
    title: (
      <>
        X1
        {' '}
        <FormattedMessage id="Resume.characteristic.plotLand" />
      </>),
    message: property.campingName,
  }];

  if (property.freeParking) {
    campCharacteristics.push({
      icon: CarIcon,
      title: (
        <>
          X1
          {' '}
          <FormattedMessage id="Resume.characteristic.parkingPlace" />
        </>),
      message: <FormattedMessage id="Resume.characteristic.carsAndMotorbikes" />,
    });
  }

  return campCharacteristics;
};

export { conditionsData, getCampCharacteristics };
