import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import makeRequestToApServer from '../../../../services/ApServer/askToApServer';
import { allowedMethods } from '../../../../constants/ApServer/apServer';
import routes from '../../../../constants/ApServer/apRoutes';
import PasswordInput from '../../../../components/PasswordInput';
import {
  wrapper,
  wrapperHeader,
  form,
  formTitle,
  input,
  button,
  error,
  emailInput,
} from './index.module.scss';

const PasswordForm = memo(({ accessToken, email, onResult }) => {
  const intl = useIntl();

  const validationSchema = Yup.object({
    email: Yup.string().trim().email().required(),
    password: Yup.string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'PasswordForm.Validation.Password.Required',
        }),
      )
      .min(
        8,
        intl.formatMessage({ id: 'PasswordForm.Validation.Password.Min' }),
      )
      .matches(
        /[a-z]/,
        intl.formatMessage({
          id: 'PasswordForm.Validation.Password.LowerCase',
        }),
      )
      .matches(
        /[A-Z]/,
        intl.formatMessage({
          id: 'PasswordForm.Validation.Password.UpperCase',
        }),
      )
      .matches(
        /[0-9]/,
        intl.formatMessage({
          id: 'PasswordForm.Validation.Password.Number',
        }),
      )
      .matches(
        /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/,
        intl.formatMessage({
          id: 'PasswordForm.Validation.Password.SpecialChar',
        }),
      ),
    confirmPassword: Yup.string()
      .trim()
      .oneOf(
        [Yup.ref('password'), null],
        intl.formatMessage({
          id: 'PasswordForm.Validation.Password.Match',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'PasswordForm.Validation.ConfirmPassword.Required',
        }),
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: email || '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      makeRequestToApServer(
        allowedMethods.put,
        routes.authPassword,
        { ...values, access_token: accessToken },
        ({ statusCode }) => {
          if (statusCode === 200) {
            onResult({ success: true });
          } else {
            onResult({
              success: false,
              error: intl.formatMessage({
                id: 'PasswordForm.Error.General',
              }),
            });
          }
          setSubmitting(false);
        },
        (err) => {
          setSubmitting(false);
          onResult({
            success: false,
            err:
              err.response?.data?.message ||
              intl.formatMessage({ id: 'PasswordForm.Error.General' }),
          });
        },
      );
    },
  });

  return (
    <div className={wrapper}>
      <div className={wrapperHeader}>
        {intl.formatMessage({ id: 'PasswordForm.Header' })}
      </div>
      <div className={formTitle}>
        {intl.formatMessage({ id: 'PasswordForm.Title' })}
      </div>
      <form onSubmit={formik.handleSubmit} className={form}>
        <input
          type="email"
          name="email"
          className={emailInput}
          value={formik.values.email}
          readOnly
          disabled
        />
        <PasswordInput
          name="password"
          placeholder={intl.formatMessage({
            id: 'PasswordForm.Placeholder.Password.Text',
          })}
          className={input}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className={error}>{formik.errors.password}</div>
        ) : null}
        <PasswordInput
          name="confirmPassword"
          placeholder={intl.formatMessage({
            id: 'PasswordForm.Placeholder.ConfirmPassword.Text',
          })}
          className={input}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
          <div className={error}>{formik.errors.confirmPassword}</div>
        ) : null}
        <button type="submit" className={button} disabled={formik.isSubmitting}>
          {intl.formatMessage({ id: 'PasswordForm.Button.Text' })}
        </button>
      </form>
    </div>
  );
});

PasswordForm.propTypes = {
  accessToken: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onResult: PropTypes.func.isRequired,
};

export default PasswordForm;
