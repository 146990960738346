import React from 'react';
import PropTypes from 'prop-types';
import {
  editableField,
  editableFieldHeader,
  disabledText,
  editableFieldValue,
  button,
} from './index.module.scss';

const EditableField = ({
  label,
  value,
  buttonLabel,
  isActive,
  isDisabled,
  onEdit,
  onCancel,
  children,
}) => (
  <div className={editableField}>
    <div className={`${editableFieldHeader} ${isDisabled ? disabledText : ''}`}>
      <h3 className={`${isDisabled ? disabledText : ''}`}>{label}</h3>

      <div>
        <button
          className={button}
          onClick={isActive ? onCancel : onEdit}
          disabled={`${isDisabled ? disabledText : ''}`}
          type="button"
        >
          {buttonLabel}
        </button>
      </div>
    </div>
    {isActive ? (
      children
    ) : (
      <div className={`${isDisabled ? disabledText : ''}`}>
        <span className={editableFieldValue}>{value}</span>
      </div>
    )}
  </div>
);
EditableField.defaultProps = {
  value: '',
};
EditableField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default EditableField;
