/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { TagsOutlined } from '@ant-design/icons';
import {
  collapseButton, featureWrapper, collapseArrow, collapseArrowCollapsed, collapseArrowUncollapsed,
  featureFlexWrapper,
} from './index.module.scss';
import { URL } from '../../constants/cms';
import { ReactComponent as CollapseArrow } from '../../assets/icons/return.svg';

const FeaturesListWithEllipsis = ({ features, language, enablePetsNotAvailableBanner }) => {
  const intl = useIntl();
  const [ellipsis, setEllipsis] = useState(true);
  const MAX_FEATURES = 9;
  return (
    <>
      <ul className={featureFlexWrapper}>
        {features.slice(0, ellipsis ? MAX_FEATURES : features.length).map((feature) => {
          if (!enablePetsNotAvailableBanner) {
            return (
              feature[0] ? (
                <li key={feature[0][language] ? feature[0][language] : feature[0].name} className={featureWrapper}>
                  {feature[0].icon ? <img src={feature[0].icon.url.includes('http') ? feature[0].icon.url : `${URL}${feature[0].icon.url}`} alt={feature[0].name} loading="lazy" /> : <TagsOutlined />}
                  <span>{feature[0][language] ? feature[0][language] : feature[0].name}</span>
                </li>
              ) : <TagsOutlined />
            );
          }
          // Pets feature ID in Strapi
          if (feature[0].id !== '110'
            && feature[0].id !== '111'
            && feature[0].id !== '112'
            && feature[0].id !== '120'
            && feature[0].id !== '121'
          ) {
            return (
              feature[0] ? (
                <li key={feature[0][language] ? feature[0][language] : feature[0].name} className={featureWrapper}>
                  {feature[0].icon ? <img src={feature[0].icon.url.includes('http') ? feature[0].icon.url : `${URL}${feature[0].icon.url}`} alt={feature[0].name} loading="lazy" /> : <TagsOutlined />}
                  {' '}
                  <span>{feature[0][language] ? feature[0][language] : feature[0].name}</span>
                </li>
              ) : <TagsOutlined />
            );
          }
          return null;
        })}
      </ul>
      {features.length > 9 && (
      <button className={collapseButton} type="button" onClick={() => { setEllipsis((prev) => !prev); }}>
        <strong>
          {intl.formatMessage({
            id: ellipsis
              ? 'FeaturesListWithEllipsis.ReadMore'
              : 'FeaturesListWithEllipsis.ReadLess',
          })}
          <CollapseArrow className={`${collapseArrow} ${ellipsis ? collapseArrowCollapsed : collapseArrowUncollapsed}`} />
        </strong>
      </button>
      )}
    </>
  );
};

FeaturesListWithEllipsis.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({
    es: PropTypes.string,
    en: PropTypes.string,
  })),
  language: PropTypes.oneOf(['en', 'es']),
  enablePetsNotAvailableBanner: PropTypes.bool,
};

FeaturesListWithEllipsis.defaultProps = {
  features: [],
  language: 'es',
  enablePetsNotAvailableBanner: false,
};
export default FeaturesListWithEllipsis;
