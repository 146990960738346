import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl, FormattedMessage } from 'react-intl';
import makeRequestToApServer from '../../../../services/ApServer/askToApServer';
import openNotification from '../../../../components/NotificacionMessage';
import { allowedMethods } from '../../../../constants/ApServer/apServer';
import routes from '../../../../constants/ApServer/apRoutes';
import { LanguageContext } from '../../../../locale/contexts/Language';
import PasswordInput from '../../../../components/PasswordInput';
import {
  wrapper,
  wrapperHeader,
  form,
  formTitle,
  input,
  inputName,
  inputSurname,
  inputDate,
  button,
  error,
  closeButton,
  span,
  spanConditions,
  spanSubscriptionInfo,
} from './index.module.scss';

const minAge = 18;

const RegisterForm = ({ email, onClose, setIsLoading }) => {
  const intl = useIntl();
  const { currentLanguage } = useContext(LanguageContext);
  const minDate = React.useMemo(() => {
    const today = new Date();
    return new Date(
      today.getFullYear() - minAge,
      today.getMonth(),
      today.getDate(),
    );
  }, []);
  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(
        intl.formatMessage({ id: 'RegisterForm.Validation.Name.Required' }),
      ),
    surname: Yup.string()
      .trim()
      .required(
        intl.formatMessage({ id: 'RegisterForm.Validation.Surname.Required' }),
      ),
    birthDate: Yup.date()
      .max(
        minDate,
        intl.formatMessage({
          id: 'RegisterForm.Validation.BirthDate.AgeLimit',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'RegisterForm.Validation.BirthDate.Required',
        }),
      ),
    password: Yup.string()
      .trim()
      .required(
        intl.formatMessage({ id: 'RegisterForm.Validation.Password.Required' }),
      )
      .min(
        8,
        intl.formatMessage({
          id: 'RegisterForm.Validation.Password.MinLength',
        }),
      )
      .matches(
        /[a-z]/,
        intl.formatMessage({
          id: 'RegisterForm.Validation.Password.LowerCase',
        }),
      )
      .matches(
        /[A-Z]/,
        intl.formatMessage({
          id: 'RegisterForm.Validation.Password.UpperCase',
        }),
      )
      .matches(
        /[0-9]/,
        intl.formatMessage({ id: 'RegisterForm.Validation.Password.Number' }),
      )
      .matches(
        /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/,
        intl.formatMessage({
          id: 'RegisterForm.Validation.Password.SpecialChar',
        }),
      ),
    confirmPassword: Yup.string()
      .trim()
      .oneOf(
        [Yup.ref('password'), null],
        intl.formatMessage({
          id: 'RegisterForm.Validation.ConfirmPassword.Match',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'RegisterForm.Validation.ConfirmPassword.Required',
        }),
      ),
    email: Yup.string()
      .trim()
      .email()
      .required(
        intl.formatMessage({ id: 'RegisterForm.Validation.Email.Required' }),
      ),
    isAllowing: Yup.bool().optional(),
    type: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      birthDate: '',
      password: '',
      confirmPassword: '',
      email: '',
      isAllowing: false,
      type: 'email',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setIsLoading(true);
      makeRequestToApServer(
        allowedMethods.post,
        routes.authRegister,
        {
          ...values,
          isAllowing: !values.isAllowing,
          lang: currentLanguage,
        },
        ({ statusCode }) => {
          setIsLoading(false);
          if (statusCode === 200)
            openNotification(
              'success',
              intl.formatMessage({
                id: 'RegisterForm.Request.Success.Title.Text',
              }),
              `${intl.formatMessage({
                id: 'RegisterForm.Request.Success.Message1.Text',
              })} 
                 ${intl.formatMessage({
                   id: 'RegisterForm.Request.Success.Message2.Text',
                 })}`,
              () => {
                onClose();
              },
              null,
            );
        },
        (err) => {
          setIsLoading(false);
          openNotification(
            'error',
            intl.formatMessage({
              id: 'PrivateArea.Request.Error.Default.Text',
            }),
            err?.response?.data?.message,
            () => {},
            null,
          );
        },
      ).finally(() => {
        setSubmitting(false);
      });
    },
  });

  /* eslint-disable */
  // slint disabled to prevent infinite re-renders
  useEffect(() => {
    formik.setFieldValue('email', email);
  }, [email]);
  /* eslint-enable */

  return (
    <div>
      <div className={wrapperHeader}>
        <div
          className={closeButton}
          onClick={() => {
            onClose();
          }}
          onKeyDown={() => {}}
          role="button"
          tabIndex={-1}
        >
          &times;
        </div>
        <div className={formTitle}>
          {intl.formatMessage({
            id: 'RegisterForm.Header',
          })}
        </div>
      </div>
      <div className={wrapper}>
        <form onSubmit={formik.handleSubmit} className={form}>
          <input
            type="text"
            name="name"
            placeholder={intl.formatMessage({
              id: 'RegisterForm.Validation.Name.Text',
            })}
            className={inputName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            autoComplete="off"
          />
          <input
            type="text"
            name="surname"
            placeholder={intl.formatMessage({
              id: 'RegisterForm.Validation.Surname.Text',
            })}
            className={inputSurname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.surname}
            autoComplete="off"
          />
          {formik.touched.name && formik.errors.name ? (
            <span className={error}>{formik.errors.name}</span>
          ) : null}
          {formik.touched.surname && formik.errors.surname ? (
            <span className={error}>{formik.errors.surname}</span>
          ) : null}
          <span className={span}>
            {intl.formatMessage({
              id: 'RegisterForm.Validation.Name.Info',
            })}
          </span>
          <div
            style={{
              display: 'flex',
            }}
          >
            <input
              type="date"
              name="birthDate"
              placeholder={intl.formatMessage({
                id: 'RegisterForm.Validation.BirthDate.Text',
              })}
              className={inputDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.birthDate}
            />
          </div>

          {formik.touched.birthDate && formik.errors.birthDate ? (
            <span className={error}>{formik.errors.birthDate}</span>
          ) : null}
          <span className={span}>
            {intl.formatMessage({
              id: 'RegisterForm.Validation.BirthDate.Info',
            })}
          </span>
          <input
            type="email"
            name="email"
            placeholder={intl.formatMessage({
              id: 'RegisterForm.Validation.Email.Text',
            })}
            className={input}
            value={formik.values.email}
            autoComplete="username"
            readOnly
          />
          {formik.touched.email && formik.errors.email ? (
            <span className={error}>{formik.errors.email}</span>
          ) : null}
          <span className={span}>
            {intl.formatMessage({
              id: 'RegisterForm.Validation.Email.Info',
            })}
          </span>
          <PasswordInput
            name="password"
            placeholder={intl.formatMessage({
              id: 'RegisterForm.Validation.Password.Text',
            })}
            className={input}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password ? (
            <span className={error}>{formik.errors.password}</span>
          ) : null}
          <PasswordInput
            name="confirmPassword"
            placeholder={intl.formatMessage({
              id: 'RegisterForm.Placeholder.ConfirmPassword.Text',
            })}
            className={input}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <span className={error}>{formik.errors.confirmPassword}</span>
          ) : null}
          <span className={spanConditions}>
            <FormattedMessage
              id="RegisterForm.Conditions"
              values={{
                serviceTerms: (
                  <a
                    href={intl.formatMessage({
                      id: 'RegisterForm.Conditions.ServiceTermsUrl',
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="RegisterForm.Conditions.ServiceTerms" />
                  </a>
                ),
                privacyPolicy: (
                  <a
                    href={intl.formatMessage({
                      id: 'RegisterForm.Conditions.PrivacyPolicyUrl',
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="RegisterForm.Conditions.PrivacyPolicy" />
                  </a>
                ),
                reservationTerms: (
                  <a
                    href={intl.formatMessage({
                      id: 'RegisterForm.Conditions.ReservationTermsUrl',
                    })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage id="RegisterForm.Conditions.ReservationTerms" />
                  </a>
                ),
              }}
            />
          </span>
          <button
            type="submit"
            className={button}
            disabled={formik.isSubmitting}
          >
            {intl.formatMessage({ id: 'RegisterForm.Button.Text' })}
          </button>
          <span className={spanSubscriptionInfo}>
            {intl.formatMessage({
              id: 'RegisterForm.SubscriptionInfo',
            })}
          </span>
          <span className={span}>
            <input
              type="checkbox"
              name="isAllowing"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.isAllowing}
            />
            {intl.formatMessage({
              id: 'RegisterForm.Validation.IsAllowing.Text',
            })}
          </span>
        </form>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default RegisterForm;
