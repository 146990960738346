import React from 'react';
import { spinner, bounce1, bounce2 } from './index.module.scss';

const Spinner = () => (
  <div className={spinner}>
    <div className={bounce1} />
    <div className={bounce2} />
  </div>
);

export default Spinner;
