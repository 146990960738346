/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import React, {
  useEffect,
  useRef,
  useContext,
  useCallback,
  useState,
} from 'react';
import moment from 'moment';
import { FormattedDate } from 'react-intl';
import { PropTypes } from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import * as s from './index.module.scss';
import {
  LanguageContext,
  localesWithFirstDay,
} from '../../locale/contexts/Language';
import isMobile from '../../utils/isMobile';

const RangeDatePicker = ({
  startDate,
  endDate,
  onChange,
  openFrom,
}) => {
  const activeMonth = useRef(null);
  const isStartSelected = useRef(true);
  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const isClosedDates = () => startDate && endDate;
  const { currentLanguage } = useContext(LanguageContext);

  const VIRTUALIZER_HEIGHT_DESKTOP = 675;
  const VIRTUALIZER_SIZE_DESKTOP = 550;
  const VIRTUALIZER_HEIGHT_MOBILE = 375;
  const VIRTUALIZER_SIZE_MOBILE = 400;
  const [virtualHeight, setVirtualHeight] = useState(
    VIRTUALIZER_HEIGHT_DESKTOP,
  );
  const [virtualSize, setVirtualSize] = useState(VIRTUALIZER_SIZE_DESKTOP);
  const MONTHS_TO_LOAD = 24;
  const isMobileScreen = isMobile();
  // Set locale based on current language
  useEffect(() => {
    moment.locale(currentLanguage);
  }, [currentLanguage]);
  // Create an array of days starting with default language start day
  const DAYS_OF_THE_WEEK = [];
  const todayWeek = moment()
    .startOf('week')
    .day(localesWithFirstDay[currentLanguage]);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 7; i++) {
    DAYS_OF_THE_WEEK.push(todayWeek.format('ddd'));
    todayWeek.add(1, 'day');
  }

  const getDaysOfTheWeek = () =>
    DAYS_OF_THE_WEEK.map((day) => <div key={day}>{day}</div>);

  const getClasses = (day, calendar) => {
    const today = moment();
    const openFromDate = moment(openFrom);
    const isADayOfTheMonth = () => day.format('M') === calendar.format('M');
    const isToday = () => day.isSame(today, 'date');
    const isStartDate = () => day.isSame(startDate, 'day');
    const isEndDate = () => day.isSame(endDate, 'day');
    const isADayBetweenDates = () => day.isBetween(startDate, endDate);
    const isANotSelectablePreviousDate = () => day.isBefore(today, 'day');
    const isBeforeOpenFrom = () => day.isBefore(openFromDate, 'day');
    const maxDaysToSelect = 89;

    let classes = '';

    if (isBeforeOpenFrom()) {
      classes += `${s.disable} `;
      classes += `${s.excededMaxDays} `;
    } else if (!isADayOfTheMonth()) {
      classes += `${s.disable} `;
      if (isANotSelectablePreviousDate()) {
        classes += `${s.notSelectable} `;
      }
    } else if (isStartDate()) {
      classes += `${s.startDay} `;
      if (isToday()) {
        classes += `${s.todayDay} `;
      }
      if (isClosedDates()) {
        classes += `${s.closedDates} `;
      }
      classes += `${s.startDay} `;
    } else if (isEndDate()) {
      classes += `${s.endDay} `;
      if (isClosedDates()) {
        classes += `${s.closedDates} `;
      }
    } else {
      if (startDate && endDate && isADayBetweenDates()) {
        classes += `${s.dayBetween} `;
      }
      if (isToday()) {
        classes += `${s.todayDay} `;
      }

      if (isANotSelectablePreviousDate()) {
        classes += `${s.notSelectable} `;
      }

      const daysFromStart = day.diff(startDate, 'days');
      if (daysFromStart > maxDaysToSelect && !endDate) {
        classes += `${s.disable} `;
        classes += `${s.excededMaxDays} `;
      }

      /*
      if (daysFromStart > maxDaysToSelect && startDate && endDate) {
        classes += `${s.disable} `;
        classes += `${s.excededMaxDays} `;
      }
        */
    }

    return classes;
  };

  const getDays = (selectedDate) => {
    const calendar = [];
    const today = selectedDate.clone();
    const startDay = today.clone().startOf('month').startOf('week');
    const endDay = today.clone().endOf('month').endOf('week');

    const date = startDay.clone().subtract(1, 'day');

    while (date.isBefore(endDay, 'day')) {
      calendar.push({
        days: Array(7)
          .fill(0)
          .map(() => date.add(1, 'day').clone()),
      });
    }

    return calendar;
  };

  const handleOnDayClick = (day, calendar) => {
    const classesForDay = getClasses(day, calendar);
    const isDisabled =
      classesForDay.includes(s.disable) ||
      classesForDay.includes(s.excededMaxDays);

    if (isDisabled) {
      return;
    }
    if (
      day.isSameOrAfter(moment(), 'day') &&
      day.format('M') === calendar.format('M')
    ) {
      if (isStartSelected.current) {
        onChange({ startDate: day.clone().add(12, 'hours'), endDate: null });
        isStartSelected.current = false;
      }
      if (day.isBefore(startDate, 'day') || day.isSame(startDate, 'day')) {
        onChange({ startDate: day.clone().add(12, 'hours'), endDate: null });
      }

      if (day.isAfter(startDate, 'day') && endDate) {
        onChange({ startDate: day.clone().add(12, 'hours'), endDate: null });
      }

      if(day.isAfter(startDate, 'day') && !endDate) {
        onChange({ startDate, endDate: day.clone().add(12, 'hours') });
      }
    }
  };

  useEffect(() => {
    if (activeMonth && activeMonth.current) {
      activeMonth.current.scrollIntoView({ behavior: 'smooth' });
      forceUpdate();
    }
  }, [forceUpdate, activeMonth]);

  useEffect(() => {
    if (isMobileScreen) {
      setVirtualHeight(VIRTUALIZER_HEIGHT_MOBILE);
      setVirtualSize(VIRTUALIZER_SIZE_MOBILE);
    } else {
      setVirtualHeight(VIRTUALIZER_HEIGHT_DESKTOP);
      setVirtualSize(VIRTUALIZER_SIZE_DESKTOP);
    }
  }, [isMobileScreen]);

  const Row = ({ index, style }) => {
    const calendar = moment().add(index, 'month');
    return (
      <div style={style}>
        <div className={s.monthCalendar} key={calendar.format('MMMM YYYY')}>
          <div
            className={s.calendarTitle}
            ref={
              startDate &&
              moment(startDate).format('MMMM YYYY') ===
                calendar.format('MMMM YYYY')
                ? activeMonth
                : null
            }
          >
            <FormattedDate value={calendar} month="long" year="numeric" />
          </div>
          {getDays(calendar).map((daysOnAWeek, weekIndex) => (
            <div className={s.week} key={weekIndex}>
              {daysOnAWeek &&
                daysOnAWeek.days.map((day) => (
                  <div
                    className={getClasses(day, calendar)}
                    onClick={() => handleOnDayClick(day, calendar)}
                    onKeyPress={() => handleOnDayClick(day, calendar)}
                    role="button"
                    tabIndex={0}
                    key={day.toString()}
                  >
                    {moment(day).format('D')}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={s.headerNamesOfTheWeek}>{getDaysOfTheWeek()}</div>
      <div className={s.allCalendars}>
        <List
          height={virtualHeight}
          itemCount={MONTHS_TO_LOAD}
          itemSize={virtualSize}
          width="100%"
        >
          {Row}
        </List>
      </div>
    </div>
  );
};

RangeDatePicker.propTypes = {
  endDate: PropTypes.instanceOf(moment),
  startDate: PropTypes.instanceOf(moment),
  onChange: PropTypes.func,
  openFrom: PropTypes.instanceOf(moment).isRequired,
};

RangeDatePicker.defaultProps = {
  endDate: null,
  startDate: null,
  onChange: () => {},
};
export default RangeDatePicker;
