/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "antd";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import {
  roomRatePlanCard,
  roomRateCardContent,
  roomRateCardContentTitle,
  details,
  roomRateCardImage,
  promoTag,
  priceWrapper,
  fullPrice,
  price,
  separator,
  night,
  person,
  petTag,
  footerWrapper,
  priceContainer,
} from "./index.module.scss";
import {
  useReservationDispatch,
  useReservationState,
} from "../../../../../services/Reservation";
import { URL } from "../../../../../constants/cms";
import { useAuthState } from "../../../../../services/Auth/useAuth";
import { ReactComponent as Arrow } from "../../../../../assets/icons/return.svg";
import ROUTES from "../../../../../routes/routes";
import FormattedPriceByTotalPrice from "../../../../../components/FormattedPrices/FormattedPriceByTotalPrice";
import Tag from "../../../../../components/Tag";
import {
  datesAreValid,
  peopleAreValid,
} from "../../../../../services/CheckValidReservation";
import Services from "../../../../../constants/services";
import useCampings from "../../../../../services/Campings/useCampings";
import PersonSelectorDrawer from "../../../../../components/PersonSelectorDrawer";
import daysBetweenCheckInAndCheckOut from "../../../../../utils/daysBetweenCheckInAndCheckOut";
import AvailabilityDrawer from "../../../../../components/AvailabilityDrawer";
import PetTag from "../../../../../components/PetTag";
import PrivateWcTag from "../../../../../components/PrivateWcTag";
import TribeTag from "../../../../../components/TribeTag";
import { LanguageContext } from "../../../../../locale/contexts/Language";
import { updateQueryParams } from "../../../../../utils/queryParamUtilities";

export const FormattedPrice = ({
  price: _price,
  daysBetweenCheckInAndCheckOut: _daysBetweenCheckInAndCheckOut,
}) => {
  const intl = useIntl();

  const getPrice = () => {
    if (_price % _daysBetweenCheckInAndCheckOut === 0) {
      return _price / _daysBetweenCheckInAndCheckOut;
    }
    return (_price / _daysBetweenCheckInAndCheckOut).toFixed(2);
  };

  return (
    <div className={priceWrapper}>
      <span className={person}>{`${intl.formatMessage({
        id: "Card.Button.From",
        defaultMessage: "Desde",
      })}`}</span>
      <span className={fullPrice}>
        <span className={price}>
          {/* eslint-disable-next-line react/style-prop-object */}
          <FormattedNumber value={getPrice()} style="currency" currency="EUR" />
        </span>
        <span className={separator}>/</span>
        <span className={night}>{`${intl.formatMessage({
          id: "Card.Button.Night",
          defaultMessage: "noche",
        })}`}</span>
      </span>
      <span className={person}>
        {/* eslint-disable-next-line react/style-prop-object */}
        <FormattedNumber value={_price} style="currency" currency="EUR" />
        {` ${intl.formatMessage({
          id: "Card.Button.InTotal",
          defaultMessage: "en total",
        })}`}
      </span>
    </div>
  );
};

FormattedPrice.propTypes = {
  price: PropTypes.number.isRequired,
  daysBetweenCheckInAndCheckOut: PropTypes.number.isRequired,
};

const RoomCard = ({
  ratePlan,
  unit,
  property,
  type,
  priceFormat,
  ratePlans,
  askingForNewRatePlans,
}) => {
  const { startDate, endDate, adults, children, babies } =
    useReservationState();
  const intl = useIntl();
  const { dispatch: dispatchCampings } = useCampings();
  const reservation = useReservationState();
  const dispatchReservation = useReservationDispatch();
  const dispatch = dispatchCampings;
  const [showAvailabilityDrawer, setShowAvailabilityDrawer] = useState(false);
  const [showPersonSelectorDrawer, setShowPersonSelectorDrawer] =
    useState(false);
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const [fpaFilter, setFpaFilter] = useState(searchParams.get("fpa") || "none");
  const filters = searchParams.get("filters");
  const { accessToken } = useAuthState();

  const { currentLanguage } = useContext(LanguageContext);
  const getRoomName = () => unit.tent_model.name;

  const handleOnClick = () => {
    if (datesAreValid(reservation) && peopleAreValid(reservation)) {
      dispatchReservation({ type: "setItAsReadyToBeUsed" });
    }
    const url = `${ROUTES.roomDetails}/${property.ulysesId}/${
      unit.roomTypeId
    }?checkin=${startDate.format("YYYY-MM-DD")}&checkout=${endDate.format(
      "YYYY-MM-DD",
    )}&adults=${adults}&children=${children}&babies=${babies}&fpa=${fpaFilter}${
      filters ? `&filters=${filters}` : ""
    }&lang=${currentLanguage}`;
    window.open(url, "_blank");
  };

  // eslint-disable-next-line react/prop-types
  const minPrice = ratePlan?.boards?.reduce((min, board) => {
    const minPriceInBoard = board?.plans?.reduce((minPlan, plan) => {
      const allAvailablePlans = plan?.roomRateDetailed?.every(
        (room) => room.available === true,
      );

      if (plan?.totalPrice !== undefined && allAvailablePlans) {
        return minPlan === undefined
          ? plan?.totalPrice
          : Math.min(minPlan, plan?.totalPrice);
      }
      return minPlan;
    }, undefined);

    return min === undefined ? minPriceInBoard : Math.min(min, minPriceInBoard);
  }, undefined);

  const getPrice = () =>
    priceFormat === "byTotalPrice" ? (
      <FormattedPriceByTotalPrice
        price={minPrice}
        daysBetweenCheckInAndCheckOut={daysBetweenCheckInAndCheckOut({
          startDate,
          endDate,
        })}
        onClick={handleOnClick}
        startDate={startDate}
        endDate={endDate}
      />
    ) : (
      <FormattedPrice
        price={minPrice}
        daysBetweenCheckInAndCheckOut={daysBetweenCheckInAndCheckOut({
          startDate,
          endDate,
        })}
        onClick={handleOnClick}
      />
    );

  const model = "tent_model";
  const propertyType = "camping";
  const getRoomRateFooter = () => {
    const maxPax = adults + children;
    if (maxPax > unit[model].maxGuests) {
      return (
        <Button
          onClick={(e) => {
            setShowPersonSelectorDrawer(true);
            e.stopPropagation();
          }}
          type="primary"
        >
          <FormattedMessage
            id="Card.Button.PeopleLimit"
            values={{ maxGuests: unit[model].maxGuests }}
          />
          <Arrow />
        </Button>
      );
    }

    let showSearchNewDate = true;
    if (ratePlan) {
      if (ratePlan.boards) {
        for (const board of ratePlan.boards) {
          if (board.plans && board?.plans?.length > 0) {
            for (const plan of board?.plans) {
              if (
                plan.roomRateDetailed &&
                plan?.roomRateDetailed?.every((detail) => detail?.available)
              ) {
                showSearchNewDate = false;
                break;
              }
            }
          }
          if (!showSearchNewDate) break;
        }
      }

      return showSearchNewDate ? (
        <Button
          onClick={(e) => {
            setShowAvailabilityDrawer(true);
            e.stopPropagation();
          }}
          type="primary"
        >
          <FormattedMessage id="Card.Button.SearchDate" />
          <Arrow />
        </Button>
      ) : (
        <div className={priceContainer} data-cy="price-container">
          {getPrice()}
          {accessToken !== null && <TribeTag roomPlans={ratePlans} smallSize />}
        </div>
      );
    }
    return (
      <Button
        onClick={(e) => {
          setShowAvailabilityDrawer(true);
          e.stopPropagation();
        }}
        type="primary"
      >
        <FormattedMessage id="Card.Button.SearchDate" />
        <Arrow />
      </Button>
    );
  };

  return (
    <>
      <div
        className={roomRatePlanCard}
        onClick={handleOnClick}
        onKeyPress={handleOnClick}
        role="button"
        tabIndex={0}
        data-cy="room-card"
      >
        {unit && (
          <div
            className={roomRateCardImage}
            style={{
              backgroundImage: `url(${
                unit.featuredImage
                  ? unit.featuredImage.formats.thumbnail.url.includes("http")
                    ? unit.featuredImage.formats.thumbnail.url
                    : `${URL}${unit.featuredImage.formats.thumbnail.url}`
                  : ""
              })`,
            }}
          >
            {ratePlan && ratePlan.ratePlanNamePublic && (
              <Tag className={promoTag}>{ratePlan.ratePlanNamePublic}</Tag>
            )}
            {unit.pets && (
              <PetTag className={petTag}>
                {intl.formatMessage({ id: "Card.Tag.Pet" })}
              </PetTag>
            )}
            {unit.privateBathroom && (
              <PrivateWcTag className={petTag}>
                {intl.formatMessage({ id: "Card.Tag.PrivateBathroom" })}
              </PrivateWcTag>
            )}
          </div>
        )}

        <div className={roomRateCardContent}>
          <Typography.Title className={roomRateCardContentTitle} level={3}>
            {getRoomName()}
          </Typography.Title>
          <p className={details}>
            {`${unit[model].area}㎡, ${
              unit[model].height
            }m ${intl.formatMessage({
              id: "AvailabilityDrawer.UnitSelector.Characteristic.Height",
              defaultMessage: "altura",
            })}, ${unit[model].maxGuests}pax.`}
          </p>
          <div className={footerWrapper}>{getRoomRateFooter()}</div>
        </div>
      </div>
      {showAvailabilityDrawer && (
        <AvailabilityDrawer
          visible={showAvailabilityDrawer}
          onClose={() => {
            setShowAvailabilityDrawer(false);
          }}
          property={unit[propertyType]}
          rateId={ratePlan ? ratePlan.rateID : undefined}
          unitId={unit ? unit.roomTypeId : undefined}
          kampaohService={type}
          onOk={() => {
            setShowAvailabilityDrawer(false);
            if (askingForNewRatePlans) askingForNewRatePlans(true);
          }}
        />
      )}
      {showPersonSelectorDrawer && (
        <PersonSelectorDrawer
          initCountersValues={{ children, adults, babies }}
          visible={showPersonSelectorDrawer}
          onClose={() => {
            setShowPersonSelectorDrawer(false);
          }}
          onReset={() => {
            dispatchReservation({ type: "resetPersons" });
          }}
          onOk={(counter) => {
            Object.keys(counter).forEach((key) => {
              dispatchReservation({ type: `set${key}`, payload: counter[key] });
              updateQueryParams(key, counter[key]);
            });
            updateQueryParams("fpa", fpaFilter);

            dispatchReservation({ type: "setRatePlansAsNotFetched" });
            dispatch({ type: "resetHotelsInitialState" });
            setShowPersonSelectorDrawer(false);

            askingForNewRatePlans(true);
          }}
          fpaFilter={fpaFilter}
          setFpaFilter={setFpaFilter}
        />
      )}
    </>
  );
};

RoomCard.propTypes = {
  ratePlan: PropTypes.shape({
    boards: PropTypes.shape({
      length: PropTypes.shape({}),
    }),
    campingId: PropTypes.string,
    roomTypeID: PropTypes.number,
    roomTypeName: PropTypes.string,
    totalRate: PropTypes.number,
    ratePlanNamePublic: PropTypes.string,
    minPrice: PropTypes.number,
    plans: PropTypes.shape({}),
    rateID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    roomsAvailable: PropTypes.number,
  }),
  unit: PropTypes.shape({
    name: PropTypes.string,
    roomTypeId: PropTypes.string,
    pets: PropTypes.bool,
    privateBathroom: PropTypes.bool,
    camping: PropTypes.oneOfType([PropTypes.object]),
    featuredImage: PropTypes.shape({
      formats: PropTypes.shape({
        thumbnail: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    tent_model: PropTypes.shape({
      area: PropTypes.number,
      height: PropTypes.number,
      maxGuests: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
  property: PropTypes.shape({
    ratePlans: PropTypes.shape({
      boards: PropTypes.shape({}),
    }),
    ulysesId: PropTypes.string,
  }).isRequired,
  priceFormat: PropTypes.oneOf(["byTotalPrice", "byPricePerNightAndTotal"]),
  type: PropTypes.oneOf(Object.values(Services)),
  ratePlans: PropTypes.arrayOf.isRequired,
  askingForNewRatePlans: PropTypes.func,
};

RoomCard.defaultProps = {
  ratePlan: undefined,
  priceFormat: "byTotalPrice",
  type: Services.CAMP,
  askingForNewRatePlans: () => {},
};

export default RoomCard;
