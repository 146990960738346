import PropTypes from 'prop-types';
import React from 'react';

import SelectField from '../SelectField';
import TextField from '../TextField';

const PersonalInfo = ({ disabledFields }) => (
  <>
    {/* <Typography.Title level={2}>
      {intl.formatMessage({ id: 'UserForm.Validation.Address.Title', defaultMessage: 'Dirección' })}
    </Typography.Title> */}
    <TextField field="PostalCode" disabled={disabledFields.PostalCode} data-cy="checkout-step3-postcode"/>
    <SelectField field="Country" disabled={disabledFields.Country} data-cy="checkout-step3-country"/>
    {/* <TextField field="Country" /> */}
    {/* <TextField field="City" /> */}
  </>
);

PersonalInfo.defaultProps = {
  disabledFields: {
    PostalCode: false,
    Country: false,
  },
};

PersonalInfo.propTypes = {
  disabledFields: PropTypes.shape({
    PostalCode: PropTypes.bool,
    Country: PropTypes.bool,
  }),
};

export default PersonalInfo;
