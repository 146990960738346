/* eslint-disable max-len */
import React, { useContext, useEffect } from 'react';
import { wrapper, active } from './index.module.scss';
import { useFilterDispatch, useFilterState } from '../../../services/Filter';
import { CampingFeaturesContext } from '../../../services/CampingFeatures/Provider';
import { useReservationState } from '../../../services/Reservation';
import Services from '../../../constants/services';
import { URL as cmsURL } from '../../../constants/cms';

const FeaturesFilter = () => {
  const { kampaohService } = useReservationState();
  const isKampaohCampService = () => kampaohService === Services.CAMP;
  const AvailableFilterCharacteristics = useContext(CampingFeaturesContext);
  const { activeCharacteristicsFilters } = useFilterState();
  const dispatch = useFilterDispatch();
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const filters = searchParams.get('filters');

  const wcPrivateFilterIdsByLang = {
    es: '7',
    en: '43',
    fr: '44',
    pt: '76',
    it: '77',
  };
  const beachFilterIdsByLang = {
    es: '4',
    en: '57',
    fr: '58',
    pt: '102',
    it: '103',
  };
  const poolFilterIdsByLang = {
    es: '2',
    en: '51',
    fr: '52',
    pt: '98',
    it: '99',
  };
  const currentLang = localStorage.getItem('countryCode');
  const WCPRIVATE =
    wcPrivateFilterIdsByLang[currentLang] ||
    parseInt(searchParams.get('lang'), 10);
  const beachFilterId =
    beachFilterIdsByLang[currentLang] || parseInt(searchParams.get('lang'), 10);
  const poolFilterId =
    poolFilterIdsByLang[currentLang] || parseInt(searchParams.get('lang'), 10);

  const isItAFeaturedFilter = (filter) =>
    isKampaohCampService() ? filter.destacado : filter.outstanding;

  const isActive = (filter) =>
    activeCharacteristicsFilters &&
    activeCharacteristicsFilters.some(
      (activeFilter) => activeFilter?.id === filter?.id,
    );

  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };

  const url = new URL(window.location);

  const updateQueryParams = (filter, addItem) => {
    const params = new URLSearchParams(url.search);
    let activeFiltersIds = activeCharacteristicsFilters.map((itm) => itm.id);
    if (addItem) {
      activeFiltersIds.push(filter.id);
    } else {
      activeFiltersIds = activeFiltersIds.filter((num) => num !== filter.id);
    }

    activeFiltersIds = [...new Set(activeFiltersIds)];

    if (activeFiltersIds.length === 0) {
      params.delete('filters');
    } else {
      const filtersIdQueryString = activeFiltersIds.join(',');
      params.set('filters', filtersIdQueryString);
    }
    url.search = params.toString();
    updateUrlWithoutNavigation(`${url.pathname}?${params.toString()}`);
  };

  const addOrRemoveFilters = (filter) => {
    if (isActive(filter)) {
      dispatch({ type: 'removeCharacteristicsFilter', payload: filter });
      updateQueryParams(filter, false);
    } else {
      dispatch({ type: 'addCharacteristicsFilter', payload: filter });
      updateQueryParams(filter, true);
    }
  };

  useEffect(() => {
    if (AvailableFilterCharacteristics) {
      const filterArray = filters?.split(',');
      // eslint-disable-next-line max-len
      const bathroomFilter = AvailableFilterCharacteristics.find(
        (filter) => filter.id === WCPRIVATE,
      );
      if (bathroomFilter) {
        if (filterArray?.includes(WCPRIVATE)) {
          dispatch({
            type: 'addCharacteristicsFilter',
            payload: bathroomFilter,
          });
        }
      }
      const beachFilter = AvailableFilterCharacteristics.find(
        (filter) => filter.id === beachFilterId,
      );
      if (beachFilter) {
        if (filterArray?.includes(beachFilterId)) {
          dispatch({ type: 'addCharacteristicsFilter', payload: beachFilter });
        }
      }
      const poolFilter = AvailableFilterCharacteristics.find(
        (filter) => filter.id === poolFilterId,
      );
      if (poolFilter) {
        if (filterArray?.includes(poolFilterId)) {
          dispatch({ type: 'addCharacteristicsFilter', payload: poolFilter });
        }
      }
    }
  }, [
    AvailableFilterCharacteristics,
    filters,
    WCPRIVATE,
    dispatch,
    poolFilterId,
    beachFilterId,
  ]);

  return (
    <div className={wrapper}>
      {AvailableFilterCharacteristics.map((filter) =>
        isItAFeaturedFilter(filter) ? (
          <button
            type="button"
            key={filter.id}
            className={isActive(filter) ? active : ''}
            onClick={() => addOrRemoveFilters(filter)}
          >
            <img
              src={
                filter.icon.url.includes('http')
                  ? filter.icon.url
                  : `${cmsURL}${filter.icon.url}`
              }
              alt={filter.name}
            />
            {filter.name}
          </button>
        ) : null,
      )}
    </div>
  );
};

export default FeaturesFilter;
