import React from 'react';
import { useIntl } from 'react-intl';
import { wrapper, title, info } from './index.module.scss';

const NotAvailableScreen = () => {
  const intl = useIntl();

  return (
    <div className={wrapper}>
      <h2 className={title}>{intl.formatMessage({ id: '#NotAvailableScreen.Title' })}</h2>
      <p className={info}>
        {intl.formatMessage({ id: '#NotAvailableScreen.info' })}
      </p>
      <span>{intl.formatMessage({ id: '#NotAvailableScreen.number' })}</span>
    </div>
  );
};

NotAvailableScreen.propTypes = {
};
NotAvailableScreen.defaultProps = {};
export default NotAvailableScreen;
