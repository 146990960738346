import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {
  wrapper, title, description, spinner, iconContainer,
} from './index.module.scss';
import { ReactComponent as EmperadorIcon } from '../../assets/icons/icon_emperador.svg';
import { ReactComponent as BaliIcon } from '../../assets/icons/icon_bali.svg';
import { ReactComponent as BellIcon } from '../../assets/icons/icon_bell.svg';
import { ReactComponent as BellxlIcon } from '../../assets/icons/icon_bellxl.svg';

const PaymentLoader = ({ display }) => {
  const intl = useIntl();
  const [activeIconIndex, setActiveIconIndex] = useState(0);
  const icons = [
    <EmperadorIcon key={1} className={`icon ${activeIconIndex === 0 ? 'active' : ''}`} />,
    <BaliIcon key={2} className={`icon ${activeIconIndex === 1 ? 'active' : ''}`} />,
    <BellIcon key={3} className={`icon ${activeIconIndex === 2 ? 'active' : ''}`} />,
    <BellxlIcon key={4} className={`icon ${activeIconIndex === 3 ? 'active' : ''}`} />,
  ];

  const changeIcon = () => {
    setActiveIconIndex((prevIndex) => (prevIndex + 1) % icons.length);
  };

  useEffect(() => {
    const intervalId = setInterval(changeIcon, 3000);

    return () => {
      clearInterval(intervalId);
    };
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (display === 'floating') {
      document.body.classList.add('withOverflowHidden');
    }

    return () => {
      document.body.classList.remove('withOverflowHidden');
    };
  }, [display]);

  return (
    <div className={wrapper}>
      <div className={spinner}>
        <div className={iconContainer}>
          <TransitionGroup>
            <CSSTransition
              key={activeIconIndex}
              timeout={0}
              classNames={{
                enter: 'icon-enter',
                enterActive: 'icon-enter-active',
                exit: 'icon-exit',
                exitActive: 'icon-exit-active',
              }}
            >
              {icons[activeIconIndex]}
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>

      <p className={title}>{intl.formatMessage({ id: 'Payment.Processing.Title' })}</p>
      <p className={description}>{intl.formatMessage({ id: 'Payment.Processing' })}</p>
    </div>
  );
};

PaymentLoader.propTypes = {
  display: PropTypes.oneOf(['floating', 'block']),
};

PaymentLoader.defaultProps = {
  display: 'floating',
};

export default PaymentLoader;
