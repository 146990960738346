import React from "react";
import { PropTypes } from "prop-types";
import { FormattedMessage } from "react-intl";
import { wrapper, locationIcon } from "./index.module.scss";
import { ReactComponent as GuestIcon } from "../../../../assets/icons/user.svg";
import { ReactComponent as DownArrowIcon } from "../../../../assets/icons/down-arrow.svg";
import { useReservationState } from "../../../../services/Reservation";

const CountersSelector = ({ onClick }) => {
  const { adults, children, babies } = useReservationState();

  const isLoading = false;
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const fpa = searchParams.get("fpa");
  return (
    <>
      <div
        className={wrapper}
        onClick={!isLoading ? onClick : undefined}
        onKeyPress={!isLoading ? onClick : undefined}
        role="button"
        tabIndex={0}
        data-cy="counters-selector"
      >
        <div>
          <GuestIcon className={locationIcon} />{" "}
          <div>
            <FormattedMessage id="typeOfPerson.adults" />
            {` x${adults}`}
            {children !== 0 && (
              <>
                {", "}
                <FormattedMessage id="typeOfPerson.children" />
                {` x${children}`}
              </>
            )}
            {babies !== 0 && (
              <>
                {", "}
                <FormattedMessage id="typeOfPerson.babies" />
                {` x${babies}`}
              </>
            )}
            {fpa === "pets" && (
              <>
                {", "}
                <FormattedMessage id="ModalForPets.title" />
              </>
            )}
            {fpa === "allergy" && (
              <>
                {", "}
                <FormattedMessage id="ModalForAllergy.title" />
              </>
            )}
          </div>
        </div>

        <DownArrowIcon />
      </div>
    </>
  );
};

CountersSelector.propTypes = {
  onClick: PropTypes.func,
};

CountersSelector.defaultProps = {
  onClick: () => {},
};
export default CountersSelector;
