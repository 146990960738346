import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { tribePromotionTag, tribePromotionSmall, tribeLevel } from './index.module.scss';
import { ReactComponent as KampaohIcon } from '../../assets/icons/kampaoh.svg';


const TribeTag = ({ roomPlans, smallSize, tagName }) => {
  const intl = useIntl();

  // Se queda comentado de momento, ya que solo va a existir Tribe 1, cuando lanzemos más niveles, nos hará falta
  
  // const formatTagName = useMemo(() => {
  //   const match = tagName?.match(/(TRIBE)(\d+)/);
  //   if (match) {
  //     return `${match[1]} ${intl.formatMessage({ id: 'tribeTag.level' })} ${match[2]}`;
  //   }
  //   return tagName;
  // }, [tagName, intl]);

  const tribePromotion = useMemo(
    () =>
        roomPlans
          ?.flatMap((element) => element?.boards || [])
          .flatMap((board) => board?.plans || [])
          .filter(
            (plan) =>
              plan?.roomRateDetailed?.every((element) => element.available) &&
              plan?.promotion?.tagList?.length > 0,
          )
          .reduce(
            (cheapestPlan, currentPlan) =>
              !cheapestPlan || currentPlan.totalPrice < cheapestPlan.totalPrice
                ? currentPlan
                : cheapestPlan,
            null,
          ),
      [roomPlans],
    );

  const renderContent = () => {
      if (tribePromotion) {
        return (
          <div className={!smallSize ? tribePromotionTag : tribePromotionSmall}>
            <KampaohIcon />
            {intl.formatMessage({ id: 'tribe.promotion' })}
          </div>
        );
      } if (tagName) {
        return (
          <div className={tribeLevel}>
            <KampaohIcon />
            {/* {formatTagName} */}
            {intl.formatMessage({ id: 'tribe.name' })}
          </div>
        );
      } 
        return <></>;
      
    };

    return renderContent();
};

TribeTag.propTypes = {
  roomPlans: PropTypes.arrayOf.isRequired,
  smallSize: PropTypes.bool,
  isRoomCard: PropTypes.bool,
};

TribeTag.defaultProps = {
  smallSize: false,
  isRoomCard: false
};
export default TribeTag;
