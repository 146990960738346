import { useContext } from 'react';
import { UserStateContext, UserDispatchContext } from './DataProvider';

const useUserState = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a DataProvider');
  }
  return context;
};

const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a DataProvider');
  }
  return context;
};

export { useUserState, useUserDispatch };
