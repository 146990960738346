import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { useNavigate } from '@reach/router';
import moment from 'moment';
import { useReservationDispatch, useReservationState } from '../../../../services/Reservation';
import ActionButton from '../../../../components/ActionButton';
import ROUTES from '../../../../routes/routes';
import AvailabilityContext from '../../../../components/AvailabilityDrawer/contexts/AvailabilityContext';

const AvailabilityActionButton = ({
  propertyId, disabled, unitId, onClose,
}) => {
  const navigate = useNavigate();

  const {
    endDateAvailable,
    startDateAvailable,
  } = useContext(AvailabilityContext);

  const {
    adults: adultsCount,
    children: boysCount,
    babies: babiesCount,
  } = useReservationState();
  const dispatch = useReservationDispatch();

  return (
    <ActionButton
      disabled={disabled}
      size="sm"
      onClick={() => {
        dispatch({ type: 'setStartDate', payload: startDateAvailable });
        dispatch({ type: 'setEndDate', payload: endDateAvailable });
        navigate(`${ROUTES.roomDetails}/${propertyId}/${unitId}?checkin=${startDateAvailable.format('YYYY-MM-DD')}&checkout=${endDateAvailable.format('YYYY-MM-DD')}&adults=${adultsCount}&children=${boysCount}&babies=${babiesCount}`);
        onClose();
      }}
    >
      OK
    </ActionButton>
  );
};

AvailabilityActionButton.propTypes = {
  propertyId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  currentDates: PropTypes.shape({
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
  }).isRequired,
  unitId: PropTypes.string.isRequired,
  selectedUnitRatePlan: PropTypes.shape({ rateID: PropTypes.string }).isRequired,
  onClose: PropTypes.func,
};

AvailabilityActionButton.defaultProps = {
  onClose: () => {},
};
export default AvailabilityActionButton;
