import PropTypes from 'prop-types';
import React from 'react';
import { useConditionsState } from '../../../../../../../../services/Conditions';
import ConditionAcceptance from '../../../ConditionAcceptance';

const PartyConditionAcceptance = ({ checked, changeChecked }) => {
  const { KPNoiseConditions } = useConditionsState();
  return (
    <ConditionAcceptance
      markdownSource={KPNoiseConditions?.description}
      checked={checked}
      changeChecked={changeChecked}
      data-cy="checkout-step2-party"
    />
  );
};

PartyConditionAcceptance.defaultProps = {
  checked: false,
  changeChecked: () => {},
};

PartyConditionAcceptance.propTypes = {
  checked: PropTypes.bool,
  changeChecked: PropTypes.func,
};

export default PartyConditionAcceptance;
