/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Location } from '@reach/router';
import { Layout, Space } from 'antd';
import { useIntl } from 'react-intl';
import {
  footerStyle,
  homeFooter,
  languageButtonWrapper,
  policies,
  mainFooter,
} from './index.module.scss';
// import LanguageSelectorButton from './LanguageButton';
// import CurrencySelectorButton from './CurrencyButton';
import useDeviceDetect from '../../utils/useDeviceDetect';
import ROUTES from '../../routes/routes';
import LanguageSection from './LanguageSection';

const { Footer: AntFooter } = Layout;

const routesWithMargin = [
  ROUTES.home,
  ROUTES.me,
  ROUTES.meSettings,
  ROUTES.places,
];

const Footer = () => {
  const intl = useIntl();
  const { isMobile } = useDeviceDetect();

  return (
    <Location>
      {({ location: { pathname } }) =>
        !pathname.includes(ROUTES.checkin) ? (
          <AntFooter
            className={
              routesWithMargin.includes(pathname) && isMobile
                ? homeFooter
                : footerStyle
            }
          >
            <div className={mainFooter}>
              <div className={languageButtonWrapper}>
                <Space align="center">
                  <LanguageSection />
                  {/* <CurrencySelectorButton /> */}
                </Space>
              </div>
              <div className={policies}>
                <p>
                  <a
                    href="https://es.kampaoh.com/camping-sin-lios/aviso-legal-condiciones-de-uso-y-politica-de-privacidad-cookies/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'Footer.LegalDocuments' })}
                  </a>
                </p>
                <p style={{ marginBottom: '15px' }}>
                  <a
                    // eslint-disable-next-line no-script-url
                    href="javascript:Cookiebot.renew();"
                  >
                    {intl.formatMessage({ id: 'Footer.Cookies' })}
                  </a>
                </p>
                <p>{'Kampaoh © '.concat(new Date().getFullYear())}</p>
              </div>
            </div>
          </AntFooter>
        ) : null
      }
    </Location>
  );
};

export default Footer;
