/* eslint-disable max-len */
import React, {
  createContext, useEffect, useReducer, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import reducer from './reducer';
import initialState from './initialState';
import sanitizeApiResponse from '../../utils/sanitizeApiResponse';
import tentOnPlacesQuery from '../GraphQL/Places/Camping/tentsOnPlacesQuery';
import tentOnRoomQuery from '../GraphQL/Room/tentsOnRoomQuery';
import tentOnPropertyQuery from '../GraphQL/Property/tentsOnPropertyQuery';
// eslint-disable-next-line import/no-cycle
import useCampings from './useCampings';
import { LanguageContext } from '../../locale/contexts/Language';
import ROUTES from '../../routes/routes';

export const TentOnPlacesState = createContext();
export const TentOnPlacesDispatch = createContext();

const tentsAsHashedObject = (result, tent) => ({
  ...result,
  [tent.roomTypeId]: tent,
});

const routeToQueryMap = {
  [ROUTES.places]: tentOnPlacesQuery,
  [ROUTES.roomDetails]: tentOnRoomQuery,
  [ROUTES.propertyDetails]: tentOnPropertyQuery,
};

const TentPlacesProvider = ({ children, campings }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    state: {
      campings: campingsState,
    },
  } = useCampings();
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const propertyFromURL = searchParams.get('property');
  const isBookResume = window.location.href.includes(ROUTES.book + ROUTES.resume);
  const isBookPolicies = window.location.href.includes(ROUTES.policies);
  const isBookForm = window.location.href.includes(ROUTES.form);
  const isBookPayment = window.location.href.includes(ROUTES.payment);

  const location = useLocation();
  let campingId;
  // eslint-disable-next-line prefer-destructuring
  campingId = location.pathname.split('/')[2];
  if (isBookResume || isBookPolicies || isBookForm || isBookPayment) {
    campingId = propertyFromURL;
  }

  const campingsArr = [];
  const property = campingsState[campingId];
  if (property) campingsArr.push(campingsState[campingId].ulysesId);
  const { currentLanguage } = useContext(LanguageContext);

  const query = useMemo(() => {
    const path = location.pathname;
    const matchedKey = Object.keys(routeToQueryMap).find((key) => path.includes(key));
    return matchedKey ? routeToQueryMap[matchedKey] : tentOnPlacesQuery;
  }, [location]);

  const { loading: loadingTents, data: dataTents } = useQuery(query, {
    variables: { filters: campings || (campingsArr.length > 0 ? campingsArr : [campingId]), lang: currentLanguage || 'es' },
  });

  useEffect(() => {
    const fetchTents = async () => {
      try {
        if (!loadingTents) {
          const tents = await dataTents;
          const tentsWithCamping = sanitizeApiResponse(tents.tents)
            .filter((tent) => tent.camping !== null);
          dispatch({
            type: 'allTentsFound',
            payload: tentsWithCamping.reduce(tentsAsHashedObject, {}),
          });
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    fetchTents();
  }, [loadingTents, dataTents]);

  return (
    <TentOnPlacesState.Provider value={state}>
      <TentOnPlacesDispatch.Provider value={dispatch}>
        {children}
      </TentOnPlacesDispatch.Provider>
    </TentOnPlacesState.Provider>
  );
};

TentPlacesProvider.propTypes = {
  children: PropTypes.element.isRequired,
  campings: PropTypes.arrayOf.isRequired,
};

export default TentPlacesProvider;
