import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../TextField';
import GenreField from './GenreField';
import PhoneNumberField from './PhoneNumber';
import { personalInfoWrapper } from './index.module.scss';

const PersonalInfo = ({ disabledFields }) => (
  <div className={personalInfoWrapper}>
    <TextField field="Name" disabled={disabledFields.Name}  data-cy="checkout-step3-name"/>
    <TextField field="Surname" disabled={disabledFields.Surname} data-cy="checkout-step3-surname" />
    <TextField field="Email" disabled={disabledFields.Email} data-cy="checkout-step3-email"/>
    <PhoneNumberField field="Phone" disabled={disabledFields.Phone}  data-cy="checkout-step3-phone"/>
    <TextField field="DNI" disabled={disabledFields.DNI}  data-cy="checkout-step3-id-number"/>
    <GenreField field="Genre" disabled={disabledFields.Genre}  data-cy="checkout-step3-genre"/>
  </div>
);

PersonalInfo.defaultProps = {
  disabledFields: {
    Name: false,
    Surname: false,
    Email: false,
    Phone: false,
    DNI: false,
    Genre: false,
  },
};

PersonalInfo.propTypes = {
  disabledFields: PropTypes.shape({
    Name: PropTypes.bool,
    Surname: PropTypes.bool,
    Email: PropTypes.bool,
    Phone: PropTypes.bool,
    DNI: PropTypes.bool,
    Genre: PropTypes.bool,
  }),
};

export default PersonalInfo;
