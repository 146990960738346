import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, useIntl } from 'react-intl';
import {
  cardContentContainer,
  cardTitle,
  cardContentButtonsContainer,
  tagsWrapper,
  cardLocation,
  closedTag,
  cardTitleAndScoreWrapper,
  cardContentFooter
} from './index.module.scss';
import Tag from '../../../../../../../../../components/Tag';
import useCampings from '../../../../../../../../../services/Campings/useCampings';
import { ReactComponent as LocationIcon } from '../../../../../../../../../assets/icons/location.svg';
import CommonLabels from '../../../../../../../../../components/CommonLabels';
import Score from '../../../../../../../../../components/Score';
import Status from '../../../../../../../../../constants/status';
import FeaturesTags from '../../../../../../../../../components/FeaturesTags';
import { SearchContext } from '../../../../../../../../../contexts/SearchContext';
import FormattedPriceByNight from '../../../../../../../../../components/FormattedPrices/FormattedPriceByNight';
import daysBetweenCheckInAndCheckOut from '../../../../../../../../../utils/daysBetweenCheckInAndCheckOut';
import { useReservationState } from '../../../../../../../../../services/Reservation';
import Services from '../../../../../../../../../constants/services';

const CardContent = injectIntl(({
  propertyID,
  onClick,
  roomPlans,
}) => {
  const intl = useIntl();
  const { state: { campings: { [propertyID]: camping } } } = useCampings();
  const {
    filteredStatus: { [propertyID]: status },
  } = useContext(SearchContext);
  const {
    startDate, endDate, adults, children, babies,
  } = useReservationState();
  const {
    location, mainLabel, labels,
    score, features, propertyName,
  } = camping;
  const pax = adults + children + babies;

  function getMonthName(openfrom) {
    const url = new URL(window.location.href);
    const monthNameTranslation = url.searchParams.get('lang');

    const locale = monthNameTranslation || 'es-ES';
    const date = new Date(openfrom);
    return date.toLocaleString(locale, { month: 'long' });
  }

  function openSoonDate(openfrom, openTo) {
    const openFromdate = new Date(openfrom);
    const openFromDay = openFromdate.getDate();
    const openFromNameMonth = getMonthName(openfrom);

    const openTodate = new Date(openTo);

    const now = new Date();

    if (openFromdate < now && openTodate < now) {
      return `${intl.formatMessage({ id: 'Card.Button.Closed' })}`;
      
    }
    if (openFromdate > now && openTodate > now) {
      
      return `${intl.formatMessage({ id: 'Card.Button.openFrom' })} ${openFromDay}  ${intl.formatMessage({ id: 'Card.Button.midCharacterOpenSoon' })} ${openFromNameMonth}`;
    }

    return `${intl.formatMessage({ id: 'Card.Button.Closed' })}`;
  }

  const getMinPriceFromRoomPlans = () => {
    // Auxiliar function to get the minimun valid price from a collect on plans.
    const getMinPrice = (plans) => plans.reduce((minPlan, { avgPrice, roomRateDetailed }) => {
      const isPriceValid = typeof avgPrice === 'number' && avgPrice > 0;
      const isAvailable = roomRateDetailed.every((room) => room.available);
      return (isPriceValid && isAvailable) ? Math.min(minPlan, avgPrice) : minPlan;
    }, Infinity);

    return roomPlans.reduce((min, plan) => {
      // Calc the minimun plan's price using auxiliar function
      // eslint-disable-next-line max-len
      const minPriceInPlan = plan.boards.reduce((minBoard, board) => Math.min(minBoard, getMinPrice(board.plans)), Infinity);
      return Math.min(min, minPriceInPlan);
    }, Infinity);
  };

  const globalMinAvgPrice = getMinPriceFromRoomPlans(roomPlans);
  // eslint-disable-next-line max-len
  const finalPrice = (globalMinAvgPrice !== Infinity) ? Math.floor(globalMinAvgPrice) : globalMinAvgPrice;

  return (
    <div
      className={cardContentContainer}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <div>
        {status !== Status.CLOSED ? (
          <div className={tagsWrapper}>
            {mainLabel && <Tag>{mainLabel}</Tag>}
            {labels && <CommonLabels labels={labels} />}
          </div>
        ) : (
          <div className={tagsWrapper}>
            <Tag className={closedTag}>
              
              {' '}
              {openSoonDate(camping.openFrom, camping.openTo)}
            </Tag>
          </div>
        )}
        <div className={cardTitleAndScoreWrapper}>
          <p className={cardTitle}>{propertyName}</p>
          <Score score={score || 5} />
        </div>
        <p className={cardLocation}>
          <LocationIcon />
          {' '}
          {`${location ? `${location}` : ''}`}
        </p>
        <div className={cardContentButtonsContainer}>
          <FeaturesTags features={features || []} />
        </div>
      </div>
      <div className={cardContentFooter}>
      {status === Status.AVAILABLE && (
        <>
          {finalPrice !== Infinity ? (
            <FormattedPriceByNight 
              daysBetweenCheckInAndCheckOut={daysBetweenCheckInAndCheckOut({ startDate, endDate })}
              type={Services.CAMP}
              dates={{ startDate, endDate }}
              pax={pax}
              roomPlans={roomPlans}
              minPrice={finalPrice}
            />
          ) : (
            <></>
          )}
        </>
      )}
      </div>
    </div>
  );
});

CardContent.propTypes = {
  propertyID: PropTypes.string,
  title: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.shape({
      render: PropTypes.func,
    }),
    icon: PropTypes.shape({
      render: PropTypes.func,
    }),
  })),
  setFilteredRatePlans: PropTypes.func,
  score: PropTypes.number,
  description: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(Status).map((key) => Status[key])),
};

CardContent.defaultProps = {
  propertyID: '',
  title: 'Nombre no disponible',
  filters: [],
  setFilteredRatePlans: () => {},
  score: 4,
  description: '',
  status: Status.NOTAVAILABLE,
};

export default CardContent;
