/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Router, useLocation, Redirect } from '@reach/router';
import PlacesComponent from '../pages/Places/Camp';
import ROUTES from './routes';
import Services from '../constants/services';
import IfReservationIsValid from '../services/CheckValidReservation';
import CheckAuthRoute from '../services/CheckAuthRoute';
import CheckNoAuthRoute from '../services/CheckNoAuthRoute';
import ConfirmationComponent from '../pages/Checkout/steps/Confirmation';
import { LanguageContext } from '../locale/contexts/Language';
import RoomDetailsComponent from '../pages/DetailsPages/RoomDetails';
import PropertyDetailsComponent from '../pages/DetailsPages/PropertyDetails';
import CheckoutComponent from '../pages/Checkout';
import AuthPage from '../pages/AuthPage';
import { usePrevious } from '../services/usePrevious';
import { useReservationDispatch } from '../services/Reservation';
import WithGoingBackButton from '../layouts/WithGoingBackButtonLayout';
import HomeLayout from '../layouts/HomeLayout';
import PlacesLayout from '../layouts/PlacesLayout';
import BasicLayout from '../layouts/BasicLayout';
import AuthLayout from '../layouts/AuthLayout';
import PoliciesAcceptance from '../pages/Checkout/steps/PoliciesAcceptance';
import UserForm from '../pages/Checkout/steps/UserForm';
import Payment from '../pages/Checkout/steps/Payment';
import OrderResume from '../pages/Checkout/steps/OrderResume';
import isMobile from '../utils/isMobile';
import { getMainLocale } from '../locale/translations';
import useSyncUserWithToken from '../utils/useSyncUserWithToken';
import PrivateAreaLayout from '../layouts/PrivateAreaLayout';
import PrivateAreaPage from '../pages/PrivateArea';
import PrivateAreaSettingsPage from '../pages/PrivateAreaSettings';
import VerifyEmailPage from '../pages/VerifyEmailPage';
import VerifyEmailLayout from '../layouts/VerifyEmailLayout';
import PasswordResetPage from '../pages/PasswordResetPage';
import PasswordResetLayout from '../layouts/PasswordResetLayout';
import allowedLocales from '../constants/locales';

const {
  home,
  places,
  book,
  roomDetails,
  propertyDetails,
  confirmation,
  resume,
  policies,
  form,
  payment,
  auth,
  me,
  meSettings,
  verifyEmail,
  passwordReset,
} = ROUTES;

const ScrollToTop = ({ children }) => {
  const { pathname, search } = useLocation();
  const { setCurrentLanguage } = useContext(LanguageContext);
  const [placesPageYOffset] = useState({ [places]: 0 });
  const [roomPageYOffset] = useState({ [places]: 0 });
  const [isScrolled, setIsScrolled] = useState(false);
  const prevPathname = usePrevious(pathname);
  const dispatch = useReservationDispatch();
  useSyncUserWithToken();
  const searchParams = new URLSearchParams(search);
  const languageFromURL = searchParams.get('lang');
  const langFromLocalStorage = localStorage.getItem('countryCode');

  const isPlacesPageNotCommingFromHome =
    pathname.includes(places) && prevPathname && !prevPathname.includes(home);
  const isPlacesPageCommingFromRoom =
    pathname.includes(places) &&
    prevPathname &&
    prevPathname.includes(roomDetails);
  const isRoomPageCommingFromRoom =
    pathname.includes(roomDetails) &&
    prevPathname &&
    prevPathname.includes(roomDetails);

  const onScroll = useCallback(() => {
    if (pathname.includes(places)) {
      if (isMobile() && isScrolled) {
        window.scrollTo(0, placesPageYOffset[pathname]);
        setIsScrolled(false);
      }
    }
  }, [pathname, isScrolled, placesPageYOffset]);

  const onScrollRoom = useCallback(() => {
    if (pathname.includes(roomDetails)) {
      if (isMobile() && isScrolled) {
        window.scrollTo(0, roomPageYOffset[pathname]);
        setIsScrolled(false);
      }
    }
  }, [pathname, isScrolled, roomPageYOffset]);
  const updateUrlWithoutNavigation = (newUrl) => {
    const currentState = window.history.state;
    const currentTitle = document.title;
    window.history.replaceState(currentState, currentTitle, newUrl);
  };

  const updateQueryParams = useCallback((key, value) => {
    const url = new window.URL(window.location);
    const currentParams = new URLSearchParams(url.search);

    currentParams.set(key, value);
    updateUrlWithoutNavigation(`${url.pathname}?${currentParams.toString()}`);
  }, []);
  const isComingFromRoom = useCallback(() => {
    if (isPlacesPageCommingFromRoom) {
      setIsScrolled(true);
    }
  }, [isPlacesPageCommingFromRoom]);

  const isRoomComingFromRoom = useCallback(() => {
    if (isRoomPageCommingFromRoom) {
      setIsScrolled(true);
    }
  }, [isRoomPageCommingFromRoom]);

  useEffect(() => {
    if (isMobile() && isScrolled) return onScroll;
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll, isScrolled]);

  useEffect(() => {
    if (isMobile() && isScrolled) return onScrollRoom;
    window.addEventListener('scroll', onScrollRoom);
    return () => window.removeEventListener('scroll', onScrollRoom);
  }, [onScrollRoom, isScrolled]);

  useEffect(() => {
    isComingFromRoom();
  }, [isComingFromRoom]);

  useEffect(() => {
    isRoomComingFromRoom();
  }, [isRoomComingFromRoom]);

  useEffect(() => {
    if (pathname !== prevPathname) {
      if (!isPlacesPageNotCommingFromHome) {
        window.scrollTo(0, 0);
      }
      if (isRoomPageCommingFromRoom) {
        window.scrollTo(0, roomPageYOffset[prevPathname]);
      }
    }
  }, [
    pathname,
    isPlacesPageNotCommingFromHome,
    prevPathname,
    isRoomPageCommingFromRoom,
    roomPageYOffset,
  ]);

  useEffect(() => {
    if (search.includes('service=kamp')) {
      dispatch({
        type: 'setKampaohService',
        payload: Services.CAMP,
      });
    }
    return undefined;
  }, [search, setCurrentLanguage, dispatch]);

  useEffect(() => {
    const navigatorLanguage = navigator.language || navigator.userLanguage;
    const countryCode = localStorage.getItem('countryCode');

    if (search.includes('lang=en')) {
      updateQueryParams('lang', 'en');
      setCurrentLanguage('en');
      return localStorage.setItem('countryCode', getMainLocale('en_US'));
    }

    if (search.includes('lang=es')) {
      updateQueryParams('lang', 'es');
      setCurrentLanguage('es');
      return localStorage.setItem('countryCode', getMainLocale('es_ES'));
    }

    if (search.includes('lang=fr')) {
      updateQueryParams('lang', 'fr');
      setCurrentLanguage('fr');
      return localStorage.setItem('countryCode', getMainLocale('fr_FR'));
    }

    if (search.includes('lang=it')) {
      updateQueryParams('lang', 'it');

      setCurrentLanguage('it');
      return localStorage.setItem('countryCode', getMainLocale('it_IT'));
    }

    if (search.includes('lang=pt')) {
      updateQueryParams('lang', 'pt');

      setCurrentLanguage('pt');
      return localStorage.setItem('countryCode', getMainLocale('pt_PT'));
    }

    if (countryCode) {
      setCurrentLanguage(getMainLocale(countryCode));
      updateQueryParams('lang', countryCode);

      return localStorage.setItem('countryCode', getMainLocale(countryCode));
    }
    const { referrer } = document;
    const referrerFromKampaohLanding = allowedLocales?.find((locale) =>
      referrer.includes(`${locale}.kampaoh`),
    );

    if (
      referrerFromKampaohLanding &&
      allowedLocales.includes(referrerFromKampaohLanding)
    ) {
      setCurrentLanguage(referrerFromKampaohLanding);
      updateQueryParams('lang', referrerFromKampaohLanding);

      return localStorage.setItem('countryCode', referrerFromKampaohLanding);
    }
    if (navigatorLanguage) {
      setCurrentLanguage(getMainLocale(navigatorLanguage));
      updateQueryParams('lang', getMainLocale(navigatorLanguage));

      return localStorage.setItem(
        'countryCode',
        getMainLocale(navigatorLanguage),
      );
    }
    setCurrentLanguage('es');
    updateQueryParams('lang', 'es');

    return localStorage.setItem('countryCode', getMainLocale('es'));
  }, [setCurrentLanguage, search, updateQueryParams]);

  useEffect(() => {
    const normalizeLanguage = (lang) => (lang ? lang.substring(0, 2) : null);
    // eslint-disable-next-line no-unused-expressions
    const normalizedLanguageFromURL =
      languageFromURL !== null && languageFromURL !== undefined
        ? normalizeLanguage(languageFromURL)
        : normalizeLanguage(langFromLocalStorage);
    updateQueryParams('lang', normalizedLanguageFromURL);
  }, [languageFromURL, updateQueryParams, langFromLocalStorage]);

  const maxCapacityIsOverreached = useCallback(() => {
    const searchParamsFromURL = new URLSearchParams(search);
    const adultsFromURL = parseInt(searchParamsFromURL.get('adults'), 10);
    const childrenFromURL = parseInt(searchParamsFromURL.get('children'), 10);
    const babiesFromURL = parseInt(searchParamsFromURL.get('babies'), 10);
    return (
      adultsFromURL >= 1 &&
      adultsFromURL + childrenFromURL <= 6 &&
      babiesFromURL <= 3
    );
  }, [search]);

  useEffect(() => {
    if (!maxCapacityIsOverreached()) {
      dispatch({ type: 'setadults', payload: 2 });
      dispatch({ type: 'setchildren', payload: 0 });
      dispatch({ type: 'setbabies', payload: 0 });
      updateQueryParams('adults', 2);
      updateQueryParams('children', 0);
      updateQueryParams('babies', 0);
    }
  }, [dispatch, updateQueryParams, maxCapacityIsOverreached]);

  if (pathname === '/') return <Redirect noThrow="true" to={home} />;
  return children;
};

const Places = PlacesLayout(PlacesComponent);
const RoomDetails = WithGoingBackButton(RoomDetailsComponent);
const PropertyDetails = WithGoingBackButton(PropertyDetailsComponent);
const Checkout = BasicLayout(CheckoutComponent);
const Confirmation = BasicLayout(ConfirmationComponent);
const Auth = AuthLayout(AuthPage);
const PrivateArea = PrivateAreaLayout(PrivateAreaPage);
const PrivateAreaSettings = PrivateAreaLayout(PrivateAreaSettingsPage);
const VerifyEmail = VerifyEmailLayout(VerifyEmailPage);
const PasswordReset = PasswordResetLayout(PasswordResetPage);

const Routes = () => (
  <Router>
    <ScrollToTop default>
      <HomeLayout path={`${home}`} />
      <IfReservationIsValid component={Places} path={`${places}`} />
      <IfReservationIsValid component={Checkout} path={`${book}`}>
        <OrderResume path={`${resume}`} />
        <PoliciesAcceptance path={`${policies}`} />
        <UserForm path={`${form}`} />
        <Payment path={`${payment}`} />
      </IfReservationIsValid>
      <RoomDetails path={`${roomDetails}/:propertyID/:roomId`} />
      <PropertyDetails path={`${propertyDetails}/:propertyId`} />
      <Confirmation path={`${confirmation}`} />
      <CheckAuthRoute component={PrivateArea} path={`${me}`} />
      <CheckAuthRoute component={PrivateAreaSettings} path={`${meSettings}`} />
      <CheckNoAuthRoute component={Auth} path={`${auth}`} />
      <VerifyEmail path={`${verifyEmail}`} />
      <PasswordReset path={`${passwordReset}`} />
    </ScrollToTop>
  </Router>
);

ScrollToTop.propTypes = {
  children: PropTypes.string,
};

ScrollToTop.defaultProps = {
  children: '',
};
export default Routes;
