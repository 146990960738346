import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../../../../../../../locale/contexts/Language';
import {
  FullConditionsContainer,
} from '../../../../index.module.scss';
import AcceptanceButton from '../../../AcceptanceButton';
import ConditionAcceptance from '../../../ConditionAcceptance';
import privacyMarkdownEN from './privacy_en.md';
import privacyMarkdownES from './privacy_es.md';
import privacyMarkdownFR from './privacy_fr.md';
import privacyMarkdownIT from './privacy_it.md';
import privacyMarkdownPT from './privacy_pt.md';

const PrivacyConditionAcceptance = ({ checked, changeChecked }) => {
  const [privacyText, setPrivacyText] = useState(null);
  const { currentLanguage } = useContext(LanguageContext);
  useEffect(() => {
    switch (currentLanguage) {
      case 'es':
        fetch(privacyMarkdownES).then((response) => response.text()).then((text) => {
          setPrivacyText(text);
        });
        break;
      case 'en':
        fetch(privacyMarkdownEN).then((response) => response.text()).then((text) => {
          setPrivacyText(text);
        });
        break;
      case 'fr':
        fetch(privacyMarkdownFR).then((response) => response.text()).then((text) => {
          setPrivacyText(text);
        });
        break;
      case 'pt':
        fetch(privacyMarkdownPT).then((response) => response.text()).then((text) => {
          setPrivacyText(text);
        });
        break;
      case 'it':
        fetch(privacyMarkdownIT).then((response) => response.text()).then((text) => {
          setPrivacyText(text);
        });
        break;
      default:
        fetch(currentLanguage.includes('es') ? privacyMarkdownES : privacyMarkdownEN).then((response) => response.text()).then((text) => {
          setPrivacyText(text);
        });
        break;
    }
  }, [currentLanguage]);
  return (
    <>
      <ConditionAcceptance
        markdownSource={privacyText}
        checked={checked}
        changeChecked={changeChecked}
        data-cy="checkout-step2-privacy"
      />
      <div className={FullConditionsContainer}>
        <AcceptanceButton
          type="policy"
          onClick={(event) => { event.preventDefault(); window.open('https://es.kampaoh.com/camping-sin-lios/aviso-legal-condiciones-de-uso-y-politica-de-privacidad-cookies/'); }}
        />
      </div>
    </>
  );
};

PrivacyConditionAcceptance.defaultProps = {
  checked: false,
  changeChecked: () => {},
};

PrivacyConditionAcceptance.propTypes = {
  checked: PropTypes.bool,
  changeChecked: PropTypes.func,
};

export default PrivacyConditionAcceptance;
