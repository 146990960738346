import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { adults, boys, babies } from '../../constants/typeOfPersons';
import initialState from './initialState';
import { setItemWithExpiryTime } from '../localStorageWithExpiration';
import Services from '../../constants/services';

const ReservationState = React.createContext();
const ReservationDispatch = React.createContext();

const reservationReducer = (state, action) => {
  switch (action.type) {
    case 'setAllRelevantDataAndReadyToUse':
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        [adults]: action.payload[adults],
        [boys]: action.payload[boys],
        [babies]: action.payload[babies],
        readyToBeUsed: true,
      };
    case 'setOnlyDefaultPropertyID':
      return {
        ...state,
        readyToBeUsed: true,
        defaultPropertyID: action.payload.defaultPropertyID,
      };

    case 'setOnlyPromoCode':
      return {
        ...state,
        readyToBeUsed: true,
        promoCode: action.payload.promoCode,
      };

    case 'setPets':
      return { ...state, pets: action.payload };
    case 'setPetsOnCamping':
      return { ...state, petsOnCamping: action.payload };

    case 'setItAsReadyToBeUsed':
      return { ...state, readyToBeUsed: true };

    case 'setRatePlansAsFetched':
      return { ...state, ratePlansFetched: true };

    case 'setRatePlansAsNotFetched':
      return { ...state, ratePlansFetched: false };

    case 'setStartDate':
      return { ...state, startDate: action.payload };

    case 'setEndDate':
      return { ...state, endDate: action.payload };

    case `increase${adults}`:
      return {
        ...state,
        [adults]: state[adults] + 1,
        lastTypeOfPersonIncreased: adults,
      };

    case `set${adults}`:
      return { ...state, [adults]: action.payload };

    case `increase${boys}`:
      return {
        ...state,
        [boys]: state[boys] + 1,
        lastTypeOfPersonIncreased: boys,
      };

    case `set${boys}`:
      return { ...state, [boys]: action.payload };

    case `increase${babies}`:
      return { ...state, [babies]: state[babies] + 1 };

    case `set${babies}`:
      return { ...state, [babies]: action.payload };

    case `decrease${adults}`:
      return { ...state, [adults]: state[adults] - 1 };

    case `decrease${boys}`:
      return { ...state, [boys]: state[boys] - 1 };

    case `decrease${babies}`:
      return { ...state, [babies]: state[babies] - 1 };

    case 'setLastTypeOfPersonIncreased':
      return { ...state, lastTypeOfPersonIncreased: action.payload };

    case 'setSelectedHotel':
      return { ...state, selectedHotel: action.payload };

    case 'setCampingName':
      return { ...state, selectedCampingName: action.payload };

    case 'setSelectedHotelId':
      return { ...state, selectedHotelId: action.payload };

    case 'setRateId':
      return { ...state, rateId: action.payload };

    case 'setRatePropertyId':
      return { ...state, ratePropertyId: action.payload };

    case 'setRateOccupancyCode':
      return { ...state, rateOccupancyCode: action.payload };

    case 'setRateOccupancyId':
      return { ...state, rateOccupancyId: action.payload };

    case 'setSelectedRoomTypeId':
      return { ...state, selectedRoomTypeId: action.payload };

    case 'setSelectedRoomTypeCode':
      return { ...state, selectedRoomTypeCode: action.payload };

    case 'setSelectedRoomType':
      return { ...state, selectedRoomType: action.payload };

    case 'setTotalPrice':
      return { ...state, totalPrice: action.payload };

    case 'setPriceByNight':
      return { ...state, priceByNight: action.payload };

    case 'setRoomId':
      return { ...state, selectedRoomTypeId: action.payload };

    case 'setBoardId':
      return { ...state, boardId: action.payload };

    case 'setOfferId':
      return { ...state, offerId: action.payload };

    case 'setPromotionId':
      return { ...state, promotionId: action.payload };

    case 'setOfferInfo':
      return { ...state, offerInfo: action.payload };

    case 'setPromotionInfo':
      return { ...state, promotionInfo: action.payload };

    case 'setRateName':
      return { ...state, rateName: action.payload };

    case 'setDeadlineDay':
      return { ...state, deadlineDay: action.payload };

    case 'setNonRefundable':
      return { ...state, nonRefundable: action.payload };

    case 'setDepositPercentage':
      return { ...state, depositPercentage: action.payload };

    case 'setConfirmationUrl':
      return { ...state, confirmationUrl: action.payload };

    case 'setKampaohService':
      if (action.payload === Services.CAMP) {
        return {
          ...state,
          kampaohService: action.payload,
          preferredLocation: state.preferredCampLocation,
        };
      }
      return {
        ...state,
        kampaohService: action.payload,
        preferredLocation: state.preferredGoLocation,
      };

    case 'fillUserForm':
      return {
        ...state,
        guestFirstName: action.payload.Name,
        guestLastName: action.payload.Surname,
        guestGender: action.payload.Genre,
        guestCountry: action.payload.Country,
        guestCity: action.payload.City,
        guestZip: action.payload.PostalCode,
        guestEmail: action.payload.Email,
        guestPhone: action.payload.prefix
          .concat(action.payload.Phone)
          .substring(1),
        guestPhoneFormField: action.payload.Phone,
        guestCompany: action.payload.COMP,
        guestMeetUsBy: action.payload.FUENTE,
        guestIdentity: action.payload.DNI.replace(' ', ''),
        guestPartyAccepted: 'Aceptado en la Web (API)',
      };
    case 'guestClub':
      return {
        ...state,
        guestClub: action.payload,
      };
    case 'acceptPartyPolicy':
      return {
        ...state,
        acceptPartyPolicy: action.payload,
      };
    case 'acceptReservationPolicy':
      return {
        ...state,
        acceptReservationPolicy: action.payload,
      };
    case 'acceptReservationParticularPolicy':
      return {
        ...state,
        acceptReservationParticularPolicy: action.payload,
      };
    case 'acceptPrivacyPolicy':
      return {
        ...state,
        acceptPrivacyPolicy: action.payload,
      };
    case 'acceptKMPolicy':
      return {
        ...state,
        acceptKMPolicy: action.payload,
      };
    case 'acceptArrivalPolicy':
      return {
        ...state,
        acceptArrivalPolicy: action.payload,
      };
    case 'acceptBailPolicy':
      return {
        ...state,
        acceptBailPolicy: action.payload,
      };
    case 'acceptUsePolicy':
      return {
        ...state,
        acceptUsePolicy: action.payload,
      };
    case 'acceptPickUpPolicy':
      return {
        ...state,
        acceptPickUpPolicy: action.payload,
      };
    case 'acceptAllPolicy':
      return {
        ...state,
        acceptAllPolicy: action.payload,
      };
    case 'setPromoCode':
      return {
        ...state,
        promoCode: action.payload,
      };

    case 'resetPersons':
      return {
        ...state,
        [adults]: initialState()[adults],
        [babies]: initialState()[babies],
        [boys]: initialState()[boys],
      };
    case 'setPreferredLocation':
      return {
        ...state,
        preferredCampLocation: action.payload,
        preferredLocation: action.payload,
      };
    case 'setPreferredZoneName':
      return {
        ...state,
        preferredZoneName: action.payload,
      };
    case 'setPreferredZoneId':
      return {
        ...state,
        preferredZoneId: action.payload,
      };
    case 'setItAsUpdatingDates':
      return {
        ...state,
        updatingDates: action.payload,
      };
    case 'setItAsUpdatingAvailability':
      return {
        ...state,
        updatingAvailability: action.payload,
      };
    case 'setRatePlanPromoCode':
      return {
        ...state,
        ratePlanPromoCode: action.payload,
      };

    case 'setRatePlanNamePrivate':
      return {
        ...state,
        ratePlanNamePrivate: action.payload,
      };

    case 'setRatePlanNamePublic':
      return {
        ...state,
        ratePlanNamePublic: action.payload,
      };
    case 'setRatePlanPropertyPolicy':
      return {
        ...state,
        ratePlanPropertyPolicy: action.payload,
      };
    case 'setRatePlanPropertyPolicyId':
      return {
        ...state,
        ratePlanPropertyPolicyId: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const ReservationProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    reservationReducer,
    initialState(),
  );

  useEffect(() => {
    setItemWithExpiryTime('startDate', state.startDate);
    setItemWithExpiryTime('endDate', state.endDate);
  }, [state.startDate, state.endDate]);

  return (
    <ReservationState.Provider value={state}>
      <ReservationDispatch.Provider value={dispatch}>
        {children}
      </ReservationDispatch.Provider>
    </ReservationState.Provider>
  );
};

ReservationProvider.propTypes = {
  children: PropTypes.element,
};

ReservationProvider.defaultProps = {
  children: {},
};

const useReservationState = () => {
  const context = React.useContext(ReservationState);
  if (context === undefined) {
    throw new Error('Reservation context must be used within a provider');
  }
  return context;
};

const useReservationDispatch = () => {
  const context = React.useContext(ReservationDispatch);
  if (context === undefined) {
    throw new Error('Reservation dispatch must be used within a provider');
  }
  return context;
};

export { ReservationProvider, useReservationState, useReservationDispatch };
