import React, { useCallback } from "react";
import { useNavigate } from "@reach/router";
import { useIntl } from "react-intl";
import ROUTES from "../../routes/routes";
import { ReactComponent as TribeIcon } from "../../assets/icons/tribe-icon.svg";
import { ReactComponent as PlantIcon } from "../../assets/icons/plant.svg";
import {
  wrapper,
  content,
  logo,
  textContainer,
  title,
  description,
  buttonsContainer,
  loginButton,
  signupButton,
  decorativeImage,
} from "./index.module.scss";

const LoginBanner = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleLoginClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      const params = new URLSearchParams(window.location.search);
      params.delete("redirect");
      const redirect = `${window.location.pathname}${
        // eslint-disable-next-line prefer-template
        params.toString() ? "?" + params.toString() : ""
      }`;
      navigate(`${ROUTES.auth}?redirect=${encodeURIComponent(redirect)}`);
    },
    [navigate],
  );

  return (
    <div className={wrapper}>
      <div className={content}>
        <TribeIcon className={logo} />
        <div className={textContainer}>
          <h2 className={title}>
            {intl.formatMessage({ id: "LoginBanner.Title" })}
          </h2>
          <p className={description}>
            {intl.formatMessage({ id: "LoginBanner.Description" })}
          </p>
          <div className={buttonsContainer}>
            <button
              className={loginButton}
              onClick={handleLoginClick}
              type="button"
            >
              {intl.formatMessage({ id: "LoginBanner.LoginButton.Text" })}
            </button>
            <button
              className={signupButton}
              onClick={handleLoginClick}
              type="button"
            >
              {intl.formatMessage({ id: "LoginBanner.SignUpButton.Text" })}
            </button>
          </div>
        </div>
      </div>
      <PlantIcon className={decorativeImage} />
    </div>
  );
};

export default LoginBanner;
