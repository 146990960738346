import React, { useEffect, useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { wrapper, footer, footerWrapper } from './index.module.scss';
import CustomDrawer from '../CustomDrawer';
import RangeDatePicker from '../RangeDatePicker';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import {
  useReservationState,
  useReservationDispatch,
} from '../../services/Reservation';
import ActionButton from '../ActionButton';

const DatePickerDrawer = ({
  visible,
  onClose,
  onOk,
  initialEndDate,
  initialStartDate,
  setRelaunchGetReference,
  openFrom,
}) => {
  const { endDate, startDate } = useReservationState();
  const [currentDates, setCurrentDates] = useState({
    endDate: initialEndDate ?? endDate,
    startDate: initialStartDate ?? startDate,
  });
  const dispatch = useReservationDispatch();

  useEffect(() => {
    if (visible) {
      setCurrentDates({ endDate, startDate });
    }
  }, [visible, endDate, startDate]);

  useEffect(() => {
    setCurrentDates({ startDate: initialStartDate, endDate: initialEndDate });
  }, [initialEndDate, initialStartDate]);

  const handleOnClose = useCallback(() => {
    if (currentDates.startDate !== null && currentDates.endDate !== null) {
      if (typeof setRelaunchGetReference === 'function') {
        setRelaunchGetReference(false);
      }
      dispatch({ type: 'setEndDate', payload: currentDates?.endDate });
      dispatch({ type: 'setStartDate', payload: currentDates?.startDate });
      onOk({ ...currentDates });
    } else {
      onClose();
    }
  }, [onClose, onOk, currentDates, dispatch, setRelaunchGetReference]);

  return (
    <CustomDrawer
      title={<FormattedMessage id="ReservationWizard.step2.title" />}
      visible={visible}
      onClose={handleOnClose}
      icon={<CloseIcon />}
      mask
      subTitle={null}
    >
      <div className={wrapper}>
        <RangeDatePicker
          startDate={currentDates.startDate}
          endDate={currentDates.endDate}
          onChange={(dates) => {
            if (dates.endDate) {
              setCurrentDates({
                startDate: dates?.startDate,
                endDate: dates?.endDate,
              });
            } else {
              setCurrentDates({ startDate: dates?.startDate, endDate: null });
              /* dispatch({ type: 'setEndDate', payload: null });
              dispatch({ type: 'setStartDate', payload: dates?.startDate }); */
            }
          }}
          openFrom={openFrom}
        />
        <div className={footerWrapper}>
          <div className={footer}>
            <div>
              <ActionButton
                size="sm"
                onClick={() => {
                  if (typeof setRelaunchGetReference === 'function') {
                    setRelaunchGetReference(false);
                  }
                  dispatch({
                    type: 'setEndDate',
                    payload: currentDates?.endDate,
                  });
                  dispatch({
                    type: 'setStartDate',
                    payload: currentDates?.startDate,
                  });
                  onOk({ ...currentDates });
                }}
                disabled={!currentDates.startDate || !currentDates.endDate}
              >
                OK
              </ActionButton>
            </div>
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
};

DatePickerDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  initialEndDate: PropTypes.instanceOf(moment),
  initialStartDate: PropTypes.instanceOf(moment),
  setRelaunchGetReference: PropTypes.func,
  openFrom: PropTypes.instanceOf(moment).isRequired,
};

DatePickerDrawer.defaultProps = {
  initialEndDate: undefined,
  initialStartDate: undefined,
  setRelaunchGetReference: undefined,
};
export default DatePickerDrawer;
