import React, { useContext } from 'react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  filterContainer,
  filterTextContainer,
  // filterMoreDestinationText,
} from './index.module.scss';
// import DestinationFilters from './components/DestinationsFilter';
import { CampingFeaturesContext } from '../../../../services/CampingFeatures/Provider';
// import { RegionsContext } from '../../../../services/Regions/Provider';
import FilterButtons from './components/FilterButtons';
import PricesFilter from './components/PricesFilter';
// import { useReservationState } from '../../../../services/Reservation';
// import Services from '../../../../constants/services';
import { kampaohCampFilters } from '../../../../constants/filters';

const { Text } = Typography;

const Filters = ({ onClick, filteredRatePlans }) => {
  // const { kampaohService } = useReservationState();
  // const isKampaohCampService = () => kampaohService === Services.CAMP;
  const AvailableFilterCharacteristics = useContext(CampingFeaturesContext);
  // const DestinationFiltersCharacteristics = useContext(RegionsContext);
  const filterTypes = kampaohCampFilters;
  return (
    <>
      <div className={filterContainer}>
        <Text className={filterTextContainer}>
          <FormattedMessage id="Kampaoh.Services" defaultMessage="FILTRO" />
        </Text>
        <FilterButtons
          type={filterTypes.SERVICE}
          availableFilters={AvailableFilterCharacteristics}
          onClick={onClick}
        />
      </div>
      {/* DestinationFilters are not shown on Kampaoh Go Service because the relation between
          regions and goPlaces is 1...n and placesGo only shows ONE goPlace.
      */}

      {/*
        {isKampaohCampService() && (
          <div className={filterContainer}>
            <Text className={filterTextContainer}>
              <FormattedMessage id="Kampaoh.Destination" defaultMessage="FILTRO" />
            </Text>
            <DestinationFilters filters={DestinationFiltersCharacteristics} onClick={onClick} />
          </div>
          )}
        */}

      <div className={filterContainer}>
        <Text className={filterTextContainer}>
          <FormattedMessage id="Kampaoh.Categories" defaultMessage="FILTRO" />
        </Text>
        <FilterButtons
          type={filterTypes.CATEGORY}
          availableFilters={AvailableFilterCharacteristics}
          onClick={onClick}
        />
      </div>
      {/* <div className={filterContainer}>
        <Text className={filterMoreDestinationText} role="button" onClick={onShowMoreDestinations}>
          <FormattedMessage id="Kampaoh.ShowMoreDestinations" defaultMessage="FILTRO" />
        </Text>
      </div> */}
      <div className={filterContainer}>
        <Text className={filterTextContainer}>
          <FormattedMessage id="Kampaoh.Price" defaultMessage="FILTRO" />
        </Text>
        <PricesFilter onClick={onClick} filteredRatePlans={filteredRatePlans} />
      </div>
    </>
  );
};

Filters.propTypes = {
  // onShowMoreDestinations: PropTypes.func,
  onClick: PropTypes.func,
  filteredRatePlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

Filters.defaultProps = {
  // onShowMoreDestinations: () => {},
  onClick: () => {},
};

export default Filters;
