import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { ReactComponent as KampaohUpconmingTripIcon } from '../../assets/icons/kampaoh-upcoming-trip-icon.svg';
import { LanguageContext } from '../../locale/contexts/Language';
import {
  tripCard,
  tripInfo,
  destination,
  dates,
  iconContainer,
  icon,
} from './index.module.scss';

const UpcomingTripCard = ({ location, checkin, checkout, onClick }) => {
  const intl = useIntl();
  const { currentLanguage } = useContext(LanguageContext);

  const dateRange = useMemo(() => {
    moment.locale(currentLanguage);
    const formattedCheckin = moment(checkin);
    const formattedCheckout = moment(checkout);

    if (formattedCheckin.year() === formattedCheckout.year()) {
      if (formattedCheckin.month() === formattedCheckout.month()) {
        return intl.formatMessage(
          {
            id: 'UpcomingTripCard.SameMonthYear',
          },
          {
            fromDay: formattedCheckin.format('D'),
            toDay: formattedCheckout.format('D'),
            month: formattedCheckout.format('MMMM'),
            year: formattedCheckout.format('YYYY'),
          },
        );
      }
      return intl.formatMessage(
        {
          id: 'UpcomingTripCard.SameYearDifferentMonth',
        },
        {
          fromDay: formattedCheckin.format('D'),
          fromMonth: formattedCheckin.format('MMMM'),
          toDay: formattedCheckout.format('D'),
          toMonth: formattedCheckout.format('MMMM'),
          year: formattedCheckout.format('YYYY'),
        },
      );
    }
    return intl.formatMessage(
      {
        id: 'UpcomingTripCard.DifferentYear',
      },
      {
        fromDay: formattedCheckin.format('D'),
        fromMonth: formattedCheckin.format('MMMM'),
        fromYear: formattedCheckin.format('YYYY'),
        toDay: formattedCheckout.format('D'),
        toMonth: formattedCheckout.format('MMMM'),
        toYear: formattedCheckout.format('YYYY'),
      },
    );
  }, [checkin, checkout, currentLanguage, intl]);
  return (
    <div
      className={tripCard}
      onClick={() => onClick()}
      role="button"
      tabIndex={-1}
      onKeyDown={() => {}}
    >
      <div className={tripInfo}>
        <h2 className={destination}>
          {intl.formatMessage(
            { id: 'UpcomingTripCard.TripAnnouncement' },
            { location },
          )}
        </h2>
        <p className={dates}>{dateRange}</p>
      </div>
      <div className={iconContainer}>
        <KampaohUpconmingTripIcon alt="Icon" className={icon} />
      </div>
    </div>
  );
};
UpcomingTripCard.propTypes = {
  location: PropTypes.string.isRequired,
  checkin: PropTypes.instanceOf(Date).isRequired,
  checkout: PropTypes.instanceOf(Date).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UpcomingTripCard;
