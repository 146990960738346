import { Form, Select, Typography } from 'antd';
import { PropTypes } from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useCountries } from '../../../../../../utils/useCountries';
import rules from '../validationRules';
import {
  formItemStyle,
  selectDisabled,
  selectFieldWrapper,
  selectStyle,
} from './index.module.scss';

const SelectField = ({ field, disabled, "data-cy": dataCy }) => {
  const intl = useIntl();
  const fieldTitle = intl.formatMessage({
    id: `#UserForm.Validation.${field}.Placeholder`,
    defaultMessage: field,
  });
  const fieldPlaceholder = intl.formatMessage({
    id: `#UserForm.Validation.${field}.Placeholder`,
    defaultMessage: field,
  });
  const fieldLabel = useMemo(
    () => <Typography.Text strong>{fieldTitle}</Typography.Text>,
    [fieldTitle],
  );
  const countriesArray = useCountries();
  const defaultCountryLabel = 'España';
  const defaultCountryValue = 'ES';

  const sortedCountriesList = useMemo(
    () => countriesArray?.sort((a, b) => a.label?.localeCompare(b.label))?.map((country) => ({
            label: country?.label,
            value: country?.value,
          })),
    [countriesArray]
  );

  const filterOption = (input, option) =>
    (option?.label ?? '')?.toLowerCase()?.includes(input?.toLowerCase());


  return (
    <div className={selectFieldWrapper}>
      <Form.Item
        className={formItemStyle}
        name={field}
        rules={rules[field]}
        label={fieldLabel}
      >
        <Select
          showSearch
          className={`${selectStyle} ${disabled && selectDisabled}`}
          bordered
          placeholder={fieldPlaceholder}
          disabled={disabled}
          virtual={false}
          filterOption={filterOption}
          options={sortedCountriesList}
          defaultValue={{ value: defaultCountryValue, label: defaultCountryLabel }}
          data-cy={dataCy}
        />
      </Form.Item>
    </div>
  );
};

SelectField.defaultProps = {
  disabled: false,
  "data-cy": undefined
};

SelectField.propTypes = {
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  "data-cy": PropTypes.string
};

export default SelectField;
