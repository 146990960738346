import React from 'react';
import ReactMarkdown from 'react-markdown';
import { wrapper, lineBreak } from './index.module.scss';
import { useTopBannerState } from '../../services/TopBanner/Provider';

const TopBanner = (positionProp) => {
  const { status, description } = useTopBannerState();
  const { position } = positionProp;
  return status ? (
    <div className={wrapper} style={{ position: position || 'sticky' }}>
      <ReactMarkdown source={description} className={lineBreak} />
    </div>
  ) : (
    <></>
  );
};

export default TopBanner;
