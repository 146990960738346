import React, { useMemo, useState, useEffect, useReducer } from "react";
import { PropTypes } from "prop-types";
import { Input } from "antd";
import { useIntl } from "react-intl";
import {
  searchInputWrapper,
  inputSearch,
  resultWrapper,
  destinationWrapper,
  resultWrapperAllDestination,
  iconWrapper,
  locationWrapper,
  removeButton,
} from "./index.module.scss";
import { ReactComponent as SearchIcon } from "../../../../../../assets/icons/search.svg";
import { ReactComponent as LocationIcon } from "../../../../../../assets/icons/location.svg";
import { ReactComponent as RemoveIcon } from "../../../../../../assets/icons/close.svg";
import useLocations from "../../../../../../services/Locations/useLocations";
import Services from "../../../../../../constants/services";
import {
  useReservationState as useReservationDispatch,
  useReservationState,
} from "../../../../../../services/Reservation";
import useKeyPress from "../../../../../../utils/useKeyPress";

const normalize = (str) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
const initialState = { selectedIndex: 0 };

const RemoveButton = ({ onClick }) => (
  <div
    onClick={() => onClick("")}
    onKeyPress={() => onClick("")}
    role="button"
    tabIndex={0}
    className={removeButton}
  >
    <RemoveIcon />
  </div>
);

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const DestinationsPicker = ({ onClick }) => {
  const intl = useIntl();
  const {
    state: { locations },
  } = useLocations();
  const { kampaohService, preferredLocation } = useReservationState();
  const dispatch = useReservationDispatch();
  const [value, setValue] = useState(
    preferredLocation ? preferredLocation.location : "",
  );
  const [isFiltered, setIsFiltered] = useState(false);
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");

  const memoLocation = useMemo(
    () =>
      Object.keys(locations)
        .reduce((acc, current) => {
          const currentProperty = locations[current];
          const existingLocation = acc.find(
            ({ region, location }) =>
              region === currentProperty.region.name &&
              location === currentProperty.location,
          );

          if (!existingLocation) {
            acc.push({
              region: currentProperty.region.name,
              location: currentProperty.location,
              properties: [currentProperty.ulysesId],
            });
          } else {
            existingLocation.properties.push(currentProperty.propertyID);
          }
          return acc;
        }, [])
        .sort((a, b) => (a.location < b.location ? -1 : 1)),
    [locations],
  );

  const [filteredLocations, setfilteredLocations] = useState(memoLocation);

  const reducer = (state, action) => {
    switch (action.type) {
      case "arrowUp":
        return {
          selectedIndex:
            state.selectedIndex !== 0
              ? state.selectedIndex - 1
              : filteredLocations.length - 1,
        };
      case "arrowDown":
        return {
          selectedIndex:
            state.selectedIndex !== filteredLocations.length - 1
              ? state.selectedIndex + 1
              : 0,
        };
      case "select":
        return { selectedIndex: action.payload };
      default:
        throw new Error();
    }
  };

  const [stateKeyPress, dispatchKeyPress] = useReducer(reducer, initialState);

  const handleOnSearch = ({ target: { value: _value } }) => {
    if (_value.length > 0) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    setValue(_value);
    setfilteredLocations(() =>
      memoLocation.filter(({ location }) =>
        normalize(location).includes(normalize(_value)),
      ),
    );
  };

  useEffect(() => {
    if (preferredLocation) {
      setfilteredLocations(() =>
        memoLocation.filter(({ location }) =>
          normalize(location).includes(normalize(preferredLocation.location)),
        ),
      );

      setValue(preferredLocation.location);
    }
    setfilteredLocations(memoLocation);
  }, [memoLocation, preferredLocation]);

  useEffect(() => {
    if (arrowUpPressed) {
      dispatchKeyPress({ type: "arrowUp" });
    }
  }, [arrowUpPressed]);

  useEffect(() => {
    if (arrowDownPressed) {
      dispatchKeyPress({ type: "arrowDown" });
    }
  }, [arrowDownPressed]);
  return (
    <>
      <div className={searchInputWrapper}>
        <SearchIcon />
        <Input.Search
          defaultValue={preferredLocation ? preferredLocation.location : ""}
          onChange={handleOnSearch}
          className={inputSearch}
          type="search"
          placeholder={intl.formatMessage({
            id: `#DestinationPicker.${kampaohService}.Input.Placeholder`,
          })}
          suffix={null}
          value={value}
        />
        {value !== "" && (
          <RemoveButton
            onClick={(v) => {
              setValue(v);
              setfilteredLocations(memoLocation);
              dispatch({ type: "setPreferredLocation", payload: undefined });
            }}
          />
        )}
      </div>
      {kampaohService === Services.CAMP && !isFiltered && (
        <div className={resultWrapperAllDestination}>
          <p>
            {intl.formatMessage({
              id: "DestinationPicker.camping.Results.All.Title",
            })}
          </p>
          <div
            className={destinationWrapper}
            onClick={() => onClick(undefined)}
            onKeyPress={() => onClick(undefined)}
            role="button"
            tabIndex={0}
          >
            <div className={iconWrapper}>
              <LocationIcon />
            </div>
            <div className={locationWrapper}>
              <p>
                {intl.formatMessage({
                  id: "DestinationPicker.camping.AllDestinations",
                })}
              </p>
              <p />
            </div>
          </div>
        </div>
      )}
      <div className={resultWrapper}>
        <p>
          {intl.formatMessage({
            id: `#DestinationPicker.${kampaohService}.Results.Title`,
          })}
        </p>
        {filteredLocations.map(
          (
            {
              region,
              location,
              properties,
              propertyName,
              ulysesId,
              campingName,
            },
            i,
          ) => (
            <div
              className={destinationWrapper}
              onClick={() => {
                onClick({
                  region,
                  location,
                  properties,
                  ulysesId,
                });
              }}
              role="button"
              tabIndex={0}
              key={`${region}_${location}_${ulysesId}`}
              style={{
                cursor: "pointer",
                backgroundColor:
                  i === stateKeyPress.selectedIndex ? "#FAF9F9" : "transparent",
              }}
              aria-pressed={i === stateKeyPress.selectedIndex}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  dispatch({ type: "select", payload: i });
                  e.target.blur();
                  onClick({ region, location, properties });
                }
              }}
            >
              <div className={iconWrapper}>
                <LocationIcon />
              </div>
              <div className={locationWrapper}>
                <p>{`${propertyName ? `${propertyName}` : `${location}`}`}</p>
                {propertyName ? <b>{`(${campingName || location}) `}</b> : null}
                <p>
                  {region.name === "Francia" ||
                  region.name === "Portugal" ||
                  region === "Francia" ||
                  region === "Portugal"
                    ? `${region.name || region}`
                    : `${region.name || region}, ${intl.formatMessage({
                        id: "DestinationsPicker.spain",
                      })}`}
                </p>
              </div>
            </div>
          ),
        )}
      </div>
    </>
  );
};

DestinationsPicker.propTypes = {
  onClick: PropTypes.func,
};

DestinationsPicker.defaultProps = {
  onClick: () => {},
};
export default DestinationsPicker;
