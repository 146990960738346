import React, { useMemo } from 'react';
import { useLocation } from '@reach/router';
import useDeviceDetect from '../../utils/useDeviceDetect';
import MobileMenu from '../MobileMenu';
import TopMenu from '../TopMenu';
import ROUTES from '../../routes/routes';

const ResponsiveMenu = React.memo(() => {
  const { isMobile } = useDeviceDetect();
  const location = useLocation();
  const path = location.pathname;
  const mobilePaths = useMemo(
    () => [
      ROUTES.auth,
      ROUTES.me,
      ROUTES.places,
      ROUTES.home,
      ROUTES.meSettings,
    ],
    [],
  );
  const isMobileRoute = useMemo(
    () => mobilePaths.includes(path),
    [path, mobilePaths],
  );
  if (isMobile && isMobileRoute) {
    return <MobileMenu />;
  }
  if (isMobile && !isMobileRoute) {
    return null;
  }
  return <TopMenu />;
});

export default ResponsiveMenu;
