import { gql } from '@apollo/client';

const campingFeatures = gql`
  query GetCampingFeatures($lang: I18NLocaleCode!) {
    features(pagination: { limit: -1 }, locale: $lang) {
      data{
        id,
        attributes{
          name,
          destacado,
          filterable,
          category,
          icon{
            data{
              id,
              attributes{
                name,
                formats,
                hash,
                url,
              }
            }
          },
        }
      }
    }
  }
`;

export default campingFeatures;
