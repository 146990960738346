import React from 'react';
import { PropTypes } from 'prop-types';
import { wrapper } from './index.module.scss';
import { ReactComponent as PetsIcon } from '../../assets/icons/pets_white.svg';

const PetTag = ({ children, className }) => (
  <div className={`${wrapper} ${className}`}>
    <PetsIcon style={{ width: '14px', height: '14px' }} />
    <span>
      {children}
    </span>
  </div>
);

PetTag.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

PetTag.defaultProps = {
  className: '',
};
export default PetTag;
