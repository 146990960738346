const Status = {
  OTHER: 'other',
  CLOSED: 'closed',
  SEARCHING: 'searching',
  CANNOTENTERTHISDAY: 'cannotEnterThisDay',
  CANNOTLEAVETHISDAY: 'cannotLeaveThisDay',
  MINSTAYWARNING: 'minStayWarning',
  MAXSTAYWARNING: 'maxStayWarning',
  AVAILABLE: 'available',
  NOTAVAILABLE: 'notavailable',
  HIDDEN: 'hidden',
};

export default Status;
