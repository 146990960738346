import React, { useEffect, useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { routes } from '../../constants/cms';
import { makeRequestToCmsServer } from '../API';
import initialState from './initialState';
import { LanguageContext } from '../../locale/contexts/Language';
import { getMainLocale } from '../../locale/translations';

const TopBannerState = createContext();
const TopBannerDispatch = createContext();

const TopBannerReducer = (state, action) => {
  switch (action.type) {
    case 'storeBanner':
      return {
        ...state,
        status: action.payload.Status,
        description: action.payload.Description,
      };

    default:
      return { ...state };
  }
};

const TopBannerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TopBannerReducer, initialState);
  const { currentLanguage } = useContext(LanguageContext);
  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const topBannerFromCMS = await makeRequestToCmsServer(
          `${routes.topBanner}?locale=${getMainLocale(currentLanguage)}`,
        );
        dispatch({
          payload: topBannerFromCMS,
          type: 'storeBanner',
        });
      } catch (error) {
        throw new Error(error);
      }
    };
    fetchBanner();
  }, [currentLanguage]);
  return (
    <TopBannerState.Provider value={state}>
      <TopBannerDispatch.Provider value={dispatch}>
        {children}
      </TopBannerDispatch.Provider>
    </TopBannerState.Provider>
  );
};

TopBannerProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useTopBannerState = () => {
  const context = React.useContext(TopBannerState);
  if (context === undefined) {
    throw new Error('Condition context must be used within a provider');
  }
  return context;
};
export default TopBannerProvider;
