import React, { useState, useRef, useMemo } from "react";
import { useNavigate } from "@reach/router";
import { useIntl } from "react-intl";
import ROUTES from "../../routes/routes";
import {
  topMenuWithBanner,
  topMenuSticky,
  headerButton,
  menuIcon,
  kampaohLogo,
} from "./index.module.scss";
import { useUserState } from "../../services/UserDetails/useUserDetails";
import { ReactComponent as MenuIcon } from "../../assets/icons/icon_menu.svg";
import { ReactComponent as KampaohIcon } from "../../assets/icons/kampaoh.svg";
import { ReactComponent as KampaohWithTextIcon } from "../../assets/icons/kampaoh-with-text.svg";
import ProfilePopup from "../ProfilePopup/ProfilePopup";
import useDeviceDetect from "../../utils/useDeviceDetect";
import { useTopBannerState } from "../../services/TopBanner/Provider";
import UserAvatar from "../UserAvatar";

const TopMenu = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { name } = useUserState();
  const [popupOpen, setPopupOpen] = useState(false);
  const buttonRef = useRef(null);
  const { isTablet, isDesktop } = useDeviceDetect();
  const { status } = useTopBannerState();

  const UserMessage = useMemo(() => {
    if (name && name.length > 0)
      return intl.formatMessage({ id: "TopMenu.UserMessage.Text" }, { name });

    return undefined;
  }, [intl, name]);

  return (
    <header className={`${status ? topMenuWithBanner : topMenuSticky}`}>
      {isTablet && <KampaohIcon onClick={() => navigate(ROUTES.home)} />}
      {isDesktop && (
        <KampaohWithTextIcon
          className={kampaohLogo}
          onClick={() => navigate(ROUTES.home)}
        />
      )}
      <div
        ref={buttonRef}
        id="menuBtn"
        className={headerButton}
        onClick={() => setPopupOpen(!popupOpen)}
        onKeyDown={() => {}}
        role="button"
        tabIndex={-1}
      >
        <MenuIcon className={menuIcon} />
        <div>
          <UserAvatar />
          {UserMessage}
        </div>
      </div>
      {popupOpen && (
        <ProfilePopup
          anchorEl={buttonRef.current}
          onClose={() => setPopupOpen(false)}
        />
      )}
    </header>
  );
};

export default TopMenu;
